import Container from 'components/Container';
import Dropdown from 'components/Dropdown';
// import Hamburger from 'components/Hamburger';
import SearchInput from 'components/SearchInput';
import { useApplication } from 'contexts/ApplicationContext';
import { useAuth } from 'contexts/AuthContext';
import useForm from 'hooks/useForm';
import useMedia from 'hooks/useMedia';
import { Box, Col, Row } from 'jsxstyle';
import paths from 'paths';
import queryString from 'query-string';
import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { colors, spacingSizes } from 'theme';
import Button, { WhiteOutlineButton } from './Button';
import Form from './Form';
import Icon from './Icon';
import Logo, { HorizontalLogo, LogoIcon, LogoProps } from './Logo';
import Separator from './Separator';
import Text from './Text';
import UserPicture from './UserPicture';

type HeaderProps = {
  variant: 'platform' | 'admin';
  backgroundColor?: string;
  children?: React.ReactNode | React.ReactNodeArray;
};

function Header({
  variant,
  children,
  backgroundColor = colors.primary500,
}: HeaderProps) {
  return (
    <React.Fragment>
      <Box
        display="flex"
        flexDirection="column"
        height={80}
        backgroundColor={backgroundColor}
        boxShadow="0px 4px 20px rgba(0, 0, 0, 0.1);"
        justifyContent="center"
        //@ts-ignore
        position="fixed"
        left={0}
        right={0}
        zIndex={10}
      >
        {variant === 'platform' && (
          <Container>
            <Row alignItems="center">{children}</Row>
          </Container>
        )}
        {variant === 'admin' && (
          <Row paddingH={spacingSizes.lg} alignItems="center">
            {children}
          </Row>
        )}
      </Box>
    </React.Fragment>
  );
}

function HeaderLogo({
  variant,
  intent,
  height = 40,
}: { variant: 'icon' | 'horizontal' | 'vertical' } & Pick<
  LogoProps,
  'height' | 'intent'
>) {
  const { authenticated } = useAuth();
  return (
    <Link to={authenticated ? paths.Admin.HOME_SCREEN : '/'}>
      {variant === 'icon' && <LogoIcon intent={intent} height={height} />}
      {variant === 'horizontal' && (
        <HorizontalLogo intent={intent} height={height} />
      )}
      {variant === 'vertical' && <Logo intent={intent} height={height} />}
    </Link>
  );
}

function HeaderSearchInput({
  pending,
  onSubmit,
}: {
  pending: boolean;
  onSubmit: (value: string) => void;
}) {
  const location = useLocation();
  const { name: search } = queryString.parse(location.search);

  const { getValue, setValue, submit } = useForm({
    initialValues: {
      search,
    },
    onSubmit: ({ search }) => {
      onSubmit?.(search);
    },
  });

  return (
    <Form onSubmit={submit}>
      <SearchInput
        value={getValue('search')}
        onChange={(value) => setValue('search', value)}
        loading={pending}
        autoFocus
      />
    </Form>
  );
}

function HeaderButtons({
  children,
}: {
  children: React.ReactNode | React.ReactNodeArray;
}) {
  return (
    <Row alignItems="center" flex={1} justifyContent="flex-end">
      {children}
    </Row>
  );
}

function HeaderButtonsLogin({
  variant,
}: {
  variant: 'primary-outline' | 'white-outline';
}) {
  const history = useHistory();

  function handleClick() {
    history.push(paths.Authentication.SIGN_IN);
  }

  return (
    <React.Fragment>
      {
        {
          'primary-outline': (
            <Button variant="outline" color="primary" onClick={handleClick}>
              Login
            </Button>
          ),
          'white-outline': (
            <WhiteOutlineButton onClick={handleClick}>Login</WhiteOutlineButton>
          ),
        }[variant]
      }
    </React.Fragment>
  );
}

function HeaderButtonsFreeTrial() {
  const history = useHistory();
  return (
    <Button
      color="secondary"
      onClick={() => {
        history.push(paths.Authentication.SIGN_IN);
      }}
    >
      Teste grátis
    </Button>
  );
}

function HeaderButtonsUser() {
  const { media } = useMedia();
  const { name, username } = useApplication();
  const { signOut } = useAuth();
  const [visible, setVisible] = React.useState(false);

  const handleLogout = () => {
    signOut();
  };

  return (
    <Dropdown
      on="hover"
      placement="bottomRight"
      visible={visible}
      onVisibleChange={(visible) => setVisible(visible)}
    >
      <Dropdown.Trigger>
        {media === 'desktop' ? (
          <Row
            padding={spacingSizes.md / 2}
            paddingLeft={spacingSizes.md}
            backgroundColor={colors.gray50}
            borderRadius={30}
            minWidth={160}
            alignItems="center"
            justifyContent="flex-end"
          >
            <Col alignItems="flex-end">
              <Text fontWeight="bold" size="sm">
                {name}
              </Text>
              <Text color={colors.gray500} size="xs">
                {username}
              </Text>
            </Col>
            <Separator size="sm" />
            <UserPicture />
          </Row>
        ) : (
          <UserPicture />
        )}
      </Dropdown.Trigger>
      <Dropdown.Menu style={{ boxShadow: '0 2px 20px rgba(0, 0, 0, 0.15)' }}>
        <Box
          minWidth={200}
          padding={spacingSizes.sm}
          transition="all 300ms ease-in-out"
        >
          <Dropdown.Item
            icon={
              <Icon
                name="logout"
                color={colors.gray200}
                width={16}
                height={16}
              />
            }
            onClick={handleLogout}
          >
            <Text>Sair</Text>
          </Dropdown.Item>
        </Box>
      </Dropdown.Menu>
    </Dropdown>
  );
}

Header.Logo = HeaderLogo;
Header.SearchInput = HeaderSearchInput;
HeaderButtons.Login = HeaderButtonsLogin;
HeaderButtons.FreeTrial = HeaderButtonsFreeTrial;
HeaderButtons.User = HeaderButtonsUser;
Header.Buttons = HeaderButtons;
export default Header;
