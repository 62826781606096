import React from 'react';
import { Col } from 'jsxstyle';
import { colors } from 'theme';
import useMedia from 'hooks/useMedia';

export interface ScreenLayoutProps {
  children: React.ReactNode | React.ReactNodeArray;
}

const ScreenLayout = ({ children }: ScreenLayoutProps) => {
  const { media } = useMedia();

  return (
    <Col
      flex={1}
      backgroundColor={colors.gray50}
      justifyContent="flex-end"
      alignItems="center"
    >
      <Col
        // position="relative"
        backgroundColor={colors.white}
        height="90vh"
        width={media === 'phone' ? '90%' : '60%'}
        minWidth={300}
        maxWidth={768}
        borderTopLeftRadius={12}
        borderTopRightRadius={12}
        boxShadow={`0 6px 1rem 0 ${colors.black}4d`}
        zIndex={1}
        overflowY="auto"
        overflowX="hidden"
        WebkitOverflowScrolling="touch"
        //@ts-ignore
        WebkitOverflowY="auto"
        props={{ id: 'container' }}
      >
        {children}
      </Col>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${media === 'desktop' ? '1440' : '440'} 320`}
        style={{ position: 'absolute' }}
      >
        {/* <g fill="grey" transform="translate(0, -24)">
          <path
            fill={colors.primary50}
            d="M0,128L60,133.3C120,139,240,149,360,133.3C480,117,600,75,720,80C840,85,960,139,1080,165.3C1200,192,1320,192,1380,192L1440,192L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
          />
        </g>
        <g fill="grey" transform="translate(0, -12)">
          <path
            fill={colors.primary100}
            d="M0,128L60,133.3C120,139,240,149,360,133.3C480,117,600,75,720,80C840,85,960,139,1080,165.3C1200,192,1320,192,1380,192L1440,192L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
          />
        </g> */}
        <path
          fill={colors.primary500}
          d="M0,128L60,133.3C120,139,240,149,360,133.3C480,117,600,75,720,80C840,85,960,139,1080,165.3C1200,192,1320,192,1380,192L1440,192L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
        />
      </svg>
    </Col>
  );
};

export default ScreenLayout;
