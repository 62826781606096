import React from 'react';
import useSWR from 'swr';
import getProductsService from 'services/getProductsService';
import ProductType from 'types/ProductType';

type ContextType = {
  products: ProductType[];
  pending: boolean;
};

export const ProductsContext = React.createContext<ContextType>({
  products: [],
  pending: true,
});

type AuthContextProviderProps = {
  children: React.ReactNode;
};

export default ({ children }: AuthContextProviderProps) => {
  const { data, error } = useSWR('/products', getProductsService, {
    revalidateOnFocus: false,
  });

  React.useEffect(() => {
    if (data?.length) {
      data.forEach((product) => {
        //CACHE PRODUCT IMAGES
        const image = new Image();
        image.src = `${process.env.REACT_APP_RESOURCES_URL}/banking/giftcash/wedding/items/${product.pictureID}`;
      });
    }
  }, [data]);

  const pending = !data && !error;

  return (
    <ProductsContext.Provider value={{ products: data, pending } as any}>
      {children}
    </ProductsContext.Provider>
  );
};

export const useProducts = () => React.useContext(ProductsContext);
