import React from 'react';
import ReactSwitch from 'react-switch';
import { colors } from 'theme';

export type SwitchProps = {
  onChange?: (value: boolean) => void;
  value?: boolean;
  id?: string;
};

const Switch = ({ value = false, onChange, id }: SwitchProps) => {
  return (
    <ReactSwitch
      id={id}
      onChange={(val) => {
        onChange?.(val);
      }}
      checked={value}
      uncheckedIcon={false}
      checkedIcon={false}
      onHandleColor={colors.secondary500}
      onColor="#fee0e5"
      offColor={colors.gray200}
      handleDiameter={18}
      height={24}
      width={44}
    />
  );
};

export default Switch;
