import { Box, CSSProperties } from 'jsxstyle';
import React from 'react';
import ReactLoading from 'react-loading';
import { colors } from 'theme';
import { SizeTypes } from 'typings';

interface ButtonProps
  extends Omit<
    React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    'style'
  > {
  variant?: 'solid' | 'outline' | 'ghost';
  color?: 'primary' | 'secondary' | 'tertiary' | 'light';
  size?: SizeTypes;
  fullWidth?: boolean;
  loading?: boolean;
  disabled?: boolean;
  minimal?: boolean;
  style?: CSSProperties;
}

function _Button(
  {
    children,
    variant = 'solid',
    color: intent = 'primary',
    size = 'md',
    loading = false,
    disabled = false,
    onClick,
    style,
    ...rest
  }: ButtonProps,
  ref: React.Ref<HTMLButtonElement>,
) {
  return (
    <Box
      component="button"
      props={{ ref, disabled, onClick, ...rest }}
      {...buttonStyles}
      {...sizeStyleMap[size]}
      {...variantIntentStyleMap[variant][intent]}
      {...(disabled ? disabledStyles : {})}
      {...style}
    >
      <Box opacity={loading ? 0 : 1}>{children}</Box>
      {loading && (
        <Box
          position="absolute"
          top={0}
          right={0}
          bottom={0}
          left={0}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <ReactLoading
            type="spin"
            color={intent === 'light' ? colors.gray700 : '#fff'}
            height={20}
            width={20}
          />
        </Box>
      )}
    </Box>
  );
}

const Button = React.forwardRef(_Button) as typeof _Button;
export default Button;

const buttonStyles: CSSProperties & React.CSSProperties = {
  fontFamily: 'Catamaran',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  padding: '1em 2em 0.75em',
  borderRadius: 30,
  border: 0,
  outline: 'none',
  cursor: 'pointer',
  alignItems: 'center',
  justifyContent: 'center',
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
  transition: 'all 125ms ease-in-out',
  fontWeight: 600,
};

const disabledStyles: CSSProperties = {
  backgroundColor: colors.gray500,
  activeBackgroundColor: `${colors.gray500} !important`,
  hoverBackgroundColor: `${colors.gray500} !important`,
  color: colors.gray700,
  border: 'none !important',
  cursor: 'not-allowed',
  opacity: '0.8',
};

const sizeStyleMap = {
  xs: {
    fontSize: 11,
  } as CSSProperties,
  sm: {
    fontSize: 12,
  } as CSSProperties,
  md: {
    fontSize: 14,
  } as CSSProperties,
  lg: {
    fontSize: 16,
  } as CSSProperties,
  xl: {
    fontSize: 18,
  } as CSSProperties,
};

const variantIntentStyleMap: any = {
  solid: {
    primary: {
      color: 'white',
      backgroundColor: colors.primary500,
      hoverBackgroundColor: colors.primary600,
      focusBackgroundColor: colors.primary600,
      activeBackgroundColor: `${colors.primary700} !important`,
    },
    secondary: {
      color: 'white',
      backgroundColor: colors.secondary500,
      hoverBackgroundColor: colors.secondary600,
      focusBackgroundColor: colors.secondary600,
      activeBackgroundColor: `${colors.secondary700} !important`,
    },
    tertiary: {
      color: 'white',
      backgroundColor: colors.tertiary500,
      hoverBackgroundColor: colors.tertiary600,
      focusBackgroundColor: colors.tertiary600,
      activeBackgroundColor: `${colors.tertiary700} !important`,
    },
    undefined: {
      color: colors.black,
      backgroundColor: colors.gray500,
      hoverBackgroundColor: colors.gray600,
      focusBackgroundColor: colors.gray600,
      activeBackgroundColor: `${colors.gray700} !important`,
    },
  },
  outline: {
    primary: {
      color: colors.primary500,
      backgroundColor: 'white',
      hoverBackgroundColor: `${colors.primary50}80`,
      focusBackgroundColor: `${colors.primary50}80`,
      activeBackgroundColor: `${colors.primary100}80 !important`,
      border: `1px solid ${colors.primary500}`,
    },
    secondary: {
      color: colors.secondary500,
      backgroundColor: 'white',
      hoverBackgroundColor: `${colors.secondary50}80`,
      focusBackgroundColor: `${colors.secondary50}80`,
      activeBackgroundColor: `${colors.secondary100}80 !important`,
      border: `1px solid ${colors.secondary500}`,
    },
    tertiary: {},
    undefined: {},
  },
  ghost: {
    primary: {
      color: colors.primary500,
      backgroundColor: 'transparent',
      hoverBackgroundColor: colors.primary50,
      focusBackgroundColor: colors.primary50,
      activeBackgroundColor: `${colors.primary100} !important`,
    },
    secondary: {},
    tertiary: {},
    undefined: {
      color: colors.black,
      backgroundColor: 'transparent',
    },
  },
};

function _WhiteOutlineButton(
  props: ButtonProps,
  ref: React.Ref<HTMLButtonElement>,
) {
  return (
    <Button
      ref={ref}
      {...props}
      variant="outline"
      style={{
        color: 'white',
        backgroundColor: 'transparent',
        hoverBackgroundColor: 'rgba(255, 255, 255, 0.15)',
        focusBackgroundColor: 'rgba(255, 255, 255, 0.15)',
        activeBackgroundColor: 'rgba(255, 255, 255, 0.3) !important',
        border: `1px solid white`,
      }}
    />
  );
}
export const WhiteOutlineButton = React.forwardRef(
  _WhiteOutlineButton,
) as typeof _WhiteOutlineButton;
