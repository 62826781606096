import usePrevious from 'hooks/usePrevious';
import queryString from 'query-string';
import React from 'react';
import { useLocation } from 'react-router-dom';
import BeforeSearch from './BeforeSearch';
import SearchResults from './SearchResults';

export type SearchScreenProps = {};

const SearchScreen = () => {
  const location = useLocation();
  const { name: search } = queryString.parse(location.search);
  const previousSearch = usePrevious<string>(search);
  const hasSearchCriteria = Boolean(search) || Boolean(previousSearch);

  return (
    <React.Fragment>
      {hasSearchCriteria ? <SearchResults /> : <BeforeSearch />}
    </React.Fragment>
  );
};

export default SearchScreen;
