import appleStore from 'assets/images/apple.png';
import googlePlay from 'assets/images/google.png';
import couple from 'assets/images/login-couple.png';
import Button from 'components/Button';
import Container from 'components/Container';
import FadeIn from 'components/FadeIn';
import InBetweenSpacing from 'components/InBetweenSpacing';
import Logo from 'components/Logo';
import Modal from 'components/Modal';
import Separator from 'components/Separator';
import Text from 'components/Text';
import { useApplication } from 'contexts/ApplicationContext';
import { useAuth } from 'contexts/AuthContext';
import useMedia from 'hooks/useMedia';
import { Box, Col, Row } from 'jsxstyle';
import Paths from 'paths';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { colors, mediaQueries, spacingSizes } from 'theme';
import CreateFormButton from './CreateFormButton';
import LoginForm from './LoginForm';

const VISIBLE = 'visible';
const HIDDEN = 'hidden';

const TERMS_AND_CONDITIONS_URL =
  'https://resources.meucompromisso.com/docs/MeuPresente-Termos-de-Uso.pdf';
const PRIVACY_POLICY_URL =
  'https://resources.meucompromisso.com/docs/MeuPresente-Politica-de-Privacidade.pdf';

interface AuthenticationScreenProps extends RouteComponentProps {}

const AuthenticationScreen = ({ history }: AuthenticationScreenProps) => {
  const { media } = useMedia();
  const [modalVisibility, setModalVisibility] = React.useState(HIDDEN);
  const { authenticated } = useAuth();
  const { onbordingIsCompleted } = useApplication();

  const handleSignIn = async () => {
    const authenticatedUser = authenticated;
    if (authenticatedUser) {
      if (onbordingIsCompleted) {
        history.push(Paths.Admin.HOME_SCREEN);
      } else {
        history.push(Paths.Onboarding.GETTING_STARTED_SCREEN);
      }
    } else {
      setModalVisibility(VISIBLE);
    }
  };

  return (
    <FadeIn>
      <React.Fragment>
        <Row flex={1}>
          <Col flexBasis={media === 'desktop' ? '45%' : '100%'}>
            <Container>
              <Box
                display="grid"
                gridTemplateColumns="100%"
                gridTemplateRows="35vh calc(100vh - 45vh) 10vh"
                maxWidth={450}
                margin={`0 auto`}
              >
                <Col justifyContent="center" alignItems="center">
                  <Logo height={104} />
                </Col>
                <Col>
                  <InBetweenSpacing size="xl">
                    <Text textAlign="center">
                      Para criar sua lista de casamento, acesse
                      <br /> com sua conta&nbsp;
                      <b>MeuCompromisso</b>
                    </Text>

                    <Text textAlign="center">
                      O MeuCompromisso é uma conta digital sem taxas onde vai
                      receber o dinheiro dos seus presentes na hora!
                      {/*<i>Criação de listas desabilitada temporariamente. 
                      Estamos construindo um novo site pra você!</i>*/}
                    </Text>
                    <Col>
                      <Button
                        onClick={handleSignIn}
                        color="secondary"
                        id="btn-ja-tenho-uma-conta-meu-compromisso"
                      >
                        Já tenho uma conta MeuCompromisso
                      </Button>
                      <Separator />
                    {/*<CreateFormButton />*/}
                    </Col>
                    <Row
                      mediaQueries={mediaQueries}
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      //@ts-ignore
                      xlFlexDirection="row"
                      lgFlexDirection="row"
                    >
                      <Box
                        component="a"
                        props={{
                          href: process.env.REACT_APP_GOOGLE_PLAY_URL,
                          target: '_href',
                        }}
                      >
                        <Box
                          component="img"
                          props={{ src: googlePlay, height: 50 }}
                        />
                      </Box>
                      <Separator />
                      <Box
                        component="a"
                        props={{
                          href: process.env.REACT_APP_APPLE_STORE_URL,
                          target: '_href',
                        }}
                      >
                        <Box
                          component="img"
                          props={{ src: appleStore, height: 50 }}
                        />
                      </Box>
                    </Row>
                    <Col>
                      <Row
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Box
                          component="a"
                          props={{ target: '_blank', href: PRIVACY_POLICY_URL }}
                        >
                          <Text size="xs">Política de Privacidade</Text>
                        </Box>
                        <Text size="sm">&nbsp;|&nbsp;</Text>
                        <Box
                          component="a"
                          props={{
                            target: '_blank',
                            href: TERMS_AND_CONDITIONS_URL,
                          }}
                        >
                          <Text size="xs">Termos de uso</Text>
                        </Box>
                      </Row>
                      <Separator size="sm" />
                      <Box textAlign="center">
                        <Text textAlign="center" size="xs" fontWeight="bolder">
                          MEU COMPROMISSO SOLUÇÕES DE PAGAMENTO LTDA.
                          36.990.228/0001-13
                        </Text>
                        <br />
                        <Text textAlign="center" size="xs">
                          @2020 TODOS OS DIREITOS RESERVADOS
                        </Text>
                      </Box>
                    </Col>
                  </InBetweenSpacing>
                </Col>
              </Box>
            </Container>
          </Col>
          {media === 'desktop' && (
            <Col
              flexBasis="55%"
              justifyContent="flex-end"
              backgroundImage={`url(${couple})`}
              backgroundRepeat="no-repeat"
              backgroundSize="cover"
              paddingBottom={spacingSizes.xl}
            >
              <Text size="lg" color={colors.white} textAlign="center">
                Realize o seu <b>casamento perfeito</b>
                <br /> de forma fácil e rápida!
              </Text>
            </Col>
          )}
        </Row>
        <Modal
          backdropColor={`${colors.primary500}bf`}
          visible={modalVisibility === VISIBLE}
          onRequestClose={() => setModalVisibility(HIDDEN)}
        >
          <LoginForm />
        </Modal>
      </React.Fragment>
    </FadeIn>
  );
};

export default AuthenticationScreen;
