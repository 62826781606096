import Container from 'components/Container';
import Divider from 'components/Divider';
import Heading from 'components/Heading';
import { BlackHorizontalLogo } from 'components/Logo';
import Separator from 'components/Separator';
import Text from 'components/Text';
import { Box, Col, Row } from 'jsxstyle';
import React from 'react';
//@ts-ignore
import Flip from 'react-reveal/Flip';
//@ts-ignore
import Tada from 'react-reveal/Tada';
//@ts-ignore
import Zoom from 'react-reveal/Zoom';
import { colors, mediaQueries, spacingSizes } from 'theme';
import AppStore from './AppStore';
import GooglePlay from './GooglePlay';

const TERMS_AND_CONDITIONS_URL =
  'https://resources.meucompromisso.com/docs/MeuPresente-Termos-de-Uso.pdf';
const PRIVACY_POLICY_URL =
  'https://resources.meucompromisso.com/docs/MeuPresente-Politica-de-Privacidade.pdf';

const INSTAGRAM_URL = 'https://www.instagram.com/meucompromissoapp';
const FACEBOOK_URL = 'https://www.facebook.com/meucompromissoapp';

const Footer = () => {
  return (
    <Col component="footer" flex={1}>
      <Box
        mediaQueries={mediaQueries}
        // width="100%"
        // height="100%"
        //@ts-ignore
        xlWidth={null}
        lgWidth={null}
        xlHeight={null}
        lgHeight={null}
        marginBottom={-10}
        xlMarginBottom={-60}
        lgMarginBottom={-40}
        backgroundColor={colors.gray50}
      >
        <svg
          viewBox="0 0 1440 210"
          fill="white"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0 0L40 16.0811C80 32.1622 160 64.3243 240 64.3243C320 64.3243 400 32.1622 480 32.1622C560 32.1622 640 64.3243 720 69.6512C800 75.3801 880 53.2686 960 64.3243C1040 75.3801 1120 117.593 1200 133.976C1280 149.755 1360 139.704 1400 133.976L1440 128.649V210H1400C1360 210 1280 210 1200 210C1120 210 1040 210 960 210C880 210 800 210 720 210C640 210 560 210 480 210C400 210 320 210 240 210C160 210 80 210 40 210H0V0Z" />
        </svg>
      </Box>
      <Col
        backgroundColor="white"
        paddingBottom={spacingSizes.md}
        alignItems="center"
        marginLeft={2}
        marginRight={1}
      >
        <Container>
          <Col justifyContent="center" alignItems="center" flex={1}>
            <Box maxWidth={750}>
              <Zoom>
                <Heading size="sm" textAlign="center">
                  Baixe o app do MeuCompromisso e comece agora a<br /> lista do
                  seu casamento!
                </Heading>
              </Zoom>
            </Box>
            <Separator size="lg" />
            <Box
              mediaQueries={mediaQueries}
              display="flex"
              flexDirection="column"
              //@ts-ignore
              lgFlexDirection="row"
              xlFlexDirection="row"
            >
              <Flip left delay={250}>
                <AppStore />
              </Flip>
              <Separator />
              <Flip right delay={400}>
                <GooglePlay />
              </Flip>
            </Box>
            <Separator size="xl" />
            <Divider />
            <Separator size="xl" />
            <Tada>
              <BlackHorizontalLogo height={32} />
            </Tada>
            <Separator />
            <Box display="flex" alignItems="center">
              <Box
                component="a"
                props={{ target: '_blank', href: PRIVACY_POLICY_URL }}
              >
                <Text size="xs">Política de Privacidade</Text>
              </Box>
              <Text size="sm">&nbsp;|&nbsp;</Text>
              <Box
                component="a"
                props={{
                  target: '_blank',
                  href: TERMS_AND_CONDITIONS_URL,
                }}
              >
                <Text size="xs">Termos de uso</Text>
              </Box>
            </Box>
          </Col>
        </Container>
        <Separator />
        <Box flex={1} />
        <Container>
          <Box textAlign="center">
            <Text textAlign="center" size="xs" fontWeight="bolder">
              MEU COMPROMISSO SOLUÇÕES DE PAGAMENTO LTDA. 36.990.228/0001-13
            </Text>
            <br />
            <Text textAlign="center" size="xs">
              @2020 TODOS OS DIREITOS RESERVADOS
            </Text>
          </Box>
          <Separator />
          <Row justifyContent="center" alignItems="center">
            <a href={INSTAGRAM_URL} target="_blank" rel="noopener noreferrer">
              <Instagram />
            </a>
            <Separator />
            <a href={FACEBOOK_URL} target="_blank" rel="noopener noreferrer">
              <Facebook />
            </a>
          </Row>
        </Container>
      </Col>
    </Col>
  );
};

export default Footer;

function Instagram({ width = 26, height = 26, color = colors.black }) {
  return (
    <svg width={width} height={height} fill={color}>
      <path d="M22.191 25.999H3.808A3.813 3.813 0 0 1 0 22.19V3.808c0-2.1 1.708-3.809 3.808-3.809h18.383c2.1 0 3.809 1.709 3.809 3.809V22.19c0 2.1-1.709 3.809-3.809 3.809zm2.285-22.191a2.287 2.287 0 0 0-2.285-2.285H3.808a2.287 2.287 0 0 0-2.285 2.285V22.19a2.288 2.288 0 0 0 2.285 2.286h18.383a2.288 2.288 0 0 0 2.285-2.286V3.808zm-3.859 3.859a2.288 2.288 0 0 1-2.285-2.285 2.288 2.288 0 0 1 2.285-2.285 2.288 2.288 0 0 1 2.285 2.285 2.288 2.288 0 0 1-2.285 2.285zm0-3.047a.763.763 0 0 0 0 1.524.763.763 0 0 0 0-1.524zM13 19.855a6.864 6.864 0 0 1-6.856-6.856c0-3.78 3.076-6.855 6.856-6.855a6.863 6.863 0 0 1 6.855 6.855c0 3.78-3.075 6.856-6.855 6.856zm0-12.188a5.338 5.338 0 0 0-5.332 5.332A5.338 5.338 0 0 0 13 18.331a5.338 5.338 0 0 0 5.332-5.332A5.338 5.338 0 0 0 13 7.667z" />
    </svg>
  );
}

function Facebook({ width = 26, height = 26, color = colors.black }) {
  return (
    <svg width={width} height={height} fill={color}>
      <path d="M3.808 25.999h8.481v-9.242H9.242V13.71h3.047V9.901c0-2.1 1.708-3.808 3.808-3.808h3.809V9.14h-3.047c-.84 0-1.523.683-1.523 1.523v3.047h4.433l-.508 3.047h-3.925v9.242h6.855c2.1 0 3.809-1.709 3.809-3.809V3.808c0-2.1-1.709-3.809-3.809-3.809H3.808A3.813 3.813 0 0 0 0 3.808V22.19c0 2.1 1.708 3.809 3.808 3.809zM1.523 3.808a2.287 2.287 0 0 1 2.285-2.285h18.383a2.287 2.287 0 0 1 2.285 2.285V22.19a2.288 2.288 0 0 1-2.285 2.286h-5.332V18.28h3.692l1.016-6.093h-4.708v-1.524h4.571V4.569h-5.333a5.337 5.337 0 0 0-5.331 5.332v2.286H7.719v6.093h3.047v6.196H3.808a2.288 2.288 0 0 1-2.285-2.286V3.808z" />
    </svg>
  );
}
