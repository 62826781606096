import React from 'react';
import toaster from 'toasted-notes';
import ToastMessage, { ToastMessageProps } from 'components/ToastMessage';
import Icon from 'components/Icon';
import { colors } from 'theme';

type ToastProps = {
  message: string;
  showsIcon?: boolean;
};

const useToast = () => {
  const notify = ({
    message,
    showsIcon = true,
    type,
  }: ToastProps & { type: ToastMessageProps['type'] }) => {
    return toaster.notify(({ onClose }) => (
      <ToastMessage
        icon={
          showsIcon ? (
            <Icon
              name="info"
              color={
                {
                  default: colors.black,
                  danger: colors.red500,
                  info: colors.blue500,
                  warning: colors.orange500,
                  success: colors.green500,
                }[type || 'default']
              }
            />
          ) : undefined
        }
        onClick={onClose}
        message={message}
        type={type}
      />
    ));
  };
  const defaultMessage = ({ message, showsIcon = true }: ToastProps) => {
    return notify({ message, showsIcon, type: 'default' });
  };
  const dangerMessage = ({ message, showsIcon = true }: ToastProps) => {
    return notify({ message, showsIcon, type: 'danger' });
  };
  const infoMessage = ({ message, showsIcon = true }: ToastProps) => {
    return notify({ message, showsIcon, type: 'info' });
  };
  const warningMessage = ({ message, showsIcon = true }: ToastProps) => {
    return notify({ message, showsIcon, type: 'warning' });
  };
  const successMessage = ({ message, showsIcon = true }: ToastProps) => {
    return notify({ message, showsIcon, type: 'success' });
  };

  return {
    defaultMessage,
    dangerMessage,
    infoMessage,
    warningMessage,
    successMessage,
  };
};

export default useToast;
