import CheckoutPersonalDataScreen from 'screens/platform/CheckoutPersonalDataScreen';
import CheckoutReviewScreen from 'screens/platform/CheckoutReviewScreen';
import HomeScreen from 'screens/platform/HomeScreen';
import PaymentConfirmationScreen from 'screens/platform/PaymentConfirmationScreen';
import PurchaseDetailScreen from 'screens/platform/PurchaseDetailScreen';
import RSVPScreen from 'screens/platform/RSVPScreen';
import SearchScreen from 'screens/platform/SearchScreen';
import Paths from '../../paths';

const paths = Paths.Platform;

export default [
  {
    path: '/',
    component: HomeScreen,
  },
  {
    path: paths.SEARCH_SCREEN,
    component: SearchScreen,
  },
  {
    path: paths.CHECKOUT_SCREEN,
    component: CheckoutReviewScreen,
  },
  {
    path: paths.PERSONAL_DATA_SCREEN,
    component: CheckoutPersonalDataScreen,
  },
  {
    path: paths.PAYMENT_CONFIRMATION_SCREEN,
    component: PaymentConfirmationScreen,
  },
  {
    path: paths.RSVP_SCREEN,
    component: RSVPScreen,
  },
  {
    path: paths.PURCHASE_DETAIL_SCREEN,
    component: PurchaseDetailScreen,
  },
];
