import React from 'react';
import { Col } from 'jsxstyle';
import MCLogoBlock from 'components/MCLogoBlock';
import InBetweenSpacing from 'components/InBetweenSpacing';

import { colors } from 'theme';
import Spinner from 'components/Spinner';

function LoadingScreen() {
  return (
    <Col
      flex={1}
      justifyContent="center"
      alignItems="center"
      backgroundColor={colors.secondary500}
    >
      <InBetweenSpacing>
        <MCLogoBlock intent="secondary" height={72} />
        <Spinner />
      </InBetweenSpacing>
    </Col>
  );
}

export default LoadingScreen;
