import React from 'react';
import isEqual from 'lodash/isEqual';

export default <T>(value: any) => {
  const [state, setState] = React.useState<T>();

  React.useEffect(() => {
    if (!value) {
      return;
    }
    if (isEqual(value, state)) {
      return;
    }
    setState(value);
  }, [value, state]);

  return state;
};
