import React from 'react';
import { Box } from 'jsxstyle';

type FormProps = {
  children: React.ReactNodeArray | React.ReactNode;
  onSubmit?: () => void;
};
const Form = ({ children, onSubmit }: FormProps) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignSelf="stretch"
      flexGrow={1}
      props={{
        onSubmit: (event: any) => {
          event.preventDefault();
          if (onSubmit) {
            onSubmit();
          }
        },
      }}
      component="form"
    >
      {children}
    </Box>
  );
};

export default Form;
