import { FontWeightProperty, TextAlignProperty } from 'csstype';
import useMedia from 'hooks/useMedia';
import { Box } from 'jsxstyle';
import React from 'react';
import { colors } from 'theme';
import { SizeTypes, TextTransformTypes } from 'typings';

const getComponentBySize = (size: SizeTypes) =>
  ({
    xs: 'h5',
    sm: 'h4',
    md: 'h3',
    lg: 'h2',
    xl: 'h1',
  }[size] as keyof JSX.IntrinsicElements);

type HeadingProps = {
  size?: SizeTypes;
  children: React.ReactNode;
  color?: string;
  fontWeight?: FontWeightProperty;
  textAlign?: TextAlignProperty;
  textTransform?: TextTransformTypes;
};

const Heading = ({
  children,
  size = 'md',
  color = colors.black,
  fontWeight = 'bold',
  textAlign = 'start',
  textTransform,
}: HeadingProps) => {
  const { media } = useMedia();

  const getFontSizeBySize = (size: SizeTypes) =>
    ({
      xs: {
        desktop: '1.25em',
        tablet: '1.1em',
        phone: '1em',
      }[media],
      sm: {
        desktop: '1.5em',
        tablet: '1.4em',
        phone: '1.3em',
      }[media],
      md: {
        desktop: '1.75em',
        tablet: '1.65em',
        phone: '1.5em',
      }[media],
      lg: {
        desktop: '2em',
        tablet: '1.9em',
        phone: '1.8em',
      }[media],
      xl: {
        desktop: '2.35em',
        tablet: '2em',
        phone: '1.7em',
      }[media],
    }[size]);

  return (
    <Box
      fontFamily="Catamaran"
      component={getComponentBySize(size)}
      color={color}
      fontWeight={fontWeight}
      fontSize={getFontSizeBySize(size)}
      textAlign={textAlign}
      textTransform={textTransform}
    >
      {children}
    </Box>
  );
};

export default Heading;
