import PurchaseDetailType, {
  PurchaseDetailRawResponseType,
} from 'types/PurchaseDetailType';
import publicApi from '../publicApi';

type Params = {
  purchaseID: string;
};
export default async ({ purchaseID }: Params): Promise<PurchaseDetailType> => {
  try {
    const { data } = await publicApi.get(`/giftcashes/purchases/${purchaseID}`);
    const rawPurchase = data as PurchaseDetailRawResponseType;

    const normalizedFee = rawPurchase.billetFee || rawPurchase.pixFee || 0;
    var normalizedInstallments = [];
    switch (rawPurchase.paymentMethod) {
      case 'PIX':
        normalizedInstallments = rawPurchase.pix ? [rawPurchase.pix] : [];
        break;
      case 'PIX_INSTALLMENT':
        normalizedInstallments = rawPurchase.pixInstallments || [];
        break;
      case 'BILLET_INSTALLMENT':
        normalizedInstallments = rawPurchase.billetInstallments || [];
        break;
      case 'BILLET':
        const {
          billetID,
          billetBarcode,
          billetDigitableLine,
          billetFee,
          amount,
        } = rawPurchase;
        const billet = {
          billetID,
          billetBarcode,
          billetDigitableLine,
          billetFee,
          amount,
        };
        normalizedInstallments = [billet];
        break;
    }

    const purchase = {
      ...rawPurchase,
      installments: normalizedInstallments,
      fee: normalizedFee,
    } as PurchaseDetailType;

    return purchase;
  } catch (error) {
    throw error;
  }
};
