import React from 'react';
import { TextAlignProperty } from 'csstype';
//@ts-ignore
import InputNumber from 'rc-input-number';
import 'rc-input-number/assets/index.css';
import { Box, Col, CSSProperties } from 'jsxstyle';
import { colors, spacingSizes } from 'theme';
import { SizeTypes } from 'typings';
import './NumberInput.css';

type InputSizeType = Exclude<SizeTypes, 'xs' | 'xl'>;

type NumberInputProps = {
  placeholder?: string;
  value?: string;
  error?: string | boolean;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  textAlign?: TextAlignProperty;
  size?: InputSizeType;
  min?: number;
  max?: number;
  onChange?: (value: string) => void;
};

const getFontSizeBySize = (size: InputSizeType) =>
  ({
    sm: 16,
    md: 22,
    lg: 28,
  }[size]);

const NumberInput = React.forwardRef(
  (
    {
      placeholder,
      onChange,
      error,
      leftIcon,
      rightIcon,
      size = 'md',
      value,
      min,
      max,
    }: NumberInputProps,
    ref,
  ) => (
    <Col position="relative" justifyContent="center" alignSelf="stretch">
      {leftIcon && (
        <Box position="absolute" left={12}>
          {leftIcon}
        </Box>
      )}
      <InputNumber
        //@ts-ignore
        style={
          {
            border: 'none',
            borderBottom: '1px solid #dbdbdb',
            height: 132,
            paddingLeft: leftIcon ? spacingSizes.lg : spacingSizes.xs,
            paddingRight: rightIcon ? spacingSizes.lg : spacingSizes.xs,
            outline: 'none',
            background: 'transparent',
            width: '100% !important',
            fontSize: getFontSizeBySize(size),
            borderColor: error && colors.red500,
            borderRadius: 0,
            transition: 'all 0.2s ease',
          } as CSSProperties
        }
        type="tel"
        placeholder={placeholder}
        onChange={(value: string) => onChange!(value)}
        value={value}
        //@ts-ignore
        min={min}
        //@ts-ignore
        max={max}
        upHandler={<></>}
        downHandler={<></>}
      />

      {rightIcon && (
        <Box position="absolute" right={12}>
          {rightIcon}
        </Box>
      )}
    </Col>
  ),
);

export default NumberInput;
