import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import ScreenLayout from 'components/ScreenLayout';
import { Box, Col } from 'jsxstyle';
import margaret from 'assets/images/margaret.png';
import useMedia from 'hooks/useMedia';
import { spacingSizes, colors } from 'theme';
import Text from 'components/Text';
import Separator from 'components/Separator';
import Button from 'components/Button';
import FadeIn from 'components/FadeIn';
import Paths from 'paths';
import { useApplication } from 'contexts/ApplicationContext';
import Logo from 'components/Logo';

interface GettinStartedScreenProps extends RouteComponentProps {}

const GettingStartedScreen = ({ history }: GettinStartedScreenProps) => {
  const { media } = useMedia();
  const imageAspectRatio = 0.91;
  const imageSize = { phone: 198, tablet: 252, desktop: 252 }[media];
  const { account } = useApplication();

  console.log({ account });

  return (
    <FadeIn>
      <ScreenLayout>
        <Col
          padding={spacingSizes.md}
          flex={1}
          justifyContent="center"
          alignItems="center"
        >
          <Col maxWidth={398} justifyContent="center" alignItems="center">
          <Text textAlign="center">
            <i> Criação de listas desabilitada temporariamente. 
                Estamos construindo um novo site pra você!</i>
          </Text>
            {/*<Box
              component="img"
              props={{
                src: margaret,
                width: imageSize * imageAspectRatio,
                height: imageSize,
              }}
            />
            <Separator />
            <Text size="lg">Bem-vindo ao</Text>
            <Separator />
            <Logo height={94} />
            <Separator />
            <Text textAlign="center">
              Nossa assistente margaret estará te acompanhando na criação da
              lista de casamento. Vamos começar?
            </Text>
            <Separator size="xl" />
            <Button
              color="secondary"
              style={{ width: '100%' }}
              onClick={() => history.push(Paths.Onboarding.CHAT_SCREEN)}
            >
              <Text
                color={colors.white}
                textTransform="uppercase"
                fontWeight="bold"
              >
                Vamos lá
              </Text>
            </Button>*/}
          </Col>
        </Col>
      </ScreenLayout>
    </FadeIn>
  );
};

export default GettingStartedScreen;
