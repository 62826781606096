import React from 'react';
import { Col } from 'jsxstyle';
import { spacingSizes, colors } from 'theme';
import useMedia from 'hooks/useMedia';
import Text from 'components/Text';
import Separator from 'components/Separator';
import Button from 'components/Button';
import useForm from 'hooks/useForm';
import InputGroup from 'components/InputGroup';
import isRequired from 'validators/isRequired';
import Form from 'components/Form';
import TextArea from 'components/TextArea';

type WeddingPhraseFormProps = {
  onSubmit: (phrase: string) => void;
};

const WeddingPhraseForm = ({ onSubmit }: WeddingPhraseFormProps) => {
  const { media } = useMedia();
  const { getValue, setValue, getError, submit } = useForm({
    validations: {
      phrase: [isRequired],
    },
    onSubmit: (values) => onSubmit(values.phrase),
  });

  return (
    <Col
      width={media === 'desktop' ? 498 : '85%'}
      alignSelf="center"
      paddingTop={spacingSizes.xl}
    >
      <Text textAlign="center" size="lg">
        Deixe uma mensagem para seus convidados
      </Text>
      <Separator />
      <Text color={colors.gray400} size="sm" textAlign="center">
        Essa mensagem será vista quando eles acessarem seu site.
      </Text>
      <Separator size="xl" />
      <Form>
        <InputGroup error={getError('phrase')}>
          <TextArea
            value={getValue('phrase')}
            onChange={(text) => {
              setValue('phrase', text);
            }}
            placeholder="Ex: Casal alto astral, Casal sem grana..."
            textAlign="center"
            error={getError('phrase')}
          />
        </InputGroup>
        <Separator size="xl" />
        <Button type="submit" color="secondary" onClick={() => submit()}>
          <Text
            color={colors.white}
            fontWeight="bold"
            textTransform="uppercase"
          >
            Enviar
          </Text>
        </Button>
      </Form>
      <Separator size="xl" />
    </Col>
  );
};

export default WeddingPhraseForm;
