import React from 'react';
import { Col, Box, Row } from 'jsxstyle';
import Heading from 'components/Heading';
import { colors, spacingSizes, mediaQueries } from 'theme';
import couple from 'assets/images/couple.jpg';
import Logo from 'components/Logo';
import CoupleThumbImage from 'components/CoupleThumbImage';
import { useWedding } from 'contexts/WeddingContext';
import Separator from 'components/Separator';
import Text from 'components/Text';
import AnimatedModal from 'components/AnimatedModal';
import useMedia from 'hooks/useMedia';
import SwipeableViews from 'react-swipeable-views';
import NameOnInvitationForm from './NameOnInvitationForm';
import AdultCountForm from './AdultCountForm';
import KidCountForm from './KidCountForm';
import ContactForm from './ContactForm';
import Success from './Success';
import useForm from 'hooks/useForm';
import useAsync from 'hooks/useAsync';
import sendGuestConfirmation from 'services/platform/sendGuestConfirmation';
import useToast from 'hooks/useToast';
import handleError from 'utils/handleError';
import Button from 'components/Button';

export type RSVPScreenProps = {};

const NAME_ON_INVITE_FORM_STEP = 0;
const ADULT_COUNT_FORM_STEP = 1;
const KID_COUNT_FORM_STEP = 2;
const CONTACT_FORM_STEP = 3;
const SUCCESS_STEP = 4;

const RSVPScreen = () => {
  const { wedding } = useWedding();
  const [modalVisibility, setModalVisibility] = React.useState<VisibilityState>(
    'hidden',
  );
  const { media } = useMedia();
  const [currentStep, setCurrentStep] = React.useState(
    NAME_ON_INVITE_FORM_STEP,
  );
  const { dangerMessage } = useToast();

  const { fetch, pending } = useAsync({
    promiseFn: sendGuestConfirmation,
    onData: () => setCurrentStep(SUCCESS_STEP),
    onError: (error) => {
      const handledError = handleError({ error });
      dangerMessage({ message: handledError.message });
    },
  });
  const { setValue, submit } = useForm({
    onSubmit: (values) => {
      fetch({
        weddingID: wedding.giftCashID,
        guestName: values.guestName,
        guestEmail: values.guestEmail,
        guestPhone: values.guestPhone,
        attend: true,
        adultsQuantity: values.adultsQuantity,
        childrenQuantity: values.childrenQuantity,
      });
    },
  });

  return (
    <Col
      flex={1}
      backgroundColor={colors.primary500}
      position="relative"
      //@ts-ignore
      beforeContent="''"
      beforePosition="absolute"
      beforeTop={0}
      beforeRight={0}
      beforeBottom={0}
      beforeLeft={0}
      beforeBackground={`linear-gradient(to top, #35389B 20%, #00000000 80%), url(${couple}) no-repeat`}
      beforeBackgroundSize="cover"
      beforeBackgroundPosition="center center"
      beforeOpacity={0.2}
    >
      <Col
        flex={1}
        position="relative"
        zIndex={2}
        justifyContent="center"
        alignItems="center"
      >
        <Box position="absolute" top={48}>
          <Logo height={64} intent="secondary" />
        </Box>
        <Box borderRadius={100} padding={4} backgroundColor={colors.white}>
          <CoupleThumbImage
            size="xl"
            giftCashID={wedding ? wedding.giftCashID : ''}
          />
        </Box>
        <Separator />
        <Heading color={colors.white}>Ta chegando a hora!</Heading>
        <Separator />
        {Boolean(wedding?.personName1) && Boolean(wedding?.personName2) && (
          <Text textAlign="center" color={colors.white}>
            Confirme presença e compre seu presente para {wedding.personName1} e{' '}
            {wedding.personName2}.
          </Text>
        )}

        <Separator size="xl" />

        <Button
          style={{ width: 240 }}
          onClick={() => setModalVisibility('visible')}
          color="secondary"
        >
          Começar agora
        </Button>

        {/* <Box
          component="button"
          props={{ onClick: () => setModalVisibility('visible') }}
          backgroundColor={colors.white}
          fontWeight="bold"
          textTransform="uppercase"
          fontSize={14}
          padding="15px 100px"
          border="1px solid transparent"
          borderRadius={30}
          cursor="pointer"
          outline="none"
          transition="all 200ms ease-in-out"
          focusBoxShadow={`0 0 0 0.25rem ${colors.white}33`}
          //@ts-ignore
          hoverTransform="scale(1.01)"
        >
          Começar agora
        </Box> */}
      </Col>
      <AnimatedModal
        visible={modalVisibility === 'visible'}
        onRequestClose={() => setModalVisibility('hidden')}
        backdropColor={`${colors.black}80`}
        width={media === 'desktop' ? 990 : 'auto'}
      >
        <Col
          mediaQueries={mediaQueries}
          borderTopLeftRadius={10}
          borderTopRightRadius={10}
          backgroundColor={colors.white}
          height="80vh"
          props={{ id: 'animated-modal-container' }}
          //@ts-ignore
          xlHeight="75vh"
          lgHeight="75vh"
        >
          {currentStep !== SUCCESS_STEP && (
            <Row
              alignItems="center"
              justifyContent="center"
              paddingTop={spacingSizes.lg}
            >
              {Array.from({ length: 4 }).map((_, index) => (
                <React.Fragment>
                  <Box
                    width={14}
                    height={14}
                    borderRadius={16}
                    border="1px solid"
                    backgroundColor={
                      currentStep >= index ? colors.secondary500 : 'transparent'
                    }
                    borderColor={
                      currentStep >= index
                        ? colors.secondary500
                        : colors.gray100
                    }
                  />
                  <Separator size="sm" />
                </React.Fragment>
              ))}
            </Row>
          )}
          <SwipeableViews
            style={{ flex: 1, display: 'flex', flexDirection: 'column' }}
            index={currentStep}
            containerStyle={{ flex: 1 }}
            slideStyle={{ display: 'flex' }}
          >
            <NameOnInvitationForm
              onSubmit={(values) => {
                setValue('guestName', values.name);
                setCurrentStep(ADULT_COUNT_FORM_STEP);
              }}
            />
            <AdultCountForm
              onSubmit={(values) => {
                setValue('adultsQuantity', Number(values.count));
                setCurrentStep(KID_COUNT_FORM_STEP);
              }}
            />
            <KidCountForm
              onSubmit={(values) => {
                setValue('childrenQuantity', Number(values.count));
                setCurrentStep(CONTACT_FORM_STEP);
              }}
            />
            <ContactForm
              pending={pending}
              onSubmit={(values) => {
                submit({ guestPhone: values.phone, guestEmail: values.email });
              }}
            />
            <Success />
          </SwipeableViews>
        </Col>
      </AnimatedModal>
    </Col>
  );
};

export default RSVPScreen;
