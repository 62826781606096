import React from 'react';
import ReactDOM from 'react-dom';

import { Box } from 'jsxstyle';
import { animated, useSpring } from 'react-spring';

type AnimatedModalProps = {
  visible: boolean;
  onRequestClose: () => void;
  duration?: number;
  children: React.ReactElement;
  width: number | string;
  backdropColor?: string;
};
function AnimatedModal({
  visible,
  onRequestClose,
  duration = 175,
  children,
  width,
  backdropColor = 'rgba(0, 0, 0, 0.5)',
}: AnimatedModalProps) {
  let ref = React.useRef<HTMLDivElement>(null);
  const [height, setHeight] = React.useState(0);

  const { translate } = useSpring({
    from: { translate: 0 },
    translate: visible ? 1 : 0,
    config: {
      duration,
    },
  });

  React.useEffect(() => {
    if (visible) {
      window.addEventListener('keydown', handleKeyPress);
    } else {
      window.removeEventListener('keydown', handleKeyPress);
    }

    return () => window.removeEventListener('keydown', handleKeyPress);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  React.useLayoutEffect(() => {
    if (ref.current) {
      setHeight(ref.current.clientHeight);
    }
  }, [visible]);

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      onRequestClose();
    }
  };

  return ReactDOM.createPortal(
    <React.Fragment>
      <animated.div
        style={{
          width,
          height,
          position: 'fixed',
          zIndex: 999,
          bottom: 0,
          left: 0,
          right: 0,
          margin: '0 auto',
          transform: translate
            .interpolate({
              range: [0, 1],
              output: [height, 0],
            })
            .interpolate((y) => `translateY(${y}px)`),
        }}
      >
        <Box props={{ ref }}>
          {React.cloneElement(children, {
            style: { height: '100% !important' },
          })}
        </Box>
      </animated.div>

      <Box
        props={{ onClick: onRequestClose }}
        position="fixed"
        top={0}
        width="100wh"
        height="100vh"
        bottom={0}
        left={0}
        right={0}
        zIndex={visible ? 998 : -1}
        transition="all 0.3s linear"
        backgroundColor={visible ? backdropColor : 'transparent'}
      />
    </React.Fragment>,
    document.body,
  );
}

export default AnimatedModal;
