import React from 'react';
import { Col, Box } from 'jsxstyle';
import Heading from 'components/Heading';
import Separator from 'components/Separator';
import InputGroup from 'components/InputGroup';
import TextInput from 'components/TextInput';
import { colors, mediaQueries, spacingSizes } from 'theme';
import useForm from 'hooks/useForm';
import Button from 'components/Button';
import isRequired from 'validators/isRequired';
import Form from 'components/Form';
import isEmail from 'validators/isEmail';
import isValidPhone from 'validators/isValidPhone';

export type ContactFormProps = {
  onSubmit?: (values: { phone: string; email: string }) => void;
  pending?: boolean;
};

const phoneMask = (value: string) => {
  return {
    raw: value.replace(/\D/g, ''),
    text: value
      .replace(/\D/g, '')
      .replace(/^(\d{2})(\d)/g, '($1) $2')
      .replace(/(\d)(\d{4})$/, '$1-$2'),
  };
};

const ContactForm = ({ onSubmit, pending }: ContactFormProps) => {
  const { getValue, setValue, getError, submit } = useForm({
    validations: {
      phone: [
        isRequired,
        (value: { raw: string; text: string }) => isValidPhone(value.text),
      ],
      email: [isEmail],
    },
    onSubmit: (values) =>
      onSubmit?.({ email: values.email, phone: values.phone.raw }),
  });
  return (
    <Col
      flex={1}
      mediaQueries={mediaQueries}
      padding={`80px ${spacingSizes.md}px 20px ${spacingSizes.md}px`}
      //@ts-ignore
      lgPadding="116px 160px 64px 160px"
      xlPadding="116px 160px 64px 160px"
    >
      <Heading textAlign="center">
        Como os noivos podem entrar em contato?
      </Heading>
      <Separator size="xl" />
      <Form onSubmit={submit}>
        <Box>
          <InputGroup error={getError('phone')}>
            <TextInput
              placeholder="(00) 00000-0000"
              textAlign="center"
              value={getValue('phone.text')}
              onChange={(text) => setValue('phone', phoneMask(text))}
              error={getError('phone')}
              leftIcon={<PhoneIcon />}
              maxLength={15}
            />
          </InputGroup>
          <Separator />
          <InputGroup error={getError('email')}>
            <TextInput
              placeholder="joão@exemplo.com"
              textAlign="center"
              value={getValue('email')}
              onChange={(text) => setValue('email', text)}
              error={getError('email')}
              leftIcon={<MailIcon />}
            />
          </InputGroup>
        </Box>
        <Box display="flex" flex={1} />
        <Box
          mediaQueries={mediaQueries}
          width="80%"
          display="flex"
          alignSelf="center"
          //@ts-ignore
          xlWidth={368}
          lgWidth={368}
        >
          <Button
            loading={pending}
            type="submit"
            color="secondary"
            onClick={submit}
            style={{ width: '100%' }}
          >
            Próximo
          </Button>
        </Box>
      </Form>
    </Col>
  );
};

export default ContactForm;

const PhoneIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.3419 19.0761L23.3302 19.1761C20.3986 17.7145 20.092 17.5195 19.7128 18.0878C19.4503 18.4812 18.6845 19.3736 18.4545 19.637C18.2212 19.8969 17.9887 19.917 17.5929 19.737C17.1929 19.537 15.9087 19.117 14.3888 17.757C13.2046 16.697 12.4105 15.3971 12.1755 14.9971C11.7847 14.3221 12.6022 14.2263 13.3463 12.8188C13.4796 12.5388 13.4121 12.3188 13.313 12.1205C13.213 11.9205 12.4172 9.96054 12.0838 9.1789C11.7638 8.40058 11.4347 8.49891 11.188 8.49891C10.4197 8.43225 9.8589 8.44308 9.36391 8.95724C7.2123 11.3222 7.75478 13.7621 9.5964 16.357C13.2146 21.0928 15.1429 21.9652 18.6678 23.1752C19.6195 23.4777 20.4878 23.4352 21.1744 23.336C21.9394 23.2152 23.5302 22.3744 23.8619 21.4344C24.2019 20.4944 24.2019 19.7145 24.1019 19.5345C24.0035 19.3561 23.7419 19.2561 23.3419 19.0761Z"
      fill={colors.primary500}
    />
    <path
      d="M27.3593 4.59905C17.1079 -5.31152 0.14083 1.87662 0.134996 15.8571C0.134996 18.652 0.866644 21.3769 2.26161 23.7836L0 32L8.44645 29.7976C18.9862 35.4907 31.9942 27.9309 31.9992 15.8663C31.9992 11.6314 30.3459 7.64647 27.3393 4.65238L27.3593 4.59905ZM29.3351 15.8213C29.3276 25.9985 18.1554 32.3542 9.31976 27.1601L8.83977 26.8743L3.8399 28.1743L5.17986 23.3144L4.86154 22.8144C-0.637483 14.0613 5.67985 2.6216 16.0954 2.6216C19.6345 2.6216 22.9552 4.00157 25.4568 6.5015C27.9568 8.9806 29.3351 12.3014 29.3351 15.8213Z"
      fill={colors.primary500}
    />
  </svg>
);

const MailIcon = () => (
  <svg
    width="35"
    height="24"
    viewBox="0 0 35 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M34.8416 1.84568C34.6711 0.807273 33.8114 0 32.7273 0H2.18182C1.09773 0 0.237955 0.807273 0.0675 1.84568C0.0436364 1.91795 0.0327273 1.98955 0.0238636 2.06386C0.0218182 2.10545 0 2.14023 0 2.18182V21.8182C0 21.8598 0.0218182 21.8966 0.0238636 21.9382C0.0327273 22.0125 0.0436364 22.082 0.0675 22.1543C0.235909 23.1907 1.09773 24 2.18182 24H32.7273C33.8114 24 34.6732 23.1907 34.8416 22.1523C34.8655 22.08 34.8764 22.0105 34.8852 21.9361C34.8873 21.8966 34.9091 21.8598 34.9091 21.8182V2.18182C34.9091 2.14023 34.8873 2.10545 34.8852 2.06386C34.8764 1.98955 34.8655 1.91795 34.8416 1.84568ZM2.18182 4.335L12.6368 12.002L2.18182 19.667V4.335ZM19.8634 11.0618C19.8634 11.0618 19.8614 11.0639 19.8634 11.0618L17.4545 12.8291L15.0477 11.0639C15.0477 11.0639 15.0457 11.0639 15.0457 11.0618L2.93659 2.18182H31.9725L19.8634 11.0618ZM14.4832 13.3548L16.8089 15.0607C17.0011 15.2025 17.2275 15.272 17.4545 15.272C17.6816 15.272 17.9086 15.2025 18.1002 15.0607L20.4259 13.3548L31.9725 21.8182H2.93659L14.4832 13.3548ZM22.2723 12.002L32.7273 4.335V19.6664L22.2723 12.002ZM32.7273 22.3705V22.9091V22.3705Z"
      fill={colors.primary500}
    />
  </svg>
);
