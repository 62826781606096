import React from 'react';
import { Col, Box } from 'jsxstyle';
import Heading from 'components/Heading';
import Separator from 'components/Separator';
import { mediaQueries, spacingSizes } from 'theme';
import useForm from 'hooks/useForm';
import Button from 'components/Button';
import isRequired from 'validators/isRequired';
import Form from 'components/Form';
import InputGroup from 'components/InputGroup';
import CounterInput from 'components/CounterInput';

export type KidCountFormProps = {
  onSubmit?: (values: { count: number }) => void;
};

const KidCountForm = ({ onSubmit }: KidCountFormProps) => {
  const { getValue, setValue, getError, submit } = useForm({
    initialValues: {
      count: '0',
    },
    validations: {
      count: [
        isRequired,
        (value: string) => {
          if (Number(value) > 100) {
            return 'O número máximo permitido é 100';
          }
        },
      ],
    },
    onSubmit,
  });
  return (
    <Col
      flex={1}
      mediaQueries={mediaQueries}
      padding={`80px ${spacingSizes.md}px 20px ${spacingSizes.md}px`}
      //@ts-ignore
      lgPadding="116px 160px 64px 160px"
      xlPadding="116px 160px 64px 160px"
    >
      <Heading textAlign="center">
        E quantas crianças irão junto com você?
      </Heading>
      <Separator size="xl" />
      <Form onSubmit={submit}>
        <Box alignSelf="center">
          <InputGroup error={getError('count')}>
            <CounterInput
              value={getValue('count')}
              onChange={(value) => setValue('count', value)}
              min={0}
            />
          </InputGroup>
        </Box>
        <Box flex={1} />
        <Box
          mediaQueries={mediaQueries}
          width="80%"
          display="flex"
          alignSelf="center"
          //@ts-ignore
          xlWidth={368}
          lgWidth={368}
        >
          <Button color="secondary" onClick={submit} style={{ width: '100%' }}>
            Próximo
          </Button>
        </Box>
      </Form>
    </Col>
  );
};

export default KidCountForm;
