import background from 'assets/images/search-couple.png';
import Button, { WhiteOutlineButton } from 'components/Button';
import Container from 'components/Container';
import Form from 'components/Form';
import Heading from 'components/Heading';
import Logo from 'components/Logo';
import SearchInput from 'components/SearchInput';
import Separator from 'components/Separator';
import Text from 'components/Text';
import useForm from 'hooks/useForm';
import useMedia from 'hooks/useMedia';
import { Box, Col, Row } from 'jsxstyle';
import paths from 'paths';
import queryString from 'query-string';
import React from 'react';
//@ts-ignore
import Fade from 'react-reveal/Fade';
//@ts-ignore
import Slide from 'react-reveal/Slide';
//@ts-ignore
import Tada from 'react-reveal/Tada';
import { useHistory, useLocation } from 'react-router-dom';
import { colors, mediaQueries, spacingSizes } from 'theme';
import Wave from './Wave';

export type BeforeSearchProps = {};

const BeforeSearch = () => {
  const { media } = useMedia();
  const history = useHistory();
  const location = useLocation();
  const { name: search } = queryString.parse(location.search);

  const { getValue, setValue, submit } = useForm({
    initialValues: {
      search,
    },
    onSubmit: ({ search }) => {
      history.replace(`${paths.Platform.SEARCH_SCREEN}?name=${search}`);
    },
  });

  return (
    <React.Fragment>
      <Col
        flex={1}
        backgroundImage={`url(${background})`}
        backgroundSize="cover"
        backgroundPosition="top center"
        backgroundRepeat="no-repeat"
      >
        <Container>
          <Row
            mediaQueries={mediaQueries}
            justifyContent="center"
            //@ts-ignore
            xlJustifyContent="flex-end"
            lgJustifyContent="flex-end"
            paddingTop={spacingSizes.md}
          >
            <WhiteOutlineButton
              onClick={() => {
                history.push(paths.Authentication.SIGN_IN);
              }}
            >
              Login
            </WhiteOutlineButton>
            <Separator />
            {media === 'desktop' && (
              <Button
                color="secondary"
                id="btn-crie-gratis"
                onClick={() => {
                  history.push(paths.Authentication.SIGN_IN);
                }}
              >
                Crie Grátis
              </Button>
            )}
          </Row>
          <Separator size="xl" />
          <Col alignItems="center">
            <Tada>
              <Logo
                intent="secondary"
                height={media === 'desktop' ? 112 : 80}
              />
            </Tada>
            <Separator size="xl" />
            <Slide bottom>
              <Heading size="xl" color="white">
                Presenteie um Casal!
              </Heading>
            </Slide>
            <Slide bottom>
              <Box textAlign="center">
                <Text
                  fontWeight="lighter"
                  color={colors.white}
                  textAlign="center"
                >
                  Encontre aqui casal e celebrar com os noivos esse momento tão
                  especial.
                </Text>
              </Box>
            </Slide>
            <Separator size="lg" />
            <Fade delay={500}>
              <Box width="90vw" maxWidth={640}>
                <Form onSubmit={submit}>
                  <SearchInput
                    value={getValue('search')}
                    onChange={(value) => setValue('search', value)}
                    autoFocus
                  />
                </Form>
              </Box>
            </Fade>
          </Col>
        </Container>
        <Box minHeight={300} />
      </Col>
      <Col>
        <Wave fill="white">
          <Container>
            <Row alignItems="center" justifyContent="center">
              <Box
                component="a"
                props={{ href: 'https://meupresente.online', target: '_blank' }}
              >
                <Text fontWeight="bold" textTransform="uppercase">
                  Como funciona?
                </Text>
              </Box>
              <Separator size="lg" />
              <Box
                component="a"
                props={{ href: 'https://meucompromisso.com', target: '_blank' }}
              >
                <Text fontWeight="bold" textTransform="uppercase">
                  Quem somos
                </Text>
              </Box>
              <Separator size="lg" />
              <Box
                component="a"
                props={{
                  href: 'https://blog.meucompromisso.com',
                  target: '_blank',
                }}
              >
                <Text fontWeight="bold" textTransform="uppercase">
                  Blog
                </Text>
              </Box>
            </Row>
            <Separator />
            <Box textAlign="center">
              <Text textAlign="center" size="xs" fontWeight="bolder">
                MEU COMPROMISSO SOLUÇÕES DE PAGAMENTO LTDA. 36.990.228/0001-13
              </Text>
              <br />
              <Text textAlign="center" size="xs">
                @2020 TODOS OS DIREITOS RESERVADOS
              </Text>
            </Box>
          </Container>
          <Separator />
        </Wave>
      </Col>
    </React.Fragment>
  );
};

export default BeforeSearch;
