import background from 'assets/images/lp-background.png';
import Button, { WhiteOutlineButton } from 'components/Button';
import Container from 'components/Container';
import FadeIn from 'components/FadeIn';
import Icon from 'components/Icon';
import Logo from 'components/Logo';
import Separator from 'components/Separator';
import Text from 'components/Text';
import useMedia from 'hooks/useMedia';
import { Box, Col, Row } from 'jsxstyle';
import paths from 'paths';
import React from 'react';
//@ts-ignore
import Fade from 'react-reveal/Fade';
//@ts-ignore
import Slide from 'react-reveal/Slide';
//@ts-ignore
import Tada from 'react-reveal/Tada';
import { useHistory } from 'react-router-dom';
import { colors, mediaQueries, spacingSizes } from 'theme';
import Cost from './Cost';
import FAQ from './FAQ';
import Footer from './Footer';
import HowItWorks from './HowItWorks';
import Prices from './Prices';

export type HomeScreenProps = {};

const HomeScreen = () => {
  const history = useHistory();
  const { media } = useMedia();

  return (
    <FadeIn>
      <Col
        position="relative"
        //@ts-ignore
        beforeContent="''"
        beforePosition="absolute"
        beforeRight={0}
        beforeLeft={0}
        beforeHeight={1100}
        beforeBackgroundImage={`url(${background})`}
        beforeBackgroundSize="cover"
        beforeBackgroundPosition="top center"
        beforeBackgroundRepeat="no-repeat"
        beforeZIndex={-1}
        flex={1}
        overflowY="hidden"
      >
        <Col minHeight={900} justifyContent="space-between">
          <Container>
            <Row
              mediaQueries={mediaQueries}
              justifyContent="center"
              //@ts-ignore
              xsFlexDirection="column-reverse"
              xlJustifyContent="flex-end"
              lgJustifyContent="flex-end"
              paddingTop={spacingSizes.md}
            >
              <Button
                variant="ghost"
                color="primary"
                onClick={() => {
                  history.push(paths.Platform.SEARCH_SCREEN);
                }}
                style={{
                  maxHeight: 40,
                  backgroundColor: 'white',
                }}
              >
                <Row alignItems="center">
                  <Icon name="search" color="currentColor" />
                  <Separator size="sm" />
                  <span>Pesquisar por noivos</span>
                </Row>
              </Button>
              <Separator />
              <WhiteOutlineButton
                onClick={() => {
                  history.push(paths.Authentication.SIGN_IN);
                }}
              >
                Login
              </WhiteOutlineButton>
              <Separator />
              <Button
                color="secondary"
                id="btn-crie-gratis"
                onClick={() => {
                  history.push(paths.Authentication.SIGN_IN);
                }}
              >
                Crie Grátis
              </Button>
            </Row>
          </Container>
          <Col flex={1} justifyContent="center">
            <Container>
              <Col alignItems="center">
                <Col
                  mediaQueries={mediaQueries}
                  alignItems="center"
                  //@ts-ignore
                  lgWidth={908}
                  xlWidth={908}
                >
                  <Tada>
                    <Logo
                      intent="secondary"
                      height={media === 'desktop' ? 112 : 80}
                    />
                  </Tada>
                  <Separator />
                  <Slide bottom>
                    <Box
                      component="h1"
                      margin={0}
                      fontSize="2.5rem"
                      color={colors.white}
                      mediaQueries={mediaQueries}
                      textAlign="center"
                      //@ts-ignore
                      lgFontSize="3.5rem"
                      xlFontSize="3.5rem"
                    >
                      Sua lista de casamento free!
                    </Box>
                  </Slide>
                  <Separator size="sm" />
                  <Slide bottom>
                    <Box
                      mediaQueries={mediaQueries}
                      //@ts-ignore
                      lgWidth={500}
                      xlWidth={500}
                      textAlign="center"
                    >
                      <Text
                        fontWeight="lighter"
                        color={colors.white}
                        textAlign="center"
                        size="lg"
                      >
                        Simples e direta, pra você que quer apenas receber
                        presentes e confirmar convidados.
                      </Text>
                    </Box>
                  </Slide>
                  <Separator size="lg" />
                  <Fade delay={1000}>
                    <Box
                      borderRadius={20}
                      boxShadow="0px 16px 20px -8px rgba(255, 0, 102, 0.5)"
                    >
                      <Button
                        size="lg"
                        color="secondary"
                        id="btn-criar-minha-lista-gratis"
                        onClick={() => {
                          history.push(paths.Authentication.SIGN_IN);
                        }}
                      >
                        Criar minha lista grátis
                      </Button>
                    </Box>
                  </Fade>
                </Col>
              </Col>
            </Container>
          </Col>
        </Col>
        <HowItWorks />
        <Separator size="xl" />
        <Cost />
        <Prices />
        <FAQ />
        <Footer />
      </Col>
    </FadeIn>
  );
};

export default HomeScreen;
