const handleError = ({ error, exceptions = undefined }) => {
  if (error?.response?.data?.userMessage) {
    return {
      code: error?.response.data.code,
      message: error?.response.data.userMessage,
    };
  }
  if (exceptions) {
    const error = Object.keys(exceptions).find(
      (key) =>
        key ===
        (error?.response?.data?.code || error?.response?.code || error?.code),
    );
    if (error) {
      return {
        code: error,
        message: exceptions[error],
      };
    }
  }
  return {
    code: 'GENERIC',
    message: 'Ocorreu um error.',
  };
};

export default handleError;
