import { FontWeightProperty, TextAlignProperty } from 'csstype';
import useMedia from 'hooks/useMedia';
import { Box } from 'jsxstyle';
import React from 'react';
import { SizeTypes, TextTransformTypes } from 'typings';

type TextProps = {
  size?: SizeTypes;
  children: any;
  color?: string;
  fontWeight?: FontWeightProperty;
  textAlign?: TextAlignProperty;
  textTransform?: TextTransformTypes;
};

const Text = ({
  children,
  size = 'md',
  color = undefined,
  fontWeight = 'normal',
  textAlign = 'start',
  textTransform,
}: TextProps) => {
  const { media } = useMedia();

  const getFontSizeBySize = (size: SizeTypes) =>
    ({
      xs: {
        desktop: '.75em',
        tablet: '.70em',
        phone: '.65em',
      }[media],
      sm: {
        desktop: '.85em',
        tablet: '.75em',
        phone: '.75em',
      }[media],
      md: {
        desktop: '1em',
        tablet: '0.95em',
        phone: '0.85em',
      }[media],
      lg: {
        desktop: '1.2em',
        tablet: '1.15em',
        phone: '1.1em',
      }[media],
      xl: {
        desktop: '1.35em',
        tablet: '1.30em',
        phone: '1.25em',
      }[media],
    }[size]);

  return (
    <Box
      fontFamily="Quicksand"
      display="inline"
      color={color ?? 'currentColor'}
      fontWeight={fontWeight}
      fontSize={getFontSizeBySize(size)}
      textAlign={textAlign}
      textTransform={textTransform}
    >
      {children}
    </Box>
  );
};

export default Text;
