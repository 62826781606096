export type NumberFormatProps = {
  amount: number;
  type: 'decimal' | 'currency' | 'percent';
  minimumFractionDigits?: number;
};

const NumberFormat = ({
  amount,
  type = 'decimal',
  minimumFractionDigits = 0,
}: NumberFormatProps) =>
  amount.toLocaleString('pt-BR', {
    currency: 'BRL',
    style: type,
    minimumFractionDigits,
  }) as any;

export default NumberFormat;
