import Button from 'components/Button';
import Card from 'components/Card';
import Clickable from 'components/Clickable';
import Container from 'components/Container';
import Heading from 'components/Heading';
import Modal from 'components/Modal';
import Separator from 'components/Separator';
import Text from 'components/Text';
import { Box, Col, Row } from 'jsxstyle';
import paths from 'paths';
import React from 'react';
//@ts-ignore
import Fade from 'react-reveal/Fade';
import { useHistory } from 'react-router';
import { colors, mediaQueries, spacingSizes } from 'theme';
import Wave from '../SearchScreen/Wave';

const Prices = () => {
  const history = useHistory();
  return (
    <Col>
      <Box minHeight={250} />
      <Wave fill={colors.primary500} invertedX>
        <Separator size="xl" />
        <Col alignItems="center" backgroundColor={colors.primary500}>
          <Container>
            <Fade bottom delay={175}>
              <Row
                alignItems="center"
                justifyContent="space-between"
                mediaQueries={mediaQueries}
                //@ts-ignore
                xsFlexDirection="column"
                smFlexDirection="column"
              >
                <Heading textAlign="center" color={colors.white}>
                  Compare os preços
                </Heading>
                <Separator />
                <Button
                  size="lg"
                  color="secondary"
                  onClick={() => {
                    history.push(paths.Authentication.SIGN_IN);
                  }}
                >
                  <Text>Crie sua conta grátis</Text>
                </Button>
              </Row>
            </Fade>
          </Container>
        </Col>
        <Col position="relative">
          <Box
            position="absolute"
            top={0}
            left={0}
            right={0}
            minHeight={500}
            backgroundColor={colors.primary500}
          />
          <Col maxWidth="100vw" overflow="auto" backgroundColor="#f2f2f2">
            <Separator size="lg" />
            <Fade bottom delay={275}>
              <Container>
                <ComparisonTableCard />
              </Container>
            </Fade>
          </Col>
        </Col>
      </Wave>
    </Col>
  );
};

export default Prices;

function ComparisonTableCard() {
  const [modalVisibility, setModalVisibility] =
    React.useState<VisibilityState>('hidden');
  return (
    <Col backgroundColor="white" borderRadius={8} zIndex={10} minWidth={540}>
      <Row alignItems="center">
        <Cell minHeight={56} />
        <MeuPresenteCell backgroundColor={`#ffedf5`} minHeight={56}>
          <Text
            size="lg"
            fontWeight="bold"
            color={colors.primary500}
            textAlign="center"
          >
            meupresente
          </Text>
        </MeuPresenteCell>
        <CompetitorCell minHeight={56}>
          <Text fontWeight="lighter" textAlign="center">
            Concorrentes
          </Text>
        </CompetitorCell>
      </Row>
      <Row alignItems="center">
        <Cell>
          <Text fontWeight="bold">Taxa sobre os presentes</Text>
        </Cell>
        <MeuPresenteCell>
          <Text
            size="lg"
            fontWeight="bold"
            color={colors.secondary500}
            textAlign="center"
          >
            R$ 5,00
          </Text>
          <Text
            size="sm"
            fontWeight="bold"
            color={colors.secondary500}
            textAlign="center"
          >
            por compra
          </Text>
        </MeuPresenteCell>
        <CompetitorCell>
          <Text fontWeight="lighter" textAlign="center">
            5,99%
          </Text>
          <Text size="sm" fontWeight="lighter" textAlign="center">
            do total acumulado
          </Text>
        </CompetitorCell>
      </Row>
      <Row alignItems="center">
        <Cell>
          <Text fontWeight="bold">Preço do site</Text>
        </Cell>
        <MeuPresenteCell>
          <Text
            size="lg"
            fontWeight="bold"
            color={colors.secondary500}
            textAlign="center"
          >
            R$ 0,00
          </Text>
          <Text
            size="sm"
            fontWeight="bold"
            color={colors.secondary500}
            textAlign="center"
          >
            (exclusivo meupresente.online)
          </Text>
        </MeuPresenteCell>
        <CompetitorCell>
          <Text fontWeight="lighter" textAlign="center">
            R$ 150,00
          </Text>
          <Text size="sm" fontWeight="lighter" textAlign="center">
            por 1 ano
          </Text>
        </CompetitorCell>
      </Row>
      <Row alignItems="center">
        <Cell>
          <Text fontWeight="bold">Taxa por saque</Text>
        </Cell>
        <MeuPresenteCell>
          <Text
            size="lg"
            fontWeight="bold"
            color={colors.secondary500}
            textAlign="center"
          >
            R$ 0,00
          </Text>
          <Text
            size="sm"
            fontWeight="bold"
            color={colors.secondary500}
            textAlign="center"
          >
            (exclusivo meupresente.online)
          </Text>
        </MeuPresenteCell>
        <CompetitorCell>
          <Text fontWeight="lighter" textAlign="center">
            R$ 8,00
          </Text>
          <Text size="sm" fontWeight="lighter" textAlign="center">
            por saque
          </Text>
        </CompetitorCell>
      </Row>
      <Row alignItems="center">
        <Cell>
          <Text fontWeight="bold">Quantidade de saques</Text>
        </Cell>
        <MeuPresenteCell>
          <Text
            size="lg"
            fontWeight="bold"
            color={colors.secondary500}
            textAlign="center"
          >
            Ilimitado
          </Text>
        </MeuPresenteCell>
        <CompetitorCell>
          <Text fontWeight="lighter" textAlign="center">
            Ilimitado
          </Text>
        </CompetitorCell>
      </Row>
      <Row alignItems="center">
        <Cell>
          <Text fontWeight="bold">Tempo de recebimento</Text>
        </Cell>
        <MeuPresenteCell>
          <Text
            size="lg"
            fontWeight="bold"
            color={colors.secondary500}
            textAlign="center"
          >
            Imediato
          </Text>
          <Text
            size="sm"
            fontWeight="bold"
            color={colors.secondary500}
            textAlign="center"
          >
            Após a confirmação do pagamento (exclusivo meupresente.online)
          </Text>
        </MeuPresenteCell>
        <CompetitorCell>
          <Text fontWeight="lighter" textAlign="center">
            Até 13 dias no cartão
          </Text>
          <Text size="sm" fontWeight="lighter" textAlign="center">
            + 2 dias úteis de repasse
          </Text>
        </CompetitorCell>
      </Row>
      <Row alignItems="center">
        <Cell>
          <Text fontWeight="bold">Parcelamento via boleto ou PIX</Text>
        </Cell>
        <MeuPresenteCell>
          <Text
            size="lg"
            fontWeight="bold"
            color={colors.secondary500}
            textAlign="center"
          >
            Sim
          </Text>
          <Text
            size="sm"
            fontWeight="bold"
            color={colors.secondary500}
            textAlign="center"
          >
            (exclusivo meupresente.online)
          </Text>
        </MeuPresenteCell>
        <CompetitorCell>
          <Text fontWeight="lighter" textAlign="center">
            Sem opções
          </Text>
        </CompetitorCell>
      </Row>
      <Row alignItems="center">
        <Cell>
          <Text fontWeight="bold">Quantidade de parcelas</Text>
        </Cell>
        <MeuPresenteCell>
          <Text
            size="lg"
            fontWeight="bold"
            color={colors.secondary500}
            textAlign="center"
          >
            6x no boleto ou PIX
          </Text>
          <Clickable
            backgroundColor="transparent"
            onClick={() => setModalVisibility('visible')}
          >
            <Text
              size="sm"
              fontWeight="bold"
              color={colors.secondary500}
              textAlign="center"
            >
              (Entenda porque não trabalhamos com cartões de créditos)
            </Text>
          </Clickable>
        </MeuPresenteCell>
        <CompetitorCell>
          <Text fontWeight="lighter" textAlign="center">
            Sem opções
          </Text>
        </CompetitorCell>
      </Row>
      <Row alignItems="center">
        <Cell>
          <Text fontWeight="bold">Valor do parcelamento</Text>
        </Cell>
        <MeuPresenteCell>
          <Text
            size="lg"
            fontWeight="bold"
            color={colors.secondary500}
            textAlign="center"
          >
            R$5,00 por parcela
          </Text>
          <Text
            size="sm"
            fontWeight="bold"
            color={colors.secondary500}
            textAlign="center"
          >
            (exclusivo meupresente.online)
          </Text>
        </MeuPresenteCell>
        <CompetitorCell>
          <Text fontWeight="lighter" textAlign="center">
            Juros composto de 2,99% a.m.
          </Text>
        </CompetitorCell>
      </Row>
      <Modal
        visible={modalVisibility === 'visible'}
        onRequestClose={() => setModalVisibility('hidden')}
      >
        <Card maxWidth={576} padding={spacingSizes.lg}>
          <Text fontWeight="bold">
            Por que não trabalhamos com cartão de crédito?
          </Text>
          <Separator />
          <Text size="sm" color={colors.gray800}>
            O forma de pagamento com cartão de créditos tem uma taxa alta para a
            plataforma. E para entregarmos o menor custo possível aos noivos,
            decidimos trabalhar somente com o boleto parcelado e PIX. Assim
            temos uma melhor experiência no parcelamento e ao mesmo tempo não
            comprometemos os convidados e nem os noivos com faturas e taxas :)
          </Text>
        </Card>
      </Modal>
    </Col>
  );
}

type CellProps = {
  children?: React.ReactNode | React.ReactNodeArray;
  minHeight?: number;
  backgroundColor?: string;
};

function Cell({ children, minHeight = 80 }: CellProps) {
  return (
    <Col
      minWidth={200}
      flexBasis="50%"
      minHeight={minHeight}
      paddingH={48}
      justifyContent="center"
    >
      {children}
    </Col>
  );
}

function MeuPresenteCell({
  children,
  minHeight = 80,
  backgroundColor = `#fff6fa`,
}: CellProps) {
  return (
    <Col
      flexBasis="25%"
      minWidth={160}
      maxWidth={280}
      minHeight={minHeight}
      backgroundColor={backgroundColor}
      paddingH={spacingSizes.md}
      alignItems="center"
      justifyContent="center"
    >
      {children}
    </Col>
  );
}

function CompetitorCell({ children, minHeight = 80 }: CellProps) {
  return (
    <Col
      flexBasis="25%"
      minWidth={160}
      maxWidth={280}
      paddingH={spacingSizes.md}
      alignItems="center"
      justifyContent="center"
      minHeight={minHeight}
    >
      {children}
    </Col>
  );
}
