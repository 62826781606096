import privateApi from '../privateApi';

type Params = {
  accountID: string | number;
  giftCashID: string;
  giftCashItemID: string;
};
export default async ({
  accountID,
  giftCashID,
  giftCashItemID,
}: Params): Promise<{ total: number }> => {
  try {
    const { data } = await privateApi.delete(
      `/accounts/${accountID}/giftcashes/${giftCashID}/items/${giftCashItemID}`,
    );
    return data;
  } catch (error) {
    throw error;
  }
};
