import Text from 'components/Text';
import { Box } from 'jsxstyle';
//@ts-ignore
import InputNumber from 'rc-input-number';
import React from 'react';

export type InstallmentFormProps = {
  installments: number;
  onChangeInstallments: (installments: number) => void;
};

function InstallmentForm({
  installments,
  onChangeInstallments,
}: InstallmentFormProps) {
  return (
    <React.Fragment>
      <Box minHeight={32}>
        <Text size="sm">Quantidade de parcelas</Text>
      </Box>
      <Box maxWidth={80}>
        <InputNumber
          max={6}
          min={1}
          defaultValue={10}
          value={installments}
          onChange={onChangeInstallments}
        />
      </Box>
    </React.Fragment>
  );
}

export default InstallmentForm;
