import React from 'react';

export type CleanLogoProps = {
  color?: string;
};

const CleanLogo = ({ color = '#E76D84' }: CleanLogoProps) => (
  <svg
    width="93"
    height="62"
    viewBox="0 0 93 62"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path d="M0.299998 27.2C4.4 28 6.7 24 8.2 20.9C9.2 18.8 10.1 16.5 11.3 14.5C12.1 13.2 13.2 11.1 15 11.2C15.4 11.2 16.7 10.5 15.8 10.3C10.8 9.49999 9.1 16.9 9.2 20.6C9.3 22.7 11.1 26.7 13.7 26.4C16.7 26 18.2 22.9 19.8 20.8C20.2 20.3 18.6 20.4 18.3 20.8C17.5 21.9 15.3 26.1 13.6 25.5C11.8 24.8 11 21.9 10.7 20.2C10.4 18.7 11.1 16.8 11.5 15.4C11.9 14.1 12.9 10.9 14.7 11.2C15 10.9 15.2 10.6 15.5 10.3C11.6 9.99999 9.6 14.3 8.2 17.3C7.2 19.4 6.4 21.5 5.2 23.5C4.3 24.9 3.1 26.8 1.2 26.5C0.799998 26.2 -0.600002 27 0.299998 27.2Z" />
      <path d="M24.7 17.1C21.3 13.9 16.5 21.1 18.6 24.3C19.8 26.1 22.1 25.9 23.3 24.2C24.5 22.5 24.9 20.1 25.1 18C25.2 17.2 23.5 17.4 23.5 18.1C23.4 19.4 23.1 20.8 22.7 22C22.5 22.7 22.2 23.4 21.7 24C21.2 24.6 20.7 24.2 20.2 23.7C18.4 22 21.5 16.1 23.5 18C24 18.5 25.4 17.7 24.7 17.1Z" />
      <path d="M23.8 18.3C24.2 21.2 24.6 27.5 29 26.1C30.7 25.5 31.6 23.4 32.3 21.8C33.1 19.9 33.7 17.9 34.1 15.8C33.6 15.9 33 16 32.5 16.2C33 18.5 37.1 23.5 33.3 24.9C33.7 24.9 34 24.9 34.4 24.9C33.9 23 36 21.3 37.3 20.2C38.9 19 40.8 17.8 42.7 17.3C42.9 17.2 43.9 16.7 43.1 16.7C41.1 16.6 39.4 17.7 38.4 19.4C37.6 20.8 37 23.3 38.1 24.6C39.3 26 41.8 25.2 42.9 24C44.4 22.2 44.1 18.8 43.5 16.7C43.4 16.3 41.8 16.8 41.9 17.1C42.4 18.7 42.5 20.5 42.2 22.1C42 23.3 40.9 25.7 39.6 24C38.9 23 39.1 21.5 39.4 20.4C39.7 19.3 40.7 17.3 42 17.3C42.1 17.1 42.3 16.9 42.4 16.7C40.2 17.3 38 18.6 36.2 19.9C34.6 21 32 23 32.6 25.3C32.7 25.6 33.5 25.3 33.7 25.3C38.8 23.4 34.7 19.1 34 15.7C33.9 15.3 32.5 15.7 32.4 16.1C31.8 19.1 30.9 22.8 28.8 25.2C27.6 26.6 26.4 23.5 26.1 22.5C25.6 21 25.5 19.4 25.3 17.8C25.3 17.5 23.7 17.8 23.8 18.3Z" />
      <path d="M42.4 21.3C42.6 22.2 42.9 24.3 44 24.6C44.8 24.8 45.7 24.5 46.3 24.1C48.3 22.7 49.1 19.8 50.5 17.9C50 18 49.4 18.1 48.9 18.3C49.8 20.9 49.5 23.7 50 26.3C50.1 26.7 51.4 26.2 51.5 26C53.5 23.8 53.9 20.6 55.9 18.3C55.4 18.4 54.9 18.5 54.4 18.6C55.5 21 55.4 23.6 56.5 26C56.7 26.4 58.3 25.9 58.1 25.6C56.9 23.3 57.1 20.6 56 18.2C55.8 17.8 54.7 18.2 54.5 18.5C52.6 20.8 52.2 24 50.1 26.2C50.6 26.1 51.1 26 51.6 25.9C51 23.3 51.4 20.5 50.5 17.9C50.3 17.4 49.1 18 48.9 18.3C47.8 19.7 47.1 21.3 46.1 22.8C45.9 23.1 45.6 23.5 45.4 23.8C45.3 23.9 45.2 24 45.1 24.1C44.7 24.5 45.4 24.2 45.2 24C44.4 23.4 44.2 21.9 44.1 21C43.9 20.5 42.3 20.9 42.4 21.3Z" />
      <path d="M64.5 16.6C60.5 15 57.3 19.7 58.5 23.4C59 24.9 60.4 25.7 61.9 25.4C63.2 25.1 63.9 23.9 64.3 22.8C65.8 19 65.9 14.5 66 10.5C66.1 7.2 66 3.9 66.1 0.599995C66.1 -0.200005 64.5 0.0999953 64.5 0.799995C64.3 9.2 64.4 17.6 65.9 25.9C66 26.6 67.6 26.2 67.5 25.5C66 17.1 65.9 8.8 66.1 0.499995C65.6 0.599995 65 0.599995 64.5 0.699995C64.4 6.7 64.7 12.8 63.8 18.8C63.7 19.7 62.9 25.1 61.3 24.4C58.4 23.1 60.3 16.5 63.6 17.8C64.1 17.9 65.4 17 64.5 16.6Z" />
      <path d="M69 17.3C68.1 19.6 67.3 27 71.7 25.7C75.1 24.7 76.2 17.8 72.4 16.6C71 16.2 68.1 16.9 68 18.6C67.8 21 70.4 20.4 71.9 20.1C74.1 19.6 76.1 18.6 78.1 17.8C82.2 16.3 86.9 15.8 91.1 17C91.5 17.1 92.9 16.5 92.4 16.4C87.5 15 82.1 15.7 77.3 17.5C75.8 18.1 74.3 18.7 72.8 19.2C72 19.5 71.2 19.8 70.4 19.7C69.5 19.7 69.4 18.4 69.6 17.7C70.1 16.4 71.8 17.3 72.3 18.1C72.7 18.7 72.8 19.4 72.9 20.1C73.1 21.7 72.8 23.7 71.7 24.9C70.6 26.1 69.8 22.1 69.8 21.6C69.7 20.1 69.9 18.3 70.5 16.9C70.7 16.8 69.2 16.9 69 17.3Z" />
      <path d="M12.3 32.3C9.70001 30.4 6.90001 35.2 9.00001 37.2C9.60001 37.8 10.4 38.2 10.9 38.9C11.4 39.6 11.6 40.5 11.6 41.3C11.6 42 11.3 42.8 10.8 43.4C10.1 44.2 9.20001 43.3 8.70001 42.8C8.30001 42.4 6.70001 43 7.20001 43.5C9.30001 45.6 12.3 44.9 13.1 42C13.5 40.5 13.1 38.9 12 37.8C11.4 37.2 10.6 36.8 10.1 36.1C9.50001 35.4 10.2 32.4 11.1 33.1C11.5 33.4 12.9 32.8 12.3 32.3Z" />
      <path d="M15 32.8C14.9 36.7 14.8 40.5 14.7 44.4C14.7 44.8 16.3 44.5 16.3 44C16.4 40.1 16.5 36.3 16.6 32.4C16.6 32 15 32.3 15 32.8Z" />
      <path d="M15.4 45.1C16.2 45 17 44.9 17.9 44.9C18.3 44.9 18.7 44.7 18.8 44.2C18.8 43.8 18.4 43.6 18 43.6C17.2 43.7 16.4 43.8 15.5 43.8C15.1 43.8 14.7 44.1 14.6 44.5C14.6 45 15 45.2 15.4 45.1Z" />
      <path d="M15.5 39.2C15.9 39.2 16.3 39.1 16.6 39.1C17 39.1 17.5 39 17.9 38.6C18.2 38.3 17.6 38.2 17.5 38.3C17.1 38.3 16.7 38.4 16.4 38.4C16 38.4 15.5 38.6 15.1 38.9C14.8 39.2 15.3 39.3 15.5 39.2Z" />
      <path d="M15.7 33.2C16.4 33.2 17 33.1 17.7 33.1C18 33.1 18.5 32.9 18.6 32.5C18.7 32.1 18.1 32.1 17.9 32.1C17.2 32.1 16.6 32.2 15.9 32.2C15.6 32.2 15.1 32.4 15 32.8C15 33.2 15.5 33.2 15.7 33.2Z" />
      <path d="M20.6 32.6C20.5 36.5 20.2 40.4 20 44.4C20 44.7 21.6 44.5 21.6 44C21.8 40.1 22 36.2 22.2 32.2C22.2 31.8 20.6 32 20.6 32.6Z" />
      <path d="M20.7 32.5C21.7 35.4 22.4 38.3 23.1 41.2C23.2 41.7 24.8 41.2 24.7 40.8C23.9 37.9 23.2 35 22.3 32.1C22.2 31.6 20.6 32 20.7 32.5Z" />
      <path d="M24.8 40.8C25.6 37.9 26.5 35.1 27.4 32.2C27.6 31.6 26 31.8 25.8 32.4C24.9 35.3 24.1 38.1 23.2 41C23 41.7 24.6 41.4 24.8 40.8Z" />
      <path d="M25.8 32.7C26 36.8 26.2 40.9 26.4 45C26.4 45.6 28 45.2 28 44.6C27.8 40.5 27.5 36.4 27.4 32.3C27.4 31.6 25.8 32 25.8 32.7Z" />
      <path d="M41.3 36.5C37 35.1 32.6 39.1 33.3 43.5C33.7 45.6 35.9 45.1 37.4 44.5C40.3 43.2 40.7 39.4 42 36.9C41.5 37 40.9 37.1 40.4 37.2C39.5 42.1 39.4 47.1 38.8 52C38.6 53.8 38.4 55.7 38 57.5C37.8 58.3 37.5 59.1 37.1 59.9C36.9 60.3 36.7 60.6 36.4 60.9C35.9 61.4 35.9 61.1 35.7 60.6C34.8 58.7 35.6 56.2 36.3 54.4C37.1 52.3 38.3 50.3 39.5 48.4C42.1 44.1 44.6 39.7 46.5 35.1C46 35.2 45.4 35.3 44.9 35.5C45.5 36.2 46.1 36.9 46.2 37.9C46.4 38.8 46.1 39.8 46 40.7C45.8 42.3 45.4 44.2 46.9 45.3C47.2 45.6 48.7 44.9 48.4 44.8C46.9 43.7 47.4 41.6 47.7 40C47.8 39.2 47.9 38.3 47.9 37.5C47.8 36.6 47.1 35.8 46.6 35.1C46.3 34.8 45.1 35.1 45 35.5C42.9 40.8 39.8 45.7 37 50.6C35.8 52.8 34.6 55 34.1 57.5C33.9 58.5 33.6 62 35.3 62C39.3 61.9 39.9 57.2 40.3 54.2C41.1 48.5 41.2 42.7 42.1 37.1C42.2 36.7 40.7 37.1 40.5 37.4C39.5 39 39 40.9 38 42.6C37.6 43.3 36.6 44.7 35.7 44.3C35.2 44.1 35.1 43.5 35 43.1C34.7 41.7 35.2 40.2 35.9 39.1C36.8 37.4 38.2 36.6 40.1 37.2C40.3 37.2 41.7 36.6 41.3 36.5Z" />
      <path d="M55 36.3C51.1 34.6 47.9 40.9 49.9 43.9C51 45.5 53.4 44.3 54.2 43.1C55.4 41.3 55.8 38.7 56 36.5C56 35.8 54.4 36.2 54.4 36.7C54.3 38 54.1 39.4 53.7 40.7C53.5 41.5 52.6 44.5 51.5 43.4C50.1 42.1 51.5 36.1 53.7 37.1C54.2 37.2 55.7 36.5 55 36.3Z" />
      <path d="M54.5 36.9C54.4 38.6 54 45 57 44.4C58.6 44.1 59.4 42.3 60.1 41.1C61 39.4 61.5 37.4 62.7 35.8C62.2 35.8 61.7 35.8 61.2 35.8C61.9 38.5 61.1 41.3 62.1 44C62.3 44.7 63.4 44.3 63.7 43.9C65.2 41.6 65.5 38.4 67.6 36.5C67.1 36.5 66.6 36.5 66.1 36.5C66.8 38.8 66.9 41.2 66.9 43.5C66.9 44.3 68.5 44 68.5 43.4C68.5 41 68.5 38.4 67.7 36.1C67.5 35.5 66.6 35.8 66.2 36.1C64 38.1 63.7 41.4 62.1 43.8C62.6 43.8 63.1 43.7 63.7 43.7C62.8 41.1 63.5 38.2 62.8 35.5C62.6 34.9 61.6 35.2 61.3 35.5C59.6 37.7 59.1 40.7 57.3 43C57.1 43.2 56.7 43.4 57.1 43.5C56.9 43.5 56.7 43 56.7 42.9C56.4 42.3 56.3 41.6 56.2 40.9C56 39.6 56 38.2 56.1 36.9C56.1 35.9 54.5 36.2 54.5 36.9Z" />
      <path d="M76 36.2C74.3 34 71.9 35.3 70.9 37.4C70 39.1 68.8 42.3 70 44.1C71.3 45.9 73.5 43.3 74.2 42.3C75.6 40.3 76.6 38 77.7 35.7C77.2 35.8 76.7 35.8 76.1 35.9C76.4 38.5 76 41.7 78.4 43.4C80.5 44.9 83.5 45.3 85.9 44.6C86.9 44.3 86.6 43.5 85.7 43.8C83.6 44.4 80.8 43.8 79.2 42.2C77.5 40.5 77.9 37.6 77.7 35.4C77.6 34.8 76.3 35.3 76.1 35.6C75.5 36.9 74.9 38.2 74.2 39.5C73.6 40.7 72.6 43 71.3 43.7C71.7 43.5 71.5 43.7 71.3 43.2C71.1 42.9 71.1 42.5 71 42.1C70.9 41.2 71.2 40.3 71.4 39.4C71.7 38.4 72 37.5 72.5 36.7C72.7 36.4 73 35.9 73.3 35.8C73.6 35.7 74.2 36.6 74.3 36.7C74.9 37.3 76.4 36.8 76 36.2Z" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="92.7" height="61.8" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default CleanLogo;
