import { Box } from 'jsxstyle';
import React from 'react';
import { colors, mediaQueries, spacingSizes } from 'theme';
import Button from './Button';
import Separator from './Separator';
import Text from './Text';

const CookieConsent = () => {
  const [accepted, setAccepted] = React.useState(true);
  React.useEffect(() => {
    const _accepted = localStorage.getItem('cookies_accepted');
    if (_accepted !== 'true') {
      setAccepted(false);
    }
  }, []);

  return accepted ? null : (
    <Box
      position="fixed"
      bottom={spacingSizes.md}
      left={spacingSizes.md}
      right={spacingSizes.md}
      backgroundColor={colors.white}
      borderRadius={8}
      padding={spacingSizes.md}
      boxShadow="0 0 10px #aaa"
      zIndex={999}
      maxWidth={990}
      margin="0 auto"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        // alignItems="flex-end"
        mediaQueries={mediaQueries}
        flexDirection="column"
        //@ts-ignore
        lgFlexDirection="row"
        xlFlexDirection="row"
        lgAlignItems="center"
        xlAlignItems="center"
      >
        <Box flexBasis="80%">
          <Text size="sm">
            <b>MeuPresente</b> utiliza cookies para que você tenha a melhor
            experiência no nosso site. Ao continuar navegando, você concorda com
            a nossa&nbsp;
            <Box
              component="a"
              props={{
                target: '_blank',
                href: 'https://resources.meucompromisso.com/docs/MeuCompromisso-Politica-de-Privacidade.pdf',
              }}
              fontWeight="bold"
              color={colors.primary500}
            >
              Política de Privacidade
            </Box>
            .
          </Text>
        </Box>
        <Separator />
        <Button
          onClick={() => {
            setAccepted(true);
            localStorage.setItem('cookies_accepted', 'true');
          }}
          size="sm"
        >
          Aceitar e continuar
        </Button>
      </Box>
    </Box>
  );
};

export default CookieConsent;
