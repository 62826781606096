import { Box, Col } from 'jsxstyle';
import React from 'react';

export type WaveProps = {
  children: React.ReactNode | React.ReactNodeArray;
  fill: string;
  invertedX?: boolean;
  invertedY?: boolean;
};

const HEIGHT = 194;
const OFFSET_TOP = 24;

const Wave = ({ children, fill, invertedX, invertedY }: WaveProps) => {
  return (
    <React.Fragment>
      <Box height={HEIGHT} overflow="hidden" marginTop={-HEIGHT} zIndex={1}>
        {invertedY ? (
          <InvertedWaveSvg fill={fill} invertedX={invertedX} />
        ) : (
          <WaveSvg fill={fill} invertedX={invertedX} />
        )}
      </Box>
      <Col
        position="relative"
        marginTop={-(HEIGHT / 2) + OFFSET_TOP}
        zIndex={2}
      >
        {children}
      </Col>
    </React.Fragment>
  );
};

export default Wave;

const WaveSvg = ({
  fill,
  invertedX,
}: Pick<WaveProps, 'fill' | 'invertedX'>) => {
  return (
    <svg
      viewBox="0 0 1280 194"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      style={{
        height: '100%',
        width: '100%',
        transform: invertedX ? 'scaleX(-1)' : undefined,
      }}
    >
      <path
        d="M0 0L35.5556 14.1667C71.1111 28.3333 142.222 56.6667 213.333 56.6667C284.444 56.6667 355.556 28.3333 426.667 28.3333C497.778 28.3333 568.889 56.6667 640 61.3594C711.111 66.4062 782.222 46.9271 853.333 56.6667C924.444 66.4062 995.556 103.594 1066.67 118.026C1137.78 131.927 1208.89 123.073 1244.44 118.026L1280 113.333V194H1244.44C1208.89 194 1137.78 194 1066.67 194C995.556 194 924.444 194 853.333 194C782.222 194 711.111 194 640 194C568.889 194 497.778 194 426.667 194C355.556 194 284.444 194 213.333 194C142.222 194 71.1111 194 35.5556 194H0V0Z"
        fill={fill}
      />
    </svg>
  );
};

const InvertedWaveSvg = ({
  fill,
  invertedX,
}: Pick<WaveProps, 'fill' | 'invertedX'>) => {
  return (
    <svg
      viewBox="0 0 1280 194"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      style={{
        height: '100%',
        width: '100%',
        transform: invertedX ? 'scaleX(-1)' : undefined,
      }}
    >
      <path
        d="M0 0L35.5556 14.1667C71.1111 28.3333 142.222 56.6667 213.333 56.6667C284.444 56.6667 355.556 28.3333 426.667 28.3333C497.778 28.3333 568.889 56.6667 640 61.3594C711.111 66.4062 782.222 46.9271 853.333 56.6667C924.444 66.4062 995.556 103.594 1066.67 118.026C1137.78 131.927 1208.89 123.073 1244.44 118.026L1280 113.333V194H1244.44C1208.89 194 1137.78 194 1066.67 194C995.556 194 924.444 194 853.333 194C782.222 194 711.111 194 640 194C568.889 194 497.778 194 426.667 194C355.556 194 284.444 194 213.333 194C142.222 194 71.1111 194 35.5556 194H0V0Z"
        fill={fill}
      />
    </svg>
  );
};
