import React from 'react';
import { format } from 'date-fns';
import Heading from './Heading';
import { colors } from 'theme';

export type WeddingDateProps = {
  date?: Date;
};

const WeddingDate = ({ date }: WeddingDateProps) => (
  <Heading size="lg" color={colors.secondary500} textAlign="center">
    {Boolean(date) && format(date!, 'dd.MM.yyyy')}
  </Heading>
);

export default WeddingDate;
