import React from 'react';
//@ts-ignore
import InputNumber from 'rc-input-number';
import trash from 'assets/images/trash.svg';
import Clickable from 'components/Clickable';
import Text from 'components/Text';
import { Box, Col, Row } from 'jsxstyle';
import { colors } from 'theme';
import GiftType from 'types/GiftType';
import Card from 'components/Card';
import GiftInfo from 'components/platform/GiftInfo';
import { useCart } from 'contexts/CartContext';
import Separator from 'components/Separator';

export type GiftCartListItemProps = {
  gift: GiftType;
};

const GiftCartListItem = ({ gift }: GiftCartListItemProps) => {
  const { removeFromCart, incrementQuantity, decrementQuantity } = useCart();

  const handleRemoveFromCart = () => {
    // eslint-disable-next-line no-restricted-globals
    const accept = confirm(
      'Tem certeza que deseja remove esse item do carrinho?',
    );
    if (accept) {
      removeFromCart(gift);
    }
  };
  return (
    <Card>
      <Row justifyContent="space-between" flex={1} alignItems="center">
        <Box flexBasis="50%">
          <GiftInfo
            name={gift.name}
            pictureID={gift.pictureID}
            amount={gift.amount}
            giftCashItemID={gift.giftCashItemID}
            useCustomPicture={gift.useCustomPicture}
          />
        </Box>
        <Col>
          <Text size="sm" color={colors.gray500}>
            Quantidade
          </Text>
          <Separator size="xs" />
          <Box width={56}>
            <InputNumber
              value={gift.quantity}
              onChange={(num: number) => {
                if (num > gift.quantity) {
                  incrementQuantity(gift);
                } else {
                  decrementQuantity(gift);
                }
              }}
            />
          </Box>
        </Col>
        <Clickable background="none" onClick={handleRemoveFromCart}>
          <Box component="img" props={{ src: trash }} />
        </Clickable>
      </Row>
    </Card>
  );
};

export default GiftCartListItem;
