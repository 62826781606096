import { Auth } from 'aws-amplify';

export type Params = {
  username: string;
  password: string;
};

export default async ({ username, password }: Params) => {
  try {
    await Auth.signIn(
      isNaN(Number(username)) ? username : `+55${username}`,
      password,
    );

    const authenticatedUser = await Auth.currentAuthenticatedUser();

    const userID = authenticatedUser.attributes['custom:userID'];

    if (userID) {
      return userID;
    }

    Auth.signOut();

    return Promise.reject({
      code: 'UserNotAuthorizedException',
      message: 'Para acessar o site é preciso ter uma conta MeuCompromisso',
    });
  } catch (e) {
    let error;

    switch (e.code) {
      case 'UserNotFoundException':
        error = {
          code: 'UserNotFoundException',
          message: 'Nenhum usuário encontrado',
        };
        break;
      case 'NotAuthorizedException':
        error = {
          code: 'NotAuthorizedException',
          message: 'Nome de usuário ou senha incorreta',
        };
        break;
      default:
        error = {
          code: 'GenericException',
          message: 'Um erro ocorreu, tente novamente',
        };
        alert(JSON.stringify(e));
        break;
    }

    return Promise.reject(error);
  }
};
