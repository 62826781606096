/* eslint-disable jsx-a11y/accessible-emoji */
import React from 'react';
import { Col, Row, Box } from 'jsxstyle';
import InBetweenSpacing from 'components/InBetweenSpacing';
import Text from 'components/Text';
import Separator from 'components/Separator';
import { spacingSizes, colors } from 'theme';
import useForm from 'hooks/useForm';
import Icon from 'components/Icon';
import Button from 'components/Button';
import Divider from 'components/Divider';
import useMedia from 'hooks/useMedia';
import useAsync from 'hooks/useAsync';
import saveWeddingAndProductListService, {
  Params as SaveWeddingParams,
} from 'services/saveWeddingAndProductListService';
import { useApplication } from 'contexts/ApplicationContext';
import history from 'browserHistory';
import ReviewTextInput from './ReviewTextInput';
import ReviewNumberInput from './ReviewNumberInput';
import Paths from 'paths';
import { format } from 'date-fns';
import { useOnboarding } from 'contexts/OnboardingContext';
import Card from 'components/Card';
import GiftInfo from 'components/platform/GiftInfo';

export type ReviewFormProps = { initialValues: any };

const ReviewForm = ({ initialValues }: ReviewFormProps) => {
  const { account, affiliate } = useApplication();
  const { setUrl } = useOnboarding();

  const { getValue, setValue } = useForm({
    initialValues: {
      ...initialValues,
      date: initialValues.date.getDate(),
      month: initialValues.date.getMonth() + 1,
      year: initialValues.date.getFullYear(),
    },
  });

  const { media } = useMedia();

  const { fetch, pending } = useAsync<SaveWeddingParams>({
    promiseFn: saveWeddingAndProductListService,
    onData: () => {
      setUrl(getValue('url'));
      history.push(Paths.Onboarding.SUCCESS_SCREEN);
    },
  });

  const handleSubmit = () => {
    fetch({
      accountID: account.accountID,
      url: getValue('url'),
      groom: getValue('names.groom'),
      bride: getValue('names.bride'),
      phrase: getValue('phrase'),
      date: format(
        new Date(
          Number(getValue('year')),
          Number(getValue('month')) - 1,
          Number(getValue('date')),
        ),
        'yyyy-MM-dd',
      ),
      state: getValue('address.state'),
      city: getValue('address.city'),
      products: getValue('products') as any,
      affiliateID: affiliate?.key,
    });
  };

  return (
    <Col
      padding={`${spacingSizes.md}px 0`}
      WebkitFlex="0 0 auto"
      msFlex="0 0 auto"
      flex="0 0 auto"
    >
      <Col
        width={media === 'desktop' ? 498 : '85%'}
        alignSelf="center"
        alignItems="center"
      >
        <InBetweenSpacing>
          <Text fontWeight="bold">
            Confira as informações da sua lista de casamento
          </Text>
          <Text color={colors.gray500}>
            Caso necessário, pode editar alguma informação aqui mesmo 😉
          </Text>
          <Separator />
          <Title>Nome dos noivos</Title>
          <Row alignItems="center">
            <InBetweenSpacing>
              <Box
                component="input"
                fontWeight="bold"
                color={colors.primary500}
                border="none"
                outline="none"
                width="auto"
                textAlign="end"
                props={{
                  value: getValue('names.groom'),
                  onChange: (e: any) => setValue('names.groom', e.target.value),
                }}
              />

              <Icon color={colors.secondary500} name="ring" />
              <ReviewTextInput
                value={getValue('names.bride')}
                onChange={(value) => setValue('names.bride', value)}
              />
            </InBetweenSpacing>
          </Row>
          <Divider borderStyle="dashed" size={2} />
          <Title>Data do casamento</Title>
          <Row alignItems="center">
            <InBetweenSpacing size="xl">
              <Label label="Dia">
                <ReviewNumberInput
                  value={getValue('date')}
                  onChange={(value) => setValue('date', value)}
                  min={1}
                  max={31}
                />
              </Label>
              <Label label="Mês">
                <ReviewNumberInput
                  value={getValue('month')}
                  onChange={(value) => setValue('month', value)}
                  min={1}
                  max={12}
                />
              </Label>
              <Label label="Ano">
                <ReviewNumberInput
                  value={getValue('year')}
                  onChange={(value) => setValue('year', value)}
                  min={2020}
                  max={2100}
                />
              </Label>
            </InBetweenSpacing>
          </Row>
          <Divider borderStyle="dashed" size={2} />
          <Title>Local do casamento</Title>
          <Row alignItems="center">
            <InBetweenSpacing size="xl">
              <Label label="Cidade">
                <ReviewTextInput
                  value={getValue('address.city')}
                  onChange={(value) => setValue('address.city', value)}
                  textAlign="center"
                />
              </Label>
              <Label label="Estado">
                <ReviewTextInput
                  value={getValue('address.state')}
                  onChange={(value) => setValue('address.state', value)}
                  textAlign="center"
                />
              </Label>
            </InBetweenSpacing>
          </Row>
          <Divider borderStyle="dashed" size={2} />
          <Title>Mensagem para os convidados</Title>
          <ReviewTextInput
            value={getValue('phrase')}
            onChange={(value) => setValue('phrase', value)}
            textAlign="center"
          />
          <Divider borderStyle="dashed" size={2} />
        </InBetweenSpacing>
      </Col>
      <Separator />
      <InBetweenSpacing>
        <Col alignSelf="center">
          <Title>Lista de presentes</Title>
        </Col>
        <Box
          display="grid"
          gridTemplateColumns="repeat(auto-fit, 280px)"
          justifyContent={'center'}
          width="100%"
          gridGap={spacingSizes.md}
          alignSelf="flex-start"
        >
          {
            //
            //@ts-ignore
            getValue('products').map((product: any) => (
              <Card backgroundColor={colors.primary500} color={colors.white}>
                <GiftInfo
                  name={product.name}
                  amount={product.value}
                  pictureID={product.pictureID}
                  useCustomPicture={product.customImage}
                />
              </Card>
            ))
          }
        </Box>
        <Col
          width={media === 'desktop' ? 498 : '85%'}
          alignSelf="center"
          alignItems="center"
        >
          <Divider borderStyle="dashed" size={2} />
          <Separator />
          <Title>Nome do site</Title>
          <Separator />
          <Text color={colors.gray300}>
            {window.location.hostname}/
            <Text color={colors.primary500} fontWeight="bold">
              {getValue('url')}
            </Text>
          </Text>
          <Separator size="xl" />
          <Button
            loading={pending}
            fullWidth
            color="secondary"
            onClick={handleSubmit}
          >
            <Text
              color={colors.white}
              fontWeight="bold"
              textTransform="uppercase"
            >
              Concluir lista
            </Text>
          </Button>
        </Col>
      </InBetweenSpacing>
    </Col>
  );
};

export default ReviewForm;

type TitleProps = {
  children: React.ReactNode;
};
const Title = ({ children }: TitleProps) => (
  <Text size="sm" color={colors.gray500} textTransform="uppercase">
    {children}
  </Text>
);

type LabelProps = {
  label: React.ReactNode;
  children?: React.ReactNode;
};
const Label = ({ label, children }: LabelProps) => (
  <Col alignItems="center">
    <Text color={colors.gray500} textTransform="uppercase">
      {label}
    </Text>
    <Separator />
    {children}
  </Col>
);
