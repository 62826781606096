import { states as statesJSON } from 'assets/json/state-city.json';
import Button from 'components/Button';
import Form from 'components/Form';
import InputGroup from 'components/InputGroup';
import Searchable from 'components/searchable';
import Separator from 'components/Separator';
import Text from 'components/Text';
import useForm from 'hooks/useForm';
import useMedia from 'hooks/useMedia';
import { Col } from 'jsxstyle';
import React from 'react';
import { colors, spacingSizes } from 'theme';
import isRequired from 'validators/isRequired';

const states = statesJSON as StateType[];

type WeddingAddressFormProps = {
  onSubmit: (values: { city: string; state: string }) => void;
};

type StateType = {
  name: string;
  short: string;
  cities: string[];
};

const WeddingAddressForm = ({ onSubmit }: WeddingAddressFormProps) => {
  const { media } = useMedia();
  const { getValue, setValues, getValues, getError, submit, isSubmitted } =
    useForm({
      validations: {
        city: [isRequired],
        state: [isRequired],
      },
      onSubmit: (values) => {
        const city = values.city;
        const state = values.state?.name;
        onSubmit?.({ city, state });
      },
    });

  const stateFilter = getValue('stateFilter') || '';
  const cityFilter = getValue('cityFilter') || '';
  const state = getValue('state') as unknown as StateType;
  const filteredStates = states.filter(
    (state) =>
      !stateFilter ||
      state.name.toLowerCase().includes(stateFilter.toLowerCase()),
  );
  const cities = state?.cities || [];
  const filteredCities = cities.filter(
    (city) =>
      !cityFilter || city.toLowerCase().includes(cityFilter.toLowerCase()),
  );

  const cityError =
    isSubmitted() && !getValue('state')
      ? 'Selecione um estado.'
      : getError('city');

  return (
    <Col
      width={media === 'desktop' ? 498 : '85%'}
      alignSelf="center"
      paddingTop={spacingSizes.xl}
    >
      <Text textAlign="center" size="lg">
        Onde será o casamento?
      </Text>
      <Separator size="xl" />
      <Form>
        <InputGroup error={getError('state')}>
          <Searchable
            placeholder="Estado"
            textAlign="center"
            inputValue={getValue('stateFilter')}
            onInputChange={(value) => {
              setValues(() => ({
                ...getValues(),
                state: undefined,
                city: undefined,
                cityFilter: '',
                stateFilter: value,
              }));
            }}
            value={getValue('state')}
            onChange={(value: StateType | null) => {
              setValues(() => ({
                ...getValues(),
                city: undefined,
                cityFilter: '',
                state: value,
                stateFilter: value?.name || '',
              }));
            }}
            error={getError('state')}
          >
            {filteredStates.map((state) => (
              <Searchable.Option value={state}>{state.name}</Searchable.Option>
            ))}
          </Searchable>
        </InputGroup>
        <InputGroup error={cityError}>
          <Searchable
            placeholder="Cidade"
            textAlign="center"
            disabled={!getValue('state')}
            inputValue={getValue('cityFilter')}
            onInputChange={(value) => {
              setValues(() => ({
                ...getValues(),
                city: undefined,
                cityFilter: value,
              }));
            }}
            value={getValue('city')}
            onChange={(value: string | null) => {
              setValues(() => ({
                ...getValues(),
                city: value,
                cityFilter: value || '',
              }));
            }}
            error={cityError}
          >
            {filteredCities.map((city) => (
              <Searchable.Option value={city}>{city}</Searchable.Option>
            ))}
          </Searchable>
        </InputGroup>
        <Separator size="xl" />
        <Button type="submit" color="secondary" onClick={() => submit()}>
          <Text
            color={colors.white}
            fontWeight="bold"
            textTransform="uppercase"
          >
            Enviar
          </Text>
        </Button>
      </Form>
      <Separator size="xl" />
    </Col>
  );
};

export default WeddingAddressForm;
