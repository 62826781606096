import Card from 'components/Card';
import GiftInfo from 'components/platform/GiftInfo';
import Separator from 'components/Separator';
import SkeletonPlaceholder from 'components/SkeletonPlaceholder';
import Text from 'components/Text';
import React from 'react';
import { colors, spacingSizes } from 'theme';
import PurchaseDetailType from 'types/PurchaseDetailType';

export type PurchaseItemsSectionProps = {
  purchase: PurchaseDetailType;
  pending: Boolean;
};

function PurchaseItemsSection({
  purchase,
  pending,
}: PurchaseItemsSectionProps) {
  const items = purchase?.giftCashItems || [];
  const products = items.filter(
    (item) => item.productCategoryID !== 'wedding_gift_card',
  );

  return (
    <React.Fragment>
      <Text fontWeight="bold" color={colors.primary500}>
        Itens de presente
      </Text>
      {pending ? (
        <React.Fragment>
          <Separator />
          <Card>
            <SkeletonPlaceholder>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: 56, height: 56, borderRadius: 2 }}></div>
                <div style={{ marginLeft: spacingSizes.sm }}>
                  <div style={{ width: 160, height: 16, borderRadius: 4 }} />
                  <div
                    style={{
                      width: 100,
                      height: 20,
                      borderRadius: 4,
                      marginTop: spacingSizes.xs,
                    }}
                  />
                </div>
              </div>
            </SkeletonPlaceholder>
          </Card>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {products?.map((item) => (
            <React.Fragment key={item.giftCashItemID}>
              <Separator />
              <Card>
                <GiftInfo
                  name={item.productName}
                  pictureID={item.productPictureID as any}
                  amount={item.amount}
                  giftCashItemID={item.giftCashItemID}
                  useCustomPicture={item.useCustomPicture}
                />
              </Card>
            </React.Fragment>
          ))}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default PurchaseItemsSection;
