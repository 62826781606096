import React from 'react';
import { Row, Box, Col } from 'jsxstyle';
import useMedia from 'hooks/useMedia';
import { spacingSizes, colors } from 'theme';
import user from 'assets/images/user-avatar.png';
import Separator from 'components/Separator';

export interface UserMessageProps {
  content: () => React.ReactElement;
  onTypingEnd: () => void;
}

const UserMessage = ({ content, onTypingEnd }: UserMessageProps) => {
  const { media } = useMedia();

  const avatarSize = media === 'phone' ? 36 : 48;

  React.useEffect(() => {
    onTypingEnd();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row
      WebkitFlex="0 0 auto"
      msFlex="0 0 auto"
      flex="0 0 auto"
      flexDirection="row-reverse"
    >
      <Col
        flexBasis={avatarSize}
        WebkitFlex="0 0 auto"
        msFlex="0 0 auto"
        flex="0 0 auto"
      >
        <Box
          component="img"
          props={{ src: user, width: avatarSize, height: avatarSize }}
        />
      </Col>
      <Col WebkitFlex="0 0 auto" msFlex="0 0 auto" flex="0 0 auto">
        <Separator />
      </Col>
      <Col
        padding={media === 'phone' ? spacingSizes.xs : spacingSizes.md}
        backgroundColor={colors.primary500}
        borderTopRightRadius={0}
        borderRadius={12}
        maxWidth="85%"
        wordBreak="break-all"
      >
        {content}
      </Col>
    </Row>
  );
};

export default UserMessage;
