import React from 'react';
import cartao1 from 'assets/images/cartao1.png';
import cartao2 from 'assets/images/cartao2.png';
import cartao3 from 'assets/images/cartao3.png';
import cartao4 from 'assets/images/cartao4.png';
import cartao5 from 'assets/images/cartao5.png';
import { Box } from 'jsxstyle';

export type GiftCardImageProps = {
  name:
    | 'Cartão de Presentes (Modelo 1)'
    | 'Cartão de Presentes (Modelo 2)'
    | 'Cartão de Presentes (Modelo 3)'
    | 'Cartão de Presentes (Modelo 4)'
    | 'Cartão de Presentes (Modelo 5)';
  width?: number | string;
  height?: number | string;
  borderRadius?: number;
};
const GiftCardImage = ({
  name,
  width = 400,
  height = 400,
  borderRadius = 30,
}: GiftCardImageProps) => {
  return (
    <Box
      component="img"
      width={width}
      height={height}
      borderRadius={borderRadius}
      props={{
        src: {
          'Cartão de Presentes (Modelo 1)': cartao1,
          'Cartão de Presentes (Modelo 2)': cartao2,
          'Cartão de Presentes (Modelo 3)': cartao3,
          'Cartão de Presentes (Modelo 4)': cartao4,
          'Cartão de Presentes (Modelo 5)': cartao5,
        }[name],
      }}
    />
  );
};

export default GiftCardImage;
