import Card from 'components/Card';
import Icon, { IconProps } from 'components/Icon';
import Separator from 'components/Separator';
import Text from 'components/Text';
import { Box, Col } from 'jsxstyle';
import React from 'react';
import { colors } from 'theme';
import PurchaseDetailType from 'types/PurchaseDetailType';

export type PaymentMethodProps = {
  purchase: PurchaseDetailType;
};

function PaymentMethodSection({ purchase }: PaymentMethodProps) {
  const isSingleInstallment =
    purchase?.paymentMethod === 'BILLET' || purchase?.paymentMethod === 'PIX';
  const isBilletPaymentMethod =
    purchase?.paymentMethod === 'BILLET_INSTALLMENT' ||
    purchase?.paymentMethod === 'BILLET';
  const isPixPaymentMethod =
    purchase?.paymentMethod === 'PIX_INSTALLMENT' ||
    purchase?.paymentMethod === 'PIX';
  const totalInstallments = (purchase?.installments || []).length;

  return (
    <React.Fragment>
      <Text
        fontWeight="bold"
        size="xs"
        textTransform="uppercase"
        color={colors.gray900}
      >
        Forma de pagamento
      </Text>
      <Separator />
      {isBilletPaymentMethod && (
        <PaymentMethod>
          <PaymentMethod.Icon iconName="barcode" />
          <Separator size="sm" />
          <Col flex={1}>
            <PaymentMethod.Title>Boleto</PaymentMethod.Title>
            {!isSingleInstallment && (
              <React.Fragment>
                <Separator size="xs" />
                <PaymentMethod.Installments>
                  {totalInstallments}
                </PaymentMethod.Installments>
              </React.Fragment>
            )}
          </Col>
        </PaymentMethod>
      )}
      {isPixPaymentMethod && (
        <PaymentMethod>
          <PaymentMethod.Icon iconName="pix" width={20} height={21} />
          <Separator size="sm" />
          <Col flex={1} justifyContent="center">
            <PaymentMethod.Title>Pix</PaymentMethod.Title>
            {!isSingleInstallment && (
              <React.Fragment>
                <Separator size="xs" />
                <PaymentMethod.Installments>
                  {totalInstallments}
                </PaymentMethod.Installments>
              </React.Fragment>
            )}
          </Col>
        </PaymentMethod>
      )}
    </React.Fragment>
  );
}

export default PaymentMethodSection;

function PaymentMethod({
  children,
}: {
  children: React.ReactNode | React.ReactNodeArray;
}) {
  return <Card flexDirection="row">{children}</Card>;
}

function PaymentMethodTitle({ children }: { children: React.ReactText }) {
  return (
    <Text size="sm" fontWeight="bold" color={colors.primary500}>
      {children}
    </Text>
  );
}

function PaymentMethodIcon({
  iconName,
  width = 18,
  height = 18,
}: {
  iconName: IconProps['name'];
  width?: number;
  height?: number;
}) {
  return (
    <Box
      width={32}
      height={32}
      borderRadius={32}
      backgroundColor={`${colors.primary500}1a`}
      justifyContent="center"
      alignItems="center"
      display="flex"
      flexShrink={0}
    >
      <Icon
        name={iconName}
        width={width}
        height={height}
        color={colors.primary500}
      />
    </Box>
  );
}

function PaymentMethodInstallments({
  children,
}: {
  children: React.ReactText;
}) {
  return (
    <Text size="sm" color={colors.gray500}>
      Quantidade de parcelas:&nbsp;
      <Box color={colors.black} component="b">
        {children}
      </Box>
    </Text>
  );
}

PaymentMethod.Title = PaymentMethodTitle;
PaymentMethod.Icon = PaymentMethodIcon;
PaymentMethod.Installments = PaymentMethodInstallments;
