import React from 'react';
import { colors } from 'theme';
import { Box } from 'jsxstyle';
import { useApplication } from 'contexts/ApplicationContext';

export type UserPictureProps = {};

const IMAGE_SIZE = 34;
const UserPicture = () => {
  const { account } = useApplication();
  const [hasError, setHasError] = React.useState(false);
  return (
    <Box
      width={IMAGE_SIZE}
      height={IMAGE_SIZE}
      borderRadius={100}
      backgroundColor={colors.gray100}
      flexShrink={0}
      justifyContent="center"
      alignItems="center"
      display="flex"
      hoverBackgroundColor={colors.gray200}
      transition="all 125ms ease-in-out"
    >
      {!account.accountID ? null : hasError ? null : (
        <Box
          borderRadius={100}
          component="img"
          props={{
            src: `${process.env.REACT_APP_RESOURCES_URL}/users/pictures/${account.userID}`,
            width: IMAGE_SIZE,
            height: IMAGE_SIZE,
            onError: () => setHasError(true),
          }}
        />
      )}
    </Box>
  );
};

export default UserPicture;
