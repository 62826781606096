import React from 'react';
import { Row, Box } from 'jsxstyle';

import './CounterInput.css';
import { colors } from 'theme';

export type CounterInputProps = {
  min?: number;
  max?: number;
  value?: string;
  onChange?: (value: string) => void;
};

const CounterInput = ({
  min = undefined,
  max = undefined,
  value,
  onChange,
}: CounterInputProps) => {
  return (
    <Row alignItems="center">
      <Box
        component="button"
        backgroundColor="transparent"
        border={`1px solid ${colors.primary500}`}
        cursor="pointer"
        borderRadius="5px 0px 0px 5px"
        height={64}
        width={64}
        outline="none"
        focusBoxShadow={`0 0 0 0.25rem ${colors.primary500}33`}
        zIndex={1}
        position="relative"
        transition="all 200ms ease-in-out"
        props={{
          type: 'button',
          onClick: () => {
            if (min !== undefined && Boolean(Number(value) - 1 < min)) {
              return;
            }
            return onChange?.((Number(value) - 1).toString());
          },
        }}
      >
        <MinosIcon />
      </Box>
      <Box
        component="input"
        className="counter-input"
        props={{
          type: 'number',
          min,
          max,
          value,
          //@ts-ignore
          onChange: (event) => onChange?.(event.target.value),
          onBlur: () => {
            if (max !== undefined && Number(value) > max) {
              onChange?.(max.toString());
            }
            if (min !== undefined && Number(value) < min) {
              onChange?.(min.toString());
            }
          },
        }}
        height={64}
        width={160}
        border={`1px solid ${colors.gray300}`}
        borderLeft="none"
        borderRight="none"
        textAlign="center"
        fontSize={24}
        outline="none"
        //@ts-ignore
        focusBorderColor={colors.primary500}
        focusBoxShadow={`0 0 0 0.25rem ${colors.primary500}33`}
        transition="all 200ms ease-in-out"
      />
      <Box
        component="button"
        backgroundColor="transparent"
        border={`1px solid ${colors.primary500}`}
        cursor="pointer"
        borderRadius="0px 5px 5px 0px"
        height={64}
        width={64}
        outline="none"
        focusBoxShadow={`0 0 0 0.25rem ${colors.primary500}33`}
        zIndex={1}
        position="relative"
        transition="all 200ms ease-in-out"
        props={{
          type: 'button',
          onClick: () => {
            if (max !== undefined && Number(value) + 1 > max) {
              return;
            }
            return onChange?.((Number(value) + 1).toString());
          },
        }}
      >
        <PlusIcon />
      </Box>
    </Row>
  );
};

export default CounterInput;

const MinosIcon = () => (
  <svg
    width="20"
    height="4"
    viewBox="0 0 20 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.3333 3.33329H0.666626V0.666626H19.3333V3.33329Z"
      fill={colors.primary500}
    />
  </svg>
);

const PlusIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.3334 11.3333H11.3334V19.3333H8.66675V11.3333H0.666748V8.66663H8.66675V0.666626H11.3334V8.66663H19.3334V11.3333Z"
      fill={colors.primary500}
    />
  </svg>
);
