import { Auth } from 'aws-amplify';
import { addDays } from 'date-fns';
import usePartner from 'hooks/usePartner';
import queryString from 'query-string';
import React from 'react';
import getAccountAndWeddingInfoService from 'services/getAccountAndWeddingInfoService';
import useSWR from 'swr';
import CheckingAccountType from 'types/CheckingAccountType';
import WeddingType from 'types/WeddingType';
import { useAuth } from './AuthContext';

type AffiliateType = {
  key: string;
  expires: number;
};

type ContextType = {
  account: CheckingAccountType;
  wedding: WeddingType;
  name: string | undefined;
  username: string | undefined;
  pending: boolean;
  onbordingIsCompleted: boolean;
  revalidate?: () => void;
  affiliate?: AffiliateType;
};

export const ApplicationContext = React.createContext<ContextType>({
  pending: false,
  account: {} as CheckingAccountType,
  username: undefined,
  name: undefined,
  wedding: {} as WeddingType,
  onbordingIsCompleted: false,
  revalidate: undefined,
  affiliate: undefined,
});

type AuthContextProviderProps = {
  children: React.ReactNode;
};

const initialState = {
  username: '',
  name: '',
  account: {},
  wedding: {},
};

export default ({ children }: AuthContextProviderProps) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const { authenticated } = useAuth();
  const partnerID = usePartner();

  const { data, error, revalidate } = useSWR(
    authenticated ? '/application' : null,
    () => getAccountAndWeddingInfoService({ partnerID }),
    {
      revalidateOnFocus: false,
    },
  );

  const onbordingIsCompleted = Boolean(data?.wedding);

  const pending = !authenticated ? false : !data && !error;

  React.useEffect(() => {
    if (data?.account) {
      dispatch({
        type: 'account-loaded',
        payload: {
          account: data.account,
          wedding: data.wedding,
        },
      });
    }
  }, [data]);

  React.useEffect(() => {
    if (authenticated) {
      (async () => {
        const user = await Auth.currentAuthenticatedUser();
        dispatch({
          type: 'user-loaded',
          payload: {
            name: user.attributes.name,
            username: user.username,
          },
        });
      })();
    }
  }, [authenticated]);

  //AFFILIATE CHECK
  React.useEffect(() => {
    const storageRef = localStorage.getItem('ref');
    let affiliate = JSON.parse(storageRef || '{}');
    const { query } = queryString.parseUrl(document.location.href);
    if (query?.ref) {
      const date = new Date();
      if (affiliate?.key !== query.ref) {
        const expires = addDays(date, 90);
        affiliate = { key: query.ref, expires: expires.getTime() };
        localStorage.setItem('ref', JSON.stringify(affiliate));
      }
    }
    dispatch({ type: 'affiliate-loaded', payload: affiliate });
  }, []);

  return (
    <ApplicationContext.Provider
      value={{ pending, ...state, onbordingIsCompleted, revalidate }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};

export const useApplication = () => React.useContext(ApplicationContext);

type State = {
  username: string;
  name: string;
  account: CheckingAccountType;
  wedding: WeddingType;
};
type Action = {
  type: 'user-loaded' | 'account-loaded' | 'affiliate-loaded';
  payload: any;
};
const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'account-loaded':
      return {
        ...state,
        account: action.payload.account,
        wedding: action.payload.wedding,
      };
    case 'user-loaded':
      return {
        ...state,
        username: action.payload.username,
        name: action.payload.name,
      };
    case 'affiliate-loaded':
      return {
        ...state,
        affiliate: action.payload,
      };
    default:
      return state;
  }
};
