import publicApi from '../publicApi';
import ConfigType from 'types/ConfigType';

export default async (): Promise<ConfigType> => {
  try {
    const { data } = await publicApi.get(`/giftcashes/configs`);
    return data;
  } catch (error) {
    throw error;
  }
};
