import React from 'react';
import { Col } from 'jsxstyle';
import Text from './Text';
import { colors } from 'theme';
import Separator from './Separator';

export type InfoProps = {
  label: string;
  description: string;
};

const Info = ({ label, description }: InfoProps) => (
  <Col>
    <Text color={colors.gray500}>{label}</Text>
    <Separator size="xs" />
    <Text fontWeight="bold" color={colors.black}>
      {description}
    </Text>
  </Col>
);

export default Info;
