import { Machine, assign } from 'xstate';

export default Machine(
  {
    id: 'assistantMachine',
    initial: 'idle',
    context: {
      index: 0,
      messages: [],
      interactions: [],
      values: {},
    },
    states: {
      idle: {
        on: {
          TYPE: 'typing',
        },
      },
      typing: {
        onEntry: 'addMessage',
        on: {
          TYPING_END: [
            { target: 'end', cond: 'isNextMessageTheLast' },
            {
              target: 'typing',
              cond: 'isInvalidInput',
              actions: 'backToQuestion',
            },
            {
              target: 'typing',
              cond: 'isNextMessageFromAssistant',
              actions: 'incrementIndex',
            },
            { target: 'awaitingUserInput', actions: 'incrementIndex' },
          ],
        },
      },
      awaitingUserInput: {
        on: {
          SUBMIT: {
            target: 'typing',
            actions: ['setValue'],
          },
        },
      },
      error: {},
      end: {},
    },
  },
  {
    actions: {
      incrementIndex: assign({
        index: context => {
          let newIndex = context.index + 1;
          if (!context.interactions[newIndex].if) {
            return newIndex;
          } else {
            while (
              context.interactions[newIndex].if &&
              !context.interactions[newIndex].if(context.values)
            ) {
              newIndex++;
            }
            return newIndex;
          }
        },
      }),
      backToQuestion: assign({
        index: context => context.index - 2,
      }),
      setValue: assign({
        values: (context, { field, value }) => ({
          ...context.values,
          [field]: value,
        }),
      }),
      addMessage: assign({
        messages: ({ messages, interactions, index, values }) => {
          return [
            ...messages,
            {
              phrase:
                typeof interactions[index].phrase === 'function'
                  ? interactions[index].phrase(values)
                  : interactions[index].phrase,
              author: interactions[index].author,
              shouldErase: interactions[index].shouldErase,
              phraseAfterErase: interactions[index].phraseAfterErase,
              render: interactions[index].render
                ? interactions[index].render(values)
                : null,
              index,
            },
          ];
        },
      }),
    },
    guards: {
      isNextMessageFromAssistant: context => {
        return context.interactions[context.index + 1].author === 'assistant';
      },
      isInvalidInput: context => {
        return (
          !!context.interactions[context.index].validate &&
          !context.interactions[context.index].validate(context.values)
        );
      },
      isNextMessageTheLast: ({ interactions, index }) =>
        index + 1 >= interactions.length,
    },
  },
);
