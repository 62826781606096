import Clickable from 'components/Clickable';
import GiftInfo, { GiftInfoProps } from 'components/platform/GiftInfo';
import Separator from 'components/Separator';
import Text from 'components/Text';
import { useCart } from 'contexts/CartContext';
import { Col } from 'jsxstyle';
import paths from 'paths';
import React from 'react';
import { useHistory, useParams } from 'react-router';
import { colors, spacingSizes } from 'theme';
import GiftType from 'types/GiftType';

export type GiftListItemProps = {
  gift: GiftType;
  variant?: GiftInfoProps['variant'];
};

const GiftListItem = ({ gift, variant }: GiftListItemProps) => {
  const { contextID } = useParams<any>();
  const history = useHistory();
  const { items, addToCart, removeFromCart } = useCart();
  const isInCart = items.some(
    (item) => item.giftCashItemID === gift.giftCashItemID,
  );

  const handleGiftPress = () => {
    if (isInCart) {
      removeFromCart(gift);
      return;
    }

    addToCart(gift);
    history.push(
      paths.Platform.PERSONAL_DATA_SCREEN.replace(':contextID', contextID!),
    );
  };

  return (
    <Col
      border={`1px solid ${isInCart ? colors.secondary500 : colors.gray100}`}
      borderRadius={5}
      padding={spacingSizes.md}
      transition="all 250ms ease-in-out"
    >
      <GiftInfo
        giftCashItemID={gift.giftCashItemID}
        name={gift.name}
        pictureID={gift.pictureID}
        amount={gift.amount}
        useCustomPicture={gift.useCustomPicture}
        variant={variant}
      />
      <Separator />
      <Clickable
        backgroundColor={isInCart ? colors.white : colors.secondary500}
        borderRadius={30}
        border={`1px solid ${isInCart ? colors.secondary500 : 'transparent'}`}
        height={32}
        padding={`0 ${spacingSizes.md}px`}
        onClick={handleGiftPress}
        transition="all 100ms ease-in-out"
      >
        <Text
          size="xs"
          textTransform="uppercase"
          color={isInCart ? colors.secondary500 : colors.white}
        >
          {isInCart ? 'Remover do carrinho' : 'Comprar'}
        </Text>
      </Clickable>
    </Col>
  );
};

export default GiftListItem;
