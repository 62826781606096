import Button from 'components/Button';
import Container from 'components/Container';
import FadeIn from 'components/FadeIn';
import Header from 'components/Header';
import CheckoutSidebar from 'components/platform/CheckoutSidebar';
import Separator from 'components/Separator';
import Text from 'components/Text';
import { Box, Col } from 'jsxstyle';
import paths from 'paths';
import React from 'react';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import getPurchaseService from 'services/platform/getPurchaseService';
import useSWR from 'swr';
import { colors, mediaQueries } from 'theme';
import CustomGiftCardSection from './CustomGiftCardSection';
import PaymentMethodSection from './PaymentMethodSection';
import PurchaseInstallmentsSection from './PurchaseInstallmentsSection';
import PurchaseItemsSection from './PurchaseItemsSection';
import PurchaseSummarySection from './PurchaseSummarySection';

const PurchaseDetailScreen = () => {
  const history = useHistory();
  const { purchaseID } = useParams<any>();
  const { data, error } = useSWR(
    ['/purchaise/installments', purchaseID],
    (key, purchaseID) =>
      getPurchaseService({
        purchaseID,
      }),
  );

  const pending = !data && !error;

  const weddingGiftCard = data?.giftCashItems.find(
    (item) => item.productCategoryID === 'wedding_gift_card',
  );

  return (
    <FadeIn>
      <Col flex={1} backgroundColor={colors.gray50}>
        <Header variant="platform">
          <Header.Logo variant="horizontal" intent="secondary" />
          <Separator />
          <Header.Buttons>
            <Link
              to={`${paths.Platform.RSVP_SCREEN.replace(
                ':contextID',
                data?.contextID!,
              )}`}
            >
              <Text
                color="white"
                textTransform="uppercase"
                size="xs"
                fontWeight="bold"
              >
                Confirmação de presença
              </Text>
            </Link>
            <Separator />
            <Button
              color="secondary"
              onClick={() => {
                history.push(
                  paths.BRIDE_AND_GROOM_SCREEN.replace(
                    ':contextID',
                    data?.contextID!,
                  ),
                );
              }}
            >
              Comprar mais
            </Button>
          </Header.Buttons>
        </Header>
        <Col flex={1} marginTop={80}>
          <Separator />
          <Container>
            <Box
              mediaQueries={mediaQueries}
              display="flex"
              flexDirection="column"
              flex={1}
              //@ts-ignore
              xlMaxWidth="40vw"
              lgMaxWidth="45vw"
            >
              <Col flex={1}>
                <PurchaseItemsSection purchase={data!} pending={pending} />
                <Separator size="lg" />
                <PurchaseInstallmentsSection
                  purchase={data!}
                  pending={pending}
                />
                <CheckoutSidebar>
                  {weddingGiftCard && (
                    <React.Fragment>
                      <CustomGiftCardSection customGiftCard={weddingGiftCard} />
                      <Separator size="lg" />
                    </React.Fragment>
                  )}
                  <PaymentMethodSection purchase={data!} />
                  <Box flex={1} />
                  <Separator />
                  <PurchaseSummarySection purchase={data!} />
                </CheckoutSidebar>
              </Col>
              <Box
                mediaQueries={mediaQueries}
                display="block"
                //@ts-ignore
                xlDisplay="none"
                lgDisplay="none"
              >
                <Separator />
              </Box>
            </Box>
          </Container>
        </Col>
      </Col>
    </FadeIn>
  );
};

export default PurchaseDetailScreen;
