import Header from 'components/Header';
import Separator from 'components/Separator';
import useMedia from 'hooks/useMedia';
import { Box, Col } from 'jsxstyle';
import React from 'react';
import { colors, mediaQueries, spacingSizes } from 'theme';
import SideBar from './SideBar';

export type ScreenLayoutProps = {};

const ScreenLayout = ({
  children,
}: React.PropsWithChildren<ScreenLayoutProps>) => {
  const { media } = useMedia();
  return (
    <Col flex={1} backgroundColor={colors.gray50} overflowY="hidden">
      <Header variant="admin" backgroundColor="white">
        <Header.Logo variant="horizontal" />
        <Header.Buttons>
          <Header.Buttons.User />
        </Header.Buttons>
      </Header>
      <Box
        mediaQueries={mediaQueries}
        flex={1}
        display="flex"
        marginTop={80}
        //@ts-ignore
        lgPadding={`0 ${spacingSizes.lg}px`}
        xlPadding={`0 ${spacingSizes.lg}px`}
      >
        <Box
          mediaQueries={mediaQueries}
          //@ts-ignore
          xlWidth={240}
          lgWidth={240}
        >
          <Box
            mediaQueries={mediaQueries}
            marginTop={spacingSizes.xl}
            position="fixed"
            backgroundColor={colors.white}
            padding={`${spacingSizes.xs}px 0`}
            boxShadow="0px -4px 20px rgba(0, 0, 0, 0.1)"
            left={0}
            right={0}
            bottom={0}
            width="100%"
            //@ts-ignore
            xlWidth={240}
            lgWidth={240}
            xlBackgroundColor="transparent"
            lgBackgroundColor="transparent"
            xlTop={80}
            lgTop={80}
            lgBoxShadow="none"
            xlBoxShadow="none"
            zIndex={50}
          >
            <SideBar />
          </Box>
        </Box>

        <Col flex={1}>
          {media === 'desktop' && <Separator />}
          {children}
        </Col>
      </Box>
    </Col>
  );
};

export default ScreenLayout;
