import React from 'react';
import { Col } from 'jsxstyle';
import { spacingSizes, colors } from 'theme';
import useMedia from 'hooks/useMedia';
import Text from 'components/Text';
import Separator from 'components/Separator';
import TextInput from 'components/TextInput';
import Button from 'components/Button';
import Icon from 'components/Icon';
import useForm from 'hooks/useForm';
import InputGroup from 'components/InputGroup';
import isRequired from 'validators/isRequired';
import Form from 'components/Form';

type BrideAndGroomsNameFormProps = {
  onSubmit: (values: { groom: string; bride: string }) => void;
};

const BrideAndGroomsNameForm = ({ onSubmit }: BrideAndGroomsNameFormProps) => {
  const { media } = useMedia();
  const { getValue, setValue, getError, submit } = useForm({
    validations: {
      groom: [isRequired],
      bride: [isRequired],
    },
    onSubmit,
  });

  return (
    <Col
      width={media === 'desktop' ? 498 : '85%'}
      alignSelf="center"
      paddingTop={spacingSizes.xl}
    >
      <Text textAlign="center" size="lg">
        Qual é o nome dos noivos?
      </Text>
      <Separator size="xl" />
      <Form>
        <InputGroup error={getError('groom')}>
          <TextInput
            placeholder="Nome do Noivo"
            leftIcon={<Icon name="ring" color={colors.gray200} />}
            textAlign="center"
            value={getValue('groom')}
            onChange={(text) => setValue('groom', text)}
            error={getError('groom')}
          />
        </InputGroup>
        <InputGroup error={getError('bride')}>
          <TextInput
            placeholder="Nome da Noiva"
            leftIcon={<Icon name="ring" color={colors.gray200} />}
            textAlign="center"
            value={getValue('bride')}
            onChange={(text) => setValue('bride', text)}
            error={getError('bride')}
          />
        </InputGroup>
        <Separator size="xl" />
        <Button type="submit" color="secondary" onClick={() => submit()}>
          <Text
            color={colors.white}
            fontWeight="bold"
            textTransform="uppercase"
          >
            Enviar
          </Text>
        </Button>
      </Form>
      <Separator size="xl" />
    </Col>
  );
};

export default BrideAndGroomsNameForm;
