import Button from 'components/Button';
import Clickable from 'components/Clickable';
import CoupleThumbImage from 'components/CoupleThumbImage';
import Heading from 'components/Heading';
import Modal from 'components/Modal';
import Separator from 'components/Separator';
import Text from 'components/Text';
import { useApplication } from 'contexts/ApplicationContext';
import useAsync from 'hooks/useAsync';
import useMedia from 'hooks/useMedia';
import useToast from 'hooks/useToast';
import { Box, Col, Row } from 'jsxstyle';
import React from 'react';
import Cropper from 'react-easy-crop';
import saveWeddingPictureService from 'services/admin/saveWeddingPictureService';
import { colors, spacingSizes } from 'theme';
import getCroppedImg from 'utils/cropImage';
import handleError from 'utils/handleError';

export type CoupleImageProps = {
  size?: 'sm' | 'md' | 'lg';
  enableChangePhoto?: boolean;
};
const CoupleImage = ({
  size = 'md',
  enableChangePhoto = false,
}: CoupleImageProps) => {
  const { wedding, account } = useApplication();
  const [updateTime, setUpdateTime] = React.useState(new Date().getTime());
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const [image, setImage] = React.useState<{ url: string; file: File }>();
  const { dangerMessage } = useToast();

  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1.5);
  const [croppedAreaPixels, setCroppedAreaPixels] = React.useState();

  const { fetch, pending } = useAsync({
    promiseFn: saveWeddingPictureService,
    onData: () => {
      setImage(undefined);
      setUpdateTime(new Date().getTime());
    },
    onError: (error) => {
      const handledError = handleError({ error });
      dangerMessage({
        message:
          handledError.code === 'GENERIC'
            ? 'Não foi possível alterar sua foto, tente novamente.'
            : handledError.message,
      });
    },
  });

  const onCropComplete = React.useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const { media } = useMedia();

  return (
    <React.Fragment>
      <Clickable
        onClick={() => {
          if (enableChangePhoto) {
            fileInputRef.current?.click();
          }
        }}
        backgroundColor="transparent"
      >
        <CoupleThumbImage
          size={size}
          giftCashID={wedding ? wedding.giftCashID : ''}
          key={updateTime}
        />
        <Box
          component="input"
          display="none"
          //@ts-ignore
          props={{
            type: 'file',
            ref: fileInputRef,
            accept: 'image/*',
            //@ts-ignore
            onChange: async (event) => {
              if (event.target.files) {
                const file = event.target.files[0];
                var reader = new FileReader();
                reader.onload = function (e: any) {
                  setImage({ url: e.target.result, file });
                };

                reader.readAsDataURL(file);
              }
            },
          }}
        />
      </Clickable>
      <Modal
        visible={Boolean(image)}
        onRequestClose={() => {
          if (pending) {
            return;
          }

          setImage(undefined);
        }}
      >
        <Col
          width={media === 'phone' ? '100%' : 540}
          height={590}
          backgroundColor={colors.white}
          borderRadius={8}
          position="relative"
          overflow="hidden"
          padding={spacingSizes.md}
          justifyContent="space-between"
          alignItems="center"
        >
          <Heading size="xs">Atualizar foto do casal</Heading>

          <Cropper
            image={String(image?.url)}
            crop={crop}
            zoom={zoom}
            aspect={1}
            cropShape="round"
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            cropSize={{ width: 300, height: 300 }}
            style={{
              containerStyle: {
                width: 300,
                height: 300,
                position: 'relative',
              },
            }}
          />

          <Row
            justifyContent="flex-end"
            alignItems="center"
            alignSelf="stretch"
            margin={-spacingSizes.md}
            borderTop="1px solid #eee"
            padding={spacingSizes.md}
          >
            <Clickable
              disabled={pending}
              onClick={() => setImage(undefined)}
              backgroundColor="transparent"
            >
              <Text fontWeight="bolder" color={colors.secondary500}>
                Cancelar
              </Text>
            </Clickable>
            <Separator />
            <Button
              minimal
              color="secondary"
              loading={pending}
              onClick={async () => {
                try {
                  const croppedImage: Blob = await getCroppedImg(
                    image?.url!,
                    croppedAreaPixels as any,
                    0,
                  );

                  fetch({
                    accountID: account.accountID,
                    giftCashID: wedding.giftCashID,
                    image: new File([croppedImage], 'thumb.jpeg', {
                      type: 'image/jpeg',
                    }),
                    type: 'thumb',
                  });
                } catch (e) {}
              }}
            >
              <Text
                fontWeight="bolder"
                textTransform="capitalize"
                color={colors.white}
              >
                Salvar
              </Text>
            </Button>
          </Row>
        </Col>
      </Modal>
    </React.Fragment>
  );
};

export default CoupleImage;
