import React from 'react';

const ChevronLeft = () => (
  <svg
    width="4"
    height="7"
    viewBox="0 0 4 7"
    fill="black"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.3"
      d="M4 0.758333L1.51351 3.25L4 5.74167L3.24324 6.5L2.77174e-07 3.25L3.24324 9.02423e-09L4 0.758333Z"
    />
  </svg>
);

export default ChevronLeft;
