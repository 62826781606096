import React from 'react';
import useSWR from 'swr';
import getConfigsService from 'services/platform/getConfigsService';
import ConfigType from 'types/ConfigType';

type ContextType = {
  data?: ConfigType;
  pending: boolean;
};

export const ConfigContext = React.createContext<ContextType>({
  data: undefined,
  pending: true,
});

type AuthContextProviderProps = {
  children: React.ReactNode;
};

export default ({ children }: AuthContextProviderProps) => {
  const { data, error } = useSWR('/configs', getConfigsService);

  return (
    <ConfigContext.Provider value={{ data, pending: !data && !error }}>
      {children}
    </ConfigContext.Provider>
  );
};

export const useConfig = () => React.useContext(ConfigContext);
