import history from 'browserHistory';
import { useApplication } from 'contexts/ApplicationContext';
import { useAuth } from 'contexts/AuthContext';
import CartContext from 'contexts/CartContext';
import CheckoutContext from 'contexts/CheckoutContext';
import OnboardingContext from 'contexts/OnboardingContext';
import WeddingContext from 'contexts/WeddingContext';
import paths from 'paths';
import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import AuthenticationScreen from 'screens/AuthenticationScreen';
import LoadingScreen from 'screens/LoadingScreen';
import BrideAndGroomScreen from 'screens/platform/BrideAndGroomScreen';
import adminRoutes from './admin';
import onboardingRoutes from './onboarding';
import platformRoutes from './platform';

export type RoutesProps = {};

export default () => {
  const { pending: authPending, authenticated } = useAuth();
  const {
    pending: applicationPending,
    onbordingIsCompleted,
    wedding,
  } = useApplication();
  const contextID = wedding?.contextID;

  React.useLayoutEffect(() => {
    if (!applicationPending) {
      if (contextID) {
        const isInCouplePath =
          window.location.href === `${window.location.origin}/${contextID}`;
        const isInAdminPaths = window.location.pathname.includes('/admin');
        if (authenticated && !isInAdminPaths && !isInCouplePath) {
          history.push(paths.Admin.HOME_SCREEN);
          return;
        }
      }

      if (authenticated && !onbordingIsCompleted) {
        history.push(paths.Onboarding.GETTING_STARTED_SCREEN);
        return;
      }
    }
  }, [authenticated, onbordingIsCompleted, applicationPending, contextID]);

  if (authPending || applicationPending) {
    return <LoadingScreen />;
  }

  return (
    <Router history={history}>
      <CartContext>
        <CheckoutContext>
          <OnboardingContext>
            <Switch>
              <Route exact path="/entrar" component={AuthenticationScreen} />

              {authenticated &&
                [
                  ...(onbordingIsCompleted ? adminRoutes : onboardingRoutes),
                ].map((route) => (
                  <Route
                    exact
                    key={route.path}
                    path={route.path}
                    component={route.component}
                  />
                ))}

              {platformRoutes.map((route) => (
                <Route
                  exact
                  key={route.path}
                  path={route.path}
                  render={(props) => {
                    const Component: any = route.component;
                    return (
                      <WeddingContext>
                        {<Component {...props} />}
                      </WeddingContext>
                    );
                  }}
                />
              ))}
              <Route
                exact
                path={paths.BRIDE_AND_GROOM_SCREEN}
                render={(props) => {
                  return (
                    <WeddingContext>
                      <BrideAndGroomScreen />
                    </WeddingContext>
                  );
                }}
              />
            </Switch>
          </OnboardingContext>
        </CheckoutContext>
      </CartContext>
    </Router>
  );
};
