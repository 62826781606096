import React from 'react';
import Loading from 'react-loading';
import { SizeTypes } from 'typings';
import { colors } from 'theme';

export type SpinnerProps = {
  color?: string;
  size?: SizeTypes;
};

const Spinner = ({ color = colors.white, size = 'md' }: SpinnerProps) => {
  const spinnerSize = {
    xs: 14,
    sm: 18,
    md: 24,
    lg: 28,
    xl: 32,
  }[size];
  return (
    <Loading
      type="spin"
      color={color}
      height={spinnerSize}
      width={spinnerSize}
    />
  );
};

export default Spinner;
