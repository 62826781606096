import { useWedding } from 'contexts/WeddingContext';
import useGiftCard from 'hooks/useGiftCards';
import useMedia from 'hooks/useMedia';
import { Box, Col, Row } from 'jsxstyle';
import React from 'react';
import { WeddingItemType } from 'services/platform/getGiftListService';
import SwiperCore, { EffectCoverflow } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { colors, spacingSizes } from 'theme';
import Button from './Button';
import Card from './Card';
import Heading from './Heading';
import Icon from './Icon';
import Modal from './Modal';
import GiftCardImage from './platform/GiftCardImage';
import Separator from './Separator';
import SkeletonPlaceholder from './SkeletonPlaceholder';
import Text from './Text';

SwiperCore.use([EffectCoverflow]);

export type GiftCardSelectProps = {
  value?: WeddingItemType;
  onChange?: (product: WeddingItemType) => void;
  disabled?: boolean;
};

const GiftCardSelect = ({
  value,
  onChange,
  disabled = false,
}: GiftCardSelectProps) => {
  const [modalVisibility, setModalVisibility] = React.useState<
    'visible' | 'hidden'
  >('hidden');
  const [currentIndex, setCurrentIndex] = React.useState(2);
  const { media } = useMedia();

  const { wedding } = useWedding();

  const { data: items, pending } = useGiftCard({
    giftCashID: wedding?.giftCashID,
  });

  React.useEffect(() => {
    if (!value) {
      return;
    }
    const index = items?.findIndex(
      (item) => item.productID === value.productID,
    );

    setCurrentIndex(index!);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <React.Fragment>
      <Card>
        {pending ? (
          <SkeletonPlaceholder>
            <div style={{ display: 'flex', marginTop: 2 }}>
              <div style={{ width: 88, height: 88, borderRadius: 5 }} />
              <div style={{ marginLeft: 16.41 }}>
                <div style={{ width: 140, borderRadius: 4, height: 16 }} />
                <div
                  style={{
                    width: 70,
                    borderRadius: 4,
                    height: 16,
                    marginTop: 2,
                  }}
                />
                <div
                  style={{
                    width: 145,
                    borderRadius: 30,
                    height: 32,
                    marginTop: spacingSizes.md,
                  }}
                />
              </div>
            </div>
          </SkeletonPlaceholder>
        ) : (
          <Row>
            <GiftCardImage
              width={88}
              height={88}
              borderRadius={5}
              //@ts-ignore
              name={value.productName}
            />
            <Separator />
            <Box>
              <Text fontWeight="bold">
                {
                  //@ts-ignore
                  value.productName
                }
              </Text>
              <Separator />
              <Button
                color="secondary"
                minimal
                size="sm"
                onClick={() => setModalVisibility('visible')}
                disabled={disabled}
              >
                <Text fontWeight="bold">Alterar modelo</Text>
              </Button>
            </Box>
          </Row>
        )}
      </Card>
      <Modal
        visible={modalVisibility === 'visible'}
        onRequestClose={() => setModalVisibility('hidden')}
        responsive={false}
      >
        <Col alignItems="center" justifyContent="justify-content">
          <Heading color={colors.white}>Cartões Disponíveis</Heading>
          <Separator size="xl" />
          <Box
            width={media === 'desktop' || media === 'tablet' ? 850 : '100%'}
            position="relative"
          >
            <CheckMark />
            <Swiper
              grabCursor
              effect="coverflow"
              spaceBetween={
                media === 'desktop' || media === 'tablet'
                  ? -245
                  : // eslint-disable-next-line no-restricted-globals
                  screen.width > 400
                  ? 0
                  : -35
              }
              centeredSlides
              coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 700,
                modifier: 1,
                slideShadows: false,
              }}
              initialSlide={currentIndex}
              slidesPerView={media === 'desktop' || media === 'tablet' ? 4 : 2}
              onSlideChange={(swiper) => {
                if (items?.length) {
                  setCurrentIndex(swiper.activeIndex);
                  // setSelectedProduct(items[swiper.activeIndex]);
                }
              }}
            >
              {items?.map((item) => (
                <SwiperSlide key={item.productID}>
                  <GiftCardImage
                    width={
                      media === 'desktop' || media === 'tablet' ? 400 : 200
                    }
                    height={
                      media === 'desktop' || media === 'tablet' ? 400 : 200
                    }
                    //@ts-ignore
                    name={item.productName}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </Box>
          <Separator size="xl" />
          <Button
            disabled={!items?.length}
            onClick={() => {
              if (items?.[currentIndex]) {
                onChange?.(items?.[currentIndex]);
              }
              setModalVisibility('hidden');
            }}
            color="light"
          >
            <Text
              color={colors.white}
              fontWeight="bold"
              size="sm"
              textTransform="uppercase"
            >
              Selecionar
            </Text>
          </Button>
          {/* <Box
            component="button"
            padding="11px 52px"
            borderRadius={30}
            backgroundColor={`${colors.white}1a`}
            alignSelf="center"
            outline="none"
            border="none"
            hoverBackgroundColor={`${colors.white}33`}
            cursor="pointer"
          >
            <Text
              color={colors.white}
              fontWeight="bold"
              size="sm"
              textTransform="uppercase"
            >
              Selecionado
            </Text>
          </Box> */}
        </Col>
      </Modal>
    </React.Fragment>
  );
};

export default GiftCardSelect;

const CheckMark = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="absolute"
      width={60}
      height={60}
      borderRadius={100}
      backgroundColor={`${colors.secondary500}1a`}
      left={0}
      right={0}
      margin="0 auto"
      zIndex={2}
      top={-30}
    >
      <Box
        display="flex"
        width={40}
        height={40}
        borderRadius={100}
        backgroundColor={colors.secondary500}
        justifyContent="center"
        alignItems="center"
      >
        <Icon name="check" width={18} height={16} />
      </Box>
    </Box>
  );
};
