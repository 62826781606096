import React from 'react';
import { Box } from 'jsxstyle';
import Clickable from './Clickable';

export type ImageType = { url: string; file: File };
export type ImagePickerProps = {
  trigger?: React.ReactNode;
  onChange?: ({ url, file }: ImageType) => void;
};

const ImagePicker = ({ onChange, trigger }: ImagePickerProps) => {
  const ref = React.useRef<HTMLInputElement>(null);

  return (
    <React.Fragment>
      <Clickable
        background="none"
        justifyContent="flex-start"
        onClick={() => ref.current?.click()}
      >
        {trigger}
      </Clickable>
      <Box
        component="input"
        display="none"
        //@ts-ignore
        props={{
          type: 'file',
          ref,
          accept: 'image/*',
          //@ts-ignore
          onChange: async (event) => {
            if (event.target.files) {
              const file = event.target.files[0];
              var reader = new FileReader();
              reader.onload = function (e: any) {
                onChange?.({ url: e.target.result, file });
              };

              reader.readAsDataURL(file);
            }
          },
        }}
      />
    </React.Fragment>
  );
};

export default ImagePicker;
