import Button from 'components/Button';
import Card from 'components/Card';
import Form from 'components/Form';
import Info from 'components/Info';
import InputGroup from 'components/InputGroup';
import CheckoutScreenLayout from 'components/platform/CheckoutScreenLayout';
import CheckoutSidebar from 'components/platform/CheckoutSidebar';
import GiftList from 'components/platform/GiftList';
import Separator from 'components/Separator';
import Spinner from 'components/Spinner';
import Text from 'components/Text';
import TextInput from 'components/TextInput';
import { useCheckout } from 'contexts/CheckoutContext';
import useAsync from 'hooks/useAsync';
import useForm from 'hooks/useForm';
import useToast from 'hooks/useToast';
import { Box, Col } from 'jsxstyle';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import paths from 'paths';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import getAddressByZipCodeService from 'services/platform/getAddressByZipCodeService';
import { colors, mediaQueries } from 'theme';
import handleError from 'utils/handleError';
import isEmail from 'validators/isEmail';
import isRequired from 'validators/isRequired';
import isValidCpf from 'validators/isValidCpf';
import minLength from 'validators/minLength';

const cpfMask = (value: string) => {
  return {
    raw: value.replace(/\D/g, ''),
    text: value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1'),
  };
};

const cepMask = (value: string) => {
  return {
    raw: value.replace(/\D/g, ''),
    text: value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,3})/, '$1-$2')
      .replace(/(-\d{3})\d+?$/, '$1'),
  };
};

const phoneMask = (value: string) => {
  return {
    raw: value.replace(/\D/g, ''),
    text: value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{1})(\d{4})(\d{1,4})/, '$1 $2-$3')
      .replace(/(-\d{4})\d+?$/, '$1'),
  };
};

export type CheckoutPersonalDataScreenProps = {};

const CheckoutPersonalDataScreen = () => {
  const { contextID } = useParams();
  const history = useHistory();
  const { values } = useCheckout();
  const { setValues } = useCheckout();
  const { dangerMessage } = useToast();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { fetch, data = {} as any, pending, error } = useAsync({
    promiseFn: getAddressByZipCodeService,
    onError: (error) => {
      const handledError = handleError({ error });
      dangerMessage({ message: handledError.message });
    },
  });

  const { getValue, setValue, getError, submit } = useForm({
    initialValues: {
      ...values,
      giftCard: true,
    },
    validations: {
      buyerName: [isRequired],
      buyerDocument: [
        (value: any) => isRequired(value.raw),
        (value: any) => minLength(value.raw, 11),
        (value: any) => isValidCpf(value.raw),
      ],
      buyerEmail: [isRequired, isEmail],
      buyerZipCode: [isRequired, (value: any) => minLength(value.raw, 8)],
    },
    onSubmit: (values) => {
      setValues({
        ...values,
        buyerAddress: data.street,
        buyerNeighborhood: data.neighborhood,
        buyerCity: data.city,
        buyerState: data.federativeUnit,
        buyerZipCode: data.zipPostalCode,
      });
      history.push(
        paths.Platform.CHECKOUT_SCREEN.replace(':contextID', contextID!),
      );
    },
  });

  const zipCode = getValue('buyerZipCode.raw');

  React.useEffect(() => {
    if (zipCode.length === 8) {
      fetch({ zipCode });
    }
  }, [zipCode, fetch]);

  return (
    <CheckoutScreenLayout>
      <Form>
        <Card>
          <Text fontWeight="bold">
            Para continuar precisamos de alguns dos seus dados
          </Text>
          <Separator />
          <InputGroup label="Nome" error={getError('buyerName')}>
            <TextInput
              placeholder="Informe o seu nome completo"
              value={getValue('buyerName')}
              onChange={(value) => setValue('buyerName', value)}
              error={getError('buyerName')}
            />
          </InputGroup>
          <InputGroup label="Celular" error={getError('buyerPhone')}>
            <TextInput
              placeholder="Informe um número do seu celular"
              value={getValue('buyerPhone.text')}
              onChange={(value) => setValue('buyerPhone', phoneMask(value))}
              error={getError('buyerPhone')}
              type="tel"
              maxLength={16}
            />
          </InputGroup>
          <InputGroup label="CPF" error={getError('buyerDocument')}>
            <TextInput
              placeholder="000.000.000-00"
              value={getValue('buyerDocument.text')}
              onChange={(value) => setValue('buyerDocument', cpfMask(value))}
              error={getError('buyerDocument')}
              type="tel"
              maxLength={14}
            />
          </InputGroup>
          <InputGroup label="E-mail" error={getError('buyerEmail')}>
            <TextInput
              placeholder="Informe seu e-mail"
              value={getValue('buyerEmail')}
              onChange={(value) => setValue('buyerEmail', value)}
              error={getError('buyerEmail')}
              type="email"
            />
          </InputGroup>
          <InputGroup
            label="CEP"
            error={error ? 'CEP Inválido' : getError('buyerZipCode')}
          >
            <TextInput
              placeholder="Informe seu CEP"
              value={getValue('buyerZipCode.text')}
              onChange={(value) => setValue('buyerZipCode', cepMask(value))}
              error={error ? 'CEP Inválido' : getError('buyerZipCode')}
              type="tel"
              rightIcon={
                pending ? (
                  <Spinner size="sm" color={colors.secondary500} />
                ) : null
              }
            />
          </InputGroup>
          <Col minHeight={120}>
            {!isEmpty(data) && (
              <Col>
                <Box
                  display="flex"
                  flexDirection="column"
                  mediaQueries={mediaQueries}
                  //@ts-ignore
                  xlFlexDirection="row"
                  lgFlexDirection="row"
                >
                  <Box flexBasis="50%">
                    <Info label="Rua" description={get(data, 'street')} />
                  </Box>
                  <Separator />
                  <Info
                    label="Bairro"
                    description={get(data, 'neighborhood')}
                  />
                </Box>
                <Separator />
                <Box
                  display="flex"
                  flexDirection="column"
                  mediaQueries={mediaQueries}
                  //@ts-ignore
                  xlFlexDirection="row"
                  lgFlexDirection="row"
                >
                  <Box flexBasis="50%">
                    <Info label="Cidade" description={get(data, 'city')} />
                  </Box>
                  <Separator />
                  <Info
                    label="Estado"
                    description={get(data, 'federativeUnit')}
                  />
                </Box>
              </Col>
            )}
          </Col>
          <Separator size="lg" />
          <Col alignItems="flex-start">
            <Button
              minimal
              size="sm"
              color="secondary"
              type="submit"
              disabled={!data}
              onClick={submit}
            >
              Continuar
            </Button>
          </Col>
        </Card>
      </Form>
      <CheckoutSidebar>
        <Text fontWeight="bold">Produtos no carrinho</Text>
        <Separator />
        <GiftList />
      </CheckoutSidebar>
    </CheckoutScreenLayout>
  );
};

export default CheckoutPersonalDataScreen;
