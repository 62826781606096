import Success from 'assets/icons/exported/Success';
import Button from 'components/Button';
import Heading from 'components/Heading';
import Separator from 'components/Separator';
import Text from 'components/Text';
import { useWedding } from 'contexts/WeddingContext';
import { Box, Col } from 'jsxstyle';
import success from 'lottie/succcess-confetti.json';
import paths from 'paths';
import React from 'react';
import Lottie from 'react-lottie';
import { useHistory, useParams } from 'react-router-dom';
import { mediaQueries, spacingSizes } from 'theme';

const defaultOptions = {
  loop: true,
  autoplay: false,
  animationData: success,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export type ContactFormProps = {
  onSubmit?: (values: { count: number }) => void;
};

const ContactForm = ({ onSubmit }: ContactFormProps) => {
  const [animationState, setAnimationState] =
    React.useState<'paused' | 'running'>('paused');
  const history = useHistory();
  const { contextID } = useParams();
  const { wedding } = useWedding();

  React.useLayoutEffect(() => {
    setTimeout(() => {
      setAnimationState('running');
    }, 1000);
  }, []);
  return (
    <Col
      flex={1}
      mediaQueries={mediaQueries}
      padding={`80px ${spacingSizes.md}px 20px ${spacingSizes.md}px`}
      //@ts-ignore
      lgPadding="116px 160px 64px 160px"
      xlPadding="116px 160px 64px 160px"
    >
      <Box alignSelf="center" position="relative">
        <Lottie
          isPaused={animationState === 'paused'}
          options={defaultOptions}
          height={300}
          width={300}
          style={{
            position: 'absolute',
            left: -80,
            right: 0,
            top: -80,
            zIndex: -1,
          }}
        />
        <Success />
      </Box>
      <Separator size="xl" />
      <Heading textAlign="center">
        Uhull!
        <br />
        Presença confirmada!
      </Heading>
      <Separator />
      <Text textAlign="center">
        {Boolean(wedding?.personName1) && Boolean(wedding?.personName2)
          ? `Que tal presentear ${wedding.personName1} e ${wedding.personName2} nesse
        momento especial?`
          : null}
      </Text>
      <Box display="flex" flex={1} />
      <Box
        mediaQueries={mediaQueries}
        width="80%"
        display="flex"
        alignSelf="center"
        //@ts-ignore
        xlWidth={368}
        lgWidth={368}
      >
        <Button
          type="submit"
          color="secondary"
          onClick={() => {
            history.push(
              paths.BRIDE_AND_GROOM_SCREEN.replace(':contextID', contextID!),
            );
          }}
          style={{ width: '100%' }}
        >
          Escolher presentes
        </Button>
      </Box>
    </Col>
  );
};

export default ContactForm;
