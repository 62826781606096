import ScreenContainer from 'components/admin/ScreenContainer';
import ScreenLayout from 'components/admin/ScreenLayout';
import ScrollBox from 'components/admin/ScrollBox';
import CopyButton from 'components/CopyButton';
import Heading from 'components/Heading';
import Icon from 'components/Icon';
import CoupleImagePicker from 'components/platform/CoupleImagePicker';
import Separator from 'components/Separator';
import Text from 'components/Text';
import { useApplication } from 'contexts/ApplicationContext';
import useMedia from 'hooks/useMedia';
import { Box, Col, Row } from 'jsxstyle';
import paths from 'paths';
import React from 'react';
import { colors, mediaQueries, spacingSizes } from 'theme';
import CominSoonTag from './ComingSoonTag';
import ConfirmedPresence from './ConfirmedPresence';
import DashboardItem from './DashboardItem';
import GiftsAvailable from './GiftsAvailable';
import TotalReceived from './TotalReceived';

export type HomeScreenProps = {};

const HomeScreen = () => {
  const { media } = useMedia();
  const { wedding } = useApplication();
  const { origin } = window.location;
  const couplePath = paths.BRIDE_AND_GROOM_SCREEN.replace(
    ':contextID',
    wedding.contextID,
  );
  const weddingURL = `${origin}${couplePath}`;

  return (
    <ScreenLayout>
      <ScreenContainer>
        <Row alignSelf="flex-start" alignItems="center" flexWrap="wrap">
          <CoupleImagePicker size="lg" enableChangePhoto />
          <Separator />
          <Col paddingTop={media === 'phone' ? spacingSizes.xs : 0}>
            <Heading color={colors.white}>
              Olá {wedding.personName1} & {wedding.personName2}
            </Heading>
            <Separator size="xs" />
            <Row alignItems="center">
              <Text color={colors.white}>{weddingURL}</Text>
              <Separator size="xs" />
              <CopyButton value={weddingURL} color={colors.white} />
            </Row>
          </Col>
        </Row>
        <Separator />
        <Text color={colors.white}>Confira seus últimos números</Text>
        <Separator />
        <ScrollBox>
          <Box
            mediaQueries={mediaQueries}
            display="flex"
            flexDirection="column"
            zIndex={1}
            //@ts-ignore
            lgFlexDirection="row"
            xlFlexDirection="row"
          >
            <TotalReceived />
            <Separator />
            <GiftsAvailable />
            <Separator />
            <ConfirmedPresence />
            <Separator />
            <DashboardItem
              disabled
              icon={<Icon name="eye" color={colors.gray500} />}
              description="Visitas do perfil"
              label={<CominSoonTag />}
              helperText="Editar página do casal"
            />
          </Box>
        </ScrollBox>
      </ScreenContainer>
    </ScreenLayout>
  );
};

export default HomeScreen;
