import useSWR from 'swr';
import getGiftListService, {
  WeddingItemType,
} from 'services/platform/getGiftListService';
import ProductType from 'types/ProductType';

type Params = {
  giftCashID: string;
};
export default ({ giftCashID }: Params) => {
  const shouldFetch = Boolean(giftCashID);

  const { data: items, revalidate, mutate, error } = useSWR(
    shouldFetch ? ['/wedding/items', giftCashID] : null,
    (url, id) => getGiftListService({ giftCashID: id }),
    { revalidateOnFocus: false, dedupingInterval: 0 },
  );

  return {
    gifts: items as Array<WeddingItemType & ProductType> | undefined,
    pending: !items && !error,
    revalidate,
    mutate,
  };
};
