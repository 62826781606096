import Success from 'assets/icons/exported/Success';
import CleanLogo from 'components/CleanLogo';
import FadeIn from 'components/FadeIn';
import Heading from 'components/Heading';
import Separator from 'components/Separator';
import Text from 'components/Text';
import { useCart } from 'contexts/CartContext';
import useMedia from 'hooks/useMedia';
import { Box, Col } from 'jsxstyle';
import Paths from 'paths';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { colors, mediaQueries, spacingSizes } from 'theme';

export type PaymentConfirmationProps = {};

const PaymentConfirmation = () => {
  const { contextID } = useParams();
  const { clearCart } = useCart();

  const { media } = useMedia();

  React.useEffect(() => {
    clearCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FadeIn>
      <Col flex={1} backgroundColor={colors.gray50}>
        <Col
          backgroundColor={colors.secondary500}
          position="absolute"
          left={0}
          right={0}
          height={320}
        />
        <Col zIndex={1} alignItems="center" padding={spacingSizes.md}>
          <CleanLogo color={colors.white} />
          <Separator size="xl" />
          <Col
            mediaQueries={mediaQueries}
            width="100%"
            borderRadius={5}
            backgroundColor={colors.white}
            padding={`${spacingSizes.md}px 40px`}
            boxShadow="0px 4px 8px rgba(0, 0, 0, 0.1);"
            //@ts-ignore
            xlWidth={580}
            lgWidth={580}
            xlPadding={`${spacingSizes.md}px 30px`}
            lgPadding={`${spacingSizes.md}px 30px`}
          >
            {/* <Col
              width={80}
              height={80}
              backgroundColor={colors.green200}
              borderRadius={100}
              justifyContent="center"
              alignItems="center"
              alignSelf="center"
            >
              <Icon
                name="check"
                width={32}
                height={32}
                color={colors.green500}
              />
            </Col> */}
            <Box alignSelf="center">
              <Success
                width={media === 'phone' ? 84 : 112}
                height={media === 'phone' ? 84 : 112}
              />
            </Box>
            <Separator />
            <Heading size="xs" textAlign="center">
              Pagamento realizado com successo
            </Heading>
            <Separator size="sm" />
            <Text color={colors.gray300} textAlign="center">
              Seu pagamento foi realizado com sucesso, em breve esse valor
              estará disponível na conta dos noivos.
            </Text>
          </Col>
        </Col>
        <Separator />
        <Link
          to={Paths.BRIDE_AND_GROOM_SCREEN.replace(':contextID', contextID!)}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            padding={`6px 20px`}
            border={`1px solid ${colors.gray100}`}
            borderRadius={30}
            background="none"
          >
            <Text size="sm" fontWeight="bold" textTransform="uppercase">
              Voltar para página do casal
            </Text>
          </Box>
        </Link>
      </Col>
    </FadeIn>
  );
};

export default PaymentConfirmation;
