import React from 'react';
import TextInput from './TextInput';
import Spinner from './Spinner';
import { colors, spacingSizes } from 'theme';

export type SearchInputProps = {
  value?: string;
  error?: string;
  placeholder?: string;
  minimal?: boolean;
  onChange?: (value: string) => void;
  loading?: boolean;
  autoFocus?: boolean;
};

const SearchInput = ({
  value,
  error,
  onChange,
  placeholder = 'Pesquise por casais',
  minimal = false,
  loading,
  autoFocus = false,
}: SearchInputProps) => (
  <TextInput
    value={value}
    error={Boolean(error)}
    onChange={onChange}
    leftIcon={<SearchIcon />}
    iconSpacing={spacingSizes.lg}
    rightIcon={loading && <Spinner color={colors.secondary500} size="sm" />}
    placeholder={placeholder}
    size={minimal ? 'sm' : 'md'}
    autoFocus
  />
);

export default SearchInput;

const SearchIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.4286 10.0571H10.6971L10.4229 9.78286C11.3371 8.77714 11.8857 7.40571 11.8857 5.94286C11.8857 2.65143 9.23429 0 5.94286 0C2.65143 0 0 2.65143 0 5.94286C0 9.23429 2.65143 11.8857 5.94286 11.8857C7.40571 11.8857 8.77714 11.3371 9.78286 10.4229L10.0571 10.6971V11.4286L14.6286 16L16 14.6286L11.4286 10.0571ZM5.94286 10.0571C3.65714 10.0571 1.82857 8.22857 1.82857 5.94286C1.82857 3.65714 3.65714 1.82857 5.94286 1.82857C8.22857 1.82857 10.0571 3.65714 10.0571 5.94286C10.0571 8.22857 8.22857 10.0571 5.94286 10.0571Z"
      fill="black"
      fillOpacity="0.3"
    />
  </svg>
);
