import { TextAlignProperty } from 'csstype';
import { Box, Col } from 'jsxstyle';
import React from 'react';
import { colors, spacingSizes } from 'theme';
import { SizeTypes } from 'typings';

type InputSizeType = Exclude<SizeTypes, 'xs' | 'xl'>;

export interface TextInputProps
  extends Omit<React.HTMLProps<HTMLInputElement>, 'size' | 'onChange'> {
  error?: string | boolean;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  textAlign?: TextAlignProperty;
  size?: InputSizeType;
  iconSpacing?: number;
  onChange?: (value: string) => void;
}

const getHeightBySize = (size: InputSizeType) =>
  ({
    sm: 32,
    md: 50,
    lg: 64,
  }[size]);

const getFontSizeBySize = (size: InputSizeType) =>
  ({
    sm: 14,
    md: 18,
    lg: 20,
  }[size]);

const TextInput = React.forwardRef(
  (
    {
      placeholder,
      type = 'text',
      error,
      name,
      leftIcon,
      rightIcon,
      textAlign = 'left',
      size = 'md',
      iconSpacing = spacingSizes.xl,
      onChange,
      ...rest
    }: TextInputProps,
    ref,
  ) => (
    <Col position="relative" justifyContent="center" alignSelf="stretch">
      {leftIcon && (
        <Box position="absolute" left={12}>
          {leftIcon}
        </Box>
      )}
      <Box
        component="input"
        border="1px solid #dbdbdb"
        paddingLeft={
          textAlign === 'left' && leftIcon ? iconSpacing : spacingSizes.sm
        }
        paddingRight={
          textAlign === 'right' && rightIcon ? iconSpacing : spacingSizes.sm
        }
        borderRadius={6}
        outline="none"
        background="transparent"
        width="100%"
        fontSize={getFontSizeBySize(size)}
        boxShadow={error && '0 0 0 0.25rem #dc354540'}
        //@ts-ignore
        focusBoxShadow={!error && `0 0 0 0.2rem ${colors.primary50}`}
        borderColor={error && colors.red500}
        //@ts-ignore
        focusBorderColor={!error && colors.primary300}
        transition="all 0.2s ease"
        disabledOpacity={0.7}
        backgroundColor={colors.white}
        props={{
          placeholder,
          onChange: (event) => {
            if (onChange) {
              onChange(event.target.value);
            }
          },
          type,
          name,
          ...rest,
        }}
        WebkitAppearance="none"
        cursor="text"
        height={getHeightBySize(size)}
        textAlign={textAlign}
        placeholderColor={colors.gray200}
      />
      {rightIcon && (
        <Box position="absolute" right={12}>
          {rightIcon}
        </Box>
      )}
    </Col>
  ),
);

export default TextInput;
