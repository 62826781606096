import axios from 'axios';
import { Auth } from 'aws-amplify';

const api = axios.create({ baseURL: `${process.env.REACT_APP_API_URL}` });
api.interceptors.request.use(async (config) => {
  const session = await Auth.currentSession();
  const authenticatedUser = await Auth.currentAuthenticatedUser();
  const jwtToken = session.getIdToken().getJwtToken();
  const userID = authenticatedUser.attributes['custom:userID'];

  config.headers.Authorization = jwtToken;
  config.url = '/users/' + userID + config.url;

  return config;
});
export default api;
