import React from 'react';
import { useMachine } from '@xstate/react';
import typingMachine from 'statecharts/typingMachine';

type DelayType = {
  erasing: number;
  typing: number;
  [key: string]: number;
};

type AnimatedTypingProps = {
  phrase: string;
  onTypingEnd: () => void;
  delay?: DelayType;
  shouldErase?: boolean;
  phraseAfterErase?: string;
};

const AnimatedTyping = ({
  phrase: text,
  onTypingEnd,
  shouldErase,
  phraseAfterErase,
  delay,
}: AnimatedTypingProps) => {
  const [current] = useMachine(typingMachine, {
    context: {
      phrase: text,
      phraseAfterErase,
      shouldErase,
      delay,
    },
  });

  React.useEffect(() => {
    if (current.matches('completed')) {
      onTypingEnd();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);

  const { typing } = current.context as any;

  return <React.Fragment>{typing}</React.Fragment>;
};

export default AnimatedTyping;
