import Button from 'components/Button';
import GiftCardSelect from 'components/GiftCardSelect';
import Heading from 'components/Heading';
import Info from 'components/Info';
import InputGroup from 'components/InputGroup';
import BackLink from 'components/platform/BackLink';
import CheckoutScreenLayout from 'components/platform/CheckoutScreenLayout';
import CheckoutSidebar from 'components/platform/CheckoutSidebar';
import GiftCartListItem from 'components/platform/GiftCartListItem';
import GiftList from 'components/platform/GiftList';
import PurchaseSummary from 'components/platform/PurchaseSummary';
import Separator from 'components/Separator';
import Switch from 'components/Switch';
import Text from 'components/Text';
import TextArea from 'components/TextArea';
import { BILLET_FEE, useCart } from 'contexts/CartContext';
import { useCheckout } from 'contexts/CheckoutContext';
import { useWedding } from 'contexts/WeddingContext';
import useAsync from 'hooks/useAsync';
import useForm from 'hooks/useForm';
import useGiftCard from 'hooks/useGiftCards';
import useMedia from 'hooks/useMedia';
import useToast from 'hooks/useToast';
import { Box, Col, Row, useMatchMedia } from 'jsxstyle';
import { default as Paths, default as paths } from 'paths';
import React from 'react';
import ReCaptcha from 'react-google-recaptcha';
import { useHistory, useParams } from 'react-router-dom';
import purchaseService, {
  PurchaseServiceParams,
} from 'services/platform/purchaseService';
import { colors, mediaQueries } from 'theme';
// import purchaseWebHookService from 'services/platform/purchaseWebHookService';
import handleError from 'utils/handleError';
import InstallmentForm from './InstallmentForm';
import PaymentMethodForm from './PaymentMethodForm';

const RECAPTCH_KEY = '6LfHCOQUAAAAAAKTup0UnXBKIO88qY3HfFpbNvfJ';

const MAX_MESSAGE_LENGTH = 300;

export type CheckoutScreenProps = {};

const CheckoutReviewScreen = () => {
  const { contextID } = useParams<any>();
  const { wedding } = useWedding();
  const history = useHistory();
  const { values: buyerInfo } = useCheckout();
  const { items, totalAmount } = useCart();
  const { dangerMessage } = useToast();

  const recaptcha = React.useRef<ReCaptcha>(null);
  const { data: giftCards } = useGiftCard({
    giftCashID: wedding?.giftCashID,
  });
  const { media } = useMedia();
  const isDesktop = useMatchMedia('screen and (min-width: 992px)');

  const { fetch, pending } = useAsync<PurchaseServiceParams>({
    promiseFn: async (values: any) => {
      const recaptchaToken = await recaptcha.current?.executeAsync();
      return purchaseService({ ...values, recaptchaToken });
    },
    onData: (purchase) => {
      history.push(
        Paths.Platform.PURCHASE_DETAIL_SCREEN.replace(
          ':purchaseID',
          purchase.giftCashPurchaseID,
        ),
      );
    },
    onError: (error) => {
      const handledError = handleError({ error });
      dangerMessage({ message: handledError.message });
    },
  });

  const { getValue, setValue, submit } = useForm({
    initialValues: {
      buyerName: buyerInfo.buyerName,
      buyerPhone: buyerInfo.buyerPhone,
      buyerDocument: buyerInfo.buyerDocument,
      buyerEmail: buyerInfo.buyerEmail,
      buyerAddress: buyerInfo.buyerAddress,
      buyerNeighborhood: buyerInfo.buyerNeighborhood,
      buyerCity: buyerInfo.buyerCity,
      buyerState: buyerInfo.buyerState,
      buyerZipCode: buyerInfo.buyerZipCode,
      giftCashCarol: (giftCards?.[0].giftCashID === '983922b6-f93c-4354-8906-cde767b2a46a' ? true : false),
      giftCardEnabled: (giftCards?.[0].giftCashID === '983922b6-f93c-4354-8906-cde767b2a46a' ? false : true),
      selectedGiftCardID: giftCards?.[0],
      paymentMethod: 'pix',
      installments: 1,
    },
    onSubmit: async (values) => {
      const giftCashItems = items.reduce(
        (acc: Array<{ giftCashItemID: string; amount: number }>, item) => {
          const quantity = item.quantity;
          return [
            ...acc,
            ...Array.from({ length: quantity }).map((_) => ({
              giftCashItemID: item.giftCashItemID,
              amount: item.amount,
            })),
          ];
        },
        [],
      );

      if (wedding?.giftCashID) {
        fetch({
          paymentMethod: values.paymentMethod,
          giftCashID: wedding?.giftCashID,
          giftCashItems: values.giftCardEnabled
            ? [
                ...giftCashItems,
                {
                  giftCashItemID: values.selectedGiftCardID.giftCashItemID,
                  amount: values.selectedGiftCardID.amount,
                },
              ]
            : giftCashItems,
          buyerName: values.buyerName,
          buyerDocument: values.buyerDocument.raw,
          buyerEmail: values.buyerEmail,
          amount: totalAmount + BILLET_FEE,
          message: values.message,
          buyerAddress: values.buyerAddress,
          buyerNeighborhood: values.buyerNeighborhood,
          buyerCity: values.buyerCity,
          buyerState: values.buyerState,
          buyerZipCode: values.buyerZipCode,
          installments: values.installments,
          recaptchaToken: values.recaptchaToken,
        });
      }
    },
  });

  return (
    <CheckoutScreenLayout>
      <BackLink
        label="Revisão da compra"
        to={paths.Platform.PERSONAL_DATA_SCREEN.replace(
          ':contextID',
          contextID!,
        )}
      />
      <Separator />
      <Heading size="xs" color={colors.secondary500}>
        Quem está comprando o presente
      </Heading>
      <Separator />
      <Col position="relative">
        <Info label="Nome" description={buyerInfo.buyerName} />
        <Separator />
        <Box
          display="flex"
          mediaQueries={mediaQueries}
          flexDirection="column"
          //@ts-ignore
          xlFlexDirection="row"
          lgFlexDirection="row"
        >
          <Info label="CPF" description={buyerInfo.buyerDocument.text} />
          <Separator size={isDesktop ? 'xl' : 'md'} />
          <Info label="E-mail" description={buyerInfo.buyerEmail} />
        </Box>
        <Separator />
        {!Boolean(getValue('giftCashCarol')) && (
          <InputGroup label="Deixe uma mensagem para os noivos!">
            <TextArea
              value={getValue('message')}
              onChange={(value) => setValue('message', value)}
              placeholder="Ex: Parabéns noivos! Aproveitem bem esse presente!"
              maxLength={MAX_MESSAGE_LENGTH}
            />
          </InputGroup>
        )}
        {Boolean(getValue('giftCashCarol')) && (
          <InputGroup label="Deixe uma mensagem!">
            <TextArea
              value={getValue('message')}
              onChange={(value) => setValue('message', value)}
              placeholder="Ex: Parabéns, aproveitem bem esse presente!"
              maxLength={MAX_MESSAGE_LENGTH}
            />
          </InputGroup>
        )}
        <Box position="absolute" bottom={10} right={0}>
          <Text size="sm" color={colors.gray500} textAlign="right">
            ({getValue('message').length} / {MAX_MESSAGE_LENGTH})
          </Text>
        </Box>
      </Col>
      <Separator />
      <Heading size="xs" color={colors.secondary500}>
        Itens de presente
      </Heading>
      <Separator />
      <GiftList
        renderItem={({ item }) => (
          <GiftCartListItem key={item.giftCashItemID} gift={item} />
        )}
      />
      <ReCaptcha ref={recaptcha} size="invisible" sitekey={RECAPTCH_KEY} />
      <CheckoutSidebar>
      {!Boolean(getValue('giftCashCarol')) && (
        <Row>
          <Switch
            id="gift-card"
            value={Boolean(getValue('giftCardEnabled'))}
            onChange={(value) => setValue('giftCardEnabled', value)}
          />
          <Separator />
          <Box
            component="label"
            props={{ htmlFor: 'gift-card' }}
            userSelect="none"
            msUserSelect="none"
            MozUserSelect="none"
            cursor="pointer"
          >
            <Text fontWeight="bold" textTransform="uppercase" size="sm">
              Presente personalizado
            </Text>
          </Box>
        </Row>)}
        <Separator />
        {!Boolean(getValue('giftCashCarol')) && (
        <Box
          transition="all 200ms ease-in-out"
          opacity={Boolean(getValue('giftCardEnabled')) ? 1 : 0.3}
        >
          <Text size="sm" color={colors.black}>
            Personalize seu e-mail com um cartão virtual ao casal!
          </Text>
          <Separator />
          <GiftCardSelect
            disabled={!Boolean(getValue('giftCardEnabled'))}
            value={getValue('selectedGiftCardID') as any}
            onChange={(id) => setValue('selectedGiftCardID', id)}
          />
        </Box>)}
        <Separator />
        <PaymentMethodForm
          paymentMethod={getValue('paymentMethod') as any}
          onChangePaymentMethod={(value) => setValue('paymentMethod', value)}
        />
        <Separator />
        <InstallmentForm
          installments={Number(getValue('installments'))}
          onChangeInstallments={(value) => setValue('installments', value)}
        />
        {media !== 'desktop' ? <Separator size="lg" /> : <Separator />}

        <PurchaseSummary
          paymentMethod={getValue('paymentMethod') as any}
          installments={Number(getValue('installments'))}
          giftCardEnabled={Boolean(getValue('giftCardEnabled'))}
        />
        <Separator />
        <Button
          loading={pending}
          disabled={!Boolean(items.length)}
          minimal
          size="sm"
          color="secondary"
          onClick={() => submit()}
        >
          Continuar
        </Button>
      </CheckoutSidebar>
    </CheckoutScreenLayout>
  );
};

export default CheckoutReviewScreen;
