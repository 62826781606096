// import barCode from 'assets/images/bar-code.svg';
import Heading from 'components/Heading';
import NumberFormat from 'components/NumberFormat';
import Separator from 'components/Separator';
import Text from 'components/Text';
import { BILLET_FEE, PIX_FEE, useCart } from 'contexts/CartContext';
import { Row } from 'jsxstyle';
import { round } from 'lodash';
import React from 'react';
import { PaymentMethodTypes } from 'screens/platform/CheckoutReviewScreen/PaymentMethodForm';
import { colors } from 'theme';

const GIFT_CARD_AMOUNT = 5;

type PurchaseSummaryProps = {
  paymentMethod: PaymentMethodTypes;
  installments: number;
  giftCardEnabled?: boolean;
};

const PurchaseSummary = ({
  giftCardEnabled = false,
  installments,
  paymentMethod,
}: PurchaseSummaryProps) => {
  const { totalAmount: totalCartAmount } = useCart();

  const isBilletPaymentMethod = paymentMethod === 'billet';
  const isPixPaymentMethod = paymentMethod === 'pix';
  const installmentAmount = round(totalCartAmount / installments, 2);
  const paymentMethodFeeAmount = isBilletPaymentMethod
    ? installments * BILLET_FEE
    : (isPixPaymentMethod) ? installments * PIX_FEE : 0;
  const giftCardAmount = giftCardEnabled ? GIFT_CARD_AMOUNT : 0;
  const cents = safeSubtractFloat(
    totalCartAmount,
    installmentAmount * installments,
  );

  const installmentWithFeesAmount =
    installmentAmount + paymentMethodFeeAmount + giftCardAmount + cents;

  const totalAmount = totalCartAmount + paymentMethodFeeAmount + giftCardAmount;

  return (
    <React.Fragment>
      <Row alignItems="center" justifyContent="space-between">
        <Text size="sm" color={colors.gray900}>
          Valor total dos itens
        </Text>
        <Text size="sm" fontWeight="bold" color={colors.secondary500}>
          <NumberFormat
            amount={totalCartAmount + giftCardAmount}
            type="currency"
            minimumFractionDigits={2}
          />
        </Text>
      </Row>
      <React.Fragment>
        <Separator />
        <Row alignItems="center" justifyContent="space-between">
          <Text size="sm" color={colors.gray900}>
            Tarifa da compra
          </Text>
          <Text size="sm" fontWeight="bold" color={colors.black}>
            +
            <NumberFormat
              amount={paymentMethodFeeAmount}
              type="currency"
              minimumFractionDigits={2}
            />
          </Text>
        </Row>
      </React.Fragment>
      <Separator />
      {installments > 1 && (
        <React.Fragment>
          <Row alignItems="center" justifyContent="space-between">
            <Text size="sm" color={colors.gray900}>
              Parcelas
            </Text>
            <Text size="sm" fontWeight="bold" color={colors.secondary500}>
              <NumberFormat
                amount={installmentWithFeesAmount}
                type="currency"
                minimumFractionDigits={2}
              />{' '}
              + {installments - 1}x{' '}
              <NumberFormat
                amount={installmentAmount}
                type="currency"
                minimumFractionDigits={2}
              />
            </Text>
          </Row>
          <Separator size="sm" />
        </React.Fragment>
      )}
      <Row alignItems="center" justifyContent="space-between">
        <Text>Valor total</Text>
        <Heading color={colors.secondary500} size="sm">
          <NumberFormat
            type="currency"
            amount={totalAmount}
            minimumFractionDigits={2}
          />
        </Heading>
      </Row>
    </React.Fragment>
  );
};

export default PurchaseSummary;

function safeSubtractFloat(float: number, float2: number) {
  return (float * 100 - float2 * 100) / 100;
}
