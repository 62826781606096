import React from 'react';
import { format, parseISO } from 'date-fns';
import { Row, Col } from 'jsxstyle';
import { colors, spacingSizes } from 'theme';
import Separator from 'components/Separator';
import Text from 'components/Text';
import CoupleThumbImage from 'components/CoupleThumbImage';

export type SearchListItemProps = {
  coupleName: string;
  date: string;
  location: string;
  giftCashID: string;
};

const SearchListItem = ({
  coupleName,
  date,
  location,
  giftCashID,
}: SearchListItemProps) => (
  <Row
    backgroundColor={colors.white}
    boxShadow="0px 4px 8px rgba(0, 0, 0, 0.1);"
    borderRadius={5}
    padding={spacingSizes.md}
    minHeight={100}
    transition="all 200ms ease-in-out"
    //@ts-ignore
    hoverTransform="translateY(-5px)"
  >
    <CoupleThumbImage size="md" giftCashID={giftCashID} />
    <Separator />
    <Col>
      <Text size="sm">{coupleName}</Text>
      <Separator size="xs" />
      <Text size="xs" color={colors.gray500}>
        Data do casamento: {format(parseISO(date), 'dd/MM/yyyy')}
      </Text>
      <Separator size="xs" />
      <Text size="xs" color={colors.gray500}>
        {location}
      </Text>
    </Col>
  </Row>
);

export default SearchListItem;
