import checkedBackground from 'assets/images/checked.svg';
import uncheckedBackground from 'assets/images/unchecked.svg';
import { Box } from 'jsxstyle';
import React from 'react';
import { colors } from 'theme';
import { SizeTypes } from 'typings';
import Text from './Text';

export type RadioInputProps = {
  label: React.ReactNode;
  value: string;
  id?: string;
  size?: SizeTypes;
  checked?: boolean;
  onChange?: (value: string) => void;
};

const RadioInput = ({
  value,
  label,
  id,
  checked,
  size = 'md',
  onChange,
}: RadioInputProps) => (
  <React.Fragment>
    <Box
      component="label"
      display="flex"
      alignItems="center"
      padding="0.4em 0.8em"
      border="1px solid"
      borderColor={checked ? colors.secondary200 : '#dbdbdb'}
      backgroundColor={checked ? `${colors.secondary50}4d` : 'transparent'}
      cursor="pointer"
      borderRadius={6}
      props={{ htmlFor: id, onClick: () => onChange?.(value) }}
    >
      <Box
        component="img"
        width={radioSizeMap[size]}
        height={radioSizeMap[size]}
        props={{ src: checked ? checkedBackground : uncheckedBackground }}
      />
      <Box minWidth={gapSizeMap[size]} />
      {typeof label === 'string' || typeof label === 'number' ? (
        <Text
          size={textSizeMap[size]}
          color={checked ? colors.secondary500 : undefined}
        >
          {label}
        </Text>
      ) : (
        label
      )}
    </Box>
  </React.Fragment>
);

export default RadioInput;

const textSizeMap = {
  xs: 'xs' as SizeTypes,
  sm: 'xs' as SizeTypes,
  md: 'sm' as SizeTypes,
  lg: 'md' as SizeTypes,
  xl: 'lg' as SizeTypes,
};

const gapSizeMap = {
  xs: 10,
  sm: 12,
  md: 10,
  lg: 12,
  xl: 14,
};

const radioSizeMap = {
  xs: 12,
  sm: 14,
  md: 16,
  lg: 20,
  xl: 24,
};
