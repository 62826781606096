import React from 'react';
import { Box } from 'jsxstyle';

type ContainerProps = {
  children: React.ReactNodeArray | React.ReactNode;
};
const Container = ({ children }: ContainerProps) => {
  return (
    <Box position="relative" className="container">
      {children}
    </Box>
  );
};

export default Container;
