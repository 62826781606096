import React from 'react';

type ContextType = {
  values: {
    buyerName: string;
    buyerDocument: {
      text: string;
      raw: string;
    };
    buyerPhone: {
      text: string;
      raw: string;
    };
    buyerEmail: string;
    buyerAddress: string;
    buyerNeighborhood: string;
    buyerCity: string;
    buyerState: string;
    buyerZipCode: string;
  };
  setValues: React.Dispatch<React.SetStateAction<typeof initialValues>>;
};

const initialValues = {
  buyerName: '',
  buyerDocument: {
    text: '',
    raw: '',
  },
  buyerPhone: {
    text: '',
    raw: '',
  },
  buyerEmail: '',
  buyerAddress: '',
  buyerNeighborhood: '',
  buyerCity: '',
  buyerState: '',
  buyerZipCode: '',
};

export const CheckoutContext = React.createContext<ContextType>({
  values: initialValues,
  setValues: () => {},
});

type AuthContextProviderProps = {
  children: React.ReactNode;
};

export default ({ children }: AuthContextProviderProps) => {
  const [values, setValues] = React.useState(initialValues);

  return (
    <CheckoutContext.Provider value={{ values, setValues }}>
      {children}
    </CheckoutContext.Provider>
  );
};

export const useCheckout = () => React.useContext(CheckoutContext);
