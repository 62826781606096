import React from 'react';
import { Col, Box } from 'jsxstyle';
import Heading from 'components/Heading';
import Separator from 'components/Separator';
import InputGroup from 'components/InputGroup';
import TextInput from 'components/TextInput';
import { mediaQueries, spacingSizes } from 'theme';
import useForm from 'hooks/useForm';
import Button from 'components/Button';
import isRequired from 'validators/isRequired';
import Form from 'components/Form';

export type NameOnInvitationFormProps = {
  onSubmit?: (values: { name: string }) => void;
};

const NameOnInvitationForm = ({ onSubmit }: NameOnInvitationFormProps) => {
  const { getValue, setValue, getError, submit } = useForm({
    validations: {
      name: [isRequired],
    },
    onSubmit,
  });
  return (
    <Col
      flex={1}
      mediaQueries={mediaQueries}
      padding={`80px ${spacingSizes.md}px 20px ${spacingSizes.md}px`}
      //@ts-ignore
      lgPadding="116px 160px 64px 160px"
      xlPadding="116px 160px 64px 160px"
    >
      <Heading textAlign="center">Qual é o nome que está no convite?</Heading>
      <Separator size="xl" />
      <Form onSubmit={submit}>
        <InputGroup error={getError('name')}>
          <TextInput
            placeholder="Ex: Daniel e Família"
            textAlign="center"
            value={getValue('name')}
            onChange={(text) => setValue('name', text)}
            error={getError('name')}
          />
        </InputGroup>
        <Separator />
        <Box
          mediaQueries={mediaQueries}
          width="80%"
          display="flex"
          alignSelf="center"
          //@ts-ignore
          xlWidth={368}
          lgWidth={368}
        >
          <Button color="secondary" onClick={submit} style={{ width: '100%' }}>
            Próximo
          </Button>
        </Box>
      </Form>
    </Col>
  );
};

export default NameOnInvitationForm;
