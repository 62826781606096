import React from 'react';

type ContextType = {
  url: string;
  setUrl: React.Dispatch<React.SetStateAction<string>>;
};

export const OnboardingContext = React.createContext<ContextType>({
  url: '',
  setUrl: () => {},
});

type AuthContextProviderProps = {
  children: React.ReactNode;
};

export default ({ children }: AuthContextProviderProps) => {
  const [url, setUrl] = React.useState<string>('');

  return (
    <OnboardingContext.Provider value={{ url, setUrl }}>
      {children}
    </OnboardingContext.Provider>
  );
};

export const useOnboarding = () => React.useContext(OnboardingContext);
