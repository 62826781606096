import React from 'react';
import Card from 'components/Card';
import Separator from 'components/Separator';
import Text from 'components/Text';
import { colors } from 'theme';
import { Link } from 'react-router-dom';
import { Box } from 'jsxstyle';

export type DashboardItemProps = {
  icon: React.ReactNode;
  label: React.ReactNode;
  description: string;
  disabled?: boolean;
  helperText: string;
  link?: string;
};

const DashboardItem = ({
  icon,
  label,
  description,
  disabled,
  helperText,
  link = '#',
}: DashboardItemProps) => {
  return (
    <Card flex={1}>
      {icon}
      <Separator size="sm" />
      {label}
      <Separator size="xs" />
      <Separator size="xs" />
      <Text size="sm" color={disabled ? colors.gray500 : colors.black}>
        {description}
      </Text>
      <Separator size="lg" />
      <Box
        cursor={disabled ? 'not-allowed' : 'pointer'}
        component={Link}
        props={{ to: link }}
        pointerEvents={disabled && 'none'}
      >
        <Text
          color={disabled ? colors.gray500 : colors.black}
          textTransform="uppercase"
          size="xs"
        >
          {helperText}
        </Text>
      </Box>
    </Card>
  );
};

export default DashboardItem;
