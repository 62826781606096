import { Box, CSSProperties } from 'jsxstyle';
import React from 'react';

interface ClickableProps extends CSSProperties {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  children: React.ReactNode;
  disabled?: boolean;
  type?: 'button' | 'submit';
}

const Clickable = ({
  onClick,
  children,
  disabled = false,
  type = 'button',
  ...style
}: ClickableProps) => {
  function handleClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event?.stopPropagation?.();
    onClick?.(event);
  }

  return (
    <Box
      component="button"
      outline="none"
      border="none"
      cursor="pointer"
      display="flex"
      justifyContent="center"
      alignItems="center"
      props={{ onClick: handleClick, disabled, type }}
      {...style}
    >
      {children}
    </Box>
  );
};

export default Clickable;
