import privateApi from '../privateApi';

type Params = {
  accountID: string | number;
  giftCashID: string;
  giftCashItemID: string;
  status?: 'PAID';
};
export default async ({
  accountID,
  giftCashID,
  giftCashItemID,
  status,
}: Params): Promise<{ total: number }> => {
  try {
    const { data } = await privateApi.get(
      `/accounts/${accountID}/giftcashes/${giftCashID}/items/${giftCashItemID}/purchases/counts`,
      {
        params: { status },
      },
    );
    return data;
  } catch (error) {
    throw error;
  }
};
