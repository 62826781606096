import GettingStartedScreen from 'screens/GettingStartedScreen';
import ChatScreen from 'screens/ChatScreen';
import SuccessScreen from 'screens/SuccessScreen';
import Paths from '../../paths';
import AuthenticationScreen from 'screens/AuthenticationScreen';

const paths = Paths.Onboarding;

export default [
  {
    path: paths.SIGN_IN,
    component: AuthenticationScreen,
  },
  {
    path: paths.GETTING_STARTED_SCREEN,
    component: GettingStartedScreen,
  },
  {
    path: paths.CHAT_SCREEN,
    component: ChatScreen,
  },
  {
    path: paths.SUCCESS_SCREEN,
    component: SuccessScreen,
  },
];
