export const colors = {
  white: '#ffffff',
  black: '#262626',
  primary50: '#E7E7F3',
  primary100: '#C2C3E0',
  primary200: '#999CCD',
  primary300: '#7174B9',
  primary400: '#5356AA',
  primary500: '#35389B',
  primary600: '#303292',
  primary700: '#292B89',
  primary800: '#21247F',
  primary900: '#16176D',
  secondary50: '#FFEBF3',
  secondary100: '#FFC1DE',
  secondary200: '#FF99C9',
  secondary300: '#FF84BE',
  secondary400: '#FE70B3',
  secondary500: '#FE499E',
  secondary600: '#FF1F87',
  secondary700: '#F50172',
  secondary800: '#DF0269',
  secondary900: '#B80056',
  tertiary50: '#a9bbcb',
  tertiary100: '#7f99b1',
  tertiary200: '#6988a4',
  tertiary300: '#547797',
  tertiary400: '#3f668a',
  tertiary500: '#2a567d',
  tertiary600: '#254d70',
  tertiary700: '#214464',
  tertiary800: '#1d3c57',
  tertiary900: '#19334b',
  gray50: '#f2f2f2',
  gray100: '#e6e6e6',
  gray200: '#d9d9d9',
  gray300: '#cdcdcd',
  gray400: '#c7c7c7',
  gray500: '#c1c1c1',
  gray600: '#adadad',
  gray700: '#9a9a9a',
  gray800: '#878787',
  gray900: '#737373',
  red50: '#ffebee',
  red100: '#ffcdd2',
  red200: '#ef9a9a',
  red300: '#e57373',
  red400: '#ef5350',
  red500: '#f44336',
  red600: '#e53935',
  red700: '#d32f2f',
  red800: '#c62828',
  red900: '#b71c1c',
  blue50: '#e3f2fd',
  blue100: '#bbdefb',
  blue200: '#90caf9',
  blue300: '#64b5f6',
  blue400: '#42a5f5',
  blue500: '#2196f3',
  blue600: '#1e88e5',
  blue700: '#1976d2',
  blue800: '#1565c0',
  blue900: '#0d47a1',
  green100: '#edf8e7',
  green200: '#caeab9',
  green300: '#a7dc8b',
  green400: '#72c745',
  green500: '#3eb300',
  green600: '#339300',
  green700: '#287200',
  green800: '#1d5200',
  green900: '#113100',
  orange50: '#fff3e0',
  orange100: '#ffe0b2',
  orange200: '#ffcc80',
  orange300: '#ffb74d',
  orange400: '#ffa726',
  orange500: '#ff9800',
  orange600: '#fb8c00',
  orange700: '#f57c00',
  orange800: '#ef6c00',
  orange900: '#e65100',
};

export const spacingSizes = {
  xs: 4,
  sm: 15,
  md: 20,
  lg: 40,
  xl: 60,
};

export const mediaQueries = {
  xs: '(max-width: 575.98px)',
  sm: '(min-width: 576px) and (max-width: 767.98px)',
  md: '(min-width: 768px) and (max-width: 991.98px)',
  lg: '(min-width: 992px) and (max-width: 1199.98px)',
  xl: '(min-width: 1200px)',
};
