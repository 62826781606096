import Card from 'components/Card';
import Icon from 'components/Icon';
import Modal from 'components/Modal';
import Separator from 'components/Separator';
import Text from 'components/Text';
import { Box } from 'jsxstyle';
import React from 'react';
import { spacingSizes, colors } from 'theme';

type InstallmentBilletInfoModalProps = {
  visible: boolean;
  onRequestClose: () => void;
};
const InstallmentBilletInfoModal = ({
  visible,
  onRequestClose,
}: InstallmentBilletInfoModalProps) => {
  return (
    <Modal visible={visible} onRequestClose={onRequestClose}>
      <Card
        maxWidth={576}
        paddingLeft={spacingSizes.lg}
        paddingRight={spacingSizes.lg}
      >
        <Box
          width={84}
          height={84}
          borderRadius={84}
          backgroundColor={`${colors.primary500}1a`}
          justifyContent="center"
          alignItems="center"
          display="flex"
        >
          <Icon
            name="barcode"
            width={36}
            height={36}
            color={colors.primary500}
          />
        </Box>
        <Separator />
        <Text size="lg" fontWeight="bold">
          Como funciona o pagamento por boleto ou Pix?
        </Text>
        <Separator />
        <Text fontWeight="bold">Regras do pagamento</Text>
        <Separator />
        <Text size="sm" color={colors.gray800}>
          &bull; Valor mínimo da compra é de R$40,00
        </Text>
        <Separator />
        <Text size="sm" color={colors.gray800}>
          &bull; A taxa por compra é R$5,00
        </Text>
        <Separator />
        <Text size="sm" color={colors.gray800}>
          &bull; A taxa por parcela também é R$5,00
        </Text>
        <Separator />
        <Text fontWeight="bold">
          Por que não trabalhamos com cartão de crédito?
        </Text>
        <Separator />
        <Text size="sm" color={colors.gray800}>
          A forma de pagamento com cartão de créditos tem uma taxa alta para a
          plataforma. E para entregarmos o menor custo possível aos noivos,
          decidimos trabalhar somente com o boleto ou PIX parcelado. Pois temos uma
          melhor experiência no parcelamento e não comprometemos você e nem os
          noivos com faturas e taxas :)
        </Text>
      </Card>
    </Modal>
  );
};

export default InstallmentBilletInfoModal;
