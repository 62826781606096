import publicApi from './publicApi';
import ProductType from 'types/ProductType';
type Params = {
  categoryID?: string;
};
export default async ({ categoryID }: Params): Promise<ProductType[]> => {
  try {
    const { data } = await publicApi.get('/giftcashes/products', {
      params: { categoryID: categoryID || 'wedding' },
    });
    return data;
  } catch (error) {
    throw error;
  }
};
