import Card from 'components/Card';
import Clickable from 'components/Clickable';
import Icon, { IconProps } from 'components/Icon';
import NumberFormat from 'components/NumberFormat';
import Separator from 'components/Separator';
import SkeletonPlaceholder from 'components/SkeletonPlaceholder';
import Text from 'components/Text';
import { compareAsc, parseISO } from 'date-fns';
import { Box, Col, Row } from 'jsxstyle';
import React from 'react';
import { colors, mediaQueries, spacingSizes } from 'theme';
import PurchaseDetailType, { InstallmentType } from 'types/PurchaseDetailType';
import CopyButton from '../../../components/CopyButton';
import BilletDownloader from './BilletDownloader';

export type PurchaseInstallmentsSectionProps = {
  purchase: PurchaseDetailType;
  pending: Boolean;
};

function PurchaseInstallmentsSection({
  purchase,
  pending,
}: PurchaseInstallmentsSectionProps) {
  const isSingleInstallment =
    purchase?.paymentMethod === 'BILLET' || purchase?.paymentMethod === 'PIX';
  const isBilletPaymentMethod =
    purchase?.paymentMethod === 'BILLET_INSTALLMENT' ||
    purchase?.paymentMethod === 'BILLET';
  const isPixPaymentMethod =
    purchase?.paymentMethod === 'PIX_INSTALLMENT' ||
    purchase?.paymentMethod === 'PIX';

  const installments = (purchase?.installments || []).sort(
    (installment0, installment1) => {
      if (!Boolean(installment0.creationDate)) return -1;
      if (!Boolean(installment1.creationDate)) return -1;

      return compareAsc(
        parseISO(installment0.creationDate!),
        parseISO(installment1.creationDate!),
      );
    },
  );

  const activeInstallment = installments.find(
    (installment) =>
      installment.status === 'GENERATED' || installment.status === 'CREATED',
  );
  const activeInstallmentID =
    activeInstallment?.billetID || activeInstallment?.pixTransactionID;

  return (
    <React.Fragment>
      <Text fontWeight="bold" color={colors.primary500}>
        {isSingleInstallment
          ? isBilletPaymentMethod
            ? 'Boleto'
            : 'Pix'
          : isBilletPaymentMethod
          ? 'Boletos'
          : 'Parcelas Pix'}
      </Text>
      {pending ? (
        <React.Fragment>
          <Separator />
          <Card>
            <SkeletonPlaceholder>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div style={{ width: 32, height: 32, borderRadius: 32 }} />
                <div style={{ marginLeft: spacingSizes.sm }}>
                  <div style={{ width: 100, height: 12, borderRadius: 4 }} />
                  <div
                    style={{
                      width: 100,
                      height: 14,
                      borderRadius: 4,
                      marginTop: spacingSizes.xs,
                    }}
                  />
                  <div
                    style={{
                      width: 100,
                      height: 12,
                      borderRadius: 4,
                      marginTop: spacingSizes.md,
                    }}
                  />
                  <div
                    style={{
                      width: 250,
                      height: 14,
                      borderRadius: 4,
                      marginTop: spacingSizes.xs,
                    }}
                  />
                </div>
                <div style={{ width: 100, height: 12, borderRadius: 4 }} />
              </div>
            </SkeletonPlaceholder>
          </Card>
        </React.Fragment>
      ) : (
        installments
          .sort((a, b) =>
            a.giftCashPurchaseInstallmentNumber <
            b.giftCashPurchaseInstallmentNumber
              ? -1
              : 1,
          )
          .map((installment) => {
            const installmentID =
              installment.billetID || installment.pixTransactionID;
            const active = activeInstallmentID === installmentID;

            return (
              <React.Fragment key={installmentID}>
                <Separator />
                <Installment active={active}>
                  <Row alignSelf="flex-start" alignItems="center">
                    <Installment.Icon
                      iconName={isPixPaymentMethod ? 'pix' : 'barcode'}
                      width={20}
                      height={21}
                    />
                    <Separator />
                    {installment.giftCashPurchaseInstallmentNumber > 0 && (
                      <Installment.Number>
                        {installment.giftCashPurchaseInstallmentNumber}
                      </Installment.Number>
                    )}
                  </Row>
                  <Separator />
                  <Col flex={1}>
                    <Installment.Value>
                      {installment.amount || 0}
                    </Installment.Value>
                    <Separator />
                    {isBilletPaymentMethod && (
                      <Installment.BilletCode>
                        {isSingleInstallment
                          ? installment.billetDigitableLine
                          : installment.digitableLine}
                      </Installment.BilletCode>
                    )}
                    {isPixPaymentMethod && (
                      <Installment.PixCode>
                        {installment.emv}
                      </Installment.PixCode>
                    )}
                    <Separator />
                  </Col>
                  <Row alignSelf="flex-start" alignItems="center">
                    {isSingleInstallment && (
                      <Installment.Status status={purchase.status} />
                    )}
                    {!isSingleInstallment && (
                      <Installment.Status status={installment.status} />
                    )}                    
                    <Separator />
                    {isPixPaymentMethod ? (
                      <Installment.PixQRCodeDownload
                        installment={installment}
                      />
                    ) : (
                      <Installment.BilletDownload
                        installment={installment}
                        purchase={purchase}
                      />
                    )}
                  </Row>
                </Installment>
              </React.Fragment>
            );
          })
      )}
      <Separator />
    </React.Fragment>
  );
}

export default PurchaseInstallmentsSection;

function Installment({
  active,
  children,
}: {
  active: boolean;
  children: React.ReactNode | React.ReactNodeArray;
}) {
  return (
    <Card
      boxShadow={active ? `0 0 0 .2rem ${colors.secondary500}33` : undefined}
      border={active ? `1px solid ${colors.secondary500}` : undefined}
    >
      <Col mediaQueries={mediaQueries} flex={1} flexWrap="wrap">
        {children}
      </Col>
    </Card>
  );
}

function InstallmentIcon({
  iconName,
  width = 18,
  height = 18,
}: {
  iconName: IconProps['name'];
  width?: number;
  height?: number;
}) {
  return (
    <Box
      width={32}
      height={32}
      borderRadius={32}
      backgroundColor={`${colors.primary500}1a`}
      justifyContent="center"
      alignItems="center"
      display="flex"
      flexShrink={0}
    >
      <Icon
        name={iconName}
        width={width}
        height={height}
        color={colors.primary500}
      />
    </Box>
  );
}

function InstallmentNumber({ children = 0 }: { children: number }) {
  return (
    <React.Fragment>
      <Text color={colors.gray500} size="md" fontWeight="bold">
        Parcela {children}
      </Text>
    </React.Fragment>
  );
}

function InstallmentValue({ children = 0 }: { children: number }) {
  return (
    <React.Fragment>
      <Text color={colors.gray500} size="xs">
        Valor
      </Text>
      <Separator size="xs" />
      <Text size="xs" fontWeight="bold">
        <NumberFormat amount={children} type="currency" />
      </Text>
    </React.Fragment>
  );
}

function InstallmentBilletCode({ children }: { children: React.ReactText }) {
  return (
    <React.Fragment>
      <Text color={colors.gray500} size="xs">
        Código do boleto
      </Text>
      <Separator size="xs" />
      <Text size="xs" fontWeight="bold">
        {children}
      </Text>
    </React.Fragment>
  );
}

function InstallmentPixCode({ children }: { children: string }) {
  return (
    <Box flex={1}>
      <Row>
        <Box overflowWrap="anywhere">
          <Text color={colors.gray500} size="xs">
            Pix Copia e Cola
          </Text>
          <Separator size="xs" />
          <Text size="xs" fontWeight="bold">
            {children}
          </Text>
        </Box>
        <Separator />
        <CopyButton value={children} color={colors.secondary500} />
      </Row>
    </Box>
  );
}

function InstallmentStatus({ status }: { status: string }) {
  return (
    <React.Fragment>
      {status === 'PAID' ? (
        <Text size="xs" textTransform="uppercase" color={colors.green400}>
          Pago
        </Text>
      ) : (
        <Text size="xs" textTransform="uppercase" color={colors.secondary500}>
          Pendente
        </Text>
      )}
    </React.Fragment>
  );
}

export function InstallmentPixQRCodeDownload({
  installment,
  children = 'Download',
}: {
  installment: InstallmentType;
  children?: string;
}) {
  const {
    image: base64,
    giftCashPurchaseInstallmentNumber: installmentNumber,
  } = installment as any;
  return (
    <Clickable
      onClick={() => {
        //console.log({ installment });
        const link = document.createElement('a');
        link.download =
          installmentNumber > 0
            ? `parcela-${installmentNumber}.png`
            : `codigo-pagamento.png`;
        link.href = 'data:image/png;base64,' + base64;
        link.click();
      }}
      borderRadius={30}
      backgroundColor={`${colors.primary500}1a`}
      alignSelf="flex-start"
      padding={`8px ${spacingSizes.sm}px`}
    >
      <Icon name="download" />
      <Separator size="sm" />
      <Text
        size="xs"
        textTransform="uppercase"
        color={colors.primary500}
        fontWeight="bold"
      >
        {children}
      </Text>
    </Clickable>
  );
}
function InstallmentBilletDownload({
  purchase,
  installment,
}: {
  purchase: PurchaseDetailType;
  installment: InstallmentType;
}) {
  const { accountID, userID, giftCashPurchaseID, giftCashID } = purchase;
  return (
    <BilletDownloader
      accountID={accountID}
      userID={userID}
      purchaseID={giftCashPurchaseID}
      giftCasheID={giftCashID}
      installmentNumber={installment.giftCashPurchaseInstallmentNumber}
    >
      {({ generatePDF }) => (
        <Clickable
          onClick={generatePDF}
          borderRadius={30}
          backgroundColor={`${colors.primary500}1a`}
          alignSelf="flex-start"
          padding={`8px ${spacingSizes.sm}px`}
        >
          <Icon name="download" />
          <Separator size="sm" />
          <Text
            size="xs"
            textTransform="uppercase"
            color={colors.primary500}
            fontWeight="bold"
          >
            Download
          </Text>
        </Clickable>
      )}
    </BilletDownloader>
  );
}

Installment.Icon = InstallmentIcon;
Installment.Number = InstallmentNumber;
Installment.Value = InstallmentValue;
Installment.Status = InstallmentStatus;
Installment.BilletCode = InstallmentBilletCode;
Installment.BilletDownload = InstallmentBilletDownload;
Installment.PixQRCodeDownload = InstallmentPixQRCodeDownload;
Installment.PixCode = InstallmentPixCode;
