import appstore from 'assets/images/app-store.png';
import { Box } from 'jsxstyle';
import React from 'react';
import { mediaQueries } from 'theme';

const AppStore = () => (
  <Box
    component="a"
    props={{
      href: 'https://apps.apple.com/us/app/meucompromisso/id1526392279',
      target: '__blank',
    }}
  >
    <Box
      transition="all 200ms ease-in-out"
      mediaQueries={mediaQueries}
      component="img"
      height={52}
      //@ts-ignore
      lgHeight={64}
      xlHeight={64}
      props={{
        src: appstore,
      }}
    />
  </Box>
);
export default AppStore;
