import Container from 'components/Container';
import Heading from 'components/Heading';
import Separator from 'components/Separator';
import Text from 'components/Text';
import useMedia from 'hooks/useMedia';
import { Box, Col } from 'jsxstyle';
import React from 'react';
//@ts-ignore
import Bounce from 'react-reveal/Bounce';
//@ts-ignore
import Fade from 'react-reveal/Fade';
import { colors, mediaQueries, spacingSizes } from 'theme';

const FAQ = () => {
  const { media } = useMedia();

  return (
    <Col backgroundColor={colors.gray50}>
      <Container>
        <Separator size="xl" />
        <Box
          mediaQueries={mediaQueries}
          display="flex"
          flexDirection="column"
          //@ts-ignore

          lgFlexDirection="row"
          xlFlexDirection="row"
        >
          <Col
            mediaQueries={mediaQueries}
            flexBasis="50%"
            alignItems="center"
            //@ts-ignore
            lgAlignItems="flex-start"
            xlAlignItems="flex-start"
          >
            <Bounce>
              <Heading size="lg">Perguntas frequentes</Heading>
            </Bounce>
            <Separator />
            <Fade left>
              <Col textAlign={media === 'desktop' ? 'left' : 'center'}>
                <Text fontWeight="bold">Ficou na dúvida?</Text>
                <Text fontWeight="bold">
                  Não se preocupe, a gente te explica tudo!
                </Text>
              </Col>
            </Fade>
            <Separator />
          </Col>
          <Fade cascade right>
            <div style={{ flexBasis: '50%' }}>
              <ItemCard
                question="Por que rende 100% do CDI?"
                answer="A conta digital do MeuCompromisso é regulada pelo BACEN e todo o dinheiro fica automáticamente investido no tesouro público. Sendo assim, sua lista do MeuCompromisso rende 100% do CDI."
              />
              <Separator />
              <ItemCard
                question="Porque é necessário criar uma conta digital MeuCompromisso?"
                answer="Todo presente arrecado cairá na sua conta digital MeuCompromisso.
                Sem custo e nem carência. Ou seja, assim que o pagamento do 
                presente for compensado, estará disponível na sua conta para usar
                como quiser."
              />
              <Separator />
              <ItemCard
                question="Quanto custa usar a lista?"
                answer="Não cobramos nenhuma porcentagem pelo montante acumulado. Apenas uma tarifa de R$ 5,00 por compra gerada, que é repassado integralmente a quem for lhe presentear. Ex: Se o presente custa R$ 300,00. será gerado um PIX ou boleto de R$ 305,00 e assim por diante."
              />
            </div>
          </Fade>
        </Box>
      </Container>
    </Col>
  );
};

export default FAQ;

const ItemCard = ({
  question,
  answer,
}: {
  question: string;
  answer: string;
}) => {
  return (
    <Box
      width="100%"
      borderRadius={8}
      backgroundColor="white"
      padding={`32px ${spacingSizes.md}px`}
    >
      <Text fontWeight="bold" color={colors.secondary500}>
        {question}
      </Text>
      <Separator />
      <Text>{answer}</Text>
    </Box>
  );
};
