import React from 'react';
import { Box } from 'jsxstyle';
import Text from 'components/Text';
import { spacingSizes, colors } from 'theme';

const CominSoonTag = () => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    alignSelf="flex-start"
    backgroundColor={colors.gray100}
    borderRadius={30}
    padding={`${spacingSizes.xs}px ${spacingSizes.md}px`}
  >
    <Text color={colors.gray500} size="sm" textTransform="uppercase">
      Em breve
    </Text>
  </Box>
);

export default CominSoonTag;
