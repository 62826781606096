import publicApi from '../publicApi';
import GuestType from 'types/GuestType';

type Params = {
  weddingID: string;
};

export default async ({ weddingID }: Params): Promise<Array<GuestType>> => {
  try {
    const { data } = await publicApi.get(
      `/giftcashes/weddings/${weddingID}/rsvp`,
    );
    return data;
  } catch (error) {
    throw error;
  }
};
