import privateApi from './privateApi';

export type Params = {
  accountID: number | string;
  personName1: string;
  personName2: string;
};
export default async ({ accountID, personName1, personName2 }: Params) => {
  try {
    const { data } = await privateApi(
      `/accounts/${accountID}/giftcashes/weddings/websites/suggestions`,
      {
        params: {
          personName1,
          personName2,
        },
      },
    );
    return data;
  } catch (error) {
    throw error;
  }
};
