import React from 'react';
import { Box, CSSProperties } from 'jsxstyle';
import { spacingSizes, colors } from 'theme';

export type CardProps = {} & CSSProperties;

const Card = ({
  children,

  ...style
}: React.PropsWithChildren<CardProps>) => (
  <Box
    display="flex"
    flexDirection="column"
    alignSelf="stretch"
    backgroundColor={style.backgroundColor || colors.white}
    borderRadius={5}
    boxShadow="0px 4px 8px rgba(0, 0, 0, 0.1);"
    padding={spacingSizes.md}
    {...style}
  >
    {children}
  </Box>
);

export default Card;
