import React from 'react';
import Card from 'components/Card';
import Text from 'components/Text';
import { Col, Row, Box } from 'jsxstyle';
import Separator from 'components/Separator';
import { colors, spacingSizes, mediaQueries } from 'theme';
import NumberFormat from 'components/NumberFormat';
import useMedia from 'hooks/useMedia';
import PurchaseType from 'types/PurchaseType';
import { parseISO, format } from 'date-fns';

export type StatementListItemProps = {
  purchase: PurchaseType;
};

const StatementListItem = ({ purchase }: StatementListItemProps) => {
  const { media } = useMedia();

  console.log({ purchase });

  const gifts = purchase.giftCashItems.reduce(
    (acc: { [key: string]: any[] }, gift) => {
      if (acc[gift.giftCashItemID]) {
        return {
          ...acc,
          [gift.giftCashItemID]: [...acc[gift.giftCashItemID], gift],
        };
      }
      return { ...acc, [gift.giftCashItemID]: [gift] };
    },
    {},
  );

  return (
    <Card
      flexDirection={media === 'desktop' ? 'row' : 'column'}
      alignItems={media === 'desktop' ? 'center' : 'flex-start'}
      justifyContent="space-between"
    >
      <Col flexBasis={media === 'desktop' && '30%'}>
        <Text size="sm" fontWeight="bold">
          {purchase.buyerName}
        </Text>
        <Separator size="xs" />
        <Box
          display="flex"
          flexDirection="column"
          mediaQueries={mediaQueries}
          //@ts-ignore
          lgFlexDirection="row"
          xlFlexDirection="row"
        >
          <Text size="xs">Email: {purchase.buyerBuyerEmail}</Text>
          {/* {media === 'desktop' && <Text size="xs">&nbsp;|&nbsp;</Text>} */}
          {/* <Text size="xs">CPF: 053.***.***-**</Text> */}
        </Box>
      </Col>
      {media !== 'desktop' && <Separator size="sm" />}
      <Col flexBasis="30%">
        <Text size="xs" color={colors.gray200} textTransform="uppercase">
          Presente
        </Text>
        <Separator size="xs" />
        <Row flexWrap="wrap">
          {gifts &&
            Object.keys(gifts).map((key, index) => {
              const gift = gifts[key];
              return (
                <React.Fragment key={String(index)}>
                  <Box
                    height={20}
                    padding={`0 ${spacingSizes.sm}px`}
                    borderRadius={30}
                    backgroundColor={colors.gray100}
                    marginBottom={4}
                  >
                    <Text size="xs">
                      {gift[0].productName} ({gift.length})
                    </Text>
                  </Box>
                  <Separator size="sm" />
                </React.Fragment>
              );
            })}
        </Row>
      </Col>
      {media !== 'desktop' && <Separator size="sm" />}
      <Col>
        <Text size="xs" color={colors.gray200} textTransform="uppercase">
          Valor a receber
        </Text>
        <Separator size="xs" />
        <Text fontWeight="bold" size="sm">
          <NumberFormat
            amount={purchase.netAmount}
            type="currency"
            minimumFractionDigits={2}
          />
        </Text>
      </Col>
      {media !== 'desktop' && <Separator size="sm" />}
      <Col>
        <Text size="xs" color={colors.gray200} textTransform="uppercase">
          Valor recebido
        </Text>
        <Separator size="xs" />
        <Text fontWeight="bold" size="sm">
          <NumberFormat
            amount={purchase.receivedNetAmount}
            type="currency"
            minimumFractionDigits={2}
          />
        </Text>
      </Col>
      {media !== 'desktop' && <Separator size="sm" />}
      <Col>
        <Text size="xs" color={colors.gray200} textTransform="uppercase">
          Data
        </Text>
        <Separator size="xs" />
        <Text size="sm">
          {format(parseISO(purchase.creationDate), 'dd/MM/yyyy')}
        </Text>
      </Col>
    </Card>
  );
};

export default StatementListItem;
