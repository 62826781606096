import React from 'react';
import { Row } from 'jsxstyle';
import { colors, spacingSizes } from 'theme';
import Icon from 'components/Icon';
import Separator from 'components/Separator';
import Text from 'components/Text';
import NumberFormat from 'components/NumberFormat';

export type StatementTotalReceivedProps = {
  amount: number;
};

const StatementTotalReceived = ({ amount }: StatementTotalReceivedProps) => (
  <Row
    backgroundColor={`${colors.white}1a`}
    padding={`0 ${spacingSizes.md}px`}
    height={32}
    alignItems="center"
    borderRadius={5}
  >
    <Icon width={20} height={20} name="money" color={colors.white} />
    <Separator />
    <Text size="sm" color={colors.white} fontWeight="bold">
      Total recebido:&nbsp;
      <NumberFormat amount={amount} type="currency" minimumFractionDigits={2} />
    </Text>
  </Row>
);

export default StatementTotalReceived;
