import Card from 'components/Card';
import useMedia from 'hooks/useMedia';
import { Box, Col } from 'jsxstyle';
import React from 'react';
import { colors, mediaQueries, spacingSizes } from 'theme';

export type ScreenContainerProps = {
  backgroundHeight?: number | string;
};

const ScreenContainer = ({
  children,
  backgroundHeight = 300,
}: React.PropsWithChildren<ScreenContainerProps>) => {
  const { media } = useMedia();

  return (
    <Card
      padding={0}
      paddingTop={media === 'phone' ? spacingSizes.sm : spacingSizes.lg}
      backgroundColor={colors.gray50}
      position="relative"
      paddingBottom={spacingSizes.xl}
      flexGrow={1}
      // height={
      //   media === 'desktop'
      //     ? 'calc(100vh - (32px + 160px))'
      //     : `calc(100vh - (${HEADER_HEIGHT}px + ${BOTTOM_TABBAR_HEIGHT}px))`
      // }
      // {...(media !== 'desktop'
      //   ? {
      //       boxShadow: 'none',
      //     }
      //   : {})}
    >
      <Box
        mediaQueries={mediaQueries}
        position="absolute"
        top={0}
        left={0}
        right={0}
        backgroundColor={colors.primary500}
        height={backgroundHeight}
        zIndex={0}
        //@ts-ignore
        xlBorderTopLeftRadius={5}
        lgBorderTopLeftRadius={5}
        xlBorderTopRightRadius={5}
        lgBorderTopRightRadius={5}
      />

      <Col
        zIndex={1}
        overflow="hidden"
        padding={spacingSizes.md}
        paddingBottom={0}
      >
        {children}
      </Col>
    </Card>
  );
};

export default ScreenContainer;
