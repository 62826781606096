import React from 'react';
import Card from 'components/Card';
import { Col } from 'jsxstyle';
import Text from 'components/Text';
import { colors } from 'theme';
import Separator from 'components/Separator';
import useMedia from 'hooks/useMedia';
import GuestType from 'types/GuestType';
import { parseISO, format } from 'date-fns';

export type GuestListItemProps = {
  guest: GuestType;
};

const GuestListItem = ({ guest }: GuestListItemProps) => {
  const { media } = useMedia();
  return (
    <Card
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Col flexBasis={media === 'desktop' && '25%'}>
        <Text textTransform="uppercase" size="xs" color={colors.gray200}>
          Convidado
        </Text>
        <Separator size="xs" />
        <Text fontWeight="bolder">{guest.guestName}</Text>
      </Col>
      <Col flexBasis={media === 'desktop' && '25%'}>
        <Text textTransform="uppercase" size="xs" color={colors.gray200}>
          Adultos
        </Text>
        <Separator size="xs" />
        <Text fontWeight="bolder">{guest.adultsQuantity}</Text>
      </Col>
      <Col flexBasis={media === 'desktop' && '25%'}>
        <Text textTransform="uppercase" size="xs" color={colors.gray200}>
          Crianças
        </Text>
        <Separator size="xs" />
        <Text fontWeight="bolder">{guest.childrenQuantity}</Text>
      </Col>
      {media === 'desktop' && (
        <Col flexBasis={media === 'desktop' && '25%'}>
          <Text textTransform="uppercase" size="xs" color={colors.gray200}>
            Confirmado em
          </Text>
          <Separator size="xs" />
          <Text fontWeight="bolder">
            {format(parseISO(guest.creationDate), 'dd/MM/yyyy')}
          </Text>
        </Col>
      )}
    </Card>
  );
};

export default GuestListItem;
