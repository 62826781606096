import React from 'react';
import { Auth } from 'aws-amplify';
import history from 'browserHistory';
import signInService from 'services/signInService';

type ContextType = {
  authenticated: boolean;
  pending: boolean;
  setAuthenticated?: (value: boolean) => void;
  signOut: () => void;
  signIn: (params: { username: string; password: string }) => Promise<void>;
};

export const AuthContext = React.createContext<ContextType>({
  authenticated: false,
  pending: false,
  signOut: () => {},
  signIn: async () => {},
});

type AuthContextProviderProps = {
  children: React.ReactNode;
};

export default ({ children }: AuthContextProviderProps) => {
  const [authenticated, setAuthenticated] = React.useState(false);
  const [pending, setPending] = React.useState(true);

  React.useEffect(() => {
    (async () => {
      const loggedIn = await isAuthenticated();
      setAuthenticated(loggedIn);
      setPending(false);
    })();
  }, []);

  const isAuthenticated = async () => {
    try {
      await Auth.currentAuthenticatedUser();
      return true;
    } catch (error) {
      return false;
    }
  };

  const signIn = async ({
    username,
    password,
  }: {
    username: string;
    password: string;
  }) => {
    try {
      await signInService({ username, password });
      setAuthenticated(true);
    } catch (error) {
      throw error;
    }
  };

  const signOut = () => {
    Auth.signOut();
    setAuthenticated(false);
    history.push('/');
  };

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        setAuthenticated,
        pending,
        signOut,
        signIn,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => React.useContext(AuthContext);
