import Card from 'components/Card';
import NumberFormat from 'components/NumberFormat';
import GiftCardImage from 'components/platform/GiftCardImage';
import Separator from 'components/Separator';
import Text from 'components/Text';
import { Col, Row } from 'jsxstyle';
import React from 'react';
import { colors } from 'theme';
import { GiftCashTypeItem } from 'types/PurchaseDetailType';

export type CustomGiftCardSectionProps = {
  customGiftCard: GiftCashTypeItem;
};

function CustomGiftCardSection({ customGiftCard }: CustomGiftCardSectionProps) {
  return (
    <React.Fragment>
      <Text
        fontWeight="bold"
        size="xs"
        textTransform="uppercase"
        color={colors.gray900}
      >
        Presente personalizado
      </Text>
      <Separator />
      <Card>
        <Row alignItems="center">
          <GiftCardImage
            width={88}
            height={88}
            borderRadius={5}
            name={customGiftCard?.productName as any}
          />
          <Separator />
          <Col>
            <Text>{customGiftCard?.productName}</Text>
            <Text fontWeight="bold">
              <NumberFormat
                amount={customGiftCard?.amount}
                type="currency"
                minimumFractionDigits={2}
              />
            </Text>
          </Col>
        </Row>
      </Card>
    </React.Fragment>
  );
}

export default CustomGiftCardSection;
