import googleplay from 'assets/images/google-play.png';
import { Box } from 'jsxstyle';
import React from 'react';
import { mediaQueries } from 'theme';

const GooglePlay = () => (
  <Box
    component="a"
    props={{
      href: 'https://play.google.com/store/apps/details?id=com.meucompromisso',
      target: '__blank',
    }}
  >
    <Box
      transition="all 200ms ease-in-out"
      mediaQueries={mediaQueries}
      component="img"
      height={52}
      //@ts-ignore
      lgHeight={64}
      xlHeight={64}
      props={{
        src: googleplay,
      }}
    />
  </Box>
);

export default GooglePlay;
