import privateApi from '../privateApi';
import PurchaseType from 'types/PurchaseType';

type Params = {
  accountID: string;
  giftCashID: string;
};
export default async ({
  accountID,
  giftCashID,
}: Params): Promise<Array<PurchaseType>> => {
  try {
    const { data } = await privateApi.get(
      `/accounts/${accountID}/giftcashes/${giftCashID}/purchases`,
      // {
      //   params: { status: 'PAID' },
      // },
    );
    return data;
  } catch (error) {
    throw error;
  }
};
