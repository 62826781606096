import React, { SetStateAction } from 'react';
import { SearchableInputProps, SearchableProps } from '.';

type ProviderProps = {
  children: React.ReactNode | React.ReactNodeArray;
  inputProps: SearchableInputProps;
  props: SearchableProps;
};

type ContextProps = {
  props: SearchableProps;
  inputProps: SearchableInputProps;
  inputRef: React.Ref<HTMLInputElement>;
  opened: boolean;
  options: any[];
  optionNodes: React.ReactNodeArray;
  activeIndex: number;
  setState: (state: SetStateAction<Omit<ContextProps, 'setState'>>) => void;
  onChange: (value: any) => void;
  onClose: () => void;
  onInputKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
};

const initialState: ContextProps = {
  props: {} as any,
  inputProps: {} as any,
  inputRef: null,
  opened: false,
  options: [],
  optionNodes: [],
  activeIndex: -1,
  setState: () => {},
  onChange: () => {},
  onClose: () => {},
  onInputKeyDown: () => {},
};

export const Context = React.createContext<ContextProps>(initialState);

function Provider({ children, inputProps, props }: ProviderProps) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [state, setState] = React.useState(initialState);
  const optionNodes = React.Children.toArray(props.children);
  const options: any[] = optionNodes.map((node: any) => node?.props?.value);

  function handleClose() {
    if (!props.value) {
      inputProps?.onInputChange?.('');
    }
    inputRef?.current?.focus();
    setState((state) => ({ ...state, opened: false, activeIndex: -1 }));
  }

  function handleChange(value: any) {
    props?.onChange?.(value);
    setState((state) => ({ ...state, opened: false, activeIndex: -1 }));
  }

  function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    switch (event.key) {
      case 'Escape':
        if (state.opened) {
          event.stopPropagation();
        }
        event.preventDefault();
        handleClose();
        break;
      case 'Enter':
        if (state.opened) {
          event.stopPropagation();
        }
        event.preventDefault();
        if (state.activeIndex >= 0) {
          handleChange(options[state.activeIndex]);
        }
        break;
      case 'ArrowUp':
        event.preventDefault();
        setState((state) => ({
          ...state,
          opened: true,
          activeIndex: !state.opened
            ? 0
            : state.activeIndex === 0
            ? state.activeIndex
            : state.activeIndex - 1,
        }));
        break;
      case 'ArrowDown':
        event.preventDefault();
        setState((state) => ({
          ...state,
          opened: true,
          activeIndex: !state.opened
            ? 0
            : state.activeIndex === options.length - 1
            ? state.activeIndex
            : state.activeIndex + 1,
        }));
        break;
      case 'Tab':
        handleClose();
        setState((state) => ({
          ...state,
          opened: false,
          activeIndex: -1,
        }));
        break;
      case 'Backspace':
        setState((state) => ({
          ...state,
          opened: true,
          activeIndex: 0,
        }));
        break;
      case 'Shift':
        event.stopPropagation();
        break;
      default:
        setState((state) => ({
          ...state,
          opened: true,
        }));
    }
  }

  return (
    <Context.Provider
      value={{
        ...state,
        props,
        inputProps,
        inputRef,
        options,
        optionNodes,
        onChange: handleChange,
        onInputKeyDown: handleKeyDown,
        onClose: handleClose,
        //@ts-ignore
        setState,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export default Provider;
