import React from 'react';
import { Row, Box, Col } from 'jsxstyle';
import { spacingSizes, colors } from 'theme';
import margaret from 'assets/images/margaret-avatar.png';
import Separator from 'components/Separator';
import useMedia from 'hooks/useMedia';
import AnimatedTyping from 'components/AnimatedTyping';

export interface AssistantMessageProps {
  message: string;
  onTypingEnd: () => void;
  shouldErase?: boolean;
  textAfterErase?: string;
  lastAuthor: 'assistant' | string;
}

const AssistantMessage = ({
  message,
  onTypingEnd,
  shouldErase,
  textAfterErase,
  lastAuthor,
}: AssistantMessageProps) => {
  const { media } = useMedia();

  const avatarSize = media === 'phone' ? 36 : 48;

  return (
    <Row WebkitFlex="0 0 auto" msFlex="0 0 auto" flex="0 0 auto">
      <Col
        flexBasis={avatarSize}
        WebkitFlex="0 0 auto"
        msFlex="0 0 auto"
        flex="0 0 auto"
      >
        {lastAuthor !== 'assistant' && (
          <Box
            component="img"
            props={{ src: margaret, width: avatarSize, height: avatarSize }}
          />
        )}
      </Col>
      <Col WebkitFlex="0 0 auto" msFlex="0 0 auto" flex="0 0 auto">
        <Separator />
      </Col>
      <Col
        padding={media === 'phone' ? spacingSizes.xs : spacingSizes.md}
        backgroundColor={colors.gray50}
        borderTopLeftRadius={0}
        borderRadius={12}
      >
        <AnimatedTyping
          onTypingEnd={onTypingEnd}
          phrase={message}
          shouldErase={shouldErase}
          phraseAfterErase={textAfterErase}
          delay={{ typing: 12, erasing: 4 }}
        />
      </Col>
    </Row>
  );
};
export default AssistantMessage;
