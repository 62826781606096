import saveWeddingProductService from './saveWeddingProductService';
import saveWeddingService from './saveWeddingService';

export type Params = {
  accountID: string | number;
  url: string;
  groom: string;
  bride: string;
  phrase: string;
  date: string;
  state: string;
  city: string;
  products: Array<any>;
  affiliateID?: string;
};

export default async ({
  accountID,
  url,
  groom,
  bride,
  phrase,
  date,
  state,
  city,
  products,
  affiliateID,
}: Params) => {
  try {
    const wedding: any = await saveWeddingService({
      accountID,
      contextID: url,
      personName1: groom,
      personName2: bride,
      welcomeMessage: phrase,
      weddingDate: date,
      weddingState: state,
      weddingCity: city,
      affiliateID,
    });
    const data = await Promise.all(
      products.map((product) =>
        saveWeddingProductService({
          accountID,
          giftCashID: wedding.modelID,
          amount: product.value,
          pictureID: product.pictureID,
          name: product.name,
          customImage: product?.customImage?.file,
        }),
      ),
    );

    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};
