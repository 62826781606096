import React from 'react';
import { Box } from 'jsxstyle';
import Icon from './Icon';
import { colors, mediaQueries } from 'theme';

export type BrideAndGroomNamesProps = {
  name1?: string;
  name2?: string;
};

const BrideAndGroomNames = ({ name1, name2 }: BrideAndGroomNamesProps) => (
  <Box
    position="relative"
    display="flex"
    justifyContent="center"
    alignItems="center"
    minHeight={342}
  >
    <Box position="absolute">
      <Icon name="wedding-ring" />
    </Box>
    <Box
      mediaQueries={mediaQueries}
      component="h1"
      fontFamily="Sacramento"
      fontWeight="normal"
      textAlign="center"
      height="100%"
      color={colors.white}
      fontSize={58}
      //@ts-ignore
      lgFontSize={72}
      xlFontSize={72}
      textShadow="2px 2px rgba(0, 0, 0, 0.4)"
    >
      {Boolean(name1) && name1}
      <br />
      {Boolean(name1) && 'e'}
      <br />
      {Boolean(name2) && name2}
    </Box>
  </Box>
);

export default BrideAndGroomNames;
