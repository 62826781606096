import Clickable from 'components/Clickable';
import Icon from 'components/Icon';
import ToastMessage from 'components/ToastMessage';
import { Box } from 'jsxstyle';
import React from 'react';
import { colors } from 'theme';
import toaster from 'toasted-notes';

export type CopyButtonProps = {
  value: string;
  color?: string;
};

const CopyButton = ({ value, color = colors.tertiary500 }: CopyButtonProps) => {
  const handleCopyToClipboard = () => {
    const el = document.createElement('textarea');
    el.value = value;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    toaster.notify(({ onClose }) => (
      <ToastMessage
        type="default"
        onClick={onClose}
        icon={<Icon name="check" width={18} height={16} color={colors.black} />}
        message="Copiado para área de transferência"
      />
    ));
  };
  return (
    <Clickable backgroundColor="transparent" onClick={handleCopyToClipboard}>
      <Box
        height={30}
        width={30}
        borderRadius={100}
        backgroundColor={`${color}1a`}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Icon name="copy" width={14} height={20} color={color} />
      </Box>
    </Clickable>
  );
};

export default CopyButton;
