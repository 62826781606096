import Header from 'components/Header';
import Separator from 'components/Separator';
import Text from 'components/Text';
import useMedia from 'hooks/useMedia';
import paths from 'paths';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import CartButton from 'screens/platform/BrideAndGroomScreen/CartButton';

function CheckoutHeader() {
  const { contextID } = useParams<any>();
  const { media } = useMedia();

  const hasContext = Boolean(contextID);

  return (
    <Header variant="platform">
      <Header.Logo
        variant={media === 'phone' ? 'icon' : 'horizontal'}
        intent="secondary"
      />
      <Separator />
      {hasContext && (
        <Header.Buttons>
          <Link
            to={`${paths.Platform.RSVP_SCREEN.replace(
              ':contextID',
              contextID!,
            )}`}
          >
            <Text
              color="white"
              textTransform="uppercase"
              size="xs"
              fontWeight="bold"
            >
              Confirmação de presença
            </Text>
          </Link>
          <Separator />
          <CartButton />
        </Header.Buttons>
      )}
    </Header>
  );
}

export default CheckoutHeader;
