import Card from 'components/Card';
import Clickable from 'components/Clickable';
import Icon, { IconProps } from 'components/Icon';
import InstallmentBilletInfoModal from 'components/platform/InstallmentBilletInfoModal';
import Separator from 'components/Separator';
import Text from 'components/Text';
import { Box, Col, CSSProperties } from 'jsxstyle';
import React from 'react';
import { colors } from 'theme';

export type PaymentMethodTypes = 'billet' | 'pix';

function PaymentMethodForm({
  paymentMethod,
  onChangePaymentMethod,
}: {
  paymentMethod: PaymentMethodTypes;
  onChangePaymentMethod: (value: PaymentMethodTypes) => void;
}) {
  const billetActive = paymentMethod === 'billet';
  const pixActive = paymentMethod === 'pix';

  return (
    <React.Fragment>
      <Box minHeight={32}>
        <Text size="sm">
          Selecione uma forma de pagamento{' '}
          <Text size="xl" color={colors.red500}>
            *
          </Text>
        </Text>
      </Box>
      <Clickable
        onClick={() => onChangePaymentMethod('pix')}
        disabled={pixActive}
        backgroundColor="transparent"
      >
        <PaymentMethod
          border={
            pixActive
              ? `1px solid ${colors.secondary500}`
              : '1px solid transparent'
          }
        >
          <BilletPaymentMethodClarificationIconButton />
          <PaymentMethod.Icon iconName="pix" width={20} height={21} />
          <Separator />
          <Col>
            <PaymentMethod.Title>Pix</PaymentMethod.Title>
            <Separator size="xs" />
            <PaymentMethod.Description>
              O dinheiro estará disponível na conta dos noivos assim que o
              pagamento for confirmado.
            </PaymentMethod.Description>
          </Col>
        </PaymentMethod>
      </Clickable>      
      <Separator size="sm" />
      <Clickable
        onClick={() => onChangePaymentMethod('billet')}
        backgroundColor="transparent"
      >
        <PaymentMethod
          border={
            billetActive
              ? `1px solid ${colors.secondary500}`
              : '1px solid transparent'
          }
        >
          <BilletPaymentMethodClarificationIconButton />
          <PaymentMethod.Icon iconName="barcode" />
          <Separator />
          <Col>
            <PaymentMethod.Title>Boleto</PaymentMethod.Title>
            <Separator size="xs" />
            <PaymentMethod.Description>
              Após o pagamento, estará disponível na conta dos noivos em 1 ou
              dois dias.
            </PaymentMethod.Description>
          </Col>
        </PaymentMethod>
      </Clickable>
    </React.Fragment>
  );
}

export default PaymentMethodForm;

type WithChildrenType = {
  children: React.ReactNode | React.ReactNodeArray | React.ReactText;
};

function PaymentMethod({
  children,
  ...rest
}: WithChildrenType & CSSProperties) {
  return (
    <Card
      flexDirection="row"
      position="relative"
      transition="all 250ms"
      {...rest}
    >
      {children}
    </Card>
  );
}

function PaymentMethodTitle({ children }: WithChildrenType) {
  return (
    <Text size="sm" fontWeight="bold" color={colors.primary500}>
      {children}
    </Text>
  );
}

function PaymentMethodDescription({ children }: WithChildrenType) {
  return (
    <Text size="xs" color={colors.gray500}>
      {children}
    </Text>
  );
}

function PaymentMethodIcon({
  iconName,
  width = 18,
  height = 18,
}: {
  iconName: IconProps['name'];
  width?: number;
  height?: number;
}) {
  return (
    <Box
      minWidth={32}
      minHeight={32}
      maxWidth={32}
      maxHeight={32}
      borderRadius={50}
      backgroundColor={`${colors.primary500}1a`}
      justifyContent="center"
      alignItems="center"
      display="flex"
    >
      <Icon
        name={iconName}
        width={width}
        height={height}
        color={colors.primary500}
      />
    </Box>
  );
}

PaymentMethod.Icon = PaymentMethodIcon;
PaymentMethod.Title = PaymentMethodTitle;
PaymentMethod.Description = PaymentMethodDescription;

function BilletPaymentMethodClarificationIconButton() {
  const [
    installmentBilletInfoModalVisibility,
    setInstallmentBilletInfoModalVisibility,
  ] = React.useState<'visible' | 'hidden'>('hidden');

  return (
    <Box position="absolute" top={8} right={8}>
      <Clickable
        backgroundColor="transparent"
        alignSelf="flex-start"
        onClick={() => setInstallmentBilletInfoModalVisibility('visible')}
      >
        <Icon name="info" width={14} height={14} color={colors.gray300} />
      </Clickable>
      <InstallmentBilletInfoModal
        visible={installmentBilletInfoModalVisibility === 'visible'}
        onRequestClose={() => setInstallmentBilletInfoModalVisibility('hidden')}
      />
    </Box>
  );
}
