import saveGiftPictureService from './admin/saveGiftPictureService';
import privateApi from './privateApi';

type Params = {
  giftCashItemID?: string;
  accountID: string | number;
  giftCashID: string;
  amount: number;
  pictureID: string;
  name: string;
  customImage?: File;
  useCustomPicture?: boolean;
};

export default async ({
  accountID,
  giftCashID,
  amount,
  giftCashItemID,
  pictureID,
  name,
  customImage,
  useCustomPicture,
}: Params) => {
  try {
    if (giftCashItemID) {
      const [response] = await Promise.all([
        privateApi.put(
          `/accounts/${accountID}/giftcashes/${giftCashID}/items/${giftCashItemID}`,
          {
            amount,
            productName: name,
            productPictureID: pictureID ?? undefined,
            productIconID: pictureID ?? undefined,
            useCustomPicture,
          },
        ),
        ...(customImage
          ? [
              saveGiftPictureService({
                accountID,
                giftCashID,
                giftCashItemID,
                image: customImage,
              }),
            ]
          : []),
      ]);

      return response.data;
    } else {
      const { data } = await privateApi.post(
        `/accounts/${accountID}/giftcashes/${giftCashID}/items`,
        {
          amount,
          productName: name,
          productPictureID: pictureID ?? undefined,
          productIconID: pictureID ?? undefined,
          useCustomPicture: Boolean(customImage),
        },
      );

      if (customImage) {
        await saveGiftPictureService({
          accountID,
          giftCashID,
          giftCashItemID: data.modelID,
          image: customImage,
        });
      }

      return data;
    }
  } catch (error) {
    throw error;
  }
};
