import React, { Children } from 'react';
import { Box, Col } from 'jsxstyle';
import PropTypes from 'prop-types';
import RCDropdown from 'rc-dropdown';
import 'rc-dropdown/assets/index.css';
import { colors } from 'theme';

const Dropdown = ({ children, visible, onVisibleChange, placement, on }) => {
  let trigger = null;
  let menu = null;

  Children.forEach(children, (child) => {
    if (child.type.displayName === 'DropdownTrigger') {
      trigger = child.props.children;
    } else {
      menu = child;
    }
  });

  return (
    <RCDropdown
      visible={visible}
      onVisibleChange={onVisibleChange}
      placement={placement}
      trigger={[on]}
      overlay={menu}
      animation="slide-up"
    >
      <div
        style={{ cursor: 'pointer', display: 'flex' }}
        onClick={(event) => event.stopPropagation()}
      >
        {trigger}
      </div>
    </RCDropdown>
  );
};

Dropdown.Menu = ({ children, style }) => (
  <Col
    background={colors.white}
    borderRadius={10}
    overflow="auto"
    boxShadow="0 5px 5px rgba(0, 0, 0, 0.15)"
    marginTop={5}
    {...style}
  >
    {children}
  </Col>
);
Dropdown.Menu.displayName = 'DropdownMenu';

Dropdown.Item = ({ icon = undefined, children, onClick }) => (
  <Box display="flex" alignItems="center" cursor="pointer" props={{ onClick }}>
    {Boolean(icon) && <Box flexBasis="20%">{icon}</Box>}
    {children}
  </Box>
);
Dropdown.Item.displayName = 'DropdownMenuItem';

Dropdown.Trigger = ({ children }) => children;
Dropdown.Trigger.displayName = 'DropdownTrigger';

Dropdown.Divider = () => (
  <Box width="100%" height={1} backgroundColor={'#00000010'} />
);
Dropdown.Divider.displayName = 'DropdownDivider';

Dropdown.propTypes = {
  children: PropTypes.node.isRequired,
  on: PropTypes.oneOf(['hover', 'click', 'focus']),
  placement: PropTypes.oneOf([
    'topLeft',
    'topCenter',
    'topRight',
    'bottomLeft',
    'bottomCenter',
    'bottomRight',
  ]),
  visible: PropTypes.bool.isRequired,
  onVisibleChange: PropTypes.func,
};

Dropdown.defaultProps = {
  on: 'click',
  placement: 'bottomCenter',
  onVisibleChange: null,
};

export default Dropdown;
