import publicApi from '../publicApi';
import ProductType from 'types/ProductType';

export type WeddingItemType = {
  accountID: number;
  amount: number;
  creationDate: string;
  giftCashID: string;
  giftCashItemID: string;
  productID: string;
  productName: string;
  productIconID: ProductType['iconID'];
  productPictureID: ProductType['pictureID'];
  useCustomPicture: boolean;
  status: 'ACTIVE';
  userID: number;
};

type Params = {
  giftCashID: string;
  productCategoryID?: string;
};
export default async ({ giftCashID, productCategoryID }: Params) => {
  try {
    const { data } = await publicApi.get(
      `/giftcashes/weddings/${giftCashID}/items`,
      {
        params: productCategoryID ? { productCategoryID } : undefined,
      },
    );
    return data as Array<WeddingItemType>;
  } catch (error) {
    throw error;
  }
};
