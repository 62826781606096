import checkedBackground from 'assets/images/checked.svg';
import uncheckedBackground from 'assets/images/unchecked.svg';
import { Box } from 'jsxstyle';
import React from 'react';
import { colors, spacingSizes } from 'theme';

export type RadioButtonProps = {
  id?: string;
  value: string;
  label?: React.ReactNode;
  onChange?: (value: string) => void;
  checked?: boolean;
};

const RadioButton = ({
  value,
  label,
  id,
  onChange,
  checked,
}: RadioButtonProps) => (
  <React.Fragment>
    <Box
      component="input"
      props={{
        type: 'radio',
        checked,
        value,
        id,
        onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
          if (onChange) {
            onChange(event.target.value);
          }
        },
        autoFocus: true,
      }}
      opacity={0}
    />
    {Boolean({ label }) && (
      <Box
        component="label"
        display="flex"
        alignItems="center"
        padding={spacingSizes.md}
        border="1px solid"
        borderColor={checked ? colors.secondary200 : '#dbdbdb'}
        backgroundColor={checked ? `${colors.secondary50}4d` : 'transparent'}
        cursor="pointer"
        borderRadius={6}
        props={{ htmlFor: id }}
      >
        <Box
          component="img"
          props={{ src: checked ? checkedBackground : uncheckedBackground }}
        />
        <Box width={spacingSizes.md} />
        {label}
      </Box>
    )}
  </React.Fragment>
);

export default RadioButton;
