import useMedia from 'hooks/useMedia';
import { Col, Row } from 'jsxstyle';
import React from 'react';
import Cropper from 'react-easy-crop';
import { colors, spacingSizes } from 'theme';
import getCroppedImg from 'utils/cropImage';
import Button from './Button';
import Clickable from './Clickable';
import Heading from './Heading';
import ImagePicker, { ImageType } from './ImagePicker';
import Modal from './Modal';
import Separator from './Separator';
import Text from './Text';

export type ImagePickerWithCropProps = {
  onCropComplete?: (file: ImageType) => void;
  pending?: boolean;
  title?: string;
  trigger: React.ReactNode;
  shape?: 'rect' | 'round';
};

const ImagePickerWithCrop = ({
  onCropComplete,
  pending = false,
  shape = 'round',
  title,
  trigger,
}: ImagePickerWithCropProps) => {
  const [image, setImage] = React.useState<ImageType>();
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = React.useState();

  const onCrop = React.useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const { media } = useMedia();

  return (
    <React.Fragment>
      <ImagePicker onChange={setImage} trigger={trigger}></ImagePicker>
      <Modal
        visible={Boolean(image)}
        onRequestClose={() => {
          if (pending) {
            return;
          }

          setImage(undefined);
        }}
      >
        <Col
          width={media === 'phone' ? '100%' : 540}
          height={590}
          backgroundColor={colors.white}
          borderRadius={8}
          position="relative"
          overflow="hidden"
          padding={spacingSizes.md}
          justifyContent="space-between"
          alignItems="center"
        >
          <Heading size="xs">{title}</Heading>

          <Cropper
            image={String(image?.url)}
            crop={crop}
            zoom={zoom}
            aspect={shape === 'round' ? 1 : 4 / 3}
            cropShape={shape}
            onCropChange={setCrop}
            rotation={0}
            onCropComplete={onCrop}
            onZoomChange={setZoom}
            // cropSize={{ width: 300, height: 300 }}
            style={{
              containerStyle: {
                width: '100%',
                height: '100%',
                position: 'relative',
              },
            }}
          />

          <Row
            justifyContent="flex-end"
            alignItems="center"
            alignSelf="stretch"
            margin={-spacingSizes.md}
            borderTop="1px solid #eee"
            padding={spacingSizes.md}
          >
            <Clickable
              disabled={pending}
              onClick={() => setImage(undefined)}
              backgroundColor="transparent"
            >
              <Text fontWeight="bolder" color={colors.secondary500}>
                Cancelar
              </Text>
            </Clickable>
            <Separator />
            <Button
              minimal
              color="secondary"
              loading={pending}
              onClick={async () => {
                try {
                  const croppedImage: Blob = await getCroppedImg(
                    image?.url!,
                    croppedAreaPixels as any,
                    0,
                  );

                  const file = new File([croppedImage], 'image.jpeg', {
                    type: 'image/jpeg',
                  });

                  var reader = new FileReader();
                  reader.onload = function (e: any) {
                    onCropComplete?.({ url: e.target.result, file });
                    setImage(undefined);
                  };

                  reader.readAsDataURL(file);
                } catch (e) {}
              }}
            >
              <Text
                fontWeight="bolder"
                textTransform="capitalize"
                color={colors.white}
              >
                Salvar
              </Text>
            </Button>
          </Row>
        </Col>
      </Modal>
    </React.Fragment>
  );
};

export default ImagePickerWithCrop;
