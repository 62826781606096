import React from 'react';
import { Box } from 'jsxstyle';
import './ScrollBox.css';
import { spacingSizes } from 'theme';

export type ScrollBoxProps = {};

const ScrollBox = ({ children }: React.PropsWithChildren<ScrollBoxProps>) => (
  <Box
    overflowY="auto"
    margin={`0 -${spacingSizes.md}px`}
    padding={`0 ${spacingSizes.md}px`}
    paddingBottom={spacingSizes.md}
    flexGrow={1}
    className="scroll-box"
  >
    {children}
  </Box>
);

export default ScrollBox;
