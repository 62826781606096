import publicApi from '../publicApi';
import WeddingType from 'types/WeddingType';

type Params = {
  id: string;
};
export default async ({ id }: Params): Promise<WeddingType> => {
  try {
    const { data } = await publicApi.get(`/giftcashes/weddings/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};
