import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { Col } from 'jsxstyle';
import useMedia from 'hooks/useMedia';
import './Modal.css';
import { createPortal } from 'react-dom';

type ModalProps = {
  visible: boolean;
  onRequestClose: () => void;
  children: React.ReactNode;
  backdropColor?: string;
  responsive?: boolean;
  containerStyle?: any;
};

const Modal = ({
  visible,
  onRequestClose,
  children,
  backdropColor = '#00000080',
  responsive = true,
  containerStyle = {},
}: ModalProps) => {
  const mounted = React.useRef(false);
  const { media } = useMedia();

  React.useEffect(() => {
    if (mounted.current) {
      const rootElement = document.getElementById('root');
      if (visible) {
        rootElement?.setAttribute('style', 'filter: blur(6px)');
        window.addEventListener('keydown', handleKeyPress);
      } else {
        rootElement?.setAttribute('style', 'filter: none;');
        window.removeEventListener('keydown', handleKeyPress);
      }

      return () => {
        window.removeEventListener('keydown', handleKeyPress);
        rootElement?.setAttribute('style', 'filter: none;');
      };
    } else {
      mounted.current = true;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      onRequestClose();
    }
  };

  return createPortal(
    <CSSTransition in={visible} classNames="fade" timeout={300} unmountOnExit>
      <Col
        position="fixed"
        top={0}
        right={0}
        bottom={0}
        left={0}
        justifyContent={media === 'phone' && responsive ? 'flex-end' : 'center'}
        alignItems="center"
        backgroundColor={backdropColor}
        props={{ onClick: onRequestClose }}
        zIndex={999}
        {...containerStyle}
      >
        <Col
          alignSelf={media === 'phone' ? 'stretch' : 'auto'}
          props={{
            onClick: (event) => event.stopPropagation(),
          }}
        >
          {children}
        </Col>
      </Col>
    </CSSTransition>,
    document.body,
  );
};

export default Modal;
