import Container from 'components/Container';
import FadeIn from 'components/FadeIn';
import Header from 'components/Header';
import Separator from 'components/Separator';
import Text from 'components/Text';
import usePartner from 'hooks/usePartner';
import usePrevious from 'hooks/usePrevious';
import { Box, Col } from 'jsxstyle';
import paths from 'paths';
import queryString from 'query-string';
import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import getWeddingsService from 'services/platform/getWeddingsService';
import useSWR, { mutate } from 'swr';
import { colors, mediaQueries, spacingSizes } from 'theme';
import WeddingType from 'types/WeddingType';
import SearchListItem from './SearchListItem';

export type SearchResultsProps = {};

const SearchResults = () => {
  const history = useHistory();
  const location = useLocation();

  const { name: search } = queryString.parse(location.search);
  const partnerID = usePartner();

  const { data, isValidating } = useSWR(
    ['/weddings', search],
    (_, name) => getWeddingsService({ name, partnerID }),
    { revalidateOnFocus: false },
  );
  const previousData = usePrevious<WeddingType[]>(data);

  return (
    <FadeIn>
      <Col flex={1} backgroundColor={colors.gray50}>
        <Header variant="platform" backgroundColor="white">
          <Header.Logo variant="icon" />
          <Separator />
          <Header.SearchInput
            pending={isValidating}
            onSubmit={(value) => {
              mutate('/weddings');
              history.push(`${paths.Platform.SEARCH_SCREEN}?name=${value}`);
            }}
          />
          <Separator />
          <Header.Buttons>
            <Box
              mediaQueries={mediaQueries}
              display="none"
              //@ts-ignore
              xlDisplay="flex"
              lgDisplay="flex"
            >
              <Header.Buttons.Login variant="primary-outline" />
              <Separator />
              <Header.Buttons.FreeTrial />
            </Box>
          </Header.Buttons>
        </Header>
        <React.Fragment>
          <Col flex={1} marginTop={80}>
            <Separator />
            <Container>
              <Box height={24}>
                {Boolean(search) && (
                  <React.Fragment>
                    {isValidating ? (
                      <Text size="sm" color={colors.gray500}>
                        Buscando por "{search}"...
                      </Text>
                    ) : data && data?.length ? (
                      <Text size="sm" color={colors.gray500}>
                        Olá! Encontramos {data?.length}&nbsp;
                        {data?.length && data.length > 1 ? 'casais' : 'casal'}
                      </Text>
                    ) : (
                      <Text size="sm" color={colors.gray500}>
                        Nenhum resultado encontrado para "{search}"
                      </Text>
                    )}
                  </React.Fragment>
                )}
              </Box>
              <Separator />
              <Box
                display="grid"
                gridTemplateColumns="repeat(auto-fit, 100%)"
                justifyContent="center"
                gridGap={spacingSizes.md}
                mediaQueries={mediaQueries}
                //@ts-ignore
                mdJustifyContent="flex-start"
                mdGridTemplateColumns="repeat(auto-fit, calc(50% - 16px))"
                lgJustifyContent="flex-start"
                lgGridTemplateColumns="repeat(auto-fit, calc(50% - 16px))"
                xlJustifyContent="flex-start"
                xlGridTemplateColumns="repeat(auto-fit, calc(33.33% - 16px))"
              >
                {previousData?.map((wedding, index) => (
                  <Link
                    key={wedding.giftCashID}
                    to={paths.BRIDE_AND_GROOM_SCREEN.replace(
                      ':contextID',
                      wedding.contextID,
                    )}
                  >
                    <SearchListItem
                      giftCashID={wedding.giftCashID}
                      coupleName={`${wedding.personName1} e ${wedding.personName2}`}
                      date={wedding.weddingDate}
                      location={`${wedding.weddingCity} - ${wedding.weddingState}`}
                    />
                  </Link>
                ))}
              </Box>
            </Container>
          </Col>
        </React.Fragment>
      </Col>
    </FadeIn>
  );
};

export default SearchResults;
