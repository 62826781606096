import Heading from 'components/Heading';
import ProductImage from 'components/ProductImage';
import { Box, Col, Row } from 'jsxstyle';
import React from 'react';
import { colors } from 'theme';
import ProductType from 'types/ProductType';
import NumberFormat from '../NumberFormat';
import Separator from '../Separator';
import Text from '../Text';

export type GiftInfoProps = {
  giftCashItemID?: string;
  pictureID: ProductType['pictureID'];
  name: string;
  amount: number;
  useCustomPicture?: boolean;
  variant?: 'preview' | 'showcase';
};

const GiftInfo = ({ variant = 'preview', ...props }: GiftInfoProps) => {
  return {
    preview: <Preview {...props} />,
    showcase: <Showcase {...props} />,
  }[variant];
};

export default GiftInfo;

function Showcase({
  giftCashItemID,
  pictureID,
  name,
  amount,
  useCustomPicture,
}: Omit<GiftInfoProps, 'variant'>) {
  return (
    <Col>
      <ProductImage
        width="auto"
        height={256}
        pictureID={pictureID}
        giftCashItemID={giftCashItemID}
        useCustomPicture={useCustomPicture}
      />
      <Separator />
      <Box minHeight={48}>
        <Text>{name}</Text>
        <Separator size="xs" />
        <Heading color={colors.secondary500}>
          <NumberFormat
            amount={amount}
            minimumFractionDigits={2}
            type="currency"
          />
        </Heading>
      </Box>
    </Col>
  );
}

function Preview({
  giftCashItemID,
  pictureID,
  name,
  amount,
  useCustomPicture,
}: Omit<GiftInfoProps, 'variant'>) {
  return (
    <Row alignItems="center">
      <ProductImage
        width="auto"
        height={56}
        pictureID={pictureID}
        giftCashItemID={giftCashItemID}
        useCustomPicture={useCustomPicture}
      />
      <Separator size="sm" />
      <Col minHeight={47}>
        <Text size="sm" color={colors.gray500}>
          {name}
        </Text>
        <Separator size="xs" />
        <Text size="sm" fontWeight="bold">
          <NumberFormat
            amount={amount}
            minimumFractionDigits={2}
            type="currency"
          />
        </Text>
      </Col>
    </Row>
  );
}
