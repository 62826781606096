import React from 'react';
import PropTypes from 'prop-types';
import './SkeletonPlaceholder.css';
import { colors } from 'theme';

type SkeletonPlaceholderType = {
  color?: string;
  hightlightColor?: string;
  children: JSX.Element;
};
function SkeletonPlaceholder({
  children,
  color = '#d9d9d9',
  hightlightColor = '#ffffff',
}: SkeletonPlaceholderType) {
  const getChildren = (element: JSX.Element) => {
    return React.Children.map(element, (child, index) => {
      const { style } = child.props;
      if (child.props.children) {
        return (
          <div
            key={index}
            style={{
              ...style,
            }}
          >
            {getChildren(child.props.children)}
          </div>
        );
      } else {
        return (
          <div
            key={index}
            style={{
              ...style,
              backgroundImage: `linear-gradient(to right,${color} 8%,${hightlightColor} 18%,${color} 33%)`,
              animation: 'shimmering 1s infinite ease-in-out',
              backgroundSize: '1000% 100%',
            }}
          />
        );
      }
    });
  };
  return <div className="shimmerdiv">{getChildren(children)}</div>;
}

SkeletonPlaceholder.Item = ({ ...style }: React.CSSProperties) => (
  <div style={style} />
);

SkeletonPlaceholder.propTypes = {
  color: PropTypes.string,
  hightlightColor: PropTypes.string,
};

SkeletonPlaceholder.defaultProps = {
  color: colors.gray100,
  hightlightColor: '#fff',
};

export default SkeletonPlaceholder;
