import getWeddingByIDService from './getWeddingByIDService';
// import purchaseCreditCardService from './purchaseCreditCardService';
import purchaseBilletService from './purchaseBilletService';
import purchasePixService from './purchasePixService';

export type PurchaseServiceParams = {
  giftCashID: string;
  paymentMethod: 'billet' | 'pix';
  giftCashItems: any[];
  buyerName: string;
  buyerDocument: string;
  buyerEmail: string;
  buyerAddress: string;
  buyerNeighborhood: string;
  buyerCity: string;
  buyerState: string;
  buyerZipCode: string;
  amount: number;
  recaptchaToken: string;
  message: string;
  installments: number;
};
export default async ({
  giftCashID,
  paymentMethod,
  giftCashItems,
  buyerName,
  buyerDocument,
  buyerEmail,
  buyerAddress,
  buyerNeighborhood,
  buyerCity,
  buyerState,
  buyerZipCode,
  amount,
  recaptchaToken,
  message,
  installments,
}: PurchaseServiceParams): Promise<any> => {
  try {
    const wedding = await getWeddingByIDService({ id: giftCashID });

    const service =
      paymentMethod === 'billet' ? purchaseBilletService : purchasePixService;

    const data = await service({
      giftCashID,
      userID: wedding.userID,
      accountID: wedding.accountID,
      giftCashItems,
      buyerName,
      buyerDocument,
      buyerEmail,
      buyerAddress,
      buyerNeighborhood,
      buyerCity,
      buyerState,
      buyerZipCode,
      amount,
      recaptchaToken,
      message,
      installments,
    });
    return data;
  } catch (error) {
    throw error;
  }
};
