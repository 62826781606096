import React from 'react';
import { Col, Row, Box } from 'jsxstyle';
import { spacingSizes, colors } from 'theme';
import useMedia from 'hooks/useMedia';
import Text from 'components/Text';
import Separator from 'components/Separator';
import Form from 'components/Form';
import InputGroup from 'components/InputGroup';
import Button from 'components/Button';
import useForm from 'hooks/useForm';
import NumberInput from 'components/NumberInput';
import InBetweenSpacing from 'components/InBetweenSpacing';
import isRequired from 'validators/isRequired';
import { isValid, parse, isBefore } from 'date-fns';

export type WeddingDateFormProps = {
  onSubmit: (date: Date) => void;
};

const WeddingDateForm = ({ onSubmit }: WeddingDateFormProps) => {
  const { media } = useMedia();
  const [error, setError] = React.useState<string | null>(null);

  const { getValue, setValue, getError, submit } = useForm({
    validations: {
      day: [isRequired],
      month: [isRequired],
      year: [isRequired],
    },
    onSubmit: (values) => {
      const date = parse(
        `${values.year}/${values.month}/${values.day}`,
        'yyyy/M/dd',
        new Date(),
      );

      if (!isValid(date)) {
        setError('Data inválida, tente novamente');
      } else if (isBefore(date, new Date())) {
        setError('A data não pode estar no passado, tente novamente');
      } else {
        onSubmit(date);
      }
    },
  });

  return (
    <Col
      width={media === 'desktop' ? 498 : '85%'}
      alignSelf="center"
      paddingTop={spacingSizes.xl}
    >
      <Text textAlign="center" size="lg">
        Qual a data do casamento?
      </Text>
      <Separator size="xl" />
      <Form>
        <Row alignItems="center" flex={1}>
          <InBetweenSpacing>
            <InputGroup error={getError('day')}>
              <NumberInput
                placeholder="DIA"
                textAlign="center"
                value={getValue('day')}
                onChange={(number) => setValue('day', number)}
                error={getError('day')}
                // min={1}
                // max={31}
              />
            </InputGroup>
            <Text size="xl" color={colors.gray200}>
              /
            </Text>
            <InputGroup error={getError('month')}>
              <NumberInput
                placeholder="MÊS"
                textAlign="center"
                value={getValue('month')}
                onChange={(number) => setValue('month', number)}
                error={getError('month')}
                // min={1}
                // max={12}
              />
            </InputGroup>
            <Text size="xl" color={colors.gray200}>
              /
            </Text>

            <InputGroup error={getError('year')}>
              <NumberInput
                placeholder="ANO"
                textAlign="center"
                value={getValue('year')}
                onChange={(number) => setValue('year', number)}
                error={getError('year')}
                min={2020}
                max={2100}
              />
            </InputGroup>
          </InBetweenSpacing>
        </Row>
        <Box height={24}>
          {error && (
            <Text size="sm" color={colors.red500}>
              {error}
            </Text>
          )}
        </Box>
        <Separator size="xl" />
        <Button type="submit" color="secondary" onClick={() => submit()}>
          <Text
            color={colors.white}
            fontWeight="bold"
            textTransform="uppercase"
          >
            Enviar
          </Text>
        </Button>
      </Form>
      <Separator size="xl" />
    </Col>
  );
};

export default WeddingDateForm;
