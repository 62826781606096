import TextInput, { TextInputProps } from 'components/TextInput';
import React from 'react';
import Container from './container';
import Menu from './menu';
import Option from './option';
import Provider, { Context } from './provider';

export type SearchableInputProps = {
  onInputChange?: TextInputProps['onChange'];
  inputValue?: TextInputProps['value'];
} & Omit<TextInputProps, 'onChange' | 'value'>;

export type SearchableProps = {
  children: React.ReactNode | React.ReactNodeArray;
  value?: any;
  onChange?: (value: any | null) => void;
} & SearchableInputProps;

function Searchable(props: SearchableProps) {
  return (
    <Provider
      props={props}
      inputProps={
        {
          ...props,
          children: undefined,
          value: undefined,
          onChange: undefined,
        } as any
      }
    >
      <SearchConsumer />
    </Provider>
  );
}

function SearchConsumer() {
  const { inputRef, inputProps, props, onInputKeyDown, setState } =
    React.useContext(Context);

  return (
    <Container>
      <TextInput
        {...inputProps}
        ref={inputRef}
        value={inputProps?.inputValue}
        onChange={(value) => {
          if (!value) {
            props?.onChange?.(null);
          }
          inputProps?.onInputChange?.(value);
        }}
        onKeyDown={onInputKeyDown}
        onClick={() =>
          setState((state) => ({
            ...state,
            opened: true,
          }))
        }
      />
      <Menu />
    </Container>
  );
}

//const Search = React.forwardRef(_Search) as typeof _Search;
Searchable.Option = Option;
export default Searchable;
