import couples from 'assets/images/lp-couples.png';
import Container from 'components/Container';
import Heading from 'components/Heading';
import Icon from 'components/Icon';
import Separator from 'components/Separator';
import Text from 'components/Text';
import useMedia from 'hooks/useMedia';
import { Box, Col } from 'jsxstyle';
import React from 'react';
//@ts-ignore
import Bounce from 'react-reveal/Bounce';
//@ts-ignore
import Fade from 'react-reveal/Fade';
import { TransitionGroup } from 'react-transition-group';
import { colors, mediaQueries, spacingSizes } from 'theme';

const steps = [
  {
    icon: <Icon name="mobile" color={colors.primary500} />,
    text: (
      <Text fontWeight="bold">
        Baixe o app MeuCompromisso <br />e abra sua conta digital
      </Text>
    ),
  },
  {
    icon: <Icon name="gift" width={32} height={23} color={colors.primary500} />,
    text: (
      <Text fontWeight="bold">
        Volte a este site e clique em 'Criar minha lista'
      </Text>
    ),
  },
  {
    icon: <Icon name="send" color={colors.primary500} />,
    text: <Text fontWeight="bold">Envie a lista para seus convidados</Text>,
  },
  {
    icon: <Icon name="money-envelope" color={colors.primary500} />,
    text: (
      <Text fontWeight="bold">
        Receba os presentes em dinheiro
        <br />
        direto na conta!
      </Text>
    ),
  },
  {
    icon: <Icon name="cake" color={colors.white} />,
    text: (
      <Text fontWeight="bold">
        Seu dinheiro cai na sua conta do MeuCompromisso e você pode usar a
        qualquer momento
      </Text>
    ),
  },
];

const HowItWorks = () => {
  const { media } = useMedia();
  return (
    <Box component="section" flex={1} className="how-it-works">
      <Box
        mediaQueries={mediaQueries}
        //@ts-ignore
        xlWidth={null}
        lgWidth={null}
        xlHeight={null}
        lgHeight={null}
        marginBottom={-10}
        xlMarginBottom={-60}
        lgMarginBottom={-40}
      >
        <svg
          viewBox="0 0 1440 174"
          fill={colors.white}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1440 0L1400 13.3243C1360 26.6486 1280 53.2973 1200 53.2973C1120 53.2973 1040 26.6486 960 26.6486C880 26.6486 800 53.2973 720 57.711C640 62.4578 560 44.1368 480 53.2973C400 62.4578 320 97.4341 240 111.008C160 124.083 80 115.755 40 111.008L0 106.595V174H40C80 174 160 174 240 174C320 174 400 174 480 174C560 174 640 174 720 174C800 174 880 174 960 174C1040 174 1120 174 1200 174C1280 174 1360 174 1400 174H1440V0Z"
            fill="white"
          />
        </svg>
      </Box>

      <Col
        backgroundColor={colors.white}
        paddingBottom={spacingSizes.md}
        alignItems="center"
        marginLeft={2}
        marginRight={1}
      >
        <Bounce top>
          <Heading textAlign="center" size="lg">
            Como funciona?
          </Heading>
        </Bounce>
        <Separator size="xl" />
        <Container>
          <Box
            mediaQueries={mediaQueries}
            display="flex"
            flexDirection="column"
            //@ts-ignore
            lgFlexDirection="row"
            xlFlexDirection="row"
            position="relative"
          >
            {media === 'desktop' && (
              <Fade delay={1000}>
                <Box
                  position="absolute"
                  width={2}
                  height="calc(100% - 82px)"
                  left={41 - 1}
                  margin="0 auto"
                  backgroundColor={colors.primary100}
                />
              </Fade>
            )}
            <TransitionGroup appear exit enter>
              {steps.map((step, index) => (
                <Fade key={String(index)} delay={125 * index} left>
                  <Box
                    mediaQueries={mediaQueries}
                    display="flex"
                    alignItems="center"
                    marginBottom={spacingSizes.md}
                    flexDirection="column"
                    //@ts-ignore
                    lgFlexDirection="row"
                    xlFlexDirection="row"
                  >
                    <Box
                      width={82}
                      height={82}
                      borderRadius={100}
                      position="relative"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      flexShrink={0}
                      border="2px solid"
                      borderColor={
                        index === steps.length - 1
                          ? 'transparent'
                          : colors.primary50
                      }
                      background={
                        index === steps.length - 1
                          ? colors.secondary500
                          : colors.white
                      }
                    >
                      {step.icon}
                    </Box>
                    <Separator />
                    <Box
                      mediaQueries={mediaQueries}
                      textAlign="center"
                      //@ts-ignore
                      lgTextAlign="left"
                      xlTextAlign="left"
                    >
                      {step.text}
                    </Box>
                  </Box>
                </Fade>
              ))}
            </TransitionGroup>
            {media === 'desktop' && (
              <Fade right delay={750}>
                <Box component="img" height={450} props={{ src: couples }} />
              </Fade>
            )}
          </Box>
        </Container>
      </Col>
    </Box>
  );
};

export default HowItWorks;
