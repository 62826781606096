import React from 'react';

const Success = ({ width = 128, height = 128 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 128 128"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.75287 127.997C2.79287 127.997 1.83287 127.63 1.10287 126.897C-0.360462 125.433 -0.360462 123.057 1.10287 121.593L7.91954 114.777C9.38287 113.313 11.7595 113.313 13.2229 114.777C14.6862 116.24 14.6862 118.617 13.2229 120.08L6.4062 126.897C5.67287 127.63 4.71287 127.997 3.75287 127.997Z"
      fill="#00429D"
    />
    <path
      d="M1.1665 126.96C1.88984 127.65 2.81984 127.997 3.74984 127.997C4.7065 127.997 5.66984 127.63 6.39984 126.897L13.2165 120.08C14.6598 118.637 14.6765 116.313 13.2765 114.843L1.1665 126.96Z"
      fill="#00347B"
    />
    <path
      d="M0.849611 101.323C1.39628 99.9899 2.21294 98.7432 3.29294 97.6632L5.95961 94.9966L11.0296 94.8299L11.2863 89.6699L16.5629 84.3933L20.5429 83.8499L21.8196 79.1366L27.2229 73.7333C27.2229 73.7333 33.6496 69.68 35.1129 71.1466L56.8563 92.8899C58.3196 94.3533 54.2063 100.843 54.2063 100.843L48.8863 106.163L44.1896 106.703L43.5863 111.463L38.3196 116.73L34.1063 116.783L32.9729 122.073L30.3363 124.71C29.3463 125.7 28.2129 126.467 27.0063 127.01C25.5396 127.67 -0.827056 105.4 0.849611 101.323Z"
      fill="#7DD5F4"
    />
    <path
      d="M46.0431 82.0831L12.4531 115.673C19.1065 121.773 26.2631 127.346 27.0065 127.013C28.2131 126.47 29.3465 125.703 30.3365 124.713L32.9731 122.076L34.1098 116.783L38.3198 116.73L43.5865 111.463L44.1898 106.703L48.8865 106.166L54.2065 100.846C54.2065 100.846 58.3231 94.3565 56.8565 92.8931L46.0431 82.0831Z"
      fill="#4793FF"
    />
    <path
      d="M16.5366 84.4033L21.8399 79.1L48.8799 106.14L43.5766 111.443L16.5366 84.4033Z"
      fill="#4793FF"
    />
    <path
      d="M35.1096 71.15C33.6462 69.6867 31.2696 69.6867 29.8062 71.15L27.1562 73.8L54.2029 100.847L56.8529 98.1967C58.3162 96.7333 58.3162 94.3567 56.8529 92.8933L35.1096 71.15Z"
      fill="#4793FF"
    />
    <path
      d="M1.11643 100.727C-0.763572 104.627 -0.263572 109.393 2.61309 112.83L15.1698 125.387C17.2498 127.127 19.8131 128 22.3798 128C24.0564 128 25.7298 127.627 27.2698 126.883L1.11643 100.727Z"
      fill="#4793FF"
    />
    <path
      d="M5.93311 95.0066L11.2364 89.7032L38.2765 116.743L32.9731 122.047L5.93311 95.0066Z"
      fill="#4793FF"
    />
    <path
      d="M124.247 86.4366C123.307 86.4366 122.36 86.0833 121.633 85.3766C113.373 77.3433 102.48 72.9166 90.9566 72.9166C81.5233 72.9166 72.1667 76.0266 64.61 81.6766C62.9533 82.9166 60.6033 82.5766 59.3633 80.92C58.1233 79.26 58.4633 76.9133 60.12 75.67C68.9633 69.06 79.9133 65.4166 90.9566 65.4166C104.443 65.4166 117.193 70.5966 126.86 80C128.347 81.4433 128.377 83.8166 126.933 85.3033C126.2 86.0566 125.223 86.4366 124.247 86.4366Z"
      fill="#CA75F5"
    />
    <path
      d="M45.8062 64.9134C45.0329 64.9134 44.2562 64.6734 43.5862 64.1834C41.9195 62.9567 41.5629 60.6067 42.7895 58.9401C45.8295 54.8101 47.4362 49.9167 47.4362 44.7834C47.4362 34.5467 40.9262 25.4634 31.2329 22.1767C29.2729 21.5101 28.2195 19.3801 28.8862 17.4201C29.5529 15.4601 31.6795 14.4101 33.6429 15.0734C46.3795 19.3934 54.9395 31.3334 54.9395 44.7834C54.9395 51.5234 52.8262 57.9567 48.8295 63.3867C48.0929 64.3867 46.9562 64.9134 45.8062 64.9134Z"
      fill="#FDBF00"
    />
    <path
      d="M72.4165 46.04C71.4565 46.04 70.4965 45.6733 69.7665 44.94C68.2998 43.4766 68.2998 41.1 69.7665 39.6366C73.3398 36.0633 78.2598 35.1733 82.6032 34.39C86.1798 33.7433 89.5565 33.1333 91.4698 31.22C93.3832 29.3066 93.9932 25.9266 94.6398 22.35C95.4232 18.0066 96.3132 13.0866 99.8865 9.5133C104.287 5.1133 110.103 3.68997 115.73 2.31663C118.29 1.68997 120.707 1.09997 122.89 0.253299C124.82 -0.496701 126.993 0.463299 127.74 2.3933C128.49 4.3233 127.53 6.49663 125.6 7.2433C122.96 8.26663 120.19 8.9433 117.51 9.59997C112.673 10.7833 108.107 11.9 105.187 14.8166C103.273 16.73 102.663 20.11 102.017 23.6833C101.23 28.0266 100.343 32.95 96.7698 36.5233C93.1965 40.0966 88.2732 40.9866 83.9298 41.77C80.3532 42.4166 76.9765 43.0266 75.0598 44.94C74.3365 45.6766 73.3765 46.04 72.4165 46.04Z"
      fill="#FF8856"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M83.5 14.25C85.5633 14.25 87.25 12.5667 87.25 10.5C87.25 8.43333 85.5667 6.75 83.5 6.75C81.4333 6.75 79.75 8.43333 79.75 10.5C79.75 12.5667 81.4333 14.25 83.5 14.25Z"
      fill="#BEE75E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1196 53.7332C14.1863 53.7332 15.8696 52.0499 15.8696 49.9832C15.8696 47.9166 14.1863 46.2333 12.1196 46.2333C10.053 46.2333 8.36963 47.9166 8.36963 49.9832C8.36963 52.0499 10.0563 53.7332 12.1196 53.7332Z"
      fill="#D98FF8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.25342 13.25C10.3168 13.25 12.0034 11.5667 12.0034 9.5C12.0034 7.43333 10.3168 5.75 8.25342 5.75C6.18675 5.75 4.50342 7.43333 4.50342 9.5C4.50342 11.5667 6.18675 13.25 8.25342 13.25Z"
      fill="#7DD5F4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M91.25 92.7466C93.3167 92.7466 95 91.0632 95 88.9966C95 86.9299 93.3167 85.2466 91.25 85.2466C89.1867 85.2466 87.5 86.9299 87.5 88.9966C87.5 91.0632 89.1833 92.7466 91.25 92.7466Z"
      fill="#FF8856"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M112.997 126.993C115.063 126.993 116.747 125.31 116.747 123.243C116.747 121.177 115.063 119.493 112.997 119.493C110.93 119.493 109.247 121.177 109.247 123.243C109.247 125.31 110.933 126.993 112.997 126.993Z"
      fill="#4793FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M62.25 122.997C64.3133 122.997 66 121.313 66 119.247C66 117.18 64.3167 115.497 62.25 115.497C60.1833 115.497 58.5 117.18 58.5 119.247C58.5 121.31 60.1833 122.997 62.25 122.997Z"
      fill="#E76D84"
    />
    <path
      d="M64.8998 24.3666C64.7964 24.3666 64.6931 24.36 64.5864 24.3533C62.5231 24.18 60.9864 22.37 61.1564 20.3066C61.5764 15.23 60.3264 10.0566 57.6364 5.72996C56.5431 3.96996 57.0831 1.65996 58.8398 0.563296C60.5964 -0.530038 62.9098 0.00996256 64.0031 1.76663C67.5398 7.44996 69.1798 14.2533 68.6298 20.92C68.4698 22.8833 66.8298 24.3666 64.8998 24.3666Z"
      fill="#D98FF8"
    />
    <path
      d="M94.9598 54.6266C93.3898 54.6266 91.9265 53.6333 91.4031 52.06C90.7465 50.0966 91.8098 47.97 93.7732 47.3167C101.623 44.7 110.09 44.0133 118.26 45.33C120.303 45.66 121.693 47.5833 121.366 49.6266C121.036 51.6733 119.113 53.0633 117.066 52.7333C110.086 51.6066 102.853 52.1933 96.1465 54.43C95.7531 54.5633 95.3532 54.6266 94.9598 54.6266Z"
      fill="#E76D84"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M66.8101 65.1033C68.8767 65.1033 70.5601 63.4199 70.5601 61.3533C70.5601 59.2899 68.8767 57.6033 66.8101 57.6033C64.7434 57.6033 63.0601 59.2866 63.0601 61.3533C63.0601 63.4199 64.7434 65.1033 66.8101 65.1033Z"
      fill="#7DD5F4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M119.497 27.5C121.563 27.5 123.247 25.8167 123.247 23.75C123.247 21.6833 121.563 20 119.497 20C117.43 20 115.747 21.6833 115.747 23.75C115.747 25.8167 117.433 27.5 119.497 27.5Z"
      fill="#00CB75"
    />
    <path
      d="M86.9263 41.1998C90.4229 40.4532 94.0029 39.2965 96.7763 36.5265C99.5496 33.7532 100.706 30.1732 101.45 26.6765L86.9263 41.1998Z"
      fill="#E76D84"
    />
    <path
      d="M126.96 1.16321L118.85 9.27321C121.106 8.71321 123.396 8.09988 125.6 7.24655C127.53 6.49655 128.49 4.32321 127.74 2.39321C127.56 1.92321 127.29 1.50987 126.96 1.16321Z"
      fill="#E76D84"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M69.4364 58.6866L64.1396 63.9833C64.8197 64.6733 65.7663 65.1033 66.8063 65.1033C68.873 65.1033 70.5564 63.42 70.5564 61.3533C70.5564 60.3133 70.1264 59.3666 69.4364 58.6866Z"
      fill="#4793FF"
    />
    <path
      d="M46.0431 82.0831L40.7397 87.3865L54.1997 100.846L56.8497 98.1965C58.3131 96.7331 58.3131 94.3565 56.8497 92.8931L46.0431 82.0831Z"
      fill="#2A567D"
    />
    <path
      d="M30.1196 97.9867L35.423 92.6833L48.883 106.143L43.5796 111.447L30.1196 97.9867Z"
      fill="#2A567D"
    />
    <path
      d="M19.5127 108.59L24.816 103.287L38.2761 116.747L32.9727 122.05L19.5127 108.59Z"
      fill="#2A567D"
    />
    <path
      d="M8.95312 119.17L15.1698 125.386C17.2498 127.126 19.8131 128 22.3798 128C24.0565 128 25.7298 127.626 27.2698 126.883L14.2531 113.866L8.95312 119.17Z"
      fill="#2A567D"
    />
  </svg>
);

Success.propTypes = {};
Success.defaultProps = {};

export default Success;
