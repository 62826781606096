import GiftForm from 'components/GiftForm';
import Icon from 'components/Icon';
import { ImageType } from 'components/ImagePicker';
import Modal from 'components/Modal';
import NumberFormat from 'components/NumberFormat';
import ProductImage from 'components/ProductImage';
import Separator from 'components/Separator';
import Text from 'components/Text';
import { Box, Col, Row } from 'jsxstyle';
import React from 'react';
import { colors, mediaQueries, spacingSizes } from 'theme';
import ProductType from 'types/ProductType';

export interface GiftBoxProps {
  name: string;
  value: number;
  onChange: ({
    value,
    pictureID,
    name,
    customImage,
  }: {
    value: number;
    pictureID: ProductType['pictureID'];
    name: string;
    customImage?: ImageType;
  }) => void;
  active?: boolean;
  onSelect: () => void;
  pictureID: ProductType['pictureID'];
  productID: ProductType['productID'];
  customImage?: ImageType;
}

const GiftBox = ({
  name,
  value,
  active,
  onSelect,
  onChange,
  pictureID,
  productID,
  customImage,
}: GiftBoxProps) => {
  const [modalVisibility, setModalVisibility] =
    React.useState<'visible' | 'hidden'>('hidden');

  function handleSubmit(values: any) {
    setTimeout(
      () =>
        onChange({
          value: values.value,
          name: values.name,
          pictureID: values.pictureID,
          customImage: values?.customImage,
        }),
      0,
    );
    setModalVisibility('hidden');
  }

  return (
    <React.Fragment>
      <Row
        justifyContent="space-between"
        alignItems="center"
        flex={1}
        height={88}
        borderRadius={6}
        backgroundColor={colors.white}
        //@ts-ignore
        hoverBackgroundColor={colors.gray100}
        border="1px solid"
        borderColor={active ? colors.secondary500 : colors.primary100}
        padding={`0 ${spacingSizes.sm}px`}
        position="relative"
        transition="all 250ms ease-in-out"
        cursor="pointer"
        props={{ onClick: () => onSelect() }}
      >
        <Row alignItems="center">
          {customImage ? (
            <Box
              component="img"
              props={{ src: customImage.url }}
              width={56}
              height={56}
              borderRadius={4}
              objectFit="contain"
            />
          ) : (
            <ProductImage pictureID={pictureID} width={56} height={56} />
          )}

          <Separator size="sm" />
          <Col>
            <Text color={colors.black} size="sm">
              {name}
            </Text>
            <Separator size="xs" />
            <Text color={colors.black} fontWeight="bold" size="lg">
              <NumberFormat
                type="currency"
                amount={value}
                minimumFractionDigits={2}
              />
            </Text>
            {/* <GiftBoxInput active={active} value={value} onChange={onChange} /> */}
          </Col>
        </Row>
        {active && (
          <Box
            props={{
              onClick: (event) => {
                event.stopPropagation();
                onSelect();
              },
            }}
            position="absolute"
            display="flex"
            justifyContent="center"
            alignItems="center"
            right={-8}
            top={-8}
            backgroundColor={colors.secondary500}
            borderRadius={100}
            width={24}
            height={24}
            border="2px solid white"
          >
            <Icon name="check" />
          </Box>
        )}
        {active && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            component="button"
            background={'transparent'}
            borderRadius={32}
            outline="none"
            border="none"
            cursor="pointer"
            width={32}
            height={32}
            // hoverBackgroundColor={`${colors.gr}1a`}
            transition="all 250ms ease-in-out"
            props={{
              onClick: (event: any) => {
                setModalVisibility('visible');
                event.stopPropagation();
              },
            }}
          >
            <Icon name="edit" color={colors.black} />
          </Box>
        )}
      </Row>
      <Modal
        visible={modalVisibility === 'visible'}
        onRequestClose={() => setModalVisibility('hidden')}
      >
        <Box
          mediaQueries={mediaQueries}
          //@ts-ignore
          xlWidth={768}
          lgWidth={768}
          lgHeight="auto"
          xlHeight="auto"
        >
          <GiftForm
            name={name}
            productID={productID}
            pictureID={pictureID}
            value={value}
            customImage={customImage}
            onSubmit={handleSubmit}
          />
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default GiftBox;
