/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
//@ts-ignore
import { animateScroll } from 'react-scroll';
import { useMachine } from '@xstate/react';
import { Col, Box } from 'jsxstyle';
import UserMessage from './UserMessage';
import useMedia from 'hooks/useMedia';
import BrideAndGroomsNameForm from './ChatBrideAndGroomsNameForm';
import assistantMachine from 'statecharts/assistantMachine';
import { spacingSizes, colors } from 'theme';
import AssistantMessage from './AssitantMessage';
import Text from 'components/Text';
import Button from 'components/Button';
import Separator from 'components/Separator';
import ScreenLayout from 'components/ScreenLayout';
import WeddingDateForm from './WeddingDateForm';
import WeddingAddressForm from './WeddingAddressForm';
import WeddingPhraseForm from './WeddingPhraseForm';
import WeddingProductsForm from './WeddingProductsForm';
import WeddingUrlForm from './WeddingUrlForm';
import ReviewForm from './ReviewForm';
import FadeIn from 'components/FadeIn';
import { useApplication } from 'contexts/ApplicationContext';
import { format } from 'date-fns';
import Modal from 'components/Modal';

type InteractionType = {
  index: number;
  author: 'assistant' | 'user';
  phrase: string;
  shouldErase?: boolean;
  phraseAfterErase?: string;
  type?: string;
  field?: string;
  render?: any;
};

const VISIBLE = 'visible';
const HIDDEN = 'hidden';

interface ChatScreenProps extends RouteComponentProps {}

const ChatScreen = ({ history }: ChatScreenProps) => {
  const { name, pending } = useApplication();

  const [modalVisibility, setModalVisibility] = React.useState(HIDDEN);

  const { media } = useMedia();

  const [current, send] = useMachine(assistantMachine, {
    context: {
      interactions: [
        {
          phrase: `Olá ${name}, sou Margaret, estarei para guia-lo na criação da lista de presentes do seu casamento.`,
          author: 'assistant',
        },
        {
          phrase:
            'Lembrando que todo o dinheiro arrecadado, cairá automaticamente na sua conta MeuCompromisso sem nenhuma taxa!',
          author: 'assistant',
        },
        {
          phrase:
            'Para começar, vamos precisar do nome dos noivos... Informe-os clicando no botão abaixo.',
          author: 'assistant',
        },
        {
          field: 'names',
          type: 'names',
          author: 'user',
          render: ({ names }: any) => (
            <Text color={colors.white}>
              {names.groom}&nbsp;e&nbsp;{names.bride}
            </Text>
          ),
        },
        {
          phrase: 'Muito bom!',
          author: 'assistant',
        },
        {
          phrase: 'Agora precisamos saber quando será realizado o casamento.',
          author: 'assistant',
        },
        {
          field: 'date',
          type: 'date',
          author: 'user',
          render: ({ date }: any) => (
            <Text color={colors.white}>
              Será&nbsp;{format(date, 'dd/MM/yyyy')}
            </Text>
          ),
        },
        {
          phrase: 'E onde será o local do casamento?',
          author: 'assistant',
        },
        {
          field: 'address',
          type: 'address',
          author: 'user',
          render: ({ address }: any) => (
            <Text color={colors.white}>
              O casamento será em:
              <br />
              {address.city}&nbsp;-&nbsp;{address.state}
            </Text>
          ),
        },
        {
          phrase: ({ address }: any) =>
            `Muito bom! ${address.city} é um belíssimo lugar 😉`,
          author: 'assistant',
        },
        {
          phrase:
            'Para deixar ainda mais personalizado, escolha a frase do seu casamento. Ela será apresentada para todos os convidados que acessarem sua lista.',
          author: 'assistant',
        },
        {
          field: 'phrase',
          type: 'phrase',
          author: 'user',
          render: ({ phrase }: any) => (
            <Text color={colors.white}>"{phrase}"</Text>
          ),
        },
        {
          phrase: 'Ficou sensacional!',
          author: 'assistant',
        },
        {
          phrase:
            'Agora vamos para a parte legal. Hora de selecionar os itens da lista.',
          author: 'assistant',
        },
        {
          field: 'products',
          type: 'products',
          author: 'user',
          render: ({ products }: any) => (
            <Text color={colors.white} textAlign="right">
              Lista de casamento completa.
              <br />
              Escolhi {products.length} itens.
            </Text>
          ),
        },
        {
          phrase: `Show ${name}, seu casamento será um sucesso. Só não esqueça de me convidar para a festa 😀`,
          author: 'assistant',
        },
        {
          phrase:
            'Para finalizar, escolha o nome do seu site. Ele será utilizado para seus convidados acessarem e encontrarem as informações do seu casamento.',
          author: 'assistant',
        },
        {
          field: 'url',
          type: 'url',
          author: 'user',
          render: ({ url }: any) => (
            <Text color={colors.white}>
              A opção escolhida é:
              <br />
              {window.location.hostname}/{url}
            </Text>
          ),
        },
        {
          phrase: `Bela escolha ${name}.`,
          author: 'assistant',
        },
        {
          phrase:
            'Agora pode deixar tudo comigo, estarei organizando sua lista de casamento. E estarei enviando um e-mail para sua caixa de entrada quando tudo estiver pronto. Obrigado por estar conosco e até mais!',
          author: 'assistant',
        },
      ] as any,
    },
    values: {
      names: {},
      date: null,
      address: {},
      phrase: null,
      products: [],
      url: '',
    },
  });

  const {
    messages,
    interactions,
    index,
  }: {
    messages: InteractionType[];
    interactions: InteractionType[];
    index: number;
  } = current.context;

  React.useEffect(() => {
    if (!pending) {
      send('TYPE');
    }
  }, [pending]);

  React.useEffect(() => {
    animateScroll.scrollToBottom({
      duration: 300,
      containerId: 'container',
      smooth: 'easeInOutQuad',
    });
  }, [messages, current.value]);

  const handleTypingEnd = React.useCallback(() => {
    setTimeout(() => send('TYPING_END'), 1000);
  }, []);

  const handleSubmit = (data: any) => {
    send({
      type: 'SUBMIT',
      field: interactions[index].field,
      value: data,
    });

    setModalVisibility(HIDDEN);
  };

  const interactionsType = interactions[index].type;

  return (
    <FadeIn>
      <ScreenLayout>
        <Col
          padding={spacingSizes.lg}
          margin="0 -15px"
          WebkitFlex="1 0 auto"
          msFlex="1 0 auto"
          flex="1 0 auto"
        >
          {messages.map((message) =>
            message.author === 'assistant' ? (
              <React.Fragment key={message.index}>
                <AssistantMessage
                  onTypingEnd={handleTypingEnd}
                  message={message.phrase}
                  shouldErase={message.shouldErase}
                  textAfterErase={message.phraseAfterErase}
                  lastAuthor={
                    messages[message.index - 1] &&
                    messages[message.index - 1].author
                  }
                />
                <Separator />
              </React.Fragment>
            ) : (
              <React.Fragment key={message.index}>
                <UserMessage
                  onTypingEnd={handleTypingEnd}
                  content={message.render}
                />
                <Separator />
              </React.Fragment>
            ),
          )}
        </Col>
        <Box
          display="flex"
          alignSelf="center"
          paddingBottom={spacingSizes.xl}
          width={media === 'desktop' ? 498 : '85%'}
          WebkitFlex="0 0 auto"
          msFlex="0 0 auto"
          flex="0 0 auto"
        >
          {current.matches('awaitingUserInput') && (
            <Button
              color="secondary"
              style={{ width: '100%' }}
              onClick={() => setModalVisibility(VISIBLE)}
            >
              <Text
                color={colors.white}
                fontWeight="bold"
                textTransform="uppercase"
              >
                {
                  //@ts-ignore
                  {
                    names: 'Informar o nome dos noivos',
                    date: 'Informar quando será o casamento',
                    address: 'Informar  o local do casamento',
                    phrase: 'Escolher uma frase de casamento',
                    products: 'Escolher itens da lista de casamento',
                    url: 'Escolher nome do site',
                  }[interactionsType!]
                }
              </Text>
            </Button>
          )}
          {current.matches('end') && (
            <Button
              color="secondary"
              style={{ width: '100%' }}
              onClick={() => setModalVisibility(VISIBLE)}
            >
              <Text
                color={colors.white}
                fontWeight="bold"
                textTransform="uppercase"
              >
                Revisar informações
              </Text>
            </Button>
          )}
        </Box>
        <Modal
          visible={modalVisibility === VISIBLE}
          onRequestClose={() => setModalVisibility(HIDDEN)}
          containerStyle={{ justifyContent: 'flex-end' }}
          // backdropColor={`${colors.secondary500}d9`}
          // width={media === 'desktop' ? 990 : 'auto'}
        >
          <Col
            borderTopLeftRadius={10}
            borderTopRightRadius={10}
            backgroundColor={colors.white}
            width={media === 'desktop' ? 990 : 'auto'}
            minHeight={media === 'desktop' ? 495 : 'auto'}
            maxHeight="85vh"
            overflowY="auto"
            props={{ id: 'animated-modal-container' }}
          >
            {current.matches('end') && (
              <ReviewForm initialValues={current.context.values} />
            )}
            {
              //@ts-ignore
              {
                names: (
                  <BrideAndGroomsNameForm
                    onSubmit={(values) => handleSubmit(values)}
                  />
                ),
                date: (
                  <WeddingDateForm
                    onSubmit={(values) => handleSubmit(values)}
                  />
                ),
                address: (
                  <WeddingAddressForm
                    onSubmit={(values) => handleSubmit(values)}
                  />
                ),
                phrase: (
                  <WeddingPhraseForm
                    onSubmit={(values) => handleSubmit(values)}
                  />
                ),
                products: (
                  <WeddingProductsForm
                    onSubmit={(values) => handleSubmit(values)}
                  />
                ),
                url: (
                  <WeddingUrlForm
                    onSubmit={(values) => handleSubmit(values)}
                    //@ts-ignore
                    names={current.context.values.names}
                  />
                ),
              }[interactionsType!]
            }
          </Col>
        </Modal>
      </ScreenLayout>
    </FadeIn>
  );
};
export default ChatScreen;
