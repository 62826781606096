import getCheckAccountService from './checkAccountService';
import getWeddingsService from './platform/getWeddingsService';
import CheckingAccountType from 'types/CheckingAccountType';
import WeddingType from 'types/WeddingType';

export default async ({
  partnerID,
}: {
  partnerID: string;
}): Promise<{
  account: CheckingAccountType;
  wedding?: WeddingType;
}> => {
  try {
    const accounts = await getCheckAccountService();
    const [account] = accounts;

    const weddings = await getWeddingsService({
      userID: account.userID,
      partnerID,
    });
    const [wedding] = weddings;

    return {
      account,
      wedding,
    };
  } catch (error) {
    throw error;
  }
};
