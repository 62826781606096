import { Box, CSSProperties } from 'jsxstyle';
import React from 'react';
import { Context } from './provider';

function Menu() {
  const { opened, optionNodes } = React.useContext(Context);

  if (!opened) {
    return null;
  }

  return <Box {...menuStyles}>{optionNodes}</Box>;
}

export default Menu;

const menuStyles: CSSProperties = {
  position: 'absolute',
  width: '100%',
  maxHeight: '10rem',
  marginTop: '0.5rem',
  overflow: 'auto',
  backgroundColor: 'white',
  border: '1px solid #dbdbdb',
  borderRadius: 5,
  boxShadow: '0 5px 5px rgba(0, 0, 0, 0.15)',
  outline: 'none',
  zIndex: 10,
};
