import React from 'react';
import { Box } from 'jsxstyle';
import { SizeTypes } from 'typings';
import { spacingSizes } from 'theme';

interface Props {
  size: SizeTypes;
}

const Separator = ({ size }: Props) => (
  <Box height={spacingSizes[size]} width={spacingSizes[size]} />
);

Separator.defaultProps = {
  size: 'md',
};

export default Separator;
