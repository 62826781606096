import Heading from 'components/Heading';
import NumberFormat from 'components/NumberFormat';
import Separator from 'components/Separator';
import Text from 'components/Text';
import { Row } from 'jsxstyle';
import React from 'react';
import { colors } from 'theme';
import PurchaseDetailType from 'types/PurchaseDetailType';

export type PurchaseSummarySectionProps = {
  purchase: PurchaseDetailType;
};

function PurchaseSummarySection({ purchase }: PurchaseSummarySectionProps) {
  const totalInstallments = (purchase?.installments || []).length;
  const totalProductsAmount = purchase?.giftCashItems.reduce(
    (acc, item) => acc + item.amount,
    0,
  );
  const totalFee = (purchase?.fee || 0) * totalInstallments;
  const hasFee = Boolean(totalFee);

  return (
    <React.Fragment>
      <Row alignItems="center" justifyContent="space-between">
        <Text size="sm" color={colors.gray900}>
          Valor total dos itens
        </Text>

        <Text size="sm" fontWeight="bold" color={colors.secondary500}>
          <NumberFormat
            amount={totalProductsAmount ?? 0}
            type="currency"
            minimumFractionDigits={2}
          />
        </Text>
      </Row>
      <Separator />
      {hasFee && (
        <React.Fragment>
          <Row alignItems="center" justifyContent="space-between">
            <Text size="sm" color={colors.gray900}>
              Tarifa da compra
            </Text>
            <Text size="sm" fontWeight="bold" color={colors.black}>
              +{' '}
              <NumberFormat
                amount={totalFee}
                type="currency"
                minimumFractionDigits={2}
              />
            </Text>
          </Row>
          <Separator />
        </React.Fragment>
      )}
      <Row alignItems="center" justifyContent="space-between">
        <Text>Valor total</Text>
        <Heading color={colors.secondary500} size="sm">
          <NumberFormat
            type="currency"
            amount={purchase?.amount ?? 0}
            minimumFractionDigits={2}
          />
        </Heading>
      </Row>
    </React.Fragment>
  );
}

export default PurchaseSummarySection;
