import { Box, CSSProperties } from 'jsxstyle';
import React from 'react';
import { Context } from './provider';

export type ContainerProps = {
  children: React.ReactNode | React.ReactNodeArray;
};

function Container({ children }: ContainerProps) {
  const containerRef = React.useRef<any>(null);
  const { setState, props, inputProps } = React.useContext(Context);

  const handleClickOutside = React.useCallback(
    (event: any) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event?.target)
      ) {
        if (!props.value) {
          inputProps?.onInputChange?.('');
        }
        setState((state) => ({ ...state, opened: false, activeIndex: -1 }));
      }
    },
    [containerRef, props, inputProps, setState],
  );

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [containerRef, handleClickOutside]);

  return (
    <Box props={{ ref: containerRef }} {...containerStyles}>
      {children}
    </Box>
  );
}

export default Container;

const containerStyles: CSSProperties = {
  position: 'relative',
  cursor: 'pointer',
};
