import React from 'react';
import { Box, CSSProperties } from 'jsxstyle';
import { colors } from 'theme';

export type ReviewTextInputProps = {
  value: string | undefined;
  onChange?: (value: string) => void;
  textAlign?: CSSProperties['textAlign'];
};

const ReviewTextInput = ({
  value,
  onChange,
  textAlign = 'start',
}: ReviewTextInputProps) => (
  <Box
    component="input"
    fontWeight="bold"
    color={colors.primary500}
    border="none"
    outline="none"
    textAlign={textAlign}
    props={{
      value,
      onChange: (e: any) => onChange && onChange(e.target.value),
    }}
  />
);

export default ReviewTextInput;
