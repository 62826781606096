import React from 'react';
import AuthContextProvider from 'contexts/AuthContext';
import Routes from 'routes';
import getProductsService from 'services/getProductsService';
import useSWR from 'swr';
import ApplicationContext from 'contexts/ApplicationContext';
import ProductsContext from 'contexts/ProductContext';
import ConfigContext from 'contexts/ConfigContext';
import CookieConsent from 'components/CookieConsent';
// import privateApi from 'services/privateApi';

export interface AppProps {}
// privateApi.delete(
//   '/accounts/7099/giftcashes/weddings/2a533b34-ac13-49eb-b9f0-7281c07f212b',
// );

const App = () => {
  //Cache gift cards
  useSWR(['/products-gift-card'], () =>
    getProductsService({ categoryID: 'wedding_gift_card' }),
  );

  return (
    <ConfigContext>
      <AuthContextProvider>
        <ApplicationContext>
          <ProductsContext>
            <Routes />
          </ProductsContext>
        </ApplicationContext>
      </AuthContextProvider>
      <CookieConsent />
    </ConfigContext>
  );
};

export default App;
