import Button from 'components/Button';
import Compressor from 'compressorjs';
import { useApplication } from 'contexts/ApplicationContext';
import useAsync from 'hooks/useAsync';
import useToast from 'hooks/useToast';
import { Box } from 'jsxstyle';
import React from 'react';
import saveWeddingPictureService from 'services/admin/saveWeddingPictureService';
import handleError from 'utils/handleError';

export type CoverInputProps = {
  onData?: () => void;
};

const CoverInput = ({ onData }: CoverInputProps) => {
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const [pending, setPending] = React.useState(false);
  const [image, setImage] = React.useState<File>();
  const { wedding, account } = useApplication();
  const { dangerMessage } = useToast();

  const { fetch } = useAsync({
    promiseFn: saveWeddingPictureService,
    onData: () => {
      onData?.();
      setPending(false);
    },
    onError: (error) => {
      setPending(false);
      const handledError = handleError({ error });
      dangerMessage({ message: handledError.message });
    },
  });

  function compressImage(file: File) {
    setPending(true);
    const _4MB = 4000000;
    new Compressor(file, {
      quality: 0.8,
      convertSize: _4MB,
      success(result) {
        if (result.size > _4MB) {
          dangerMessage({
            message: 'Envie uma imagem menor que 4MB.',
          });
          setPending(false);
          return;
        }
        setImage(result as File);
      },
      error(err) {
        setPending(false);
        console.log(err.message);
      },
    });
  }

  React.useEffect(() => {
    if (image) {
      fetch({
        accountID: account.accountID,
        giftCashID: wedding.giftCashID,
        image,
        type: 'banner',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image]);

  return (
    <React.Fragment>
      <Button
        size="sm"
        color="light"
        loading={pending}
        onClick={() => fileInputRef.current?.click()}
      >
        Adicionar capa
      </Button>
      <Box
        component="input"
        display="none"
        //@ts-ignore
        props={{
          type: 'file',
          ref: fileInputRef,
          //@ts-ignore
          onChange: (event) => {
            const file = event.target.files?.[0];
            if (file) {
              compressImage(file);
            }
          },
        }}
      />
    </React.Fragment>
  );
};

export default CoverInput;
