import React from 'react';
import { Box } from 'jsxstyle';
import { mediaQueries, spacingSizes, colors } from 'theme';
import Text from 'components/Text';
import IconButton from 'components/admin/IconButton';
import Icon from 'components/Icon';
import { useApplication } from 'contexts/ApplicationContext';
import Form from 'components/Form';

export type WelcomeMessageInputProps = {
  onSubmit: (welcomeMessage: string) => void;
};

const WelcomeMessageInput = ({ onSubmit }: WelcomeMessageInputProps) => {
  const { wedding } = useApplication();
  const [editing, setEditing] = React.useState(false);
  const [localMessage, setLocalMessage] = React.useState('');
  const textAreaRef = React.useRef<HTMLTextAreaElement>(null);

  React.useEffect(() => {
    if (wedding.welcomeMessage) {
      setLocalMessage(wedding.welcomeMessage);
    }
  }, [wedding.welcomeMessage]);

  React.useEffect(() => {
    if (editing) {
      textAreaRef.current?.setSelectionRange(
        localMessage.length,
        localMessage.length,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editing]);

  const handleSubmit = () => {
    if (wedding.welcomeMessage !== localMessage) {
      setEditing(false);
      onSubmit(localMessage);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Box position="relative">
        <Box
          mediaQueries={mediaQueries}
          padding={spacingSizes.md}
          backgroundColor={colors.gray50}
          borderRadius={5}
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight={152}
          //@ts-ignore
          xlPadding={`${spacingSizes.lg}px ${spacingSizes.xl}px`}
          mdPadding={`${spacingSizes.lg}px ${spacingSizes.xl}px`}
          props={{
            onClick: (event) => {
              event.stopPropagation();
              setEditing(true);
            },
          }}
        >
          {editing ? (
            <Box
              component="textarea"
              backgroundColor="transparent"
              border="none"
              outline="none"
              textAlign="center"
              resize="none"
              boxShadow="none"
              width="100%"
              props={{
                ref: textAreaRef,
                value: localMessage,
                //@ts-ignore
                onChange: (event) => setLocalMessage(event.target.value),
                autoFocus: true,
                onBlur: handleSubmit,
                rows: 8,
              }}
            />
          ) : (
            <Text textAlign="center">
              {localMessage ? `"${localMessage}"` : ''}
            </Text>
          )}
        </Box>
        <Box position="absolute" right={-16} top={-16}>
          <IconButton
            onClick={() => {
              setEditing(!editing);
            }}
          >
            <Icon name={editing ? 'close' : 'edit'} color={colors.black} />
          </IconButton>
        </Box>
      </Box>
    </Form>
  );
};

export default WelcomeMessageInput;
