import React from 'react';
import BrideAndGroomNames from 'components/BrideAndGroomNames';
import useForm from 'hooks/useForm';
import { Row, Box } from 'jsxstyle';
import Clickable from 'components/Clickable';
import Icon from 'components/Icon';
import { colors, mediaQueries } from 'theme';
import Form from 'components/Form';
import { useApplication } from 'contexts/ApplicationContext';

export type BrideAndGroomNamesInputProps = {
  onSubmit: (personName1: string, personName2: string) => void;
};

const BrideAndGroomNamesInput = ({
  onSubmit,
}: BrideAndGroomNamesInputProps) => {
  const { wedding } = useApplication();
  const [editing, setEditing] = React.useState(false);

  const { getValue, setValue } = useForm({
    initialValues:
      wedding.personName1 && wedding.personName2
        ? { name: `${wedding.personName1} e ${wedding.personName2}` }
        : {},
  });

  const handleSubmit = () => {
    const [personName1, personName2] = getValue('name').split(' e ');

    if (
      personName1 !== wedding.personName1 ||
      personName2 !== wedding.personName2
    ) {
      setEditing(false);
      onSubmit(personName1, personName2);
    }
  };

  return (
    <Row
      justifyContent="center"
      alignItems="center"
      position="relative"
      flex={0}
      alignSelf="center"
    >
      {editing ? (
        <Form onSubmit={handleSubmit}>
          <Box
            position="relative"
            minHeight={342}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box position="absolute">
              <Icon name="wedding-ring" />
            </Box>
            <Box
              props={{
                autoFocus: true,
                value: getValue('name'),
                onChange: (e: any) => setValue('name', e.target.value),
                onBlur: handleSubmit,
                size: getValue('name').length,
              }}
              component="input"
              mediaQueries={mediaQueries}
              fontFamily="Sacramento"
              fontWeight="normal"
              textAlign="center"
              color={colors.white}
              backgroundColor="transparent"
              outline="none"
              border="none"
              height="100%"
              fontSize={58}
              //@ts-ignore
              lgFontSize={72}
              xlFontSize={72}
            />
          </Box>
        </Form>
      ) : (
        <Clickable
          onClick={() => setEditing(true)}
          backgroundColor="transparent"
        >
          <BrideAndGroomNames
            name1={getValue('name').split(' e ')[0]}
            name2={getValue('name').split(' e ')[1]}
          />
        </Clickable>
      )}

      {Boolean(getValue('name')) && (
        <Clickable
          backgroundColor={`${colors.white}1a`}
          width={32}
          height={32}
          borderRadius={100}
          position="absolute"
          right={editing ? -60 : -90}
          onClick={() => setEditing(!editing)}
        >
          <Icon name={editing ? 'close' : 'edit'} color={colors.white} />
        </Clickable>
      )}
    </Row>
  );
};

export default BrideAndGroomNamesInput;
