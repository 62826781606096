import useSWR from 'swr';
import getGiftListService from 'services/platform/getGiftListService';

type UseGiftCardParamsType = {
  giftCashID: string;
};
const useGiftCard = ({ giftCashID }: UseGiftCardParamsType) => {
  const { data, error } = useSWR(
    giftCashID ? ['/wedding/gift-cards', giftCashID] : null,
    (url, id) =>
      getGiftListService({
        giftCashID: id,
        productCategoryID: 'wedding_gift_card',
      }),
  );

  return { data, error, pending: !data && !error };
};

export default useGiftCard;
