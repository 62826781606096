import ScreenContainer from 'components/admin/ScreenContainer';
import ScreenLayout from 'components/admin/ScreenLayout';
import ScrollBox from 'components/admin/ScrollBox';
import AnimatedModal from 'components/AnimatedModal';
import Button from 'components/Button';
import Card from 'components/Card';
import Clickable from 'components/Clickable';
import Heading from 'components/Heading';
import Icon from 'components/Icon';
import SearchInput from 'components/SearchInput';
import Separator from 'components/Separator';
import SkeletonPlaceholder from 'components/SkeletonPlaceholder';
import Text from 'components/Text';
import { useApplication } from 'contexts/ApplicationContext';
import useAsync from 'hooks/useAsync';
import useGifts from 'hooks/useGifts';
import useMedia from 'hooks/useMedia';
import useToast from 'hooks/useToast';
import { Box, Row } from 'jsxstyle';
import { orderBy } from 'lodash';
import React from 'react';
import WeddingProductsForm from 'screens/ChatScreen/WeddingProductsForm';
import saveWeddingProductService from 'services/saveWeddingProductService';
import { colors, mediaQueries, spacingSizes } from 'theme';
import handleError from 'utils/handleError';
import AdminGiftListItem from './AdminGiftListItem';

export type GiftScreenProps = {};

const GiftScreen = () => {
  const [modalVisibility, setModalVisibility] =
    React.useState<VisibilityState>('hidden');
  const [search, setSearch] = React.useState('');
  const { media } = useMedia();
  const { wedding, account } = useApplication();

  const {
    gifts = [],
    pending,
    revalidate,
    mutate,
  } = useGifts({
    giftCashID: wedding.giftCashID,
  });

  const { dangerMessage } = useToast();

  const filteredGifts = React.useMemo(() => {
    const filtered = gifts.filter((gift) =>
      gift.productName?.toLowerCase().includes(search.toLowerCase()),
    );
    return orderBy(filtered, ['amount', 'productName'], ['asc', 'asc']);
  }, [search, gifts]);

  const { fetch, pending: saving } = useAsync({
    promiseFn: async (value: any) => {
      await Promise.all(
        value.products.map((product: any) =>
          saveWeddingProductService({
            giftCashID: value.giftCashID,
            accountID: value.accountID,
            amount: product.value,
            pictureID: product.pictureID,
            name: product.name,
            customImage: product?.customImage?.file,
          }),
        ),
      );

      return true;
    },
    onData: () => {
      setTimeout(revalidate, 1000);
      setModalVisibility('hidden');
    },
    onError: (error) => {
      const handledError = handleError({ error });
      dangerMessage({ message: handledError.message });
    },
  });

  return (
    <ScreenLayout>
      <ScreenContainer backgroundHeight={170}>
        <Row alignItems="center" justifyContent="space-between">
          <Heading size="xs" color={colors.white}>
            Lista de Presentes
          </Heading>
          {media !== 'desktop' && (
            <Clickable
              disabled={!gifts}
              width={32}
              height={32}
              borderRadius={100}
              border="1px solid white"
              backgroundColor="transparent"
              onClick={() => setModalVisibility('visible')}
            >
              <Icon name="plus" width={14} height={14} />
            </Clickable>
          )}
        </Row>
        <Separator />
        <Box
          mediaQueries={mediaQueries}
          display="flex"
          flexDirection="column"
          //@ts-ignore
          lgFlexDirection="row"
          xlFlexDirection="row"
          lgJustifyContent="space-between"
          xlJustifyContent="space-between"
          lgAlignItems="center"
          xlAlignItems="center"
        >
          <Box flexBasis={media === 'desktop' && '40%'}>
            <SearchInput
              placeholder="Pesquisar por presentes"
              value={search}
              onChange={(value) => setSearch(value)}
              minimal
            />
          </Box>
          {media === 'desktop' && (
            <Button
              disabled={!gifts}
              onClick={() => setModalVisibility('visible')}
              color="tertiary"
              size="sm"
              minimal
            >
              Adicionar presente
            </Button>
          )}
        </Box>
        <Separator />
        {pending ? (
          <React.Fragment>
            <GiftListPlaceholder />
            <Separator size="sm" />
            <GiftListPlaceholder />
          </React.Fragment>
        ) : (
          <ScrollBox>
            {filteredGifts.map((product) => (
              <React.Fragment key={product.giftCashItemID}>
                <AdminGiftListItem
                  product={product}
                  onUpdate={(updatedProduct) => {
                    mutate(
                      (data) =>
                        data.map((item) =>
                          item.giftCashItemID === updatedProduct.giftCashItemID
                            ? updatedProduct
                            : item,
                        ),
                      false,
                    );
                  }}
                  onDelete={(deletedItem) => {
                    mutate(
                      (data) =>
                        data.filter(
                          (item) =>
                            item.giftCashItemID !== deletedItem.giftCashItemID,
                        ),
                      false,
                    );
                  }}
                />
                <Separator size="sm" />
              </React.Fragment>
            ))}
            {filteredGifts.length === 0 && (
              <Card flexDirection="row" alignItems="center">
                <Icon name="info" color={colors.gray600} />
                <Separator />
                <Text color={colors.gray600}>Nenhum presente encontrato</Text>
              </Card>
            )}
          </ScrollBox>
        )}
      </ScreenContainer>
      <AnimatedModal
        visible={modalVisibility === 'visible'}
        onRequestClose={() => setModalVisibility('hidden')}
        backdropColor={`${colors.secondary500}d9`}
        width={media === 'desktop' ? 990 : 'auto'}
      >
        <Card overflowY="auto" padding={-spacingSizes.md}>
          {Boolean(gifts) && (
            <WeddingProductsForm
              key={gifts?.length}
              initialSelectedProducts={gifts}
              pending={saving}
              onSubmit={(products) => {
                fetch({
                  accountID: account.accountID,
                  giftCashID: wedding.giftCashID,
                  products,
                });
              }}
            />
          )}
        </Card>
      </AnimatedModal>
    </ScreenLayout>
  );
};

export default GiftScreen;

const GiftListPlaceholder = () => {
  return (
    <Card>
      <SkeletonPlaceholder>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ width: 32, height: 32, borderRadius: 5 }} />
            <div style={{ marginLeft: spacingSizes.md }}>
              <div style={{ width: 120, height: 15, borderRadius: 4 }} />
              <div
                style={{
                  width: 100,
                  height: 18,
                  marginTop: 4,
                  borderRadius: 4,
                }}
              />
            </div>
          </div>
          <div>
            <div style={{ width: 120, height: 15, borderRadius: 4 }} />
            <div
              style={{ width: 100, height: 18, marginTop: 4, borderRadius: 4 }}
            />
          </div>
          <div style={{ width: 32, height: 32, borderRadius: 100 }} />
        </div>
      </SkeletonPlaceholder>
    </Card>
  );
};
