import { useEffect, useState } from 'react';

export type MediaType = 'phone' | 'tablet' | 'desktop';
export type OrientationType = 'landscape' | 'portrait';

const PHONE = 'screen and (min-width: 320px) and (max-width: 600.98px)';
const TABLET = 'screen and (min-width: 601px) and (max-width: 1023.98px)';
const DESKTOP = 'screen and (min-width: 1024px)';
const PORTRAIT = 'screen and (orientation:portrait)';
const LANDSCAPE = 'screen and (orientation:landscape)';

const phoneMedia = window.matchMedia(PHONE);
const tabletMedia = window.matchMedia(TABLET);
const desktopMedia = window.matchMedia(DESKTOP);
const portraitMedia = window.matchMedia(PORTRAIT);
const landscapeMedia = window.matchMedia(LANDSCAPE);

const initialState: { media: MediaType; orientation: OrientationType } = {
  media: desktopMedia.matches
    ? 'desktop'
    : tabletMedia.matches
    ? 'tablet'
    : 'phone',
  orientation: landscapeMedia.matches ? 'landscape' : 'portrait',
};

const useMedia = () => {
  const [media, setMedia] = useState<MediaType>(initialState.media);

  const [orientation, setOrientation] = useState<OrientationType>(
    initialState.orientation,
  );

  const handlePhone = ({ matches }: MediaQueryListEvent) =>
    matches && setMedia('phone');

  const handleTablet = ({ matches }: MediaQueryListEvent) =>
    matches && setMedia('tablet');

  const handleDesktop = ({ matches }: MediaQueryListEvent) =>
    matches && setMedia('desktop');

  const handlePortrait = ({ matches }: MediaQueryListEvent) =>
    matches && setOrientation('portrait');

  const handleLandscape = ({ matches }: MediaQueryListEvent) =>
    matches && setOrientation('landscape');

  useEffect(() => {
    phoneMedia.addListener(handlePhone);
    tabletMedia.addListener(handleTablet);
    desktopMedia.addListener(handleDesktop);
    portraitMedia.addListener(handlePortrait);
    landscapeMedia.addListener(handleLandscape);

    return () => {
      phoneMedia.removeListener(handlePhone);
      tabletMedia.removeListener(handleTablet);
      desktopMedia.removeListener(handleDesktop);
      portraitMedia.removeListener(handlePortrait);
      landscapeMedia.removeListener(handleLandscape);
    };
  }, []);

  return { media, orientation };
};

export default useMedia;
