import { pickBy } from 'lodash';
import publicApi from '../publicApi';

type Params = {
  userID: string;
  accountID: string;
  giftCasheID: string;
  purchaseID: string;
  installment?: number;
};
export default async ({
  userID,
  accountID,
  giftCasheID,
  purchaseID,
  installment,
}: Params): Promise<string> => {
  try {
    const {
      data,
    } = await publicApi.get(
      `/users/${userID}/accounts/${accountID}/giftcashes/${giftCasheID}/purchases/${purchaseID}/billets/pdf`,
      { params: pickBy({ installment }) },
    );
    return data;
  } catch (error) {
    throw error;
  }
};
