import React from 'react';
import { Box, CSSProperties } from 'jsxstyle';
import { colors } from 'theme';

type DividerProps = {
  size?: number;
  borderStyle?: CSSProperties['borderStyle'];
};

const Divider = ({ size = 1, borderStyle = 'solid' }: DividerProps) => (
  <Box
    width="100%"
    border={0}
    borderBottom={size}
    borderColor={colors.gray50}
    borderStyle={borderStyle}
  />
);

export default Divider;
