import Icon from 'components/Icon';
import NumberFormat from 'components/NumberFormat';
import ToastMessage from 'components/ToastMessage';
import { Box, Row } from 'jsxstyle';
import React from 'react';
import { colors } from 'theme';
import toaster from 'toasted-notes';

const GIFT_MIN_VALUE = 40;

export type GiftBoxInputProps = {
  active?: boolean;
  value: number | string;
  onChange: (value: number) => void;
  activatedColor?: string;
  onBlur?: () => void;
  minValue?: number;
};

const GiftBoxInput = ({
  active = false,
  value,
  onChange,
  activatedColor = colors.white,
  onBlur,
  minValue = GIFT_MIN_VALUE,
}: GiftBoxInputProps) => {
  const inputRef = React.useRef<HTMLInputElement>(null);

  const toCurrency = (number: number) =>
    new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(number);

  return (
    <Row>
      <Box
        component="input"
        props={{
          onBlur: () => {
            if (minValue && value < minValue) {
              toaster.notify(({ onClose }) => (
                <ToastMessage
                  icon={<Icon name="info" color={colors.black} />}
                  onClick={onClose}
                  message={`O valor mínimo por presente é de ${NumberFormat({
                    type: 'currency',
                    amount: minValue,
                    minimumFractionDigits: 2,
                  })}`}
                  type="default"
                />
              ));
              onChange(minValue);
            }
            onBlur?.();
          },
          ref: inputRef,
          value: toCurrency(Number(value)),
          onClick: (event: any) => (active ? event.stopPropagation() : null),
          onChange: (event: any) =>
            onChange(parseInt(event.target.value.replace(/[^0-9]/g, '')) / 100),
          readOnly: !active,
        }}
        color={active ? activatedColor : colors.black}
        background="transparent"
        outline="none"
        border="none"
        fontWeight="bold"
        fontSize={18}
        maxWidth={120}
        cursor={active ? 'auto' : 'pointer'}
      />
    </Row>
  );
};

export default GiftBoxInput;
