import Heading from 'components/Heading';
import Icon from 'components/Icon';
import NumberFormat from 'components/NumberFormat';
import SkeletonPlaceholder from 'components/SkeletonPlaceholder';
import { useApplication } from 'contexts/ApplicationContext';
import paths from 'paths';
import React from 'react';
import getBalanceService from 'services/admin/getBalanceService';
import useSWR from 'swr';
import { colors } from 'theme';
import DashboardItem from './DashboardItem';

const TotalReceived = () => {
  const { account, wedding } = useApplication();
  const shouldFetch = account.accountID && wedding.giftCashID;

  const { data, error } = useSWR(
    shouldFetch ? ['/balance', account.accountID, wedding.giftCashID] : null,
    (_, accountID, giftCashID) => getBalanceService({ accountID, giftCashID }),
  );
  const pending = !data && !error;
  return (
    <DashboardItem
      icon={<Icon name="money" />}
      description="Total recebido"
      helperText="Visualizar extrato"
      link={paths.Admin.STATEMENT_SCREEN}
      label={
        pending ? (
          <SkeletonPlaceholder>
            <div style={{ width: 160, height: 32, borderRadius: 5 }} />
          </SkeletonPlaceholder>
        ) : (
          <Heading color={colors.secondary500}>
            <NumberFormat amount={data?.netBalance || 0} type="currency" />
          </Heading>
        )
      }
    />
  );
};

export default TotalReceived;
