import React from 'react';
import { CSSTransition } from 'react-transition-group';

type FadeInProps = {
  children: React.ReactNodeArray | React.ReactNode;
};

const FadeIn = ({ children }: FadeInProps) => {
  const [mounted, setMounted] = React.useState(false);

  React.useLayoutEffect(() => {
    setMounted(true);
  }, []);

  return (
    <CSSTransition
      classNames="fade"
      in={mounted}
      mountOnEnter
      timeout={750}
      unmountOnExit
    >
      {children}
    </CSSTransition>
  );
};

export default FadeIn;
