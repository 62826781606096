import publicApi from '../publicApi';

type Params = {
  giftCashID: string;
  userID: string | number;
  accountID: string | number;
  giftCashItems: any[];
  buyerName: string;
  buyerDocument: string;
  buyerEmail: string;
  buyerAddress: string;
  buyerNeighborhood: string;
  buyerCity: string;
  buyerState: string;
  buyerZipCode: string;
  amount: number;
  recaptchaToken: string;
  message: string;
  installments: number;
};
export default async ({
  giftCashID,
  userID,
  accountID,
  giftCashItems,
  buyerName,
  buyerDocument,
  buyerEmail,
  buyerAddress,
  buyerNeighborhood,
  buyerCity,
  buyerState,
  buyerZipCode,
  amount,
  recaptchaToken,
  message,
  installments,
}: Params): Promise<any> => {
  try {
    const { data } = await publicApi.post(
      `/users/${userID}/accounts/${accountID}/giftcashes/${giftCashID}/purchases/pix${
        installments > 1 ? '/installments' : ''
      }`,
      {
        giftCashItems,
        buyerName,
        buyerDocument,
        buyerEmail,
        buyerAddress,
        buyerNeighborhood,
        buyerCity,
        buyerState,
        buyerZipCode,
        amount,
        recaptchaToken,
        message,
        pixTotalInstallments: installments,
      },
    );
    return data;
  } catch (error) {
    throw error;
  }
};
