import React from 'react';
import { Box } from 'jsxstyle';
import Icon from './Icon';
import { colors } from 'theme';

export type CoupleThumbImageProps = {
  size: 'sm' | 'md' | 'lg' | 'xl';
  giftCashID: string;
};

const CoupleThumbImage = ({ size, giftCashID }: CoupleThumbImageProps) => {
  const [url, setUrl] = React.useState<string>();
  const imageSize = {
    sm: 34,
    md: 40,
    lg: 64,
    xl: 120,
  }[size];

  React.useEffect(() => {
    if (giftCashID) {
      setUrl(
        `${
          process.env.REACT_APP_RESOURCES_URL
        }/banking/giftcash/wedding/${giftCashID}-thumb?q=${Date.now()}`,
      );
    }
  }, [giftCashID]);

  return (
    <Box
      width={imageSize}
      height={imageSize}
      borderRadius={100}
      backgroundColor={url ? 'transparent' : colors.gray100}
      flexShrink={0}
      justifyContent="center"
      alignItems="center"
      display="flex"
      hoverBackgroundColor={colors.gray200}
      transition="all 125ms ease-in-out"
    >
      {url ? (
        <Box
          borderRadius={100}
          component="img"
          props={{
            src: url,
            width: imageSize,
            height: imageSize,

            onError: () => setUrl(undefined),
          }}
        />
      ) : (
        <Icon name="couple" />
      )}
    </Box>
  );
};

export default CoupleThumbImage;
