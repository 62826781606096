import publicApi from '../publicApi';

type Params = {
  guestName: string;
  guestEmail: string;
  guestPhone: string;
  attend: string;
  adultsQuantity: number;
  childrenQuantity: number;
  weddingID: string;
};
export default async ({
  guestName,
  guestEmail,
  guestPhone,
  attend,
  adultsQuantity,
  childrenQuantity,
  weddingID,
}: Params): Promise<any> => {
  try {
    const { data } = await publicApi.post(
      `/giftcashes/weddings/${weddingID}/rsvp`,
      {
        guestName,
        guestEmail,
        guestPhone: guestPhone.includes('+55')
          ? guestPhone
          : `+55${guestPhone}`,
        attend,
        adultsQuantity,
        childrenQuantity,
      },
    );
    return data;
  } catch (error) {
    throw error;
  }
};
