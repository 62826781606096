import privateApi from './privateApi';

type RequiredParams = {
  contextID: string;
  personName1: string;
  personName2: string;
  welcomeMessage: string;
  weddingDate: string;
  weddingState: string;
  weddingCity: string;
  affiliateID?: string;
};

type Params<T> = {
  accountID: string | number;
  giftCashID?: T;
} & (T extends undefined ? RequiredParams : Partial<RequiredParams>);

export default async <T>({
  accountID,
  giftCashID,
  contextID,
  personName1,
  personName2,
  welcomeMessage,
  weddingDate,
  weddingState,
  weddingCity,
  affiliateID,
}: Params<T>) => {
  try {
    if (giftCashID) {
      const { data } = await privateApi.put(
        `/accounts/${accountID}/giftcashes/weddings/${giftCashID}`,
        {
          contextID,
          personName1,
          personName2,
          welcomeMessage,
          weddingDate,
          weddingState,
          weddingCity,
        },
      );

      return data;
    } else {
      const { data } = await privateApi.post(
        `/accounts/${accountID}/giftcashes/weddings`,
        {
          contextID,
          personName1,
          personName2,
          welcomeMessage,
          weddingDate,
          weddingState,
          weddingCity,
          affiliateID,
        },
      );
      return data;
    }
  } catch (error) {
    throw error;
  }
};
