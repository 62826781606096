import privateApi from '../privateApi';
import WeddingBalanceType from 'types/WeddingBalanceType';

type Params = {
  accountID: string;
  giftCashID: string;
};
export default async ({
  accountID,
  giftCashID,
}: Params): Promise<WeddingBalanceType> => {
  try {
    const { data } = await privateApi.get(
      `/accounts/${accountID}/giftcashes/${giftCashID}/balances `,
    );
    return data;
  } catch (error) {
    throw error;
  }
};
