import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'normalize.css';
import 'bootstrap-4-grid/css/grid.min.css';
import './scrollbar.css';
import 'toasted-notes/src/styles.css';
import 'swiper/swiper-bundle.css';
import 'react-tabs/style/react-tabs.css';
import './index.css';

Amplify.configure({
  Auth: {
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    ...(process.env.NODE_ENV !== 'production'
      ? {}
      : {
          cookieStorage: {
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
            secure: false,
          },
        }),
  },
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
