import Button from 'components/Button';
import Form from 'components/Form';
import RadioButton from 'components/RadioButton';
import Separator from 'components/Separator';
import Text from 'components/Text';
import { useApplication } from 'contexts/ApplicationContext';
import useAsync from 'hooks/useAsync';
import useForm from 'hooks/useForm';
import useMedia from 'hooks/useMedia';
import { Col } from 'jsxstyle';
import React from 'react';
import ReactLoading from 'react-loading';
import getSiteNameSuggestionsService, {
  Params as GetSiteNameSuggestionsParams,
} from 'services/getSiteNameSuggestionsService';
import { colors, spacingSizes } from 'theme';

export type WeddingUrlFormProps = {
  onSubmit: (url: string) => void;
  names: { groom: string; bride: string };
};

const WeddingUrlForm = ({ onSubmit, names }: WeddingUrlFormProps) => {
  const { account } = useApplication();
  const { media } = useMedia();

  const {
    fetch,
    data = [],
    pending,
  } = useAsync<GetSiteNameSuggestionsParams>({
    promiseFn: getSiteNameSuggestionsService,
  });

  React.useEffect(() => {
    fetch({
      accountID: account.accountID,
      personName1: names.groom,
      personName2: names.bride,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetch, account.accountID]);

  const { getValue, setValue, submit } = useForm({
    initialValues: {
      url: data && data[0],
    },
    onSubmit: (values) => onSubmit(values.url),
  });

  if (pending) {
    return (
      <Col flex={1} justifyContent="center" alignItems="center">
        <ReactLoading
          type="spin"
          color={colors.gray500}
          height={42}
          width={42}
        />
      </Col>
    );
  }
  return (
    <Col
      width={media === 'desktop' ? 'auto' : '85%'}
      alignSelf="center"
      height={500}
      paddingTop={spacingSizes.xl}
    >
      <Text textAlign="center" size="lg">
        Escolha o nome do site
      </Text>
      <Separator size="xl" />
      <Form>
        {data &&
          data.map((suggestion) => (
            <RadioButton
              key={suggestion}
              value={suggestion}
              id={suggestion}
              label={
                <Text color={colors.gray400}>
                  {window.location.hostname}/
                  <Text color={colors.black}>{suggestion}</Text>
                </Text>
              }
              checked={getValue('url') === suggestion}
              onChange={(value) => setValue('url', value)}
            />
          ))}
        <Separator size="xl" />
        <Button type="submit" color="secondary" onClick={() => submit()}>
          <Text
            color={colors.white}
            fontWeight="bold"
            textTransform="uppercase"
          >
            Enviar
          </Text>
        </Button>
      </Form>

      <Separator size="xl" />
    </Col>
  );
};

export default WeddingUrlForm;
