import { Machine, assign } from 'xstate';

const isOnEnd = ctx =>
  ctx.index === (ctx.erased ? ctx.phraseAfterErase : ctx.phrase).length + 1;

const typingMachine = Machine(
  {
    id: 'typingMachine',
    initial: 'idle',
    context: {
      index: 0,
      phrase: '',
      shouldErase: false,
      phraseAfterErase: '',
      erased: false,
      typing: null,
      delay: {
        typing: 18,
        erasing: 4,
      },
    },
    states: {
      idle: {
        after: {
          1000: 'typing',
        },
      },
      typing: {
        onEntry: ['incrementIndex', 'setTyping'],
        after: {
          TYPING_DELAY: [
            { target: 'erasing', cond: 'shouldErase' },
            { target: 'completed', cond: 'isOnEnd' },
            { target: 'typing' },
          ],
        },
      },
      erasing: {
        onEntry: ['decrementIndex', 'setTyping'],
        after: {
          ERASING_DELAY: [
            {
              target: 'typing',
              cond: 'isOnStart',
              actions: 'setEraseCompleted',
            },
            { target: 'erasing' },
          ],
        },
      },
      completed: {
        type: 'final',
      },
    },
  },
  {
    actions: {
      setTyping: assign({
        typing: ctx =>
          (ctx.erased ? ctx.phraseAfterErase : ctx.phrase).substring(
            0,
            ctx.index,
          ),
      }),
      incrementIndex: assign({ index: ctx => ctx.index + 1 }),
      decrementIndex: assign({ index: ctx => ctx.index - 1 }),
      setEraseCompleted: assign({ erased: true }),
    },
    guards: {
      shouldErase: ctx => ctx.shouldErase && isOnEnd(ctx) && !ctx.erased,
      isOnEnd,
      isOnStart: ctx => ctx.index === 0,
    },
    delays: {
      TYPING_DELAY: ctx => {
        const char = ctx.typing.charAt(ctx.index - 1);

        if (ctx.delay[char] && ctx.phrase.charAt(ctx.index) !== char) {
          return ctx.delay[char];
        }
        return ctx.delay.typing;
      },
      ERASING_DELAY: ctx => ctx.delay.erasing,
    },
  },
);

export default typingMachine;
