import React from 'react';

type MCLogoBlockType = {
  height?: number;
  intent?: 'primary' | 'secondary';
};

const MCLogoBlock = ({ intent, height }: MCLogoBlockType) => {
  return {
    primary: <PrimaryLogo height={height} />,
    secondary: <SecondaryLogo height={height} />,
    undefined: <IntentlessLogo height={height} />,
    //@ts-ignore
  }[intent];
};

export default MCLogoBlock;

const PrimaryLogo = ({ height = 64 }) => (
  <svg
    width="440"
    height={height}
    viewBox="0 0 440 232"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M298.812 40C296.187 40 294 42.1283 294 44.7574C294 47.3865 296.125 49.5149 298.812 49.5149C315.687 49.5149 329.437 63.2238 329.437 80C329.437 96.7762 315.687 110.485 298.812 110.485C296.187 110.485 294 112.613 294 115.243C294 117.872 296.125 120 298.812 120C321 120 339 102.034 339 79.9374C339 57.8404 321 40 298.812 40Z"
      fill="#FF499E"
    />
    <path
      d="M244.945 67.541C242.016 48.918 232.575 31.8033 218.317 19.4098C203.929 6.88525 185.504 0 166.428 0C122.613 0 87 35.8689 87 80C87 124.131 122.613 160 166.428 160C185.114 160 203.278 153.311 217.536 141.246C218.708 140.262 219.424 138.82 219.489 137.311C219.554 135.803 219.034 134.23 217.992 133.115L217.927 133.049C216.885 131.934 215.388 131.279 213.89 131.279C212.588 131.279 211.286 131.738 210.309 132.59C198.07 142.951 182.509 148.721 166.428 148.721C128.798 148.721 98.1981 117.902 98.1981 80C98.1981 42.0984 128.798 11.2787 166.428 11.2787C182.77 11.2787 198.59 17.1803 210.961 27.9344C223.2 38.5574 231.338 53.2459 233.812 69.2459C234.268 72 236.547 74.0328 239.346 74.0328C239.672 74.0328 239.932 74.0328 240.258 73.9672C241.755 73.7049 243.057 72.918 243.904 71.7377C244.815 70.4918 245.141 68.9836 244.945 67.541Z"
      fill="white"
    />
    <path
      d="M209.08 92.459C212.009 111.082 221.448 128.197 235.704 140.59C250.09 153.115 268.511 160 287.584 160C331.393 160 367 124.131 367 80C367 35.8689 331.328 0 287.519 0C268.837 0 250.675 6.68853 236.42 18.7541C235.248 19.7377 234.532 21.1803 234.467 22.6885C234.402 24.1967 234.922 25.7705 235.964 26.8852L236.029 26.9508C237.071 28.0656 238.568 28.7213 240.065 28.7213C241.367 28.7213 242.669 28.2623 243.645 27.4098C255.883 17.0492 271.441 11.2787 287.519 11.2787C325.144 11.2787 355.739 42.0984 355.739 80C355.739 117.902 325.144 148.721 287.519 148.721C271.18 148.721 255.362 142.82 242.994 132.066C230.756 121.443 222.62 106.754 220.146 90.7541C219.69 88 217.412 85.9672 214.613 85.9672C214.287 85.9672 214.027 85.9672 213.701 86.0328C212.204 86.2951 210.902 87.082 210.056 88.2623C209.21 89.5082 208.819 91.0164 209.08 92.459Z"
      fill="white"
    />
    <path
      d="M14.4563 193.12C17.2517 193.12 19.2484 193.76 20.6062 195.12C21.964 196.48 22.7627 198.16 23.2419 200.32L22.3633 199.84L22.7627 199.04C23.162 198.24 23.801 197.36 24.6795 196.48C25.5581 195.52 26.5964 194.8 27.7944 194.16C28.9925 193.52 30.4301 193.2 31.9476 193.2C34.4236 193.2 36.3404 193.76 37.6183 194.8C38.8962 195.84 39.8547 197.28 40.3339 199.12C40.8131 200.88 41.0527 202.88 41.0527 205.12V218.48C41.0527 219.36 40.7332 220.16 40.1741 220.72C39.6151 221.36 38.8164 221.6 37.9378 221.6C37.0592 221.6 36.2606 221.28 35.7015 220.72C35.1424 220.08 34.8229 219.36 34.8229 218.48V205.04C34.8229 203.92 34.6632 202.88 34.4236 201.92C34.184 200.96 33.6249 200.24 32.9061 199.68C32.1872 199.12 31.1489 198.88 29.7912 198.88C28.4334 198.88 27.3152 199.12 26.4367 199.68C25.4782 200.24 24.7594 200.96 24.3601 201.92C23.8809 202.88 23.6412 203.84 23.6412 205.04V218.4C23.6412 219.28 23.3218 220.08 22.7627 220.64C22.2036 221.28 21.4049 221.52 20.5264 221.52C19.6478 221.52 18.8491 221.2 18.29 220.64C17.7309 220 17.4115 219.28 17.4115 218.4V205.04C17.4115 203.92 17.2517 202.88 17.0121 201.92C16.7725 200.96 16.2134 200.24 15.4946 199.68C14.7758 199.12 13.7375 198.88 12.3797 198.88C11.0219 198.88 9.90376 199.12 9.0252 199.68C8.06678 200.24 7.34795 200.96 6.94861 201.92C6.46939 202.88 6.22979 203.84 6.22979 205.04V218.4C6.22979 219.28 5.91031 220.08 5.35123 220.64C4.79214 221.28 3.99345 221.52 3.11489 221.52C2.23633 221.52 1.43764 221.2 0.87856 220.64C0.319476 220 0 219.28 0 218.4V196.8C0 195.92 0.319476 195.12 0.87856 194.56C1.43764 193.92 2.23633 193.68 3.11489 193.68C3.99345 193.68 4.79214 194 5.35123 194.56C5.91031 195.2 6.22979 195.92 6.22979 196.8V199.04L5.4311 198.88C5.75057 198.32 6.14992 197.68 6.709 196.96C7.26809 196.32 7.90704 195.68 8.70573 195.04C9.50442 194.48 10.383 194 11.3414 193.6C12.2998 193.28 13.3381 193.12 14.4563 193.12Z"
      fill="white"
    />
    <path
      d="M60.7804 222.08C57.8253 222.08 55.2694 221.44 53.113 220.24C50.9565 219.04 49.2793 217.36 48.0812 215.2C46.8832 213.04 46.3241 210.64 46.3241 208C46.3241 204.88 46.9631 202.16 48.241 200C49.5189 197.76 51.1961 196.08 53.1929 194.88C55.2694 193.68 57.4259 193.12 59.7421 193.12C61.4992 193.12 63.1765 193.52 64.7739 194.24C66.3713 194.96 67.729 196 69.0069 197.28C70.205 198.56 71.1634 200 71.8822 201.68C72.601 203.36 72.9205 205.12 72.9205 207.04C72.9205 207.84 72.5212 208.56 71.9621 209.04C71.3231 209.6 70.6043 209.84 69.8056 209.84H49.8383L48.241 204.64H67.4095L66.2914 205.68V204.24C66.2115 203.2 65.892 202.32 65.2531 201.52C64.6141 200.72 63.8154 200.08 62.857 199.6C61.8986 199.12 60.8603 198.88 59.822 198.88C58.7837 198.88 57.8253 199.04 56.8668 199.28C55.9883 199.52 55.1896 200 54.5506 200.72C53.9117 201.36 53.3526 202.24 52.9532 203.36C52.5539 204.48 52.3942 205.92 52.3942 207.6C52.3942 209.52 52.7935 211.04 53.5922 212.4C54.3909 213.68 55.3493 214.72 56.6272 215.36C57.8253 216 59.183 216.4 60.5408 216.4C61.8187 216.4 62.857 216.32 63.6557 216.08C64.4544 215.84 65.0135 215.6 65.4927 215.36C65.9719 215.04 66.3713 214.8 66.7706 214.64C67.4095 214.32 67.9686 214.16 68.5277 214.16C69.3264 214.16 69.9654 214.4 70.4446 214.96C70.9238 215.52 71.1634 216.08 71.1634 216.8C71.1634 217.76 70.6842 218.56 69.7257 219.36C68.8472 220.16 67.5693 220.8 65.892 221.36C64.2148 221.84 62.5375 222.08 60.7804 222.08Z"
      fill="white"
    />
    <path
      d="M98.8779 193.6C99.7565 193.6 100.555 193.92 101.114 194.48C101.673 195.12 101.993 195.84 101.993 196.72V210C101.993 213.68 100.954 216.64 98.8779 218.8C96.8013 220.96 93.8461 222 90.0124 222C86.1787 222 83.2236 220.96 81.147 218.8C79.1502 216.64 78.1119 213.68 78.1119 210V196.72C78.1119 195.84 78.4314 195.04 78.9905 194.48C79.5496 193.84 80.3483 193.6 81.2268 193.6C82.1054 193.6 82.9041 193.92 83.4632 194.48C84.0222 195.12 84.3417 195.84 84.3417 196.72V210C84.3417 212.16 84.8209 213.68 85.7794 214.72C86.7378 215.76 88.0956 216.24 90.0124 216.24C91.9293 216.24 93.3669 215.76 94.3254 214.72C95.2838 213.68 95.763 212.08 95.763 210V196.72C95.763 195.84 96.0825 195.04 96.6416 194.48C97.2006 193.92 97.9195 193.6 98.8779 193.6Z"
      fill="white"
    />
    <path
      d="M120.922 193.12C122.919 193.12 124.596 193.36 126.113 193.76C127.551 194.16 128.749 194.8 129.548 195.52C130.346 196.24 130.746 197.2 130.746 198.24C130.746 198.96 130.506 199.6 130.107 200.16C129.707 200.8 129.068 201.04 128.27 201.04C127.711 201.04 127.231 200.96 126.912 200.8C126.513 200.64 126.193 200.48 125.954 200.16C125.714 199.92 125.315 199.68 124.995 199.52C124.676 199.28 124.117 199.12 123.398 199.04C122.679 198.88 122.12 198.88 121.8 198.88C120.043 198.88 118.526 199.28 117.328 200C116.13 200.8 115.171 201.84 114.452 203.12C113.813 204.4 113.494 205.92 113.494 207.6C113.494 209.28 113.813 210.8 114.532 212.08C115.171 213.36 116.13 214.4 117.328 215.2C118.526 216 119.883 216.32 121.481 216.32C122.359 216.32 123.078 216.24 123.717 216.16C124.356 216.08 124.835 215.92 125.315 215.68C125.794 215.44 126.273 215.12 126.592 214.8C126.992 214.48 127.551 214.32 128.35 214.32C129.228 214.32 129.947 214.64 130.426 215.2C130.905 215.76 131.145 216.48 131.145 217.28C131.145 218.16 130.666 218.96 129.707 219.68C128.749 220.4 127.471 220.96 125.874 221.44C124.276 221.84 122.599 222.08 120.762 222.08C118.046 222.08 115.65 221.44 113.654 220.16C111.657 218.88 110.06 217.12 109.021 214.96C107.983 212.8 107.344 210.32 107.344 207.6C107.344 204.72 107.903 202.24 109.101 200.08C110.299 197.92 111.897 196.16 113.973 194.96C115.89 193.76 118.206 193.12 120.922 193.12Z"
      fill="white"
    />
    <path
      d="M162.454 207.6C162.454 210.48 161.815 212.96 160.537 215.12C159.259 217.28 157.582 218.96 155.425 220.24C153.269 221.44 150.873 222.08 148.237 222.08C145.601 222.08 143.205 221.44 141.049 220.24C138.892 219.04 137.215 217.36 135.937 215.12C134.659 212.96 134.02 210.4 134.02 207.6C134.02 204.72 134.659 202.24 135.937 200.08C137.215 197.92 138.892 196.16 141.049 194.96C143.205 193.76 145.601 193.12 148.237 193.12C150.873 193.12 153.269 193.76 155.425 194.96C157.582 196.16 159.259 197.92 160.537 200.08C161.815 202.24 162.454 204.8 162.454 207.6ZM156.144 207.6C156.144 205.84 155.825 204.32 155.106 202.96C154.387 201.68 153.428 200.64 152.23 199.92C151.032 199.2 149.675 198.8 148.237 198.8C146.799 198.8 145.442 199.2 144.244 199.92C143.046 200.64 142.087 201.68 141.368 202.96C140.649 204.24 140.33 205.84 140.33 207.6C140.33 209.36 140.649 210.88 141.368 212.16C142.087 213.44 143.046 214.48 144.244 215.2C145.442 215.92 146.799 216.32 148.237 216.32C149.675 216.32 151.032 215.92 152.23 215.2C153.428 214.48 154.387 213.44 155.106 212.16C155.825 210.88 156.144 209.36 156.144 207.6Z"
      fill="white"
    />
    <path
      d="M182.261 193.12C185.057 193.12 187.053 193.76 188.411 195.12C189.769 196.48 190.568 198.16 191.047 200.32L190.168 199.84L190.568 199.04C190.967 198.24 191.606 197.36 192.484 196.48C193.363 195.52 194.401 194.8 195.599 194.16C196.797 193.52 198.235 193.2 199.752 193.2C202.228 193.2 204.145 193.76 205.423 194.8C206.701 195.84 207.66 197.28 208.139 199.12C208.618 200.88 208.858 202.88 208.858 205.12V218.48C208.858 219.36 208.538 220.16 207.979 220.72C207.42 221.36 206.621 221.6 205.743 221.6C204.864 221.6 204.065 221.28 203.506 220.72C202.947 220.08 202.628 219.36 202.628 218.48V205.04C202.628 203.92 202.468 202.88 202.228 201.92C201.989 200.96 201.43 200.24 200.711 199.68C199.992 199.12 198.954 198.88 197.596 198.88C196.238 198.88 195.12 199.12 194.242 199.68C193.283 200.24 192.564 200.96 192.165 201.92C191.686 202.88 191.446 203.84 191.446 205.04V218.4C191.446 219.28 191.127 220.08 190.568 220.64C190.008 221.28 189.21 221.52 188.331 221.52C187.453 221.52 186.654 221.2 186.095 220.64C185.536 220 185.216 219.28 185.216 218.4V205.04C185.216 203.92 185.057 202.88 184.817 201.92C184.577 200.96 184.018 200.24 183.299 199.68C182.581 199.12 181.542 198.88 180.185 198.88C178.827 198.88 177.709 199.12 176.83 199.68C175.872 200.24 175.153 200.96 174.753 201.92C174.274 202.88 174.035 203.84 174.035 205.04V218.4C174.035 219.28 173.715 220.08 173.156 220.64C172.597 221.28 171.798 221.52 170.92 221.52C170.041 221.52 169.243 221.2 168.683 220.64C168.124 220 167.805 219.28 167.805 218.4V196.8C167.805 195.92 168.124 195.12 168.683 194.56C169.243 193.92 170.041 193.68 170.92 193.68C171.798 193.68 172.597 194 173.156 194.56C173.715 195.2 174.035 195.92 174.035 196.8V199.04L173.236 198.88C173.555 198.32 173.955 197.68 174.514 196.96C175.073 196.32 175.712 195.68 176.511 195.04C177.309 194.48 178.188 194 179.146 193.6C180.105 193.28 181.143 193.12 182.261 193.12Z"
      fill="white"
    />
    <path
      d="M230.183 193.12C232.579 193.12 234.815 193.76 236.732 194.96C238.649 196.16 240.166 197.92 241.284 200.08C242.403 202.24 242.962 204.72 242.962 207.6C242.962 210.48 242.403 212.96 241.284 215.12C240.166 217.28 238.649 219.04 236.732 220.24C234.815 221.44 232.738 222.08 230.342 222.08C228.985 222.08 227.627 221.84 226.429 221.44C225.231 220.96 224.113 220.4 223.234 219.68C222.276 218.96 221.637 218.24 221.078 217.6C220.598 216.88 220.359 216.32 220.359 215.76L221.956 215.12V228.88C221.956 229.76 221.637 230.56 221.078 231.12C220.518 231.76 219.72 232 218.841 232C217.963 232 217.164 231.68 216.605 231.12C216.046 230.56 215.726 229.76 215.726 228.8V196.8C215.726 195.92 216.046 195.12 216.605 194.56C217.164 193.92 217.963 193.68 218.841 193.68C219.72 193.68 220.518 194 221.078 194.56C221.637 195.2 221.956 195.92 221.956 196.8V199.36L221.078 198.88C221.078 198.4 221.317 197.84 221.796 197.2C222.276 196.56 222.915 195.92 223.793 195.28C224.592 194.64 225.63 194.08 226.668 193.68C227.787 193.28 228.985 193.12 230.183 193.12ZM229.384 198.88C227.866 198.88 226.509 199.28 225.39 200C224.272 200.8 223.314 201.84 222.675 203.12C222.036 204.4 221.716 205.92 221.716 207.6C221.716 209.2 222.036 210.72 222.675 212.08C223.314 213.44 224.192 214.48 225.39 215.28C226.509 216.08 227.866 216.4 229.384 216.4C230.901 216.4 232.259 216 233.377 215.28C234.496 214.48 235.374 213.44 236.013 212.08C236.652 210.72 236.971 209.28 236.971 207.6C236.971 205.92 236.652 204.48 236.013 203.12C235.374 201.84 234.496 200.8 233.377 200C232.259 199.2 230.901 198.88 229.384 198.88Z"
      fill="white"
    />
    <path
      d="M251.348 221.6C250.469 221.6 249.671 221.28 249.112 220.72C248.553 220.08 248.233 219.36 248.233 218.48V196.88C248.233 196 248.553 195.2 249.112 194.64C249.671 194 250.469 193.76 251.348 193.76C252.227 193.76 253.025 194.08 253.584 194.64C254.143 195.28 254.463 196 254.463 196.88V201.76L254.064 198.24C254.463 197.44 254.942 196.64 255.581 196C256.22 195.36 256.859 194.8 257.658 194.4C258.456 194 259.255 193.68 260.134 193.44C261.012 193.2 261.891 193.12 262.769 193.12C263.808 193.12 264.686 193.44 265.405 194C266.124 194.56 266.443 195.28 266.443 196.08C266.443 197.2 266.124 198.08 265.565 198.56C265.006 199.04 264.367 199.28 263.648 199.28C263.009 199.28 262.37 199.12 261.811 198.88C261.252 198.64 260.693 198.48 259.974 198.48C259.335 198.48 258.696 198.64 258.057 198.96C257.418 199.28 256.859 199.68 256.3 200.32C255.741 200.96 255.341 201.76 255.022 202.64C254.702 203.6 254.543 204.64 254.543 205.92V218.32C254.543 219.2 254.223 220 253.664 220.56C253.025 221.28 252.227 221.6 251.348 221.6Z"
      fill="white"
    />
    <path
      d="M296.474 207.6C296.474 210.48 295.835 212.96 294.557 215.12C293.279 217.28 291.602 218.96 289.446 220.24C287.289 221.44 284.893 222.08 282.257 222.08C279.622 222.08 277.226 221.44 275.069 220.24C272.913 219.04 271.235 217.36 269.957 215.12C268.68 212.96 268.041 210.4 268.041 207.6C268.041 204.72 268.68 202.24 269.957 200.08C271.235 197.92 272.913 196.16 275.069 194.96C277.226 193.76 279.622 193.12 282.257 193.12C284.893 193.12 287.289 193.76 289.446 194.96C291.602 196.16 293.279 197.92 294.557 200.08C295.835 202.24 296.474 204.8 296.474 207.6ZM290.244 207.6C290.244 205.84 289.925 204.32 289.206 202.96C288.487 201.68 287.529 200.64 286.331 199.92C285.133 199.2 283.775 198.8 282.337 198.8C280.9 198.8 279.542 199.2 278.344 199.92C277.146 200.64 276.187 201.68 275.468 202.96C274.75 204.24 274.43 205.84 274.43 207.6C274.43 209.36 274.75 210.88 275.468 212.16C276.187 213.44 277.146 214.48 278.344 215.2C279.542 215.92 280.9 216.32 282.337 216.32C283.775 216.32 285.133 215.92 286.331 215.2C287.529 214.48 288.487 213.44 289.206 212.16C289.845 210.88 290.244 209.36 290.244 207.6Z"
      fill="white"
    />
    <path
      d="M316.361 193.12C319.157 193.12 321.154 193.76 322.511 195.12C323.869 196.48 324.668 198.16 325.147 200.32L324.268 199.84L324.668 199.04C325.067 198.24 325.706 197.36 326.585 196.48C327.463 195.52 328.501 194.8 329.779 194.16C330.977 193.52 332.415 193.2 333.933 193.2C336.408 193.2 338.325 193.76 339.603 194.8C340.881 195.84 341.84 197.28 342.319 199.12C342.798 200.88 343.038 202.88 343.038 205.12V218.48C343.038 219.36 342.718 220.16 342.159 220.72C341.6 221.36 340.801 221.6 339.923 221.6C339.044 221.6 338.245 221.28 337.686 220.72C337.127 220.08 336.808 219.36 336.808 218.48V205.04C336.808 203.92 336.648 202.88 336.408 201.92C336.169 200.96 335.61 200.24 334.891 199.68C334.172 199.12 333.134 198.88 331.776 198.88C330.418 198.88 329.3 199.12 328.422 199.68C327.463 200.24 326.744 200.96 326.345 201.92C325.866 202.88 325.626 203.84 325.626 205.04V218.4C325.626 219.28 325.307 220.08 324.748 220.64C324.189 221.28 323.39 221.52 322.511 221.52C321.633 221.52 320.834 221.2 320.275 220.64C319.716 220 319.396 219.28 319.396 218.4V205.04C319.396 203.92 319.237 202.88 318.997 201.92C318.757 200.96 318.198 200.24 317.48 199.68C316.761 199.12 315.722 198.88 314.365 198.88C313.007 198.88 311.889 199.12 311.01 199.68C310.052 200.24 309.333 200.96 308.934 201.92C308.454 202.88 308.215 203.84 308.215 205.04V218.4C308.215 219.28 307.895 220.08 307.336 220.64C306.777 221.28 305.978 221.52 305.1 221.52C304.221 221.52 303.423 221.2 302.863 220.64C302.304 220 301.985 219.28 301.985 218.4V196.8C301.985 195.92 302.304 195.12 302.863 194.56C303.423 193.92 304.221 193.68 305.1 193.68C305.978 193.68 306.777 194 307.336 194.56C307.895 195.2 308.215 195.92 308.215 196.8V199.04L307.416 198.88C307.736 198.32 308.135 197.68 308.694 196.96C309.253 196.32 309.892 195.68 310.691 195.04C311.489 194.48 312.368 194 313.326 193.6C314.125 193.28 315.243 193.12 316.361 193.12Z"
      fill="white"
    />
    <path
      d="M352.462 190.24C351.264 190.24 350.465 190.08 349.986 189.68C349.507 189.28 349.267 188.64 349.267 187.68V186.64C349.267 185.6 349.507 184.96 350.066 184.56C350.625 184.16 351.424 184 352.542 184C353.74 184 354.619 184.16 355.098 184.56C355.577 184.96 355.817 185.6 355.817 186.56V187.52C355.817 188.56 355.577 189.2 355.018 189.6C354.539 190.08 353.66 190.24 352.462 190.24ZM355.657 218.4C355.657 219.28 355.337 220.08 354.778 220.64C354.219 221.28 353.421 221.52 352.542 221.52C351.664 221.52 350.865 221.2 350.306 220.64C349.747 220 349.427 219.28 349.427 218.4V196.8C349.427 195.92 349.747 195.12 350.306 194.56C350.865 193.92 351.664 193.68 352.542 193.68C353.421 193.68 354.219 194 354.778 194.56C355.337 195.2 355.657 195.92 355.657 196.8V218.4Z"
      fill="white"
    />
    <path
      d="M360.449 217.28C360.13 216.8 359.97 216.16 359.97 215.36C359.97 214.56 360.449 213.92 361.328 213.36C361.887 213.04 362.446 212.88 363.085 212.88C363.724 212.96 364.283 213.28 364.922 213.84C365.96 214.88 366.998 215.6 368.117 216.16C369.235 216.72 370.592 216.96 372.27 216.96C372.829 216.96 373.388 216.88 374.027 216.72C374.666 216.56 375.225 216.32 375.704 215.92C376.183 215.52 376.423 214.8 376.423 213.92C376.423 213.12 376.183 212.56 375.624 212.08C375.065 211.6 374.426 211.28 373.548 210.96C372.669 210.64 371.711 210.32 370.672 210.08C369.634 209.76 368.516 209.44 367.398 209.04C366.28 208.64 365.241 208.16 364.283 207.52C363.324 206.88 362.606 206.08 361.967 205.12C361.408 204.08 361.088 202.8 361.088 201.28C361.088 199.52 361.567 198.08 362.526 196.88C363.484 195.68 364.762 194.72 366.28 194.08C367.797 193.44 369.474 193.12 371.152 193.12C372.19 193.12 373.388 193.28 374.506 193.52C375.704 193.76 376.822 194.16 377.861 194.8C378.899 195.36 379.857 196.16 380.576 197.04C380.975 197.6 381.215 198.16 381.215 198.96C381.295 199.68 380.975 200.32 380.257 200.96C379.698 201.36 379.138 201.6 378.42 201.52C377.701 201.44 377.142 201.2 376.663 200.8C376.103 200 375.305 199.44 374.266 198.96C373.308 198.48 372.11 198.32 370.832 198.32C370.273 198.32 369.714 198.4 369.155 198.48C368.516 198.64 368.037 198.88 367.557 199.28C367.078 199.68 366.919 200.32 366.919 201.2C366.919 202 367.158 202.64 367.717 203.12C368.276 203.6 368.915 204 369.794 204.24C370.672 204.56 371.631 204.8 372.669 205.12C373.707 205.36 374.746 205.76 375.864 206.08C376.902 206.48 377.94 206.96 378.819 207.6C379.698 208.24 380.416 209.04 380.975 210C381.535 210.96 381.774 212.24 381.774 213.76C381.774 215.52 381.215 217.04 380.177 218.32C379.139 219.6 377.861 220.56 376.263 221.2C374.666 221.84 373.068 222.16 371.471 222.16C369.394 222.16 367.398 221.84 365.321 221.12C363.564 220.32 361.807 219.04 360.449 217.28Z"
      fill="white"
    />
    <path
      d="M385.608 217.28C385.288 216.8 385.129 216.16 385.129 215.36C385.129 214.56 385.608 213.92 386.486 213.36C387.046 213.04 387.605 212.88 388.244 212.88C388.883 212.96 389.442 213.28 390.081 213.84C391.119 214.88 392.157 215.6 393.275 216.16C394.393 216.72 395.751 216.96 397.428 216.96C397.988 216.96 398.547 216.88 399.186 216.72C399.825 216.56 400.384 216.32 400.863 215.92C401.342 215.52 401.582 214.8 401.582 213.92C401.582 213.12 401.342 212.56 400.783 212.08C400.224 211.6 399.585 211.28 398.706 210.96C397.828 210.64 396.869 210.32 395.831 210.08C394.793 209.76 393.675 209.44 392.556 209.04C391.438 208.64 390.4 208.16 389.442 207.52C388.483 206.88 387.764 206.08 387.125 205.12C386.566 204.08 386.247 202.8 386.247 201.28C386.247 199.52 386.726 198.08 387.684 196.88C388.643 195.68 389.921 194.72 391.438 194.08C392.956 193.44 394.633 193.12 396.31 193.12C397.349 193.12 398.547 193.28 399.665 193.52C400.863 193.76 401.981 194.16 403.019 194.8C404.058 195.36 405.016 196.16 405.735 197.04C406.134 197.6 406.374 198.16 406.374 198.96C406.454 199.68 406.134 200.32 405.415 200.96C404.856 201.36 404.297 201.6 403.578 201.52C402.86 201.44 402.301 201.2 401.821 200.8C401.262 200 400.464 199.44 399.425 198.96C398.467 198.48 397.269 198.32 395.991 198.32C395.432 198.32 394.873 198.4 394.314 198.48C393.675 198.64 393.195 198.88 392.716 199.28C392.237 199.68 392.077 200.32 392.077 201.2C392.077 202 392.317 202.64 392.876 203.12C393.435 203.6 394.074 204 394.953 204.24C395.831 204.56 396.79 204.8 397.828 205.12C398.866 205.36 399.904 205.76 401.023 206.08C402.061 206.48 403.099 206.96 403.978 207.6C404.856 208.24 405.575 209.04 406.134 210C406.693 210.96 406.933 212.24 406.933 213.76C406.933 215.52 406.374 217.04 405.336 218.32C404.297 219.6 403.019 220.56 401.422 221.2C399.825 221.84 398.227 222.16 396.63 222.16C394.553 222.16 392.556 221.84 390.48 221.12C388.643 220.32 386.966 219.04 385.608 217.28Z"
      fill="white"
    />
    <path
      d="M439.2 207.6C439.2 210.48 438.561 212.96 437.283 215.12C436.005 217.28 434.328 218.96 432.172 220.24C430.015 221.44 427.619 222.08 424.983 222.08C422.348 222.08 419.952 221.44 417.795 220.24C415.639 219.04 413.961 217.36 412.684 215.12C411.406 212.96 410.767 210.4 410.767 207.6C410.767 204.72 411.406 202.24 412.684 200.08C413.961 197.92 415.639 196.16 417.795 194.96C419.952 193.76 422.348 193.12 424.983 193.12C427.619 193.12 430.015 193.76 432.172 194.96C434.328 196.16 436.005 197.92 437.283 200.08C438.561 202.24 439.2 204.8 439.2 207.6ZM432.89 207.6C432.89 205.84 432.571 204.32 431.852 202.96C431.133 201.68 430.175 200.64 428.977 199.92C427.779 199.2 426.421 198.8 424.983 198.8C423.546 198.8 422.188 199.2 420.99 199.92C419.792 200.64 418.833 201.68 418.115 202.96C417.396 204.24 417.076 205.84 417.076 207.6C417.076 209.36 417.396 210.88 418.115 212.16C418.833 213.44 419.792 214.48 420.99 215.2C422.188 215.92 423.546 216.32 424.983 216.32C426.421 216.32 427.779 215.92 428.977 215.2C430.175 214.48 431.133 213.44 431.852 212.16C432.571 210.88 432.89 209.36 432.89 207.6Z"
      fill="white"
    />
  </svg>
);

const SecondaryLogo = ({ height = 232 }) => (
  <svg
    width="440"
    height={height}
    viewBox="0 0 440 232"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.4563 193.12C17.2517 193.12 19.2484 193.76 20.6062 195.12C21.964 196.48 22.7627 198.16 23.2419 200.32L22.3633 199.84L22.7627 199.04C23.162 198.24 23.801 197.36 24.6795 196.48C25.5581 195.52 26.5964 194.8 27.7944 194.16C28.9925 193.52 30.4301 193.2 31.9476 193.2C34.4236 193.2 36.3404 193.76 37.6183 194.8C38.8962 195.84 39.8547 197.28 40.3339 199.12C40.8131 200.88 41.0527 202.88 41.0527 205.12V218.48C41.0527 219.36 40.7332 220.16 40.1741 220.72C39.6151 221.36 38.8164 221.6 37.9378 221.6C37.0592 221.6 36.2606 221.28 35.7015 220.72C35.1424 220.08 34.8229 219.36 34.8229 218.48V205.04C34.8229 203.92 34.6632 202.88 34.4236 201.92C34.184 200.96 33.6249 200.24 32.9061 199.68C32.1872 199.12 31.1489 198.88 29.7912 198.88C28.4334 198.88 27.3152 199.12 26.4367 199.68C25.4782 200.24 24.7594 200.96 24.3601 201.92C23.8809 202.88 23.6412 203.84 23.6412 205.04V218.4C23.6412 219.28 23.3218 220.08 22.7627 220.64C22.2036 221.28 21.4049 221.52 20.5264 221.52C19.6478 221.52 18.8491 221.2 18.29 220.64C17.7309 220 17.4115 219.28 17.4115 218.4V205.04C17.4115 203.92 17.2517 202.88 17.0121 201.92C16.7725 200.96 16.2134 200.24 15.4946 199.68C14.7758 199.12 13.7375 198.88 12.3797 198.88C11.0219 198.88 9.90376 199.12 9.0252 199.68C8.06678 200.24 7.34795 200.96 6.94861 201.92C6.46939 202.88 6.22979 203.84 6.22979 205.04V218.4C6.22979 219.28 5.91031 220.08 5.35123 220.64C4.79214 221.28 3.99345 221.52 3.11489 221.52C2.23633 221.52 1.43764 221.2 0.87856 220.64C0.319476 220 0 219.28 0 218.4V196.8C0 195.92 0.319476 195.12 0.87856 194.56C1.43764 193.92 2.23633 193.68 3.11489 193.68C3.99345 193.68 4.79214 194 5.35123 194.56C5.91031 195.2 6.22979 195.92 6.22979 196.8V199.04L5.4311 198.88C5.75057 198.32 6.14992 197.68 6.709 196.96C7.26809 196.32 7.90704 195.68 8.70573 195.04C9.50442 194.48 10.383 194 11.3414 193.6C12.2998 193.28 13.3381 193.12 14.4563 193.12Z"
      fill="white"
    />
    <path
      d="M60.7804 222.08C57.8252 222.08 55.2694 221.44 53.113 220.24C50.9565 219.04 49.2793 217.36 48.0812 215.2C46.8832 213.04 46.3241 210.64 46.3241 208C46.3241 204.88 46.963 202.16 48.241 200C49.5189 197.76 51.1961 196.08 53.1928 194.88C55.2694 193.68 57.4259 193.12 59.7421 193.12C61.4992 193.12 63.1765 193.52 64.7739 194.24C66.3712 194.96 67.729 196 69.0069 197.28C70.2049 198.56 71.1634 200 71.8822 201.68C72.601 203.36 72.9205 205.12 72.9205 207.04C72.9205 207.84 72.5212 208.56 71.9621 209.04C71.3231 209.6 70.6043 209.84 69.8056 209.84H49.8383L48.241 204.64H67.4095L66.2914 205.68V204.24C66.2115 203.2 65.892 202.32 65.2531 201.52C64.6141 200.72 63.8154 200.08 62.857 199.6C61.8986 199.12 60.8603 198.88 59.822 198.88C58.7837 198.88 57.8252 199.04 56.8668 199.28C55.9883 199.52 55.1896 200 54.5506 200.72C53.9117 201.36 53.3526 202.24 52.9532 203.36C52.5539 204.48 52.3941 205.92 52.3941 207.6C52.3941 209.52 52.7935 211.04 53.5922 212.4C54.3909 213.68 55.3493 214.72 56.6272 215.36C57.8252 216 59.183 216.4 60.5408 216.4C61.8187 216.4 62.857 216.32 63.6557 216.08C64.4544 215.84 65.0135 215.6 65.4927 215.36C65.9719 215.04 66.3712 214.8 66.7706 214.64C67.4095 214.32 67.9686 214.16 68.5277 214.16C69.3264 214.16 69.9653 214.4 70.4446 214.96C70.9238 215.52 71.1634 216.08 71.1634 216.8C71.1634 217.76 70.6842 218.56 69.7257 219.36C68.8472 220.16 67.5693 220.8 65.892 221.36C64.2148 221.84 62.5375 222.08 60.7804 222.08Z"
      fill="white"
    />
    <path
      d="M98.8779 193.6C99.7565 193.6 100.555 193.92 101.114 194.48C101.673 195.12 101.993 195.84 101.993 196.72V210C101.993 213.68 100.954 216.64 98.8779 218.8C96.8013 220.96 93.8461 222 90.0124 222C86.1787 222 83.2236 220.96 81.147 218.8C79.1502 216.64 78.1119 213.68 78.1119 210V196.72C78.1119 195.84 78.4314 195.04 78.9905 194.48C79.5496 193.84 80.3483 193.6 81.2268 193.6C82.1054 193.6 82.9041 193.92 83.4632 194.48C84.0222 195.12 84.3417 195.84 84.3417 196.72V210C84.3417 212.16 84.8209 213.68 85.7794 214.72C86.7378 215.76 88.0956 216.24 90.0124 216.24C91.9293 216.24 93.3669 215.76 94.3254 214.72C95.2838 213.68 95.763 212.08 95.763 210V196.72C95.763 195.84 96.0825 195.04 96.6416 194.48C97.2006 193.92 97.9195 193.6 98.8779 193.6Z"
      fill="white"
    />
    <path
      d="M120.922 193.12C122.919 193.12 124.596 193.36 126.113 193.76C127.551 194.16 128.749 194.8 129.548 195.52C130.346 196.24 130.746 197.2 130.746 198.24C130.746 198.96 130.506 199.6 130.107 200.16C129.707 200.8 129.068 201.04 128.27 201.04C127.711 201.04 127.231 200.96 126.912 200.8C126.513 200.64 126.193 200.48 125.954 200.16C125.714 199.92 125.315 199.68 124.995 199.52C124.676 199.28 124.117 199.12 123.398 199.04C122.679 198.88 122.12 198.88 121.8 198.88C120.043 198.88 118.526 199.28 117.328 200C116.13 200.8 115.171 201.84 114.452 203.12C113.813 204.4 113.494 205.92 113.494 207.6C113.494 209.28 113.813 210.8 114.532 212.08C115.171 213.36 116.13 214.4 117.328 215.2C118.526 216 119.883 216.32 121.481 216.32C122.359 216.32 123.078 216.24 123.717 216.16C124.356 216.08 124.835 215.92 125.315 215.68C125.794 215.44 126.273 215.12 126.592 214.8C126.992 214.48 127.551 214.32 128.35 214.32C129.228 214.32 129.947 214.64 130.426 215.2C130.905 215.76 131.145 216.48 131.145 217.28C131.145 218.16 130.666 218.96 129.707 219.68C128.749 220.4 127.471 220.96 125.874 221.44C124.276 221.84 122.599 222.08 120.762 222.08C118.046 222.08 115.65 221.44 113.654 220.16C111.657 218.88 110.06 217.12 109.021 214.96C107.983 212.8 107.344 210.32 107.344 207.6C107.344 204.72 107.903 202.24 109.101 200.08C110.299 197.92 111.897 196.16 113.973 194.96C115.89 193.76 118.206 193.12 120.922 193.12Z"
      fill="white"
    />
    <path
      d="M162.454 207.6C162.454 210.48 161.815 212.96 160.537 215.12C159.259 217.28 157.582 218.96 155.425 220.24C153.269 221.44 150.873 222.08 148.237 222.08C145.601 222.08 143.205 221.44 141.049 220.24C138.892 219.04 137.215 217.36 135.937 215.12C134.659 212.96 134.02 210.4 134.02 207.6C134.02 204.72 134.659 202.24 135.937 200.08C137.215 197.92 138.892 196.16 141.049 194.96C143.205 193.76 145.601 193.12 148.237 193.12C150.873 193.12 153.269 193.76 155.425 194.96C157.582 196.16 159.259 197.92 160.537 200.08C161.815 202.24 162.454 204.8 162.454 207.6ZM156.144 207.6C156.144 205.84 155.825 204.32 155.106 202.96C154.387 201.68 153.428 200.64 152.23 199.92C151.032 199.2 149.675 198.8 148.237 198.8C146.799 198.8 145.442 199.2 144.244 199.92C143.046 200.64 142.087 201.68 141.368 202.96C140.649 204.24 140.33 205.84 140.33 207.6C140.33 209.36 140.649 210.88 141.368 212.16C142.087 213.44 143.046 214.48 144.244 215.2C145.442 215.92 146.799 216.32 148.237 216.32C149.675 216.32 151.032 215.92 152.23 215.2C153.428 214.48 154.387 213.44 155.106 212.16C155.825 210.88 156.144 209.36 156.144 207.6Z"
      fill="white"
    />
    <path
      d="M182.261 193.12C185.057 193.12 187.053 193.76 188.411 195.12C189.769 196.48 190.568 198.16 191.047 200.32L190.168 199.84L190.568 199.04C190.967 198.24 191.606 197.36 192.484 196.48C193.363 195.52 194.401 194.8 195.599 194.16C196.797 193.52 198.235 193.2 199.752 193.2C202.228 193.2 204.145 193.76 205.423 194.8C206.701 195.84 207.66 197.28 208.139 199.12C208.618 200.88 208.858 202.88 208.858 205.12V218.48C208.858 219.36 208.538 220.16 207.979 220.72C207.42 221.36 206.621 221.6 205.743 221.6C204.864 221.6 204.065 221.28 203.506 220.72C202.947 220.08 202.628 219.36 202.628 218.48V205.04C202.628 203.92 202.468 202.88 202.228 201.92C201.989 200.96 201.43 200.24 200.711 199.68C199.992 199.12 198.954 198.88 197.596 198.88C196.238 198.88 195.12 199.12 194.242 199.68C193.283 200.24 192.564 200.96 192.165 201.92C191.686 202.88 191.446 203.84 191.446 205.04V218.4C191.446 219.28 191.127 220.08 190.568 220.64C190.008 221.28 189.21 221.52 188.331 221.52C187.453 221.52 186.654 221.2 186.095 220.64C185.536 220 185.216 219.28 185.216 218.4V205.04C185.216 203.92 185.057 202.88 184.817 201.92C184.577 200.96 184.018 200.24 183.299 199.68C182.581 199.12 181.542 198.88 180.185 198.88C178.827 198.88 177.709 199.12 176.83 199.68C175.872 200.24 175.153 200.96 174.753 201.92C174.274 202.88 174.035 203.84 174.035 205.04V218.4C174.035 219.28 173.715 220.08 173.156 220.64C172.597 221.28 171.798 221.52 170.92 221.52C170.041 221.52 169.243 221.2 168.683 220.64C168.124 220 167.805 219.28 167.805 218.4V196.8C167.805 195.92 168.124 195.12 168.683 194.56C169.243 193.92 170.041 193.68 170.92 193.68C171.798 193.68 172.597 194 173.156 194.56C173.715 195.2 174.035 195.92 174.035 196.8V199.04L173.236 198.88C173.555 198.32 173.955 197.68 174.514 196.96C175.073 196.32 175.712 195.68 176.511 195.04C177.309 194.48 178.188 194 179.146 193.6C180.105 193.28 181.143 193.12 182.261 193.12Z"
      fill="white"
    />
    <path
      d="M230.183 193.12C232.579 193.12 234.815 193.76 236.732 194.96C238.649 196.16 240.166 197.92 241.284 200.08C242.403 202.24 242.962 204.72 242.962 207.6C242.962 210.48 242.403 212.96 241.284 215.12C240.166 217.28 238.649 219.04 236.732 220.24C234.815 221.44 232.738 222.08 230.342 222.08C228.985 222.08 227.627 221.84 226.429 221.44C225.231 220.96 224.113 220.4 223.234 219.68C222.276 218.96 221.637 218.24 221.078 217.6C220.598 216.88 220.359 216.32 220.359 215.76L221.956 215.12V228.88C221.956 229.76 221.637 230.56 221.078 231.12C220.518 231.76 219.72 232 218.841 232C217.963 232 217.164 231.68 216.605 231.12C216.046 230.56 215.726 229.76 215.726 228.8V196.8C215.726 195.92 216.046 195.12 216.605 194.56C217.164 193.92 217.963 193.68 218.841 193.68C219.72 193.68 220.518 194 221.078 194.56C221.637 195.2 221.956 195.92 221.956 196.8V199.36L221.078 198.88C221.078 198.4 221.317 197.84 221.796 197.2C222.276 196.56 222.915 195.92 223.793 195.28C224.592 194.64 225.63 194.08 226.668 193.68C227.787 193.28 228.985 193.12 230.183 193.12ZM229.384 198.88C227.866 198.88 226.509 199.28 225.39 200C224.272 200.8 223.314 201.84 222.675 203.12C222.036 204.4 221.716 205.92 221.716 207.6C221.716 209.2 222.036 210.72 222.675 212.08C223.314 213.44 224.192 214.48 225.39 215.28C226.509 216.08 227.866 216.4 229.384 216.4C230.901 216.4 232.259 216 233.377 215.28C234.496 214.48 235.374 213.44 236.013 212.08C236.652 210.72 236.971 209.28 236.971 207.6C236.971 205.92 236.652 204.48 236.013 203.12C235.374 201.84 234.496 200.8 233.377 200C232.259 199.2 230.901 198.88 229.384 198.88Z"
      fill="white"
    />
    <path
      d="M251.348 221.6C250.469 221.6 249.671 221.28 249.112 220.72C248.553 220.08 248.233 219.36 248.233 218.48V196.88C248.233 196 248.553 195.2 249.112 194.64C249.671 194 250.469 193.76 251.348 193.76C252.227 193.76 253.025 194.08 253.584 194.64C254.143 195.28 254.463 196 254.463 196.88V201.76L254.064 198.24C254.463 197.44 254.942 196.64 255.581 196C256.22 195.36 256.859 194.8 257.658 194.4C258.456 194 259.255 193.68 260.134 193.44C261.012 193.2 261.891 193.12 262.769 193.12C263.808 193.12 264.686 193.44 265.405 194C266.124 194.56 266.443 195.28 266.443 196.08C266.443 197.2 266.124 198.08 265.565 198.56C265.006 199.04 264.367 199.28 263.648 199.28C263.009 199.28 262.37 199.12 261.811 198.88C261.252 198.64 260.693 198.48 259.974 198.48C259.335 198.48 258.696 198.64 258.057 198.96C257.418 199.28 256.859 199.68 256.3 200.32C255.741 200.96 255.341 201.76 255.022 202.64C254.702 203.6 254.543 204.64 254.543 205.92V218.32C254.543 219.2 254.223 220 253.664 220.56C253.025 221.28 252.227 221.6 251.348 221.6Z"
      fill="white"
    />
    <path
      d="M296.474 207.6C296.474 210.48 295.835 212.96 294.557 215.12C293.279 217.28 291.602 218.96 289.446 220.24C287.289 221.44 284.893 222.08 282.257 222.08C279.622 222.08 277.226 221.44 275.069 220.24C272.913 219.04 271.235 217.36 269.957 215.12C268.68 212.96 268.041 210.4 268.041 207.6C268.041 204.72 268.68 202.24 269.957 200.08C271.235 197.92 272.913 196.16 275.069 194.96C277.226 193.76 279.622 193.12 282.257 193.12C284.893 193.12 287.289 193.76 289.446 194.96C291.602 196.16 293.279 197.92 294.557 200.08C295.835 202.24 296.474 204.8 296.474 207.6ZM290.244 207.6C290.244 205.84 289.925 204.32 289.206 202.96C288.487 201.68 287.529 200.64 286.331 199.92C285.133 199.2 283.775 198.8 282.337 198.8C280.9 198.8 279.542 199.2 278.344 199.92C277.146 200.64 276.187 201.68 275.468 202.96C274.75 204.24 274.43 205.84 274.43 207.6C274.43 209.36 274.75 210.88 275.468 212.16C276.187 213.44 277.146 214.48 278.344 215.2C279.542 215.92 280.9 216.32 282.337 216.32C283.775 216.32 285.133 215.92 286.331 215.2C287.529 214.48 288.487 213.44 289.206 212.16C289.845 210.88 290.244 209.36 290.244 207.6Z"
      fill="white"
    />
    <path
      d="M316.361 193.12C319.157 193.12 321.154 193.76 322.511 195.12C323.869 196.48 324.668 198.16 325.147 200.32L324.268 199.84L324.668 199.04C325.067 198.24 325.706 197.36 326.585 196.48C327.463 195.52 328.501 194.8 329.779 194.16C330.977 193.52 332.415 193.2 333.933 193.2C336.408 193.2 338.325 193.76 339.603 194.8C340.881 195.84 341.84 197.28 342.319 199.12C342.798 200.88 343.038 202.88 343.038 205.12V218.48C343.038 219.36 342.718 220.16 342.159 220.72C341.6 221.36 340.801 221.6 339.923 221.6C339.044 221.6 338.245 221.28 337.686 220.72C337.127 220.08 336.808 219.36 336.808 218.48V205.04C336.808 203.92 336.648 202.88 336.408 201.92C336.169 200.96 335.61 200.24 334.891 199.68C334.172 199.12 333.134 198.88 331.776 198.88C330.418 198.88 329.3 199.12 328.422 199.68C327.463 200.24 326.744 200.96 326.345 201.92C325.866 202.88 325.626 203.84 325.626 205.04V218.4C325.626 219.28 325.307 220.08 324.748 220.64C324.189 221.28 323.39 221.52 322.511 221.52C321.633 221.52 320.834 221.2 320.275 220.64C319.716 220 319.396 219.28 319.396 218.4V205.04C319.396 203.92 319.237 202.88 318.997 201.92C318.757 200.96 318.198 200.24 317.48 199.68C316.761 199.12 315.722 198.88 314.365 198.88C313.007 198.88 311.889 199.12 311.01 199.68C310.052 200.24 309.333 200.96 308.934 201.92C308.454 202.88 308.215 203.84 308.215 205.04V218.4C308.215 219.28 307.895 220.08 307.336 220.64C306.777 221.28 305.978 221.52 305.1 221.52C304.221 221.52 303.423 221.2 302.863 220.64C302.304 220 301.985 219.28 301.985 218.4V196.8C301.985 195.92 302.304 195.12 302.863 194.56C303.423 193.92 304.221 193.68 305.1 193.68C305.978 193.68 306.777 194 307.336 194.56C307.895 195.2 308.215 195.92 308.215 196.8V199.04L307.416 198.88C307.736 198.32 308.135 197.68 308.694 196.96C309.253 196.32 309.892 195.68 310.691 195.04C311.489 194.48 312.368 194 313.326 193.6C314.125 193.28 315.243 193.12 316.361 193.12Z"
      fill="white"
    />
    <path
      d="M352.462 190.24C351.264 190.24 350.465 190.08 349.986 189.68C349.507 189.28 349.267 188.64 349.267 187.68V186.64C349.267 185.6 349.507 184.96 350.066 184.56C350.625 184.16 351.424 184 352.542 184C353.74 184 354.619 184.16 355.098 184.56C355.577 184.96 355.817 185.6 355.817 186.56V187.52C355.817 188.56 355.577 189.2 355.018 189.6C354.539 190.08 353.66 190.24 352.462 190.24ZM355.657 218.4C355.657 219.28 355.337 220.08 354.778 220.64C354.219 221.28 353.421 221.52 352.542 221.52C351.664 221.52 350.865 221.2 350.306 220.64C349.747 220 349.427 219.28 349.427 218.4V196.8C349.427 195.92 349.747 195.12 350.306 194.56C350.865 193.92 351.664 193.68 352.542 193.68C353.421 193.68 354.219 194 354.778 194.56C355.337 195.2 355.657 195.92 355.657 196.8V218.4Z"
      fill="white"
    />
    <path
      d="M360.449 217.28C360.13 216.8 359.97 216.16 359.97 215.36C359.97 214.56 360.449 213.92 361.328 213.36C361.887 213.04 362.446 212.88 363.085 212.88C363.724 212.96 364.283 213.28 364.922 213.84C365.96 214.88 366.998 215.6 368.117 216.16C369.235 216.72 370.592 216.96 372.27 216.96C372.829 216.96 373.388 216.88 374.027 216.72C374.666 216.56 375.225 216.32 375.704 215.92C376.183 215.52 376.423 214.8 376.423 213.92C376.423 213.12 376.183 212.56 375.624 212.08C375.065 211.6 374.426 211.28 373.548 210.96C372.669 210.64 371.711 210.32 370.672 210.08C369.634 209.76 368.516 209.44 367.398 209.04C366.28 208.64 365.241 208.16 364.283 207.52C363.324 206.88 362.606 206.08 361.967 205.12C361.408 204.08 361.088 202.8 361.088 201.28C361.088 199.52 361.567 198.08 362.526 196.88C363.484 195.68 364.762 194.72 366.28 194.08C367.797 193.44 369.474 193.12 371.152 193.12C372.19 193.12 373.388 193.28 374.506 193.52C375.704 193.76 376.822 194.16 377.861 194.8C378.899 195.36 379.857 196.16 380.576 197.04C380.975 197.6 381.215 198.16 381.215 198.96C381.295 199.68 380.975 200.32 380.257 200.96C379.698 201.36 379.138 201.6 378.42 201.52C377.701 201.44 377.142 201.2 376.663 200.8C376.103 200 375.305 199.44 374.266 198.96C373.308 198.48 372.11 198.32 370.832 198.32C370.273 198.32 369.714 198.4 369.155 198.48C368.516 198.64 368.037 198.88 367.557 199.28C367.078 199.68 366.919 200.32 366.919 201.2C366.919 202 367.158 202.64 367.717 203.12C368.276 203.6 368.915 204 369.794 204.24C370.672 204.56 371.631 204.8 372.669 205.12C373.707 205.36 374.746 205.76 375.864 206.08C376.902 206.48 377.94 206.96 378.819 207.6C379.698 208.24 380.416 209.04 380.975 210C381.535 210.96 381.774 212.24 381.774 213.76C381.774 215.52 381.215 217.04 380.177 218.32C379.139 219.6 377.861 220.56 376.263 221.2C374.666 221.84 373.068 222.16 371.471 222.16C369.394 222.16 367.398 221.84 365.321 221.12C363.564 220.32 361.807 219.04 360.449 217.28Z"
      fill="white"
    />
    <path
      d="M385.608 217.28C385.288 216.8 385.129 216.16 385.129 215.36C385.129 214.56 385.608 213.92 386.486 213.36C387.045 213.04 387.605 212.88 388.244 212.88C388.882 212.96 389.442 213.28 390.081 213.84C391.119 214.88 392.157 215.6 393.275 216.16C394.393 216.72 395.751 216.96 397.428 216.96C397.988 216.96 398.547 216.88 399.186 216.72C399.825 216.56 400.384 216.32 400.863 215.92C401.342 215.52 401.582 214.8 401.582 213.92C401.582 213.12 401.342 212.56 400.783 212.08C400.224 211.6 399.585 211.28 398.706 210.96C397.828 210.64 396.869 210.32 395.831 210.08C394.793 209.76 393.675 209.44 392.556 209.04C391.438 208.64 390.4 208.16 389.442 207.52C388.483 206.88 387.764 206.08 387.125 205.12C386.566 204.08 386.247 202.8 386.247 201.28C386.247 199.52 386.726 198.08 387.684 196.88C388.643 195.68 389.921 194.72 391.438 194.08C392.956 193.44 394.633 193.12 396.31 193.12C397.349 193.12 398.547 193.28 399.665 193.52C400.863 193.76 401.981 194.16 403.019 194.8C404.058 195.36 405.016 196.16 405.735 197.04C406.134 197.6 406.374 198.16 406.374 198.96C406.454 199.68 406.134 200.32 405.415 200.96C404.856 201.36 404.297 201.6 403.578 201.52C402.86 201.44 402.3 201.2 401.821 200.8C401.262 200 400.463 199.44 399.425 198.96C398.467 198.48 397.269 198.32 395.991 198.32C395.432 198.32 394.873 198.4 394.314 198.48C393.675 198.64 393.195 198.88 392.716 199.28C392.237 199.68 392.077 200.32 392.077 201.2C392.077 202 392.317 202.64 392.876 203.12C393.435 203.6 394.074 204 394.953 204.24C395.831 204.56 396.79 204.8 397.828 205.12C398.866 205.36 399.904 205.76 401.023 206.08C402.061 206.48 403.099 206.96 403.978 207.6C404.856 208.24 405.575 209.04 406.134 210C406.693 210.96 406.933 212.24 406.933 213.76C406.933 215.52 406.374 217.04 405.336 218.32C404.297 219.6 403.019 220.56 401.422 221.2C399.825 221.84 398.227 222.16 396.63 222.16C394.553 222.16 392.556 221.84 390.48 221.12C388.643 220.32 386.966 219.04 385.608 217.28Z"
      fill="white"
    />
    <path
      d="M439.2 207.6C439.2 210.48 438.561 212.96 437.283 215.12C436.005 217.28 434.328 218.96 432.172 220.24C430.015 221.44 427.619 222.08 424.983 222.08C422.348 222.08 419.952 221.44 417.795 220.24C415.639 219.04 413.961 217.36 412.684 215.12C411.406 212.96 410.767 210.4 410.767 207.6C410.767 204.72 411.406 202.24 412.684 200.08C413.961 197.92 415.639 196.16 417.795 194.96C419.952 193.76 422.348 193.12 424.983 193.12C427.619 193.12 430.015 193.76 432.172 194.96C434.328 196.16 436.005 197.92 437.283 200.08C438.561 202.24 439.2 204.8 439.2 207.6ZM432.89 207.6C432.89 205.84 432.571 204.32 431.852 202.96C431.133 201.68 430.175 200.64 428.977 199.92C427.779 199.2 426.421 198.8 424.983 198.8C423.546 198.8 422.188 199.2 420.99 199.92C419.792 200.64 418.833 201.68 418.115 202.96C417.396 204.24 417.076 205.84 417.076 207.6C417.076 209.36 417.396 210.88 418.115 212.16C418.833 213.44 419.792 214.48 420.99 215.2C422.188 215.92 423.546 216.32 424.983 216.32C426.421 216.32 427.779 215.92 428.977 215.2C430.175 214.48 431.133 213.44 431.852 212.16C432.571 210.88 432.89 209.36 432.89 207.6Z"
      fill="white"
    />
    <path
      d="M291.812 40C289.187 40 287 42.1283 287 44.7574C287 47.3865 289.125 49.5149 291.812 49.5149C308.687 49.5149 322.437 63.2238 322.437 80C322.437 96.7762 308.687 110.485 291.812 110.485C289.187 110.485 287 112.613 287 115.243C287 117.872 289.125 120 291.812 120C314 120 332 102.034 332 79.9374C332 57.8404 314 40 291.812 40Z"
      fill="#35389B"
    />
    <path
      d="M237.945 67.541C235.016 48.918 225.575 31.8033 211.317 19.4098C196.929 6.88525 178.504 0 159.428 0C115.613 0 80 35.8689 80 80C80 124.131 115.613 160 159.428 160C178.114 160 196.278 153.311 210.536 141.246C211.708 140.262 212.424 138.82 212.489 137.311C212.554 135.803 212.034 134.23 210.992 133.115L210.927 133.049C209.885 131.934 208.388 131.279 206.89 131.279C205.588 131.279 204.286 131.738 203.309 132.59C191.07 142.951 175.509 148.721 159.428 148.721C121.798 148.721 91.1981 117.902 91.1981 80C91.1981 42.0984 121.798 11.2787 159.428 11.2787C175.77 11.2787 191.59 17.1803 203.961 27.9344C216.2 38.5574 224.338 53.2459 226.812 69.2459C227.268 72 229.547 74.0328 232.346 74.0328C232.672 74.0328 232.932 74.0328 233.258 73.9672C234.755 73.7049 236.057 72.918 236.904 71.7377C237.815 70.4918 238.141 68.9836 237.945 67.541Z"
      fill="white"
    />
    <path
      d="M202.08 92.459C205.009 111.082 214.448 128.197 228.704 140.59C243.09 153.115 261.511 160 280.584 160C324.393 160 360 124.131 360 80C360 35.8689 324.328 0 280.519 0C261.837 0 243.675 6.68853 229.42 18.7541C228.248 19.7377 227.532 21.1803 227.467 22.6885C227.402 24.1967 227.922 25.7705 228.964 26.8852L229.029 26.9508C230.071 28.0656 231.568 28.7213 233.065 28.7213C234.367 28.7213 235.669 28.2623 236.645 27.4098C248.883 17.0492 264.441 11.2787 280.519 11.2787C318.144 11.2787 348.739 42.0984 348.739 80C348.739 117.902 318.144 148.721 280.519 148.721C264.18 148.721 248.362 142.82 235.994 132.066C223.756 121.443 215.62 106.754 213.146 90.7541C212.69 88 210.412 85.9672 207.613 85.9672C207.287 85.9672 207.027 85.9672 206.701 86.0328C205.204 86.2951 203.902 87.082 203.056 88.2623C202.21 89.5082 201.819 91.0164 202.08 92.459Z"
      fill="white"
    />
  </svg>
);

const IntentlessLogo = ({ height = 232 }) => (
  <svg
    width="440"
    height={height}
    viewBox="0 0 440 232"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.4563 193.12C17.2517 193.12 19.2484 193.76 20.6062 195.12C21.964 196.48 22.7627 198.16 23.2419 200.32L22.3633 199.84L22.7627 199.04C23.162 198.24 23.801 197.36 24.6795 196.48C25.5581 195.52 26.5964 194.8 27.7944 194.16C28.9925 193.52 30.4301 193.2 31.9476 193.2C34.4236 193.2 36.3404 193.76 37.6183 194.8C38.8962 195.84 39.8547 197.28 40.3339 199.12C40.8131 200.88 41.0527 202.88 41.0527 205.12V218.48C41.0527 219.36 40.7332 220.16 40.1741 220.72C39.6151 221.36 38.8164 221.6 37.9378 221.6C37.0592 221.6 36.2606 221.28 35.7015 220.72C35.1424 220.08 34.8229 219.36 34.8229 218.48V205.04C34.8229 203.92 34.6632 202.88 34.4236 201.92C34.184 200.96 33.6249 200.24 32.9061 199.68C32.1872 199.12 31.1489 198.88 29.7912 198.88C28.4334 198.88 27.3152 199.12 26.4367 199.68C25.4782 200.24 24.7594 200.96 24.3601 201.92C23.8809 202.88 23.6412 203.84 23.6412 205.04V218.4C23.6412 219.28 23.3218 220.08 22.7627 220.64C22.2036 221.28 21.4049 221.52 20.5264 221.52C19.6478 221.52 18.8491 221.2 18.29 220.64C17.7309 220 17.4115 219.28 17.4115 218.4V205.04C17.4115 203.92 17.2517 202.88 17.0121 201.92C16.7725 200.96 16.2134 200.24 15.4946 199.68C14.7758 199.12 13.7375 198.88 12.3797 198.88C11.0219 198.88 9.90376 199.12 9.0252 199.68C8.06678 200.24 7.34795 200.96 6.94861 201.92C6.46939 202.88 6.22979 203.84 6.22979 205.04V218.4C6.22979 219.28 5.91031 220.08 5.35123 220.64C4.79214 221.28 3.99345 221.52 3.11489 221.52C2.23633 221.52 1.43764 221.2 0.87856 220.64C0.319476 220 0 219.28 0 218.4V196.8C0 195.92 0.319476 195.12 0.87856 194.56C1.43764 193.92 2.23633 193.68 3.11489 193.68C3.99345 193.68 4.79214 194 5.35123 194.56C5.91031 195.2 6.22979 195.92 6.22979 196.8V199.04L5.4311 198.88C5.75057 198.32 6.14992 197.68 6.709 196.96C7.26809 196.32 7.90704 195.68 8.70573 195.04C9.50442 194.48 10.383 194 11.3414 193.6C12.2998 193.28 13.3381 193.12 14.4563 193.12Z"
      fill="#35389B"
    />
    <path
      d="M60.7804 222.08C57.8252 222.08 55.2694 221.44 53.113 220.24C50.9565 219.04 49.2793 217.36 48.0812 215.2C46.8832 213.04 46.3241 210.64 46.3241 208C46.3241 204.88 46.963 202.16 48.241 200C49.5189 197.76 51.1961 196.08 53.1928 194.88C55.2694 193.68 57.4259 193.12 59.7421 193.12C61.4992 193.12 63.1765 193.52 64.7739 194.24C66.3712 194.96 67.729 196 69.0069 197.28C70.2049 198.56 71.1634 200 71.8822 201.68C72.601 203.36 72.9205 205.12 72.9205 207.04C72.9205 207.84 72.5212 208.56 71.9621 209.04C71.3231 209.6 70.6043 209.84 69.8056 209.84H49.8383L48.241 204.64H67.4095L66.2914 205.68V204.24C66.2115 203.2 65.892 202.32 65.2531 201.52C64.6141 200.72 63.8154 200.08 62.857 199.6C61.8986 199.12 60.8603 198.88 59.822 198.88C58.7837 198.88 57.8252 199.04 56.8668 199.28C55.9883 199.52 55.1896 200 54.5506 200.72C53.9117 201.36 53.3526 202.24 52.9532 203.36C52.5539 204.48 52.3941 205.92 52.3941 207.6C52.3941 209.52 52.7935 211.04 53.5922 212.4C54.3909 213.68 55.3493 214.72 56.6272 215.36C57.8252 216 59.183 216.4 60.5408 216.4C61.8187 216.4 62.857 216.32 63.6557 216.08C64.4544 215.84 65.0135 215.6 65.4927 215.36C65.9719 215.04 66.3712 214.8 66.7706 214.64C67.4095 214.32 67.9686 214.16 68.5277 214.16C69.3264 214.16 69.9653 214.4 70.4446 214.96C70.9238 215.52 71.1634 216.08 71.1634 216.8C71.1634 217.76 70.6842 218.56 69.7257 219.36C68.8472 220.16 67.5693 220.8 65.892 221.36C64.2148 221.84 62.5375 222.08 60.7804 222.08Z"
      fill="#35389B"
    />
    <path
      d="M98.8779 193.6C99.7565 193.6 100.555 193.92 101.114 194.48C101.673 195.12 101.993 195.84 101.993 196.72V210C101.993 213.68 100.954 216.64 98.8779 218.8C96.8013 220.96 93.8461 222 90.0124 222C86.1787 222 83.2236 220.96 81.147 218.8C79.1502 216.64 78.1119 213.68 78.1119 210V196.72C78.1119 195.84 78.4314 195.04 78.9905 194.48C79.5496 193.84 80.3483 193.6 81.2268 193.6C82.1054 193.6 82.9041 193.92 83.4632 194.48C84.0222 195.12 84.3417 195.84 84.3417 196.72V210C84.3417 212.16 84.8209 213.68 85.7794 214.72C86.7378 215.76 88.0956 216.24 90.0124 216.24C91.9293 216.24 93.3669 215.76 94.3254 214.72C95.2838 213.68 95.763 212.08 95.763 210V196.72C95.763 195.84 96.0825 195.04 96.6416 194.48C97.2006 193.92 97.9195 193.6 98.8779 193.6Z"
      fill="#35389B"
    />
    <path
      d="M120.922 193.12C122.918 193.12 124.596 193.36 126.113 193.76C127.551 194.16 128.749 194.8 129.548 195.52C130.346 196.24 130.746 197.2 130.746 198.24C130.746 198.96 130.506 199.6 130.107 200.16C129.707 200.8 129.068 201.04 128.27 201.04C127.711 201.04 127.231 200.96 126.912 200.8C126.513 200.64 126.193 200.48 125.953 200.16C125.714 199.92 125.315 199.68 124.995 199.52C124.676 199.28 124.116 199.12 123.398 199.04C122.679 198.88 122.12 198.88 121.8 198.88C120.043 198.88 118.526 199.28 117.328 200C116.13 200.8 115.171 201.84 114.452 203.12C113.813 204.4 113.494 205.92 113.494 207.6C113.494 209.28 113.813 210.8 114.532 212.08C115.171 213.36 116.13 214.4 117.328 215.2C118.526 216 119.883 216.32 121.481 216.32C122.359 216.32 123.078 216.24 123.717 216.16C124.356 216.08 124.835 215.92 125.315 215.68C125.794 215.44 126.273 215.12 126.592 214.8C126.992 214.48 127.551 214.32 128.35 214.32C129.228 214.32 129.947 214.64 130.426 215.2C130.905 215.76 131.145 216.48 131.145 217.28C131.145 218.16 130.666 218.96 129.707 219.68C128.749 220.4 127.471 220.96 125.874 221.44C124.276 221.84 122.599 222.08 120.762 222.08C118.046 222.08 115.65 221.44 113.654 220.16C111.657 218.88 110.06 217.12 109.021 214.96C107.983 212.8 107.344 210.32 107.344 207.6C107.344 204.72 107.903 202.24 109.101 200.08C110.299 197.92 111.897 196.16 113.973 194.96C115.89 193.76 118.206 193.12 120.922 193.12Z"
      fill="#35389B"
    />
    <path
      d="M162.454 207.6C162.454 210.48 161.815 212.96 160.537 215.12C159.259 217.28 157.582 218.96 155.425 220.24C153.269 221.44 150.873 222.08 148.237 222.08C145.601 222.08 143.205 221.44 141.049 220.24C138.892 219.04 137.215 217.36 135.937 215.12C134.659 212.96 134.02 210.4 134.02 207.6C134.02 204.72 134.659 202.24 135.937 200.08C137.215 197.92 138.892 196.16 141.049 194.96C143.205 193.76 145.601 193.12 148.237 193.12C150.873 193.12 153.269 193.76 155.425 194.96C157.582 196.16 159.259 197.92 160.537 200.08C161.815 202.24 162.454 204.8 162.454 207.6ZM156.144 207.6C156.144 205.84 155.825 204.32 155.106 202.96C154.387 201.68 153.428 200.64 152.23 199.92C151.032 199.2 149.675 198.8 148.237 198.8C146.799 198.8 145.442 199.2 144.244 199.92C143.045 200.64 142.087 201.68 141.368 202.96C140.649 204.24 140.33 205.84 140.33 207.6C140.33 209.36 140.649 210.88 141.368 212.16C142.087 213.44 143.045 214.48 144.244 215.2C145.442 215.92 146.799 216.32 148.237 216.32C149.675 216.32 151.032 215.92 152.23 215.2C153.428 214.48 154.387 213.44 155.106 212.16C155.825 210.88 156.144 209.36 156.144 207.6Z"
      fill="#35389B"
    />
    <path
      d="M182.261 193.12C185.057 193.12 187.053 193.76 188.411 195.12C189.769 196.48 190.568 198.16 191.047 200.32L190.168 199.84L190.568 199.04C190.967 198.24 191.606 197.36 192.484 196.48C193.363 195.52 194.401 194.8 195.599 194.16C196.797 193.52 198.235 193.2 199.753 193.2C202.228 193.2 204.145 193.76 205.423 194.8C206.701 195.84 207.66 197.28 208.139 199.12C208.618 200.88 208.858 202.88 208.858 205.12V218.48C208.858 219.36 208.538 220.16 207.979 220.72C207.42 221.36 206.621 221.6 205.743 221.6C204.864 221.6 204.065 221.28 203.506 220.72C202.947 220.08 202.628 219.36 202.628 218.48V205.04C202.628 203.92 202.468 202.88 202.228 201.92C201.989 200.96 201.43 200.24 200.711 199.68C199.992 199.12 198.954 198.88 197.596 198.88C196.238 198.88 195.12 199.12 194.242 199.68C193.283 200.24 192.564 200.96 192.165 201.92C191.686 202.88 191.446 203.84 191.446 205.04V218.4C191.446 219.28 191.127 220.08 190.568 220.64C190.009 221.28 189.21 221.52 188.331 221.52C187.453 221.52 186.654 221.2 186.095 220.64C185.536 220 185.216 219.28 185.216 218.4V205.04C185.216 203.92 185.057 202.88 184.817 201.92C184.577 200.96 184.018 200.24 183.3 199.68C182.581 199.12 181.542 198.88 180.185 198.88C178.827 198.88 177.709 199.12 176.83 199.68C175.872 200.24 175.153 200.96 174.754 201.92C174.274 202.88 174.035 203.84 174.035 205.04V218.4C174.035 219.28 173.715 220.08 173.156 220.64C172.597 221.28 171.798 221.52 170.92 221.52C170.041 221.52 169.243 221.2 168.683 220.64C168.124 220 167.805 219.28 167.805 218.4V196.8C167.805 195.92 168.124 195.12 168.683 194.56C169.243 193.92 170.041 193.68 170.92 193.68C171.798 193.68 172.597 194 173.156 194.56C173.715 195.2 174.035 195.92 174.035 196.8V199.04L173.236 198.88C173.556 198.32 173.955 197.68 174.514 196.96C175.073 196.32 175.712 195.68 176.511 195.04C177.309 194.48 178.188 194 179.146 193.6C180.105 193.28 181.143 193.12 182.261 193.12Z"
      fill="#35389B"
    />
    <path
      d="M230.183 193.12C232.579 193.12 234.815 193.76 236.732 194.96C238.649 196.16 240.166 197.92 241.284 200.08C242.403 202.24 242.962 204.72 242.962 207.6C242.962 210.48 242.403 212.96 241.284 215.12C240.166 217.28 238.649 219.04 236.732 220.24C234.815 221.44 232.738 222.08 230.342 222.08C228.985 222.08 227.627 221.84 226.429 221.44C225.231 220.96 224.113 220.4 223.234 219.68C222.276 218.96 221.637 218.24 221.078 217.6C220.598 216.88 220.359 216.32 220.359 215.76L221.956 215.12V228.88C221.956 229.76 221.637 230.56 221.078 231.12C220.518 231.76 219.72 232 218.841 232C217.963 232 217.164 231.68 216.605 231.12C216.046 230.56 215.726 229.76 215.726 228.8V196.8C215.726 195.92 216.046 195.12 216.605 194.56C217.164 193.92 217.963 193.68 218.841 193.68C219.72 193.68 220.518 194 221.078 194.56C221.637 195.2 221.956 195.92 221.956 196.8V199.36L221.078 198.88C221.078 198.4 221.317 197.84 221.796 197.2C222.276 196.56 222.915 195.92 223.793 195.28C224.592 194.64 225.63 194.08 226.668 193.68C227.787 193.28 228.985 193.12 230.183 193.12ZM229.384 198.88C227.866 198.88 226.509 199.28 225.39 200C224.272 200.8 223.314 201.84 222.675 203.12C222.036 204.4 221.717 205.92 221.717 207.6C221.717 209.2 222.036 210.72 222.675 212.08C223.314 213.44 224.192 214.48 225.39 215.28C226.509 216.08 227.866 216.4 229.384 216.4C230.901 216.4 232.259 216 233.377 215.28C234.496 214.48 235.374 213.44 236.013 212.08C236.652 210.72 236.972 209.28 236.972 207.6C236.972 205.92 236.652 204.48 236.013 203.12C235.374 201.84 234.496 200.8 233.377 200C232.259 199.2 230.901 198.88 229.384 198.88Z"
      fill="#35389B"
    />
    <path
      d="M251.348 221.6C250.469 221.6 249.671 221.28 249.112 220.72C248.553 220.08 248.233 219.36 248.233 218.48V196.88C248.233 196 248.553 195.2 249.112 194.64C249.671 194 250.469 193.76 251.348 193.76C252.226 193.76 253.025 194.08 253.584 194.64C254.143 195.28 254.463 196 254.463 196.88V201.76L254.063 198.24C254.463 197.44 254.942 196.64 255.581 196C256.22 195.36 256.859 194.8 257.658 194.4C258.456 194 259.255 193.68 260.134 193.44C261.012 193.2 261.891 193.12 262.769 193.12C263.808 193.12 264.686 193.44 265.405 194C266.124 194.56 266.443 195.28 266.443 196.08C266.443 197.2 266.124 198.08 265.565 198.56C265.006 199.04 264.367 199.28 263.648 199.28C263.009 199.28 262.37 199.12 261.811 198.88C261.252 198.64 260.693 198.48 259.974 198.48C259.335 198.48 258.696 198.64 258.057 198.96C257.418 199.28 256.859 199.68 256.3 200.32C255.741 200.96 255.341 201.76 255.022 202.64C254.702 203.6 254.543 204.64 254.543 205.92V218.32C254.543 219.2 254.223 220 253.664 220.56C253.025 221.28 252.226 221.6 251.348 221.6Z"
      fill="#35389B"
    />
    <path
      d="M296.474 207.6C296.474 210.48 295.835 212.96 294.557 215.12C293.279 217.28 291.602 218.96 289.445 220.24C287.289 221.44 284.893 222.08 282.257 222.08C279.622 222.08 277.225 221.44 275.069 220.24C272.913 219.04 271.235 217.36 269.957 215.12C268.679 212.96 268.041 210.4 268.041 207.6C268.041 204.72 268.679 202.24 269.957 200.08C271.235 197.92 272.913 196.16 275.069 194.96C277.225 193.76 279.622 193.12 282.257 193.12C284.893 193.12 287.289 193.76 289.445 194.96C291.602 196.16 293.279 197.92 294.557 200.08C295.835 202.24 296.474 204.8 296.474 207.6ZM290.244 207.6C290.244 205.84 289.925 204.32 289.206 202.96C288.487 201.68 287.529 200.64 286.331 199.92C285.133 199.2 283.775 198.8 282.337 198.8C280.899 198.8 279.542 199.2 278.344 199.92C277.146 200.64 276.187 201.68 275.468 202.96C274.75 204.24 274.43 205.84 274.43 207.6C274.43 209.36 274.75 210.88 275.468 212.16C276.187 213.44 277.146 214.48 278.344 215.2C279.542 215.92 280.899 216.32 282.337 216.32C283.775 216.32 285.133 215.92 286.331 215.2C287.529 214.48 288.487 213.44 289.206 212.16C289.845 210.88 290.244 209.36 290.244 207.6Z"
      fill="#35389B"
    />
    <path
      d="M316.361 193.12C319.157 193.12 321.153 193.76 322.511 195.12C323.869 196.48 324.668 198.16 325.147 200.32L324.268 199.84L324.668 199.04C325.067 198.24 325.706 197.36 326.585 196.48C327.463 195.52 328.501 194.8 329.779 194.16C330.977 193.52 332.415 193.2 333.932 193.2C336.408 193.2 338.325 193.76 339.603 194.8C340.881 195.84 341.84 197.28 342.319 199.12C342.798 200.88 343.038 202.88 343.038 205.12V218.48C343.038 219.36 342.718 220.16 342.159 220.72C341.6 221.36 340.801 221.6 339.923 221.6C339.044 221.6 338.245 221.28 337.686 220.72C337.127 220.08 336.808 219.36 336.808 218.48V205.04C336.808 203.92 336.648 202.88 336.408 201.92C336.169 200.96 335.61 200.24 334.891 199.68C334.172 199.12 333.134 198.88 331.776 198.88C330.418 198.88 329.3 199.12 328.422 199.68C327.463 200.24 326.744 200.96 326.345 201.92C325.866 202.88 325.626 203.84 325.626 205.04V218.4C325.626 219.28 325.307 220.08 324.748 220.64C324.188 221.28 323.39 221.52 322.511 221.52C321.633 221.52 320.834 221.2 320.275 220.64C319.716 220 319.396 219.28 319.396 218.4V205.04C319.396 203.92 319.237 202.88 318.997 201.92C318.757 200.96 318.198 200.24 317.479 199.68C316.761 199.12 315.722 198.88 314.365 198.88C313.007 198.88 311.889 199.12 311.01 199.68C310.052 200.24 309.333 200.96 308.933 201.92C308.454 202.88 308.215 203.84 308.215 205.04V218.4C308.215 219.28 307.895 220.08 307.336 220.64C306.777 221.28 305.978 221.52 305.1 221.52C304.221 221.52 303.423 221.2 302.863 220.64C302.304 220 301.985 219.28 301.985 218.4V196.8C301.985 195.92 302.304 195.12 302.863 194.56C303.423 193.92 304.221 193.68 305.1 193.68C305.978 193.68 306.777 194 307.336 194.56C307.895 195.2 308.215 195.92 308.215 196.8V199.04L307.416 198.88C307.735 198.32 308.135 197.68 308.694 196.96C309.253 196.32 309.892 195.68 310.691 195.04C311.489 194.48 312.368 194 313.326 193.6C314.125 193.28 315.243 193.12 316.361 193.12Z"
      fill="#35389B"
    />
    <path
      d="M352.462 190.24C351.264 190.24 350.465 190.08 349.986 189.68C349.507 189.28 349.267 188.64 349.267 187.68V186.64C349.267 185.6 349.507 184.96 350.066 184.56C350.625 184.16 351.424 184 352.542 184C353.74 184 354.619 184.16 355.098 184.56C355.577 184.96 355.817 185.6 355.817 186.56V187.52C355.817 188.56 355.577 189.2 355.018 189.6C354.539 190.08 353.66 190.24 352.462 190.24ZM355.657 218.4C355.657 219.28 355.337 220.08 354.778 220.64C354.219 221.28 353.421 221.52 352.542 221.52C351.664 221.52 350.865 221.2 350.306 220.64C349.747 220 349.427 219.28 349.427 218.4V196.8C349.427 195.92 349.747 195.12 350.306 194.56C350.865 193.92 351.664 193.68 352.542 193.68C353.421 193.68 354.219 194 354.778 194.56C355.337 195.2 355.657 195.92 355.657 196.8V218.4Z"
      fill="#35389B"
    />
    <path
      d="M360.449 217.28C360.13 216.8 359.97 216.16 359.97 215.36C359.97 214.56 360.449 213.92 361.328 213.36C361.887 213.04 362.446 212.88 363.085 212.88C363.724 212.96 364.283 213.28 364.922 213.84C365.96 214.88 366.998 215.6 368.117 216.16C369.235 216.72 370.593 216.96 372.27 216.96C372.829 216.96 373.388 216.88 374.027 216.72C374.666 216.56 375.225 216.32 375.704 215.92C376.183 215.52 376.423 214.8 376.423 213.92C376.423 213.12 376.183 212.56 375.624 212.08C375.065 211.6 374.426 211.28 373.548 210.96C372.669 210.64 371.711 210.32 370.672 210.08C369.634 209.76 368.516 209.44 367.398 209.04C366.28 208.64 365.241 208.16 364.283 207.52C363.324 206.88 362.606 206.08 361.967 205.12C361.408 204.08 361.088 202.8 361.088 201.28C361.088 199.52 361.567 198.08 362.526 196.88C363.484 195.68 364.762 194.72 366.28 194.08C367.797 193.44 369.474 193.12 371.152 193.12C372.19 193.12 373.388 193.28 374.506 193.52C375.704 193.76 376.822 194.16 377.861 194.8C378.899 195.36 379.857 196.16 380.576 197.04C380.976 197.6 381.215 198.16 381.215 198.96C381.295 199.68 380.976 200.32 380.257 200.96C379.698 201.36 379.139 201.6 378.42 201.52C377.701 201.44 377.142 201.2 376.663 200.8C376.104 200 375.305 199.44 374.267 198.96C373.308 198.48 372.11 198.32 370.832 198.32C370.273 198.32 369.714 198.4 369.155 198.48C368.516 198.64 368.037 198.88 367.558 199.28C367.078 199.68 366.919 200.32 366.919 201.2C366.919 202 367.158 202.64 367.717 203.12C368.276 203.6 368.915 204 369.794 204.24C370.672 204.56 371.631 204.8 372.669 205.12C373.707 205.36 374.746 205.76 375.864 206.08C376.902 206.48 377.941 206.96 378.819 207.6C379.698 208.24 380.416 209.04 380.976 210C381.535 210.96 381.774 212.24 381.774 213.76C381.774 215.52 381.215 217.04 380.177 218.32C379.139 219.6 377.861 220.56 376.263 221.2C374.666 221.84 373.068 222.16 371.471 222.16C369.395 222.16 367.398 221.84 365.321 221.12C363.564 220.32 361.807 219.04 360.449 217.28Z"
      fill="#35389B"
    />
    <path
      d="M385.608 217.28C385.288 216.8 385.129 216.16 385.129 215.36C385.129 214.56 385.608 213.92 386.486 213.36C387.046 213.04 387.605 212.88 388.244 212.88C388.883 212.96 389.442 213.28 390.081 213.84C391.119 214.88 392.157 215.6 393.275 216.16C394.393 216.72 395.751 216.96 397.428 216.96C397.988 216.96 398.547 216.88 399.186 216.72C399.825 216.56 400.384 216.32 400.863 215.92C401.342 215.52 401.582 214.8 401.582 213.92C401.582 213.12 401.342 212.56 400.783 212.08C400.224 211.6 399.585 211.28 398.706 210.96C397.828 210.64 396.869 210.32 395.831 210.08C394.793 209.76 393.675 209.44 392.556 209.04C391.438 208.64 390.4 208.16 389.442 207.52C388.483 206.88 387.764 206.08 387.125 205.12C386.566 204.08 386.247 202.8 386.247 201.28C386.247 199.52 386.726 198.08 387.684 196.88C388.643 195.68 389.921 194.72 391.438 194.08C392.956 193.44 394.633 193.12 396.31 193.12C397.349 193.12 398.547 193.28 399.665 193.52C400.863 193.76 401.981 194.16 403.019 194.8C404.058 195.36 405.016 196.16 405.735 197.04C406.134 197.6 406.374 198.16 406.374 198.96C406.454 199.68 406.134 200.32 405.415 200.96C404.856 201.36 404.297 201.6 403.578 201.52C402.86 201.44 402.301 201.2 401.821 200.8C401.262 200 400.464 199.44 399.425 198.96C398.467 198.48 397.269 198.32 395.991 198.32C395.432 198.32 394.873 198.4 394.314 198.48C393.675 198.64 393.195 198.88 392.716 199.28C392.237 199.68 392.077 200.32 392.077 201.2C392.077 202 392.317 202.64 392.876 203.12C393.435 203.6 394.074 204 394.953 204.24C395.831 204.56 396.79 204.8 397.828 205.12C398.866 205.36 399.904 205.76 401.023 206.08C402.061 206.48 403.099 206.96 403.978 207.6C404.856 208.24 405.575 209.04 406.134 210C406.693 210.96 406.933 212.24 406.933 213.76C406.933 215.52 406.374 217.04 405.336 218.32C404.297 219.6 403.019 220.56 401.422 221.2C399.825 221.84 398.227 222.16 396.63 222.16C394.553 222.16 392.556 221.84 390.48 221.12C388.643 220.32 386.966 219.04 385.608 217.28Z"
      fill="#35389B"
    />
    <path
      d="M439.2 207.6C439.2 210.48 438.561 212.96 437.283 215.12C436.005 217.28 434.328 218.96 432.171 220.24C430.015 221.44 427.619 222.08 424.983 222.08C422.348 222.08 419.952 221.44 417.795 220.24C415.639 219.04 413.961 217.36 412.683 215.12C411.406 212.96 410.767 210.4 410.767 207.6C410.767 204.72 411.406 202.24 412.683 200.08C413.961 197.92 415.639 196.16 417.795 194.96C419.952 193.76 422.348 193.12 424.983 193.12C427.619 193.12 430.015 193.76 432.171 194.96C434.328 196.16 436.005 197.92 437.283 200.08C438.561 202.24 439.2 204.8 439.2 207.6ZM432.89 207.6C432.89 205.84 432.571 204.32 431.852 202.96C431.133 201.68 430.175 200.64 428.977 199.92C427.779 199.2 426.421 198.8 424.983 198.8C423.546 198.8 422.188 199.2 420.99 199.92C419.792 200.64 418.833 201.68 418.115 202.96C417.396 204.24 417.076 205.84 417.076 207.6C417.076 209.36 417.396 210.88 418.115 212.16C418.833 213.44 419.792 214.48 420.99 215.2C422.188 215.92 423.546 216.32 424.983 216.32C426.421 216.32 427.779 215.92 428.977 215.2C430.175 214.48 431.133 213.44 431.852 212.16C432.571 210.88 432.89 209.36 432.89 207.6Z"
      fill="#35389B"
    />
    <path
      d="M291.812 40C289.187 40 287 42.1283 287 44.7574C287 47.3865 289.125 49.5149 291.812 49.5149C308.687 49.5149 322.437 63.2238 322.437 80C322.437 96.7762 308.687 110.485 291.812 110.485C289.187 110.485 287 112.613 287 115.243C287 117.872 289.125 120 291.812 120C314 120 332 102.034 332 79.9374C332 57.8404 314 40 291.812 40Z"
      fill="#FF499E"
    />
    <path
      d="M237.945 67.541C235.016 48.918 225.575 31.8033 211.317 19.4098C196.929 6.88525 178.504 0 159.428 0C115.613 0 80 35.8689 80 80C80 124.131 115.613 160 159.428 160C178.114 160 196.278 153.311 210.536 141.246C211.708 140.262 212.424 138.82 212.489 137.311C212.554 135.803 212.034 134.23 210.992 133.115L210.927 133.049C209.885 131.934 208.388 131.279 206.89 131.279C205.588 131.279 204.286 131.738 203.309 132.59C191.07 142.951 175.509 148.721 159.428 148.721C121.798 148.721 91.1981 117.902 91.1981 80C91.1981 42.0984 121.798 11.2787 159.428 11.2787C175.77 11.2787 191.59 17.1803 203.961 27.9344C216.2 38.5574 224.338 53.2459 226.812 69.2459C227.268 72 229.547 74.0328 232.346 74.0328C232.672 74.0328 232.932 74.0328 233.258 73.9672C234.755 73.7049 236.057 72.918 236.904 71.7377C237.815 70.4918 238.141 68.9836 237.945 67.541Z"
      fill="#35389B"
    />
    <path
      d="M202.08 92.459C205.009 111.082 214.448 128.197 228.704 140.59C243.09 153.115 261.511 160 280.584 160C324.393 160 360 124.131 360 80C360 35.8689 324.328 0 280.519 0C261.837 0 243.675 6.68853 229.42 18.7541C228.248 19.7377 227.532 21.1803 227.467 22.6885C227.402 24.1967 227.922 25.7705 228.964 26.8852L229.029 26.9508C230.071 28.0656 231.568 28.7213 233.065 28.7213C234.367 28.7213 235.669 28.2623 236.645 27.4098C248.883 17.0492 264.441 11.2787 280.519 11.2787C318.144 11.2787 348.739 42.0984 348.739 80C348.739 117.902 318.144 148.721 280.519 148.721C264.18 148.721 248.362 142.82 235.994 132.066C223.756 121.443 215.62 106.754 213.146 90.7541C212.69 88 210.412 85.9672 207.613 85.9672C207.287 85.9672 207.027 85.9672 206.701 86.0328C205.204 86.2951 203.902 87.082 203.056 88.2623C202.21 89.5082 201.819 91.0164 202.08 92.459Z"
      fill="#35389B"
    />
  </svg>
);
