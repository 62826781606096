import Heading from 'components/Heading';
import Icon from 'components/Icon';
import SkeletonPlaceholder from 'components/SkeletonPlaceholder';
import { useApplication } from 'contexts/ApplicationContext';
import paths from 'paths';
import React from 'react';
import getGuestConfirmation from 'services/admin/getGuestConfirmation';
import useSWR from 'swr';
import { colors } from 'theme';
import DashboardItem from './DashboardItem';

export type ConfirmedPresenceProps = {};

const ConfirmedPresence = () => {
  const { wedding } = useApplication();
  const shouldFetch = wedding.giftCashID;

  const { data, error } = useSWR(
    shouldFetch ? ['/rsvp', wedding.giftCashID] : null,
    (_, weddingID) => getGuestConfirmation({ weddingID }),
  );
  const pending = !data && !error;

  return (
    <DashboardItem
      icon={
        <Icon
          width={24}
          height={24}
          name="guests"
          color={colors.secondary500}
        />
      }
      description="Confirmaram presença"
      link={paths.Admin.GUESTS_SCREEN}
      label={
        pending ? (
          <SkeletonPlaceholder>
            <div style={{ width: 80, height: 32, borderRadius: 5 }} />
          </SkeletonPlaceholder>
        ) : (
          <Heading color={colors.secondary500}>
            {(data && data.length) || 0}
          </Heading>
        )
      }
      helperText="Visualizar convidados"
    />
  );
};

export default ConfirmedPresence;
