import React from 'react';
import { Box, Row } from 'jsxstyle';
import { colors } from 'theme';
import Separator from './Separator';
import Text from './Text';

type ToastType = 'warning' | 'danger' | 'info' | 'default' | 'success';
export type ToastMessageProps = {
  onClick: () => void;
  icon: React.ReactNode;
  message: string;
  type?: ToastType;
};

const ToastMessage = ({
  onClick,
  icon,
  message,
  type = 'default',
}: ToastMessageProps) => {
  const getColorByType = (type: ToastType) => {
    switch (type) {
      case 'warning':
        return [colors.orange100, colors.orange500];
      case 'danger':
        return [colors.red100, colors.red500];
      case 'success':
        return [colors.green100, colors.green500];
      case 'info':
        return [colors.blue100, colors.blue500];
      default:
        return [colors.white, colors.black];
    }
  };

  const [backgroundColor, textColor] = getColorByType(type);

  return (
    <Box
      backgroundColor={backgroundColor}
      padding={`12px 16px`}
      borderRadius={5}
      props={{ onClick }}
      cursor="pointer"
      border="1px solid"
      borderColor={textColor}
      boxShadow="0px 2px 8px rgba(0, 0, 0, 0.15);"
    >
      <Row alignItems="center">
        {Boolean(icon) && (
          <React.Fragment>
            {icon}
            <Separator size="sm" />
          </React.Fragment>
        )}
        <Text size="sm" color={textColor} fontWeight="bold">
          {message}
        </Text>
      </Row>
    </Box>
  );
};

export default ToastMessage;
