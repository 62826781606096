import Success from 'assets/icons/exported/Success';
import Button from 'components/Button';
import CopyButton from 'components/CopyButton';
import Heading from 'components/Heading';
import ScreenLayout from 'components/ScreenLayout';
import Separator from 'components/Separator';
import Text from 'components/Text';
import { useApplication } from 'contexts/ApplicationContext';
import { useOnboarding } from 'contexts/OnboardingContext';
import useMedia from 'hooks/useMedia';
import { Box, Col, Row } from 'jsxstyle';
import QRCode from 'qrcode.react';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { colors, spacingSizes } from 'theme';

interface GettinStartedScreenProps extends RouteComponentProps {}

const GettingStartedScreen = ({ history }: GettinStartedScreenProps) => {
  const { media } = useMedia();
  const { revalidate } = useApplication();
  const { url } = useOnboarding();
  const siteUrl = `https://${window.location.hostname}/${url}`;

  return (
    <ScreenLayout>
      <Col
        padding={spacingSizes.md}
        flex={1}
        justifyContent="center"
        alignItems="center"
      >
        <Col
          flex={1}
          maxWidth={498}
          justifyContent="space-between"
          alignItems="center"
          paddingTop={spacingSizes.lg}
        >
          <Box fontFamily="Samantha">
            <Heading textAlign="center" size="sm">
              Casando Sem Grana
            </Heading>
          </Box>
          <Success
            width={media === 'phone' ? 84 : 112}
            height={media === 'phone' ? 84 : 112}
          />
          <Col alignItems="center">
            <Heading size="lg" textAlign="center">
              Sua lista foi criada com sucesso!
            </Heading>
            <Separator size="sm" />
            <Box maxWidth={348} margin="0 auto" textAlign="center">
              <Text textAlign="center" color={colors.gray500}>
                Agora é hora de compartilhar seu site com os amigos e
                familiares.
              </Text>
            </Box>
            <Separator />
            <QRCode value={siteUrl} size={media === 'phone' ? 94 : 150} />
            <Separator />
            <Col alignItems={'center'}>
              <Text size="sm" textTransform="uppercase" color={colors.gray500}>
                Meu site
              </Text>
              <Row alignItems="center">
                <Text fontWeight="bold" color={colors.secondary500}>
                  {siteUrl}
                </Text>
                <Separator size="sm" />
                <CopyButton color={colors.secondary500} value={siteUrl} />
              </Row>
            </Col>
          </Col>
          <Button color="secondary" onClick={revalidate}>
            Ir para painel dos noivos
          </Button>
        </Col>
      </Col>
    </ScreenLayout>
  );
};

export default GettingStartedScreen;
