import ScreenContainer from 'components/admin/ScreenContainer';
import ScreenLayout from 'components/admin/ScreenLayout';
import ScrollBox from 'components/admin/ScrollBox';
import Card from 'components/Card';
import Heading from 'components/Heading';
import Icon from 'components/Icon';
import CoupleImagePicker from 'components/platform/CoupleImagePicker';
import Separator from 'components/Separator';
import Text from 'components/Text';
import { useApplication } from 'contexts/ApplicationContext';
import useAsync from 'hooks/useAsync';
import useForm from 'hooks/useForm';
import useMedia from 'hooks/useMedia';
import useToast from 'hooks/useToast';
import { Box, Col, Row } from 'jsxstyle';
import paths from 'paths';
import React from 'react';
import saveWeddingService from 'services/saveWeddingService';
import { mutate } from 'swr';
import { colors, mediaQueries } from 'theme';
import CheckingAccountType from 'types/CheckingAccountType';
import WeddingType from 'types/WeddingType';
import handleError from 'utils/handleError';
import BannerImage from './BannerImage';
import BrideAndGroomNamesInput from './BrideAndGroomNamesInput';
import CoverInput from './CoverInput';
import DateInput from './DateInput';
import WelcomeMessageInput from './WelcomeMessageInput';

export type UserScreenProps = {};

const UserScreen = () => {
  const { media } = useMedia();
  const { wedding, account } = useApplication();
  const [updateTime, setUpdateTime] = React.useState<number>();
  const { dangerMessage } = useToast();

  const { fetch } = useAsync({
    promiseFn: saveWeddingService,
    onData: (response) => {
      mutate(
        '/application',
        (data: { account: CheckingAccountType; wedding?: WeddingType }) => ({
          ...data,
          wedding: { ...data.wedding, ...response },
        }),
        false,
      );
    },
    onError: (error) => {
      const handledError = handleError({ error });
      dangerMessage({ message: handledError.message });
    },
  });

  const { submit } = useForm({
    initialValues: wedding,
    onSubmit: (values) => {
      fetch({
        accountID: account.accountID,
        giftCashID: wedding.giftCashID,
        personName1: values.personName1,
        personName2: values.personName2,
        welcomeMessage: values.welcomeMessage,
        weddingDate: values.weddingDate,
        weddingState: values.weddingState,
        weddingCity: values.weddingCity,
      });
    },
  });

  const couplePath = paths.BRIDE_AND_GROOM_SCREEN.replace(
    ':contextID',
    wedding.contextID,
  );
  const weddingURL = `${origin}${couplePath}`;

  return (
    <ScreenLayout>
      <ScreenContainer backgroundHeight="80%">
        <BannerImage key={updateTime} />
        <Row
          mediaQueries={mediaQueries}
          alignItems="center"
          justifyContent="space-between"
          zIndex={1}
        >
          <Row alignItems="center">
            <Heading size="xs" color={colors.white}>
              Minha página
            </Heading>
            <Separator size="sm" />
            <Box
              component="a"
              props={{ href: weddingURL, target: '_blank' }}
              display="flex"
              width={36}
              height={36}
              alignItems="center"
              justifyContent="center"
              cursor="pointer"
              borderRadius={50}
              transition="all 250ms ease-in-out"
              backgroundColor="rgba(255, 255, 255, 0.1)"
              hoverBackgroundColor="rgba(255, 255, 255, 0.2)"
            >
              <Icon name="eye" color={colors.white} />
            </Box>
          </Row>
          <CoverInput onData={() => setUpdateTime(new Date().getTime())} />
        </Row>
        <ScrollBox>
          <Col>
            <Separator size={media === 'desktop' ? 'xl' : 'sm'} />
            <BrideAndGroomNamesInput
              onSubmit={(personName1, personName2) =>
                submit({ personName1, personName2 })
              }
            />
            <Separator />
            <Card
              width="100%"
              alignSelf="center"
              justifyContent="center"
              position="relative"
            >
              <Box position="absolute" top={-32} alignSelf="center">
                <CoupleImagePicker size="lg" enableChangePhoto />
              </Box>
              <Separator />
              <Text
                color={colors.gray500}
                textAlign="center"
                textTransform="uppercase"
                size="xs"
              >
                Save the date
              </Text>
              <Separator size="xs" />
              <DateInput onSubmit={(weddingDate) => submit({ weddingDate })} />
              <Separator />
              <Box alignSelf="center">
                <Icon name="love-chat" />
              </Box>
              <Separator />
              <WelcomeMessageInput
                onSubmit={(welcomeMessage) => submit({ welcomeMessage })}
              />
            </Card>
          </Col>
        </ScrollBox>
      </ScreenContainer>
    </ScreenLayout>
  );
};

export default UserScreen;
