import brFlag from 'assets/images/br.png';
import Alert from 'components/Alert';
import Button from 'components/Button';
import Form from 'components/Form';
import InputGroup from 'components/InputGroup';
import MCLogoBlock from 'components/MCLogoBlock';
import Separator from 'components/Separator';
import Text from 'components/Text';
import TextInput from 'components/TextInput';
import { useAuth } from 'contexts/AuthContext';
import useAsync from 'hooks/useAsync';
import useForm from 'hooks/useForm';
import useMedia from 'hooks/useMedia';
import { Box, Col, Row } from 'jsxstyle';
import React from 'react';
import { Params as SignInParams } from 'services/signInService';
import { colors, spacingSizes } from 'theme';
import isRequired from 'validators/isRequired';

const LoginForm = () => {
  const { media } = useMedia();
  const { signIn } = useAuth();

  const { fetch, pending, error } = useAsync<SignInParams>({
    promiseFn: signIn,
  });

  const { getValue, setValue, getError, submit } = useForm({
    validations: {
      username: [isRequired],
      password: [isRequired],
    },
    onSubmit: (values) => {
      fetch({ username: values.username, password: values.password });
    },
  });

  const isPhoneNumber = Number(getValue('username'));

  return (
    <Col
      width={media === 'phone' ? '100%' : 460}
      height={490}
      backgroundColor={colors.white}
      borderRadius={8}
    >
      <Col
        flex={1}
        padding="30px 42px"
        justifyContent="space-between"
        alignItems="center"
      >
        <MCLogoBlock height={48} />
        <Col alignSelf="stretch">
          <Text
            textAlign="center"
            color={colors.primary500}
            textTransform="uppercase"
            fontWeight="bold"
          >
            Acessar conta MeuCompromisso
          </Text>
          <Separator />
          <Form>
            <InputGroup error={getError('username')}>
              <TextInput
                placeholder="Nome de usuário ou telefone"
                value={getValue('username')}
                onChange={(text) => {
                  setValue('username', text);
                }}
                error={getError('username')}
                autoFocus
                iconSpacing={isPhoneNumber ? 80 : spacingSizes.lg}
                leftIcon={
                  getValue('username') ? (
                    isPhoneNumber ? (
                      <Row alignItems="center">
                        <Box
                          component="img"
                          marginRight={spacingSizes.xs}
                          props={{ src: brFlag, height: 20 }}
                        />
                        <Text size="lg" color={colors.gray500}>
                          +55
                        </Text>
                      </Row>
                    ) : (
                      <Text size="lg" color={colors.gray500}>
                        @
                      </Text>
                    )
                  ) : null
                }
              />
            </InputGroup>
            <InputGroup error={getError('password')}>
              <TextInput
                placeholder="Senha"
                type="password"
                value={getValue('password')}
                onChange={(text) => setValue('password', text)}
                error={getError('password')}
              />
            </InputGroup>
            <Button
              loading={pending}
              color="secondary"
              onClick={() => submit()}
              type="submit"
            >
              Entrar
            </Button>
          </Form>
        </Col>
        <Col alignSelf="stretch">
          {error && (
            //@ts-ignore
            <Alert intent="danger" message={error.message} />
          )}
        </Col>
        {/* <Text
          textAlign="center"
          size="xs"
          textTransform="uppercase"
          color="#ccc"
        >
          Esqueceu a senha?
        </Text> */}
      </Col>
    </Col>
  );
};

export default LoginForm;
