import React from 'react';
import { useParams } from 'react-router-dom';
import GiftType from 'types/GiftType';

const MIN_QUANTITY = 1;
const MAX_QUANTITY = 3;
export const BILLET_FEE = 5;
export const PIX_FEE = 5;

type ContextType = {
  addToCart: (item: GiftType) => void;
  removeFromCart: (item: GiftType) => void;
  incrementQuantity: (item: GiftType) => void;
  decrementQuantity: (item: GiftType) => void;
  clearCart: () => void;
  items: GiftType[];
  totalAmount: number;
};

export const CartContext = React.createContext<ContextType>({
  addToCart: () => {},
  removeFromCart: () => {},
  incrementQuantity: () => {},
  decrementQuantity: () => {},
  clearCart: () => {},
  items: [],
  totalAmount: 0,
});

type AuthContextProviderProps = {
  children: React.ReactNode;
};

export default ({ children }: AuthContextProviderProps) => {
  const { contextID } = useParams<any>();
  const [items, setItems] = React.useState<GiftType[]>([]);

  function addToCart(item: GiftType) {
    const newItems = [...items, { ...item, quantity: 1 }];
    localStorage.setItem(contextID, JSON.stringify(newItems));
    setItems(newItems);
  }

  function removeFromCart(item: GiftType) {
    const newItems = items?.filter(
      (prod) => prod.giftCashItemID !== item.giftCashItemID,
    );
    localStorage.setItem(contextID, JSON.stringify(newItems));
    setItems(newItems);
  }

  function updateGiftQuantity({
    giftCashItemID,
    quantity,
  }: {
    giftCashItemID: string;
    quantity: number;
  }) {
    const newItems = items.map((gift) =>
      gift.giftCashItemID === giftCashItemID &&
      quantity >= MIN_QUANTITY &&
      quantity <= MAX_QUANTITY
        ? { ...gift, quantity }
        : gift,
    );
    localStorage.setItem(contextID, JSON.stringify(newItems));
    setItems(newItems);
  }

  function incrementQuantity(item: GiftType) {
    updateGiftQuantity({
      giftCashItemID: item.giftCashItemID,
      quantity: item.quantity + 1,
    });
  }

  function decrementQuantity(item: GiftType) {
    updateGiftQuantity({
      giftCashItemID: item.giftCashItemID,
      quantity: item.quantity - 1,
    });
  }

  function clearCart() {
    localStorage.removeItem(contextID);
    setItems([]);
  }

  React.useEffect(() => {
    const cart = localStorage.getItem(contextID);
    setItems(cart ? JSON.parse(cart) : []);
  }, [contextID]);

  const totalAmount = items.reduce(
    (acc, item) => acc + item.amount * item.quantity,
    0,
  );

  return (
    <CartContext.Provider
      value={{
        items,
        addToCart,
        removeFromCart,
        incrementQuantity,
        decrementQuantity,
        totalAmount,
        clearCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => React.useContext(CartContext);
