import React from 'react';
import { Box } from 'jsxstyle';
import { colors } from 'theme';
import Text from './Text';

type IntentType = 'success' | 'danger' | 'warning' | 'info';

const getStylesByIntent = (intent: IntentType) =>
  ({
    success: {
      color: colors.green600,
      backgroundColor: colors.green100,
      borderColor: colors.green300,
    },
    danger: {
      color: colors.red600,
      backgroundColor: colors.red100,
      borderColor: colors.red300,
    },
    warning: {
      color: colors.orange600,
      backgroundColor: colors.orange100,
      borderColor: colors.orange300,
    },
    info: {
      color: colors.blue600,
      backgroundColor: colors.blue100,
      borderColor: colors.blue300,
    },
  }[intent]);

const getIconByIntent = (intent: IntentType) =>
  ({
    success: SuccessIcon,
    danger: ErrorIcon,
    warning: WarningIcon,
    info: InfoIcon,
  }[intent]);

type AlertType = {
  intent: IntentType;
  message: string;
};
const Alert = ({ intent, message }: AlertType) => {
  const Icon = getIconByIntent(intent);

  return (
    <Box
      position="relative"
      padding="0.75rem 1.25rem"
      marginBottom="1rem"
      border="1px solid"
      borderRadius="0.25rem"
      display="flex"
      alignItems="center"
      flexShrink={0}
      {...getStylesByIntent(intent)}
    >
      <Box flexBasis="4%">
        <Icon size={20} color={getStylesByIntent(intent).color} />
      </Box>
      <Box flexBasis="96%">
        <Text size="sm">{message}</Text>
      </Box>
    </Box>
  );
};

export default Alert;

type IconType = {
  size: number;
  color: string;
};

function ErrorIcon({ size, color }: IconType) {
  return (
    <svg viewBox="0 0 512 512" width={size} fill={color}>
      <path d="M256,0C114.6,0,0,114.6,0,256s114.6,256,256,256s256-114.6,256-256S397.4,0,256,0z M256,384c-17.7,0-32-14.3-32-32  s14.3-32,32-32s32,14.3,32,32S273.7,384,256,384z M272,288h-32l-16-160h64L272,288z" />
    </svg>
  );
}

function InfoIcon({ size, color }: IconType) {
  return (
    <svg viewBox="0 0 512 512" width={size} fill={color}>
      <path d="M437,75C388.7,26.6,324.4,0,256,0C187.6,0,123.3,26.6,75,75C26.6,123.3,0,187.6,0,256c0,68.4,26.6,132.7,75,181  c48.4,48.4,112.6,75,181,75c68.4,0,132.7-26.6,181-75c48.4-48.4,75-112.6,75-181C512,187.6,485.4,123.3,437,75z M288,384  c0,17.7-14.3,32-32,32c-17.7,0-32-14.3-32-32V224c0-17.7,14.3-32,32-32c17.7,0,32,14.3,32,32V384z M256,160c-17.7,0-32-14.3-32-32  c0-17.7,14.3-32,32-32s32,14.3,32,32C288,145.7,273.7,160,256,160z" />
    </svg>
  );
}

function SuccessIcon({ size, color }: IconType) {
  return (
    <svg viewBox="0 0 512 512" width={size} fill={color}>
      <path d="M484.1,139.7c-54.4,62.6-167,190-203.8,233c-5.9,6.8-14.6,11.2-24.3,11.2c-8.8,0-16.8-3.6-22.6-9.4c-45.3-45.3-50.7-50.7-96-96c-5.8-5.8-9.4-13.8-9.4-22.6c0-17.7,14.3-32,32-32c8.8,0,16.8,3.6,22.6,9.4c35.6,35.6,46.6,46.6,71.6,71.6c47-54.8,150.9-171.7,192.5-219.7C399.8,32.9,331.8,0,256,0C114.6,0,0,114.6,0,256s114.6,256,256,256s256-114.6,256-256C512,214.1,501.9,174.6,484.1,139.7z" />
    </svg>
  );
}

function WarningIcon({ size, color }: IconType) {
  return (
    <svg viewBox="0 0 512 512" width={size} fill={color}>
      <path d="M506.3,409.3l-214-353.7c-16.8-30.6-55.8-32.6-72.6,0L5.7,409.3C-8,436.5,5,480,42,480h428C506,480,522.5,436.5,506.3,409.3z M224,392c0-42.5,64-42,64,0C288,433.5,224,434.5,224,392z M288,288c0,42.5-64,40.5-64,0c0-20.4,0-83.6,0-104c0-43,64-43.5,64,0C288,204.4,288,267.6,288,288z" />
    </svg>
  );
}
