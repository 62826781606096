import ScreenContainer from 'components/admin/ScreenContainer';
import ScreenLayout from 'components/admin/ScreenLayout';
import Heading from 'components/Heading';
import SearchInput from 'components/SearchInput';
import Separator from 'components/Separator';
import useMedia from 'hooks/useMedia';
import { Box, Row } from 'jsxstyle';
import React from 'react';
import { colors, mediaQueries, spacingSizes } from 'theme';
import StatementTotalReceived from './StatementTotalReceived';
import StatementFilter, { PeriodFilterType } from './StatementFilter';
import StatementListItem from './StatementListItem';
import ScrollBox from 'components/admin/ScrollBox';
import useSWR from 'swr';
import getPurchasesService from 'services/admin/getPurchasesService';
import { useApplication } from 'contexts/ApplicationContext';
import { parseISO, differenceInDays } from 'date-fns';
import Card from 'components/Card';
import SkeletonPlaceholder from 'components/SkeletonPlaceholder';
import Text from 'components/Text';
import Icon from 'components/Icon';

export type StatementScreenProps = {};

const StatementScreen = () => {
  const [search, setSearch] = React.useState('');
  const [periodFilter, setPeriodFilter] = React.useState<PeriodFilterType>(
    'ALL',
  );
  const { media } = useMedia();
  const { account, wedding } = useApplication();
  const shouldFetch = account.accountID && wedding.giftCashID;

  const { data, error } = useSWR(
    shouldFetch ? ['/purchases', account.accountID, wedding.giftCashID] : null,
    (key, accountID, giftCashID) =>
      getPurchasesService({ accountID, giftCashID }),
  );

  const purchases = data?.filter(
    (purchase) => purchase.status === 'PAID' || purchase.status === 'PAYING',
  );

  const filteredPurchases = React.useMemo(() => {
    if (purchases) {
      let filteredData = purchases.filter((purchase) =>
        String(purchase.buyerName).toLowerCase().includes(search.toLowerCase()),
      );

      if (periodFilter !== 'ALL') {
        filteredData.filter(
          (purchase) =>
            differenceInDays(parseISO(purchase.creationDate), new Date()) <=
            Number(periodFilter),
        );
      }

      return filteredData;
    }

    return [];
  }, [search, purchases, periodFilter]);

  const pending = !data && !error;

  const total =
    (purchases &&
      purchases.reduce((acc, purchase) => acc + purchase.receivedNetAmount, 0)) ||
    0;

  return (
    <ScreenLayout>
      <ScreenContainer backgroundHeight={media === 'desktop' ? 170 : 250}>
        <Row alignItems="center" justifyContent="space-between">
          <Heading size="xs" color={colors.white}>
            Extrato
          </Heading>
          <StatementTotalReceived amount={total} />
        </Row>
        <Separator size={media === 'desktop' ? 'md' : 'lg'} />
        <Box
          mediaQueries={mediaQueries}
          display="flex"
          flexDirection="column"
          //@ts-ignore
          xlFlexDirection="row !important"
          lgFlexDirection="row !important"
          xlJustifyContent="space-between"
          lgJustifyContent="space-between"
          xlAlignItems="center"
          lgAlignItems="center"
        >
          <Box flexBasis={media === 'desktop' && '40%'}>
            <SearchInput
              placeholder="Pesquisar por pessoas"
              value={search}
              onChange={(value) => setSearch(value)}
              minimal
            />
          </Box>
          {media !== 'desktop' && <Separator size="sm" />}
          <Box alignSelf="flex-end">
            <StatementFilter value={periodFilter} onChange={setPeriodFilter} />
          </Box>
        </Box>
        <Separator size={media === 'desktop' ? 'md' : 'lg'} />
        {pending ? (
          <React.Fragment>
            <StatementListItemPlaceholder />
            <Separator size="sm" />
            <StatementListItemPlaceholder />
            <Separator size="sm" />
          </React.Fragment>
        ) : (
          <ScrollBox>
            {filteredPurchases && filteredPurchases.length ? (
              filteredPurchases.map((purchase, index) => (
                <React.Fragment key={String(index)}>
                  <StatementListItem purchase={purchase} />
                  <Separator size="sm" />
                </React.Fragment>
              ))
            ) : (
              <Card flexDirection="row" alignItems="center">
                <Icon name="info" color={colors.gray600} />
                <Separator />
                <Text color={colors.gray600}>Nenhum dado encontrato</Text>
              </Card>
            )}
          </ScrollBox>
        )}
      </ScreenContainer>
    </ScreenLayout>
  );
};

export default StatementScreen;

const StatementListItemPlaceholder = () => {
  const { media } = useMedia();
  return (
    <Card>
      <SkeletonPlaceholder>
        <div
          style={{
            display: 'flex',
            flexDirection: media === 'desktop' ? 'row' : 'column',
            alignItems: media === 'desktop' ? 'center' : 'flex-start',
          }}
        >
          <div style={{ flexBasis: '30%' }}>
            <div style={{ width: 140, height: 18, borderRadius: 4 }} />
            <div
              style={{ width: 200, height: 15, borderRadius: 4, marginTop: 4 }}
            />
          </div>
          <div
            style={{
              flexBasis: '30%',
              marginTop: media === 'phone' ? spacingSizes.sm : 0,
            }}
          >
            <div style={{ width: 140, height: 18, borderRadius: 4 }} />
            <div
              style={{ width: 200, height: 15, borderRadius: 4, marginTop: 4 }}
            />
          </div>
          <div
            style={{
              flexBasis: '20%',
              marginTop: media === 'phone' ? spacingSizes.sm : 0,
            }}
          >
            <div style={{ width: 140, height: 18, borderRadius: 4 }} />
            <div
              style={{ width: 60, height: 18, borderRadius: 4, marginTop: 4 }}
            />
          </div>
          <div
            style={{
              flexBasis: '20%',
              marginTop: media === 'phone' ? spacingSizes.sm : 0,
            }}
          >
            <div style={{ width: 140, height: 18, borderRadius: 4 }} />
            <div
              style={{ width: 80, height: 15, borderRadius: 4, marginTop: 4 }}
            />
          </div>
        </div>
      </SkeletonPlaceholder>
    </Card>
  );
};
