import Button from 'components/Button';
import Heading from 'components/Heading';
import InputGroup from 'components/InputGroup';
import NumberFormat from 'components/NumberFormat';
import Separator from 'components/Separator';
import Text from 'components/Text';
import TextInput from 'components/TextInput';
import { useConfig } from 'contexts/ConfigContext';
import { useProducts } from 'contexts/ProductContext';
import useForm from 'hooks/useForm';
import { Box, Col, Row } from 'jsxstyle';
import React from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { colors, mediaQueries, spacingSizes } from 'theme';
import isRequired from 'validators/isRequired';
import Form from './Form';
import Icon from './Icon';
import { ImageType } from './ImagePicker';
import ImagePickerWithCrop from './ImagePickerWithCrop';
import ProductImage from './ProductImage';

export type GiftFormProps = {
  giftCashItemID?: string;
  name: string;
  value: number;
  iconID?: string;
  productID?: string;
  pictureID: string;
  onSubmit?: (values: any) => void;
  loading?: boolean;
  customImage?: ImageType;
  useCustomPicture?: boolean;
};

const getValueWithInterestRate = (amount: number, rate?: number) => {
  return amount + amount * (rate ? rate / 100 : 0);
};

const GiftForm = ({
  giftCashItemID,
  productID,
  name,
  value,
  iconID,
  pictureID,
  onSubmit,
  loading,
  customImage,
  useCustomPicture,
}: GiftFormProps) => {
  const isEdit = Boolean(productID);
  const { data: config } = useConfig();

  function handleOnSubmit(values: any) {
    onSubmit?.({
      name: values.name,
      useCustomPicture: values.useCustomPicture,
      ...(values.useCustomPicture
        ? {
            customImage: values.image,
          }
        : { pictureID: values.pictureID }),

      value: values.value,
    });
  }

  const { getValue, setValue, setValues, submit, getError, isTouched } =
    useForm({
      initialValues: {
        name,
        value,
        valueWithInterestRate: value,
        iconID,
        pictureID,
        image: customImage,
        useCustomPicture,
      },
      validations: {
        name: [
          isRequired,
          (value: string) =>
            value.length > 60
              ? 'Este campo deve conter no máximo 60 caracteres'
              : undefined,
        ],
        value: [
          isRequired,
          (value: any) =>
            value < 40 ? 'O valor mínimo é de R$ 40,00' : undefined,
        ],
      },
      onSubmit: handleOnSubmit,
    });

  return (
    <Form onSubmit={submit}>
      <Col
        mediaQueries={mediaQueries}
        padding={spacingSizes.md}
        borderTopLeftRadius={5}
        borderTopRightRadius={5}
        backgroundColor={colors.white}
        //@ts-ignore
        xlBorderRadius={5}
        lgBorderRadius={5}
        minHeight={580}
      >
        <Heading color={colors.black}>
          {isEdit ? 'Editar presente' : 'Novo presente'}
        </Heading>
        <Separator />
        <Tabs>
          <TabList>
            <Tab>Detalhes do presente</Tab>
            <Tab>Foto do presente</Tab>
          </TabList>
          <TabPanel>
            <React.Fragment>
              <Separator />
              <InputGroup label="Nome do presente" error={getError('name')}>
                <TextInput
                  value={getValue('name')}
                  onChange={(value) => setValue('name', value)}
                  placeholder="Nome do presente"
                  error={getError('name')}
                />
              </InputGroup>
              <InputGroup label="Valor do presente" error={getError('value')}>
                <TextInput
                  value={NumberFormat({
                    type: 'currency',
                    minimumFractionDigits: 2,
                    amount: Number(getValue('value')),
                  })}
                  type="tel"
                  onChange={(value) => {
                    const parsedValue =
                      parseInt(value.replace(/[^0-9]/g, ''), 10) / 100 || 0;
                    setValues(() => ({
                      value: parsedValue,
                      valueWithInterestRate: getValueWithInterestRate(
                        parsedValue,
                        config?.interestRate,
                      ),
                    }));
                  }}
                  placeholder="Valor do presente"
                  error={getError('value')}
                />
              </InputGroup>
            </React.Fragment>
          </TabPanel>
          <TabPanel style={{ margin: `0 -${spacingSizes.md}px` }}>
            <React.Fragment>
              <Separator />
              <Row padding={`0px ${spacingSizes.md}px`} alignItems="center">
                <Box width={85} height={85}>
                  {getValue('image') ? (
                    <Box
                      height={85}
                      width="100%"
                      component="img"
                      borderRadius={4}
                      objectFit="contain"
                      props={{
                        src: getValue('image.url'),
                        alt: 'Foto do presente',
                      }}
                    />
                  ) : (
                    <ProductImage
                      useCustomPicture={Boolean(getValue('useCustomPicture'))}
                      pictureID={getValue('pictureID')}
                      giftCashItemID={
                        isTouched('pictureID') ? undefined : giftCashItemID
                      }
                      height={85}
                      width="100%"
                    />
                  )}
                </Box>
                <Separator />
                <Col>
                  <Box height="80%">
                    <ImagePickerWithCrop
                      shape="rect"
                      key={getValue('pictureID')}
                      title="Alterar foto do presente"
                      onCropComplete={(image) => {
                        setValues(() => ({
                          image,
                          useCustomPicture: true,
                        }));
                      }}
                      trigger={
                        <Text
                          size="sm"
                          color={colors.secondary500}
                          fontWeight="bold"
                        >
                          Enviar foto
                        </Text>
                      }
                    ></ImagePickerWithCrop>
                  </Box>
                  <Separator size="xs" />
                  <Text size="sm" color={colors.gray200}>
                    ou
                  </Text>
                  <Separator size="xs" />
                  <Text size="sm">Selecione uma abaixo:</Text>
                </Col>
              </Row>
              <Separator />
              <GiftPhotoList
                selectedPictureID={
                  getValue('useCustomPicture')
                    ? undefined
                    : getValue('pictureID')
                }
                onSelect={(pictureID) => {
                  setValues(() => ({
                    pictureID,
                    image: undefined,
                    useCustomPicture: false,
                  }));
                }}
              />
            </React.Fragment>
          </TabPanel>
        </Tabs>
        <Box flex={1} />
        <Box alignSelf="flex-end">
          <Button
            type="submit"
            size="sm"
            color="secondary"
            loading={loading}
            disabled={loading}
          >
            Salvar
          </Button>
        </Box>
      </Col>
    </Form>
  );
};

export default GiftForm;

type GiftPhotoListProps = {
  selectedPictureID?: string;
  onSelect?: (pictureID: string) => void;
};
const GiftPhotoList = ({ selectedPictureID, onSelect }: GiftPhotoListProps) => {
  const { products } = useProducts();

  const memoizedProducts = React.useMemo(
    () => products.sort((a, b) => (a.iconID === selectedPictureID ? -1 : 0)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return (
    <Box
      mediaQueries={mediaQueries}
      display="grid"
      gridTemplateColumns="repeat(2, 1fr)"
      //@ts-ignore
      lgGridTemplateColumns="repeat(4, 1fr)"
      xlGridTemplateColumns="repeat(4, 1fr)"
      gridTemplateRows="repeat(auto-fit, calc(1fr / 4))"
      gap={spacingSizes.xs}
      height={280}
      overflowY="scroll"
      width="100%"
      padding={`0px ${spacingSizes.md}px`}
    >
      {memoizedProducts.map((product, index) => {
        const selected = selectedPictureID === product.pictureID;
        return (
          <Box
            key={String(index)}
            width="100%"
            height="calc(1fr / 4)"
            backgroundColor={colors.gray100}
            borderRadius={12}
            cursor="pointer"
            props={{ onClick: () => onSelect?.(product.pictureID) }}
            position="relative"
          >
            {selected && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                position="absolute"
                left={0}
                right={0}
                top={0}
                bottom={0}
                zIndex={2}
              >
                <Icon
                  name="check"
                  color={colors.white}
                  width={26}
                  height={26}
                />
              </Box>
            )}
            <Box
              width="100%"
              height="calc(1fr / 4)"
              filter={selected ? 'brightness(40%)' : 'none'}
            >
              <ProductImage
                pictureID={product.pictureID}
                height="100%"
                width="100%"
              />
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};
