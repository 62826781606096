import React from 'react';
import { Box } from 'jsxstyle';
import { colors } from 'theme';

export type IconButtonProps = {
  disabled?: boolean;
  onClick?: () => void;
};

const IconButton = ({
  onClick,
  children,
  disabled = false,
}: React.PropsWithChildren<IconButtonProps>) => {
  return (
    <Box
      props={{ onClick, disabled }}
      component="button"
      outline="none"
      display="flex"
      justifyContent="center"
      alignItems="center"
      width={36}
      height={36}
      borderRadius={100}
      backgroundColor={colors.gray50}
      border="4px solid white"
      cursor="pointer"
      activeBackgroundColor={`${colors.gray200} !important`}
      hoverBackgroundColor={colors.gray100}
      transition="all 125ms ease-in-out"
    >
      {children}
    </Box>
  );
};

export default IconButton;
