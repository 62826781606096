import GiftsScreen from 'screens/admin/GiftsScreen';
import GuestsScreen from 'screens/admin/GuestsScreen';
import HomeScreen from 'screens/admin/HomeScreen';
import StatementScreen from 'screens/admin/StatementScreen';
import UserScreen from 'screens/admin/UserScreen';
import Paths from '../../paths';

const paths = Paths.Admin;

export default [
  {
    path: paths.HOME_SCREEN,
    component: HomeScreen,
  },
  {
    path: paths.USER_SCREEN,
    component: UserScreen,
  },
  {
    path: paths.GIFTS_SCREEN,
    component: GiftsScreen,
  },
  {
    path: paths.GUESTS_SCREEN,
    component: GuestsScreen,
  },
  {
    path: paths.STATEMENT_SCREEN,
    component: StatementScreen,
  },
];
