import ScreenContainer from 'components/admin/ScreenContainer';
import ScreenLayout from 'components/admin/ScreenLayout';
import Heading from 'components/Heading';
import SearchInput from 'components/SearchInput';
import Separator from 'components/Separator';
import React from 'react';
import { colors, mediaQueries } from 'theme';
import { Box } from 'jsxstyle';
import GuestListItem from './GuestListItem';
import useMedia from 'hooks/useMedia';
import ScrollBox from 'components/admin/ScrollBox';
import { useApplication } from 'contexts/ApplicationContext';
import useSWR from 'swr';
import getGuestConfirmation from 'services/admin/getGuestConfirmation';
import Card from 'components/Card';
import Icon from 'components/Icon';
import Text from 'components/Text';
import SkeletonPlaceholder from 'components/SkeletonPlaceholder';

export type GuestsScreenProps = {};

const GuestsScreen = () => {
  const { media } = useMedia();
  const { wedding } = useApplication();
  const shouldFetch = wedding.giftCashID;
  const [search, setSearch] = React.useState('');

  const { data, error } = useSWR(
    shouldFetch ? ['/rsvp', wedding.giftCashID] : null,
    (_, weddingID) => getGuestConfirmation({ weddingID }),
  );

  const pending = !data && !error;

  const filteredData = React.useMemo(
    () =>
      data?.filter((guest) =>
        guest.guestName.toLowerCase().includes(search.toLowerCase()),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [search, data?.length],
  );

  const totalAdult = data
    ? data.reduce((acc, guest) => acc + guest.adultsQuantity, 0)
    : 0;
  const totalChildren = data
    ? data.reduce((acc, guest) => acc + guest.childrenQuantity, 0)
    : 0;

  return (
    <ScreenLayout>
      <ScreenContainer backgroundHeight={240}>
        <Heading size="xs" color={colors.white}>
          Lista de Convidados
        </Heading>
        <Separator />
        <Box
          mediaQueries={mediaQueries}
          display="flex"
          flexDirection="column"
          //@ts-ignore
          lgFlexDirection="row"
          xlFlexDirection="row"
          lgJustifyContent="space-between"
          xlJustifyContent="space-between"
          lgAlignItems="center"
          xlAlignItems="center"
        >
          <Box flexBasis={media === 'desktop' && '40%'}>
            <SearchInput
              placeholder="Pesquisar por presentes"
              value={search}
              onChange={(value) => setSearch(value)}
              minimal
            />
          </Box>
          <Separator size="sm" />
          <Box
            alignSelf="flex-start"
            mediaQueries={mediaQueries}
            //@ts-ignore
            lgAlignSelf="flex-end"
            xlAlignSelf="flex-end"
          >
            <Box
              display="flex"
              mediaQueries={mediaQueries}
              //@ts-ignore
              lgFlexDirection="column"
              xlFlexDirection="column"
            >
              <Box
                padding="6px 16px"
                backgroundColor={`${colors.white}1a`}
                borderRadius={5}
                display="flex"
                alignItems="center"
              >
                <Icon name="guests" color={colors.white} />
                <Separator size="sm" />
                <Text color={colors.white} fontWeight="bold" size="sm">
                  Adultos confirmados: {totalAdult}
                </Text>
              </Box>
              <Separator size="sm" />
              <Box
                padding="6px 16px"
                backgroundColor={`${colors.white}1a`}
                borderRadius={5}
                display="flex"
                alignItems="center"
              >
                <Icon name="guests" color={colors.white} />
                <Separator size="sm" />
                <Text color={colors.white} fontWeight="bold" size="sm">
                  Crianças confirmados: {totalChildren}
                </Text>
              </Box>
            </Box>

            {/* <GuestFilter /> */}
          </Box>
        </Box>
        <Separator />

        {pending ? (
          <React.Fragment>
            <GuestListPlaceholder />
            <Separator size="sm" />
            <GuestListPlaceholder />
          </React.Fragment>
        ) : (
          <ScrollBox>
            {filteredData && filteredData.length ? (
              filteredData.map((guest) => (
                <React.Fragment>
                  <GuestListItem guest={guest} />
                  <Separator size="sm" />
                </React.Fragment>
              ))
            ) : (
              <Card flexDirection="row" alignItems="center">
                <Icon name="info" color={colors.gray600} />
                <Separator />
                <Text color={colors.gray600}>Nenhum convidado encontrato</Text>
              </Card>
            )}
          </ScrollBox>
        )}
      </ScreenContainer>
    </ScreenLayout>
  );
};

export default GuestsScreen;

const GuestListPlaceholder = () => {
  return (
    <Card>
      <SkeletonPlaceholder>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ flexBasis: '25%' }}>
            <div style={{ width: 80, height: 15, borderRadius: 4 }} />
            <div
              style={{
                width: 130,
                height: 18,
                marginTop: 4,
                borderRadius: 4,
              }}
            />
          </div>
          <div style={{ flexBasis: '25%' }}>
            <div style={{ width: 120, height: 15, borderRadius: 4 }} />
            <div
              style={{
                width: 100,
                height: 18,
                marginTop: 4,
                borderRadius: 4,
              }}
            />
          </div>
          <div style={{ flexBasis: '25%' }}>
            <div style={{ width: 120, height: 15, borderRadius: 4 }} />
            <div
              style={{
                width: 100,
                height: 18,
                marginTop: 4,
                borderRadius: 4,
              }}
            />
          </div>
        </div>
      </SkeletonPlaceholder>
    </Card>
  );
};
