import React from 'react';
import { Box } from 'jsxstyle';
import { mediaQueries, spacingSizes } from 'theme';
import { useApplication } from 'contexts/ApplicationContext';
import useMedia from 'hooks/useMedia';

export type BannerImageProps = {};

const BannerImage = () => {
  const { wedding } = useApplication();
  const time = React.useMemo(() => new Date().getTime(), []);
  const { media } = useMedia();
  return (
    <Box
      mediaQueries={mediaQueries}
      position="absolute"
      height={media === 'phone' ? '85%' : '80%'}
      left={0}
      right={0}
      marginTop={media === 'phone' ? -spacingSizes.lg : -spacingSizes.xl}
      marginBottom={-spacingSizes.xl}
      //@ts-ignore
      xlBeforeBorderTopLeftRadius={5}
      lgBeforeBorderTopLeftRadius={5}
      xlBeforeBorderTopRightRadius={5}
      lgBeforeBorderTopRightRadius={5}
      //@ts-ignore
      beforeContent="''"
      beforePosition="absolute"
      beforeTop={0}
      beforeRight={0}
      beforeBottom={0}
      beforeLeft={0}
      beforeBackgroundSize="cover"
      beforeBackground={`radial-gradient(circle, transparent 40%, #00000080 75%),
    url(${process.env.REACT_APP_RESOURCES_URL}/banking/giftcash/wedding/${wedding.giftCashID}-banner?q=${time}) no-repeat;`}
      beforeBackgroundPosition="center"
      beforeFilter="brightness(70%);"
    />
  );
};

export default BannerImage;
