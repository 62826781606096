import React from 'react';
import Dropdown from 'components/Dropdown';
import { spacingSizes, colors } from 'theme';
import { Row, Box } from 'jsxstyle';
import Text from 'components/Text';
import Separator from 'components/Separator';
import Icon from 'components/Icon';

export type PeriodFilterType = 'ALL' | '30' | '7';

export type GuestFilterProps = {
  value: PeriodFilterType;
  onChange?: (filter: PeriodFilterType) => void;
};

const options: Array<{ value: PeriodFilterType; label: string }> = [
  { value: 'ALL', label: 'Desde o começo' },
  {
    value: '30',
    label: 'Últimos 30 dias',
  },
  {
    value: '7',
    label: 'Últimos 7 dias',
  },
];

const GuestFilter = ({ value, onChange }: GuestFilterProps) => {
  const [dropdownVisible, setDropdownVisible] = React.useState(false);

  const selectedOption = options.find((opt) => opt.value === value);
  return (
    <Dropdown
      on="click"
      placement="bottomRight"
      visible={dropdownVisible}
      onVisibleChange={(visible) => setDropdownVisible(visible)}
    >
      <Dropdown.Trigger>
        <Row
          padding={`${spacingSizes.xs}px ${spacingSizes.sm}px`}
          height={32}
          backgroundColor={colors.white}
          borderRadius={5}
          width={180}
          alignItems="center"
          justifyContent="space-between"
        >
          <Row alignItems="center">
            <Icon name="calendar" color={colors.secondary500} />
            <Separator size="xs" />
            <Text
              textTransform="uppercase"
              size="xs"
              fontWeight="bolder"
              color={colors.secondary500}
            >
              {selectedOption?.label}
            </Text>
          </Row>

          <Icon name="chevron-down" color={colors.gray100} />
        </Row>
      </Dropdown.Trigger>
      <Dropdown.Menu style={{ boxShadow: '0 2px 20px rgba(0, 0, 0, 0.15)' }}>
        {options.map((option) => (
          <Dropdown.Item
            key={option.value}
            onClick={() => {
              onChange?.(option.value);
              setDropdownVisible(false);
            }}
          >
            <Box minWidth={200} padding={spacingSizes.sm}>
              <Text>{option.label}</Text>
            </Box>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default GuestFilter;
