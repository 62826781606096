const usePartner = () => {
  const { hostname } = window.location;
  const [partnerID] = hostname.split('.');

  if (process.env.REACT_APP_COOKIE_DOMAIN === 'localhost') {
    return '';
  }
  return partnerID;
};

export default usePartner;
