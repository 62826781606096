import React from 'react';
import Heading from 'components/Heading';
import Icon from 'components/Icon';
import DashboardItem from './DashboardItem';
import { colors } from 'theme';
import useSWR from 'swr';
import { useApplication } from 'contexts/ApplicationContext';
import getGiftsAvailableService from 'services/admin/getGiftsAvailableService';
import SkeletonPlaceholder from 'components/SkeletonPlaceholder';
import paths from 'paths';

export type GiftsAvailableProps = {};

const GiftsAvailable = () => {
  const { wedding } = useApplication();
  const shouldFetch = wedding.giftCashID;

  const { data, error } = useSWR(
    shouldFetch ? ['/gifts-available', wedding.giftCashID] : null,
    (_, weddingID) => getGiftsAvailableService({ weddingID }),
  );
  const pending = !data && !error;

  return (
    <DashboardItem
      icon={
        <Icon name="gift" width={28} height={22} color={colors.secondary500} />
      }
      description="Presentes disponíveis"
      helperText="Visualizar presentes"
      link={paths.Admin.GIFTS_SCREEN}
      label={
        pending ? (
          <SkeletonPlaceholder>
            <div style={{ width: 80, height: 32, borderRadius: 5 }} />
          </SkeletonPlaceholder>
        ) : (
          <Heading color={colors.secondary500}>
            {(data && data.length) || 0}
          </Heading>
        )
      }
    />
  );
};

export default GiftsAvailable;
