import React from 'react';
import { Box } from 'jsxstyle';
import Text from 'components/Text';
import Icon from 'components/Icon';
import { colors, spacingSizes, mediaQueries } from 'theme';
import Separator from 'components/Separator';
import useMedia from 'hooks/useMedia';
import { useRouteMatch, Link } from 'react-router-dom';
import paths from 'paths';

const menu = [
  {
    icon: 'home',
    label: 'Principal',
    link: paths.Admin.HOME_SCREEN,
    disabled: false,
  },
  {
    icon: 'hearts',
    label: 'Minha página',
    link: paths.Admin.USER_SCREEN,
    disabled: false,
  },
  {
    icon: 'gift',
    label: 'Presentes',
    link: paths.Admin.GIFTS_SCREEN,
    disabled: false,
  },
  {
    icon: 'guests',
    label: 'Convidados',
    link: paths.Admin.GUESTS_SCREEN,
    disabled: false,
  },
  {
    icon: 'statement',
    label: 'Extrato',
    link: paths.Admin.STATEMENT_SCREEN,
    disabled: false,
  },
];

export type SideBarProps = {};

const SideBar = () => {
  const match = useRouteMatch();

  return (
    <Box
      mediaQueries={mediaQueries}
      display="flex"
      alignItems="center"
      justifyContent="space-around"
      //@ts-ignore
      lgFlexDirection="column"
      xlFlexDirection="column"
      lgJustifyContent="flex-start"
      xlJustifyContent="flex-start"
      lgAlignItems="flex-start"
      xlAlignItems="flex-start"
    >
      {menu.map((section) => {
        const activated = match.path === section.link;

        return (
          <SideBarItem
            disabled={section.disabled}
            key={section.link}
            link={section.link}
            label={section.label}
            icon={
              <Icon
                name={section.icon as any}
                color={
                  activated
                    ? colors.secondary500
                    : section.disabled
                    ? colors.gray200
                    : colors.gray700
                }
              />
            }
          />
        );
      })}
    </Box>
  );
};

export default SideBar;

type SideBarItemProps = {
  icon: ((activated: boolean) => React.ReactNode) | React.ReactNode;
  label: string;
  link: string;
  disabled: boolean;
};
const SideBarItem = ({ icon, label, link, disabled }: SideBarItemProps) => {
  const { media } = useMedia();
  const match = useRouteMatch();
  const activated = match.path === link;
  return (
    <Box
      props={{ to: disabled ? '#' : link }}
      component={Link}
      mediaQueries={mediaQueries}
      display="flex"
      borderRadius={100}
      width={48}
      height={48}
      justifyContent="center"
      alignItems="center"
      backgroundColor={activated ? `${colors.secondary500}1a` : 'none'}
      //@ts-ignore
      xlWidth="100%"
      lgWidth="100%"
      xlHeight="auto"
      lgHeight="auto"
      lgPadding={`${spacingSizes.sm}px ${spacingSizes.lg}px`}
      xlPadding={`${spacingSizes.sm}px ${spacingSizes.lg}px`}
      lgJustifyContent="flex-start"
      xlJustifyContent="flex-start"
      lgBorderTopLeftRadius={0}
      lgBorderBottomLeftRadius={0}
      xlBorderTopLeftRadius={0}
      xlBorderBottomLeftRadius={0}
      transition="all 250ms"
      {...(disabled
        ? {}
        : {
            xlHoverBackgroundColor: !activated && `${colors.gray100}bf`,
            lgHoverBackgroundColor: !activated && `${colors.gray100}bf`,
          })}
      cursor={disabled ? 'not-allowed' : 'pointer'}
      position="relative"
    >
      {icon}
      {media === 'desktop' && <Separator />}
      <Box
        mediaQueries={mediaQueries}
        display="none"
        //@ts-ignore
        xlDisplay="block"
        lgDisplay="block"
      >
        <Text
          color={
            activated
              ? colors.secondary500
              : disabled
              ? colors.gray200
              : colors.gray600
          }
          fontWeight={activated ? 'bold' : 'lighter'}
        >
          {label}
        </Text>
      </Box>
    </Box>
  );
};
