import React from 'react';
import { useCart } from 'contexts/CartContext';
import GiftType from 'types/GiftType';
import Card from 'components/Card';
import GiftInfo from './GiftInfo';
import Separator from 'components/Separator';

type RenderItemProps = { item: GiftType; index: number };

export type GiftListProps = {
  renderItem?: ({ item, index }: RenderItemProps) => React.ReactNode;
};

const GiftList = ({ renderItem }: GiftListProps) => {
  const { items } = useCart();

  const renderDefaultItem = ({ item, index }: RenderItemProps) => (
    <Card>
      <GiftInfo
        key={item.giftCashItemID}
        name={item.name}
        pictureID={item.pictureID}
        amount={item.amount}
        giftCashItemID={item.giftCashItemID}
        useCustomPicture={item.useCustomPicture}
      />
    </Card>
  );

  return (
    <React.Fragment>
      {items.map((gift, index) => (
        <React.Fragment key={gift.giftCashItemID}>
          {(renderItem ? renderItem : renderDefaultItem)({ item: gift, index })}
          <Separator />
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

export default GiftList;
