///v1/users/89/accounts/80/giftcashes/weddings/c0486b3b-82e6-4431-ac06-313f6d5c7035/pictures?pictureType=banner

import privateApi from '../privateApi';

type Params = {
  accountID: string | number;
  giftCashID: string;
  type: 'banner' | 'thumb';
  image: File;
};

// const geImageType = (file: File) => {
//   let extension = 'jpeg',
//     _;

//   try {
//     [_, extension] = file.match(/\.(\w+)$/);
//   } catch {
//     extension = 'jpeg';
//   }

//   return `image/${extension}`;
// };

export default async ({ accountID, giftCashID, type, image }: Params) => {
  const formData = new FormData();

  formData.append('image', image);

  try {
    const { data } = await privateApi.post(
      `/accounts/${accountID}/giftcashes/weddings/${giftCashID}/pictures`,
      formData,
      {
        params: {
          pictureType: type,
        },
      },
    );
    return data;
  } catch (error) {
    throw error;
  }
};
