import React from 'react';
import { Col } from 'jsxstyle';
import Text from './Text';
import { colors } from 'theme';
import Separator from './Separator';

export type InputGroupProps = {
  children: React.ReactNode;
  label?: string;
  error?: string;
};

const InputGroup = ({ children, label, error = '' }: InputGroupProps) => (
  <Col flex={1}>
    {Boolean(label) && (
      <React.Fragment>
        <Text color={error ? colors.red500 : colors.gray500}>{label}</Text>
        <Separator size="xs" />
      </React.Fragment>
    )}
    {children}
    <Col height={28} justifyContent="center">
      <Text size="sm" color={colors.red500}>
        {error}
      </Text>
    </Col>
  </Col>
);

export default InputGroup;
