import React from 'react';
import { Row, Box } from 'jsxstyle';
import ChevronLeft from 'assets/icons/exported/ChevronLeft';
import Separator from 'components/Separator';
import Text from 'components/Text';
import { colors } from 'theme';
import { Link } from 'react-router-dom';

export type BackLinkProps = {
  label: string;
  to: string;
};

const BackLink = ({ label, to }: BackLinkProps) => (
  <Link to={to}>
    <Row alignItems="center">
      <Box
        display="flex"
        width={24}
        height={24}
        border={`1px solid ${colors.gray200}`}
        borderRadius={5}
        justifyContent="center"
        alignItems="center"
      >
        <ChevronLeft />
      </Box>
      <Separator size="sm" />
      <Text
        size="xs"
        fontWeight="bold"
        textTransform="uppercase"
        color={colors.gray600}
      >
        {label}
      </Text>
    </Row>
  </Link>
);

export default BackLink;
