import Button from 'components/Button';
import Icon from 'components/Icon';
import Separator from 'components/Separator';
import Text from 'components/Text';
import { useCart } from 'contexts/CartContext';
import { Box, Row } from 'jsxstyle';
import Paths from 'paths';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { colors, mediaQueries } from 'theme';

export type CartButtonProps = {};

const CartButton = () => {
  const { items } = useCart();
  const { contextID } = useParams<any>();
  const history = useHistory();

  const isCheckoutScreen = history.location.pathname.includes('/checkout');

  return (
    <Button
      style={{ maxHeight: 40 }}
      color="secondary"
      onClick={() => {
        if (!items.length) {
          return;
        }

        if (isCheckoutScreen) {
          history.push(
            Paths.BRIDE_AND_GROOM_SCREEN.replace(':contextID', contextID!),
          );
          return;
        }

        history.push(
          Paths.Platform.PERSONAL_DATA_SCREEN.replace(':contextID', contextID!),
        );
      }}
    >
      <Row alignItems="center">
        <Box position="relative">
          {Boolean(items.length) && (
            <Box
              width={12}
              height={12}
              backgroundColor={colors.tertiary500}
              borderRadius={100}
              position="absolute"
              top={-4}
              right={-7}
              display="flex"
              justifyContent="center"
              alignItems="center"
              fontSize={9}
              color={colors.white}
            >
              {items.length}
            </Box>
          )}

          <Icon name="cart" />
        </Box>
        <Row
          alignItems="center"
          mediaQueries={mediaQueries}
          //@ts-ignore
          xsDisplay="none"
          smDisplay="none"
        >
          <Separator size="sm" />
          <Text
            size="sm"
            color={colors.white}
            textTransform="uppercase"
            fontWeight="bold"
          >
            {isCheckoutScreen ? 'Continuar Comprando' : 'Meu carrinho'}
          </Text>
        </Row>
      </Row>
    </Button>
  );
};

export default CartButton;
