import React from 'react';
import { TextAlignProperty } from 'csstype';
import { Box } from 'jsxstyle';
import { colors, spacingSizes } from 'theme';
import { SizeTypes } from 'typings';

type InputSizeType = Exclude<SizeTypes, 'xs' | 'xl'>;

type TextAreaProps = {
  error?: string | boolean;
  textAlign?: TextAlignProperty;
  value?: string;
  onChange?: (text: string) => void;
  placeholder: string;
  maxLength?: number;
};

const TextArea = React.forwardRef(
  (
    {
      placeholder,
      onChange,
      error,
      textAlign = 'unset',
      value,
      maxLength = 500,
    }: TextAreaProps,
    ref: any,
  ) => (
    <Box
      component="textarea"
      border="1px solid #dbdbdb"
      borderRadius={6}
      props={{
        maxLength,
        placeholder,
        value,
        onChange: (event) => {
          if (onChange) {
            onChange(event.target.value);
          }
        },
        ref,
      }}
      padding={spacingSizes.md}
      outline="none"
      boxShadow={error && '0 0 0 0.25rem #dc354540'}
      paddingTop={spacingSizes.md}
      //@ts-ignore
      focusBoxShadow={!error && `0 0 0 0.2rem ${colors.primary50}`}
      borderColor={error && colors.red500}
      //@ts-ignore
      focusBorderColor={!error && colors.primary300}
      transition="all 0.2s ease"
      placeholderColor={colors.gray200}
      textAlign={textAlign}
      height={106}
      resize="none"
    />
    // <Col position="relative" justifyContent="center" alignSelf="stretch">
    //   {leftIcon && (
    //     <Box position="absolute" left={12}>
    //       {leftIcon}
    //     </Box>
    //   )}
    //   <Box
    //     component="input"
    //     border="1px solid #dbdbdb"
    //     paddingLeft={leftIcon ? spacingSizes.lg : spacingSizes.xs}
    //     paddingRight={rightIcon ? spacingSizes.lg : spacingSizes.xs}
    //     borderRadius={6}
    //     outline="none"
    //     background="transparent"
    //     width="100%"
    //     fontSize={getFontSizeBySize(size)}
    //     boxShadow={error && '0 0 0 0.25rem #dc354540'}
    //     //@ts-ignore
    //     focusBoxShadow={!error && `0 0 0 0.2rem ${colors.primary50}`}
    //     borderColor={error && colors.red500}
    //     focusBorderColor={!error && colors.primary300}
    //     transition="all 0.2s ease"
    //     disabledOpacity={0.7}
    //     props={{ placeholder, onChange, type, name, ...rest }}
    //     WebkitAppearance="none"
    //     cursor="text"
    //     height={getHeightBySize(size)}
    //     textAlign={textAlign}
    //     placeholderColor={colors.gray200}
    //   />
    //   {rightIcon && (
    //     <Box position="absolute" right={12}>
    //       {rightIcon}
    //     </Box>
    //   )}
    // </Col>
  ),
);

export default TextArea;
