import React from 'react';
import { SizeTypes } from 'typings';

export type ProductImageProps = {
  pictureID?: string;
  giftCashItemID?: string;
  size?: SizeTypes;
  width?: number | string;
  height?: number | string;
  useCustomPicture?: boolean;
};

const ProductImage = ({
  giftCashItemID,
  pictureID,
  size = 'md',
  width,
  height,
  useCustomPicture,
}: ProductImageProps) => {
  const isCustomSize = width || height;

  const getSize = () =>
    ({
      xs: 24,
      sm: 32,
      md: 42,
      lg: 52,
      xl: 64,
    }[size]);

  return (
    <img
      src={
        useCustomPicture
          ? `${process.env.REACT_APP_RESOURCES_URL}/banking/giftcash/wedding/items/${giftCashItemID}`
          : `${process.env.REACT_APP_RESOURCES_URL}/banking/giftcash/wedding/items/${pictureID}`
      }
      alt="Foto do presente"
      width={isCustomSize ? width : getSize()}
      height={isCustomSize ? height : getSize()}
      style={
        useCustomPicture
          ? { borderRadius: 5, objectFit: 'cover', overflow: 'hidden' }
          : { objectFit: 'contain' }
      }
      onError={() => {}}
    />
  );
};

export default ProductImage;
