import publicApi from '../publicApi';
import ZipCodeType from 'types/ZipCodeType';

type Params = {
  zipCode: string;
};
export default async ({ zipCode }: Params): Promise<ZipCodeType> => {
  try {
    const { data } = await publicApi.get('/address', {
      params: { zipCode },
      baseURL: process.env.REACT_APP_BANKING_API_URL,
    });
    return data;
  } catch (error) {
    throw error;
  }
};
