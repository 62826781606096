import publicApi from '../publicApi';

type Params = {
  weddingID: string;
};
export default async ({ weddingID }: Params): Promise<[]> => {
  try {
    const { data } = await publicApi.get(
      `/giftcashes/weddings/${weddingID}/items`,
    );
    return data;
  } catch (error) {
    throw error;
  }
};
