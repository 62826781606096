import React from 'react';
//@ts-ignore
import InputNumber from 'rc-input-number';
import { CSSProperties } from 'jsxstyle';
import { colors } from 'theme';
import './ReviewNumberInput.css';

export type ReviewTextInputProps = {
  value: string | undefined;
  onChange?: (value: string) => void;
  textAlign?: CSSProperties['textAlign'];
  min?: number | undefined;
  max?: number | undefined;
};

const ReviewTextInput = ({
  value,
  onChange,
  min = undefined,
  max = undefined,
}: ReviewTextInputProps) => (
  <InputNumber
    prefixCls="review-input-number"
    //@ts-ignore
    style={
      {
        border: 'none',
        outline: 'none',
        background: 'transparent',
        width: '100% !important',
        borderRadius: 0,
        transition: 'all 0.2s ease',
        fontSize: 16,
        fontWeight: 'bold',
        color: `${colors.tertiary500} !important`,
      } as CSSProperties
    }
    onChange={(value: string) => onChange!(value)}
    value={value}
    //@ts-ignore
    min={min}
    //@ts-ignore
    max={max}
    upHandler={<></>}
    downHandler={<></>}
  />
);

export default ReviewTextInput;
