import React from 'react';
import { Col, Box } from 'jsxstyle';
import { mediaQueries, colors, spacingSizes } from 'theme';
import Separator from '../Separator';

export type CheckoutSidebarProps = {};

const CheckoutSidebar = ({
  children,
}: React.PropsWithChildren<CheckoutSidebarProps>) => (
  <React.Fragment>
    <Box
      mediaQueries={mediaQueries}
      display="block"
      //@ts-ignore
      xlDisplay="none"
      lgDisplay="none"
    >
      <Separator />
    </Box>
    <Col
      mediaQueries={mediaQueries}
      backgroundColor={`${colors.white}80`}
      padding={spacingSizes.md}
      //@ts-ignore
      lgRight={80}
      width="100%"
      xlRight="16vw"
      xlDisplay="flex"
      lgDisplay="flex"
      xlPosition="fixed"
      lgPosition="fixed"
      xlTop={80}
      lgTop={80}
      xlMaxWidth={380}
      lgMaxWidth={380}
      xlBottom={0}
      lgBottom={0}
      overflow="auto"
    >
      {children}
    </Col>
  </React.Fragment>
);

export default CheckoutSidebar;
