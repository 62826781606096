import { Box } from 'jsxstyle';
import { CSSProperties } from 'jsxstyle-utils';
import React from 'react';
import { colors } from 'theme';
import { Context } from './provider';

export type OptionProps = {
  children: React.ReactNode | React.ReactNodeArray;
  value: any;
};

function Option({ children, value: optionValue }: OptionProps) {
  const { props, activeIndex, options, setState, onChange } =
    React.useContext(Context);
  const currentIndex = options.findIndex((option) => option === optionValue);
  const selected = props?.value === optionValue;
  const active = currentIndex === activeIndex;

  return (
    <Box
      component="span"
      {...styles}
      {...(selected ? selectedStyles : {})}
      {...(!selected && active ? activeStyles : {})}
      {...(!selected && !active ? defaultStyles : {})}
      props={{
        onClick: () => onChange(optionValue),
        onMouseEnter: () =>
          setState((state) => ({ ...state, activeIndex: currentIndex })),
      }}
    >
      {children}
    </Box>
  );
}

export default Option;

const styles: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '2rem',
  padding: '0.25em 0.5em',
};

const defaultStyles: CSSProperties = {
  color: colors.black,
};

const activeStyles: CSSProperties = {
  color: colors.black,
  backgroundColor: colors.primary50,
  cursor: 'pointer',
};

const selectedStyles: CSSProperties = {
  fontWeight: 600,
  color: colors.primary900,
  backgroundColor: colors.primary100,
  cursor: 'default',
  userSelect: 'none',
};
