import Button from 'components/Button';
import GiftForm from 'components/GiftForm';
import Icon from 'components/Icon';
import { ImageType } from 'components/ImagePicker';
import Modal from 'components/Modal';
import NumberFormat from 'components/NumberFormat';
import Separator from 'components/Separator';
import Text from 'components/Text';
import TextInput from 'components/TextInput';
import { useProducts } from 'contexts/ProductContext';
import useMedia from 'hooks/useMedia';
import { Box, Col, Row } from 'jsxstyle';
import pickBy from 'lodash/pickBy';
import React from 'react';
import ReactLoading from 'react-loading';
//@ts-ignore
import { animateScroll } from 'react-scroll';
import { WeddingItemType } from 'services/platform/getGiftListService';
import { colors, mediaQueries, spacingSizes } from 'theme';
import ProductType from 'types/ProductType';
import GiftBox from './GiftBox';

type LocalProductType = {
  _localID: string;
  _selected: boolean;
  iconID: string;
  name: string;
  value: number;
  customImage?: ImageType;
} & ProductType &
  WeddingItemType;

type WeddingProductsFormProps = {
  onSubmit: (values: ProductType[]) => void;
  pending?: boolean;
  initialSelectedProducts?: Array<{ amount: number } & ProductType>;
};

const DEFAULT_GIFT_VALUE = 100;
const WeddingProductsForm = ({
  onSubmit,
  pending = false,
  initialSelectedProducts = [],
}: WeddingProductsFormProps) => {
  const [showNewGiftFormModal, setShowNewGiftFormModal] = React.useState(false);
  const [products, setProducts] = React.useState<LocalProductType[]>([]);
  const [filter, setFilter] = React.useState<string>('');
  const [error, setError] = React.useState<string>('');

  const { media } = useMedia();
  const { products: data = [] } = useProducts();
  const productsIsPending = data === undefined;

  function handleProductAdd(addedProduct: LocalProductType) {
    const updatedProducts = [...products, { ...addedProduct, _selected: true }];
    setProducts(updatedProducts);
  }

  function handleProductChange(product: LocalProductType) {
    const updatedProducts = products.map((savedProduct) =>
      savedProduct._localID === product._localID ? product : savedProduct,
    );
    setProducts(updatedProducts);
  }

  function handleSelect(product: LocalProductType) {
    const alreadySelected = product._selected;

    const updatedProducts = products!.map((savedProduct) =>
      savedProduct._localID === product._localID
        ? { ...product, _selected: alreadySelected ? false : true }
        : savedProduct,
    );
    if (error) {
      setError('');
    }
    setProducts(updatedProducts);
  }

  React.useEffect(() => {
    const availableProducts = data.filter(
      (product) =>
        !initialSelectedProducts.some(
          (selectedProduct) => selectedProduct.productID === product.productID,
        ),
    );

    setProducts(
      availableProducts.map(
        (product) =>
          ({
            _localID: product.productID,
            _selected: false,
            value: DEFAULT_GIFT_VALUE,
            ...product,
          } as LocalProductType),
      ),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.length]);

  const filteredProducts = products.filter((product) =>
    product.name.toLowerCase().includes(filter.toLowerCase()),
  );
  const selectedProducts = products.filter((product) => product._selected);
  const hasSelectedProducts = selectedProducts.length > 0;
  const totalSelected = selectedProducts.reduce(
    (acc, product) => acc + product.value,
    0,
  );
  const totalSelectedAsCurrency = NumberFormat({
    minimumFractionDigits: 2,
    amount: totalSelected,
    type: 'currency',
  });

  return (
    <Col height="90vh" WebkitFlex="1 0 auto" msFlex="1 0 auto" flex="1 0 auto">
      {productsIsPending ? (
        <Col flex={1} justifyContent="center" alignItems="center">
          <ReactLoading
            type="spin"
            color={colors.gray500}
            height={42}
            width={42}
          />
        </Col>
      ) : (
        <Col>
          <Row
            justifyContent="space-between"
            alignItems="center"
            padding={spacingSizes.md}
            position="-webkit-sticky"
            //@ts-ignore
            // eslint-disable-next-line react/jsx-no-duplicate-props
            position="sticky"
            top={0}
            borderBottom="3px solid"
            borderColor={colors.gray100}
            zIndex={1}
            backgroundColor={colors.white}
          >
            <Text>
              {hasSelectedProducts
                ? `${selectedProducts.length} ${
                    selectedProducts.length > 1
                      ? 'itens selecionados'
                      : 'item selecionado'
                  } - Total: ${totalSelectedAsCurrency}`
                : 'Selecione os presentes'}
            </Text>
            <Box flexBasis="35%">
              <TextInput
                size="sm"
                placeholder="Pesquisar por presentes"
                leftIcon={<Icon name="search" />}
                value={filter}
                onChange={(text) => {
                  setFilter(text);
                  animateScroll.scrollToTop({
                    duration: 175,
                    containerId: 'animated-modal-container',
                  });
                }}
              />
            </Box>
          </Row>

          <Col WebkitFlex="1 0 auto" msFlex="1 0 auto" flex="1 0 auto">
            <Box
              display="grid"
              gridTemplateColumns="repeat(auto-fit, 300px)"
              justifyContent={
                filteredProducts.length === 0 || filteredProducts.length >= 3
                  ? 'center'
                  : 'flex-start'
              }
              gridGap={spacingSizes.md}
              padding={
                filteredProducts.length < 3
                  ? spacingSizes.md
                  : `${spacingSizes.md}px 0`
              }
            >
              {filteredProducts.map((product, index) => (
                <GiftBox
                  key={product._localID}
                  productID={product.productID}
                  name={product.name}
                  value={product.value}
                  active={product._selected}
                  pictureID={product.pictureID}
                  customImage={product.customImage}
                  onChange={({ pictureID, customImage, name, value }) => {
                    handleProductChange(
                      pickBy({
                        ...product,
                        pictureID,
                        value,
                        name,
                        //prevent to override an already selected picture with undefined or null
                        ...pickBy({
                          customImage,
                        }),
                      }) as LocalProductType,
                    );
                  }}
                  onSelect={() => {
                    handleSelect(product);
                  }}
                />
              ))}
              <Box
                props={{ onClick: () => setShowNewGiftFormModal(true) }}
                display="flex"
                alignItems="center"
                justifyContent="center"
                height={88}
                cursor="pointer"
                transition="all 250ms ease-in-out"
                hoverBackgroundColor={colors.gray100}
                border={`2px dashed ${colors.gray300}`}
                borderRadius={6}
              >
                <Icon name="plus" color={colors.gray400} />
              </Box>
            </Box>
          </Col>
          {filteredProducts.length === 0 && (
            <Col
              alignItems="center"
              justifyContent="center"
              padding={spacingSizes.md}
            >
              <Text color={colors.gray500}>
                {filter
                  ? `Nenhum produto encontrado para "${filter}".`
                  : 'Clique na area marcada acima para criar um novo presente.'}
              </Text>
            </Col>
          )}
          <Separator size="sm" />
          <Box width={media === 'desktop' ? 498 : '85%'} alignSelf="center">
            <Box textAlign="center" height={24}>
              {error && (
                <Text size="sm" color={colors.red500}>
                  {error}
                </Text>
              )}
            </Box>
            <Separator />
            {filteredProducts.length ? (
              <Button
                type="submit"
                loading={pending}
                color="secondary"
                style={{ width: '100%' }}
                disabled={selectedProducts.length === 0}
                onClick={() => {
                  if (selectedProducts.length) {
                    onSubmit(selectedProducts);
                    return;
                  }

                  setError('Selecione pelo menos um item da lista');
                }}
              >
                <Text
                  color={colors.white}
                  fontWeight="bold"
                  textTransform="uppercase"
                >
                  Adicionar {selectedProducts.length} à lista
                </Text>
              </Button>
            ) : null}
            <Separator size="xl" />
          </Box>
        </Col>
      )}

      <Modal
        visible={showNewGiftFormModal}
        onRequestClose={() => setShowNewGiftFormModal(false)}
      >
        <Box
          mediaQueries={mediaQueries}
          //@ts-ignore
          xlWidth={768}
          lgWidth={768}
          lgHeight="auto"
          xlHeight="auto"
        >
          <GiftForm
            name=""
            value={0}
            pictureID={data[0]?.pictureID || ''}
            onSubmit={({ value, name, pictureID, customImage } = {}) => {
              setShowNewGiftFormModal(false);
              handleProductAdd({
                _localID: new Date().getTime().toString(),
                value,
                name,
                pictureID,
                customImage,
              } as LocalProductType);
            }}
          />
        </Box>
      </Modal>
    </Col>
  );
};

export default WeddingProductsForm;
