import CheckingAccountType from 'types/CheckingAccountType';
import privateApi from './privateApi';

export default async (): Promise<CheckingAccountType[]> => {
  try {
    const { data } = await privateApi.get('/accounts/checking', {
      baseURL: process.env.REACT_APP_ACCOUNT_API_URL,
    });

    return data;
  } catch (error) {
    throw error;
  }
};
