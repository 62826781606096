import getBilletPDFService from 'services/platform/getBilletPDFService';
import useSWR from 'swr';

export type DownloadBilletProps = {
  accountID?: string;
  userID?: string;
  giftCasheID?: string;
  purchaseID?: string;
  installmentNumber?: number;
  children: ({ generatePDF }: { generatePDF: () => void }) => JSX.Element;
};

const DownloadBillet = ({
  accountID,
  userID,
  giftCasheID,
  purchaseID,
  installmentNumber,
  children,
}: DownloadBilletProps) => {
  const shouldFetch = Boolean(accountID && userID && giftCasheID && purchaseID);

  const saveFile = (binaryData: string) => {
    const link = document.createElement('a');
    link.download = 'boleto.pdf';
    link.href = 'data:application/octet-stream;base64,' + binaryData;
    link.click();
  };

  const { data } = useSWR(
    shouldFetch
      ? [
          '/billet/pdf',
          accountID,
          userID,
          giftCasheID,
          purchaseID,
          installmentNumber,
        ]
      : null,
    (_, account, user, giftCache, purchase, inst) =>
      getBilletPDFService({
        userID: user,
        accountID: account,
        giftCasheID: giftCache,
        purchaseID: purchase,
        installment: inst,
      }),
    {
      revalidateOnFocus: false,
    },
  );

  const generatePDF = () => {
    if (data === undefined) {
      return;
    }
    saveFile(data);
  };

  return children({ generatePDF });
};

export default DownloadBillet;
