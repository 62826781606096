/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useMachine } from '@xstate/react';
import fetchMachine from '../statecharts/fetchMachine';

interface UseAsyncInterface {
  promiseFn: Function;
  onData?: (data: any, params: any) => void;
  onError?: (data: any, params: any) => void;
}

export default function <P>({
  promiseFn = () => {
    throw new Error('useAsync expects a promiseFn');
  },
  onData = () => {},
  onError = () => {},
}: UseAsyncInterface) {
  const [current, send] = useMachine(fetchMachine, {
    context: { promiseFn },
  } as any);

  const { data, error, params } = current.context;

  React.useEffect(() => {
    if (current.matches('failure')) {
      onError(error, params);
    }
  }, [current.value]);

  React.useEffect(() => {
    if (current.matches('success')) {
      onData(data, params);
    }
  }, [current.value]);

  const fetch = React.useCallback(
    (args?: P) => send({ type: 'FETCH', params: args } as any),
    [],
  );

  return { pending: current.matches('fetching'), data, error, fetch };
}
