const PLATFORM_PATH = '';
const ONBOARDING_PATH = '/onboarding';
const ADMIN_PATH = '/admin';

export default {
  BRIDE_AND_GROOM_SCREEN: '/:contextID',
  Authentication: {
    SIGN_IN: '/entrar',
  },
  Platform: {
    SEARCH_SCREEN: PLATFORM_PATH + '/encontrar',
    CHECKOUT_SCREEN: PLATFORM_PATH + '/:contextID/checkout',
    PERSONAL_DATA_SCREEN: PLATFORM_PATH + '/:contextID/checkout/dados-pessoais',
    BILLET_SCREEN: PLATFORM_PATH + '/:contextID/boleto/:purchaseID',
    PURCHASE_DETAIL_SCREEN: PLATFORM_PATH + '/compras/:purchaseID',
    PAYMENT_CONFIRMATION_SCREEN:
      PLATFORM_PATH + '/:contextID/confirmacao-de-pagamento',
    RSVP_SCREEN: PLATFORM_PATH + '/:contextID/confirmar-presenca',
  },
  Onboarding: {
    SIGN_IN: ONBOARDING_PATH,
    GETTING_STARTED_SCREEN: ONBOARDING_PATH + '/getting-started',
    CHAT_SCREEN: ONBOARDING_PATH + '/chat',
    SUCCESS_SCREEN: ONBOARDING_PATH + '/success',
  },
  Admin: {
    HOME_SCREEN: ADMIN_PATH,
    USER_SCREEN: ADMIN_PATH + '/minha-pagina',
    GIFTS_SCREEN: ADMIN_PATH + '/presentes',
    GUESTS_SCREEN: ADMIN_PATH + '/convidados',
    STATEMENT_SCREEN: ADMIN_PATH + '/extrato',
  },
};
