import usePartner from 'hooks/usePartner';
import React from 'react';
import { useParams } from 'react-router-dom';
import getWeddingsService from 'services/platform/getWeddingsService';
import useSWR from 'swr';
import WeddingType from 'types/WeddingType';

type ContextType = {
  wedding: WeddingType;
  pending: boolean;
};

export const WeddingContext = React.createContext<ContextType>({
  wedding: {} as WeddingType,
  pending: true,
});

type AuthContextProviderProps = {
  children: React.ReactNode;
};

export default ({ children }: AuthContextProviderProps) => {
  const [wedding, setWedding] = React.useState<WeddingType>();

  const { contextID } = useParams<any>();
  const partnerID = usePartner();

  const { data: weddings, error } = useSWR(
    contextID ? ['/wedding', contextID] : null,
    (key, id) => getWeddingsService({ contextID: id, partnerID }),
  );

  const pending = !weddings && !error;

  React.useEffect(() => {
    if (weddings?.length) {
      setWedding(weddings[0]);
    }
  }, [weddings]);

  return (
    <WeddingContext.Provider value={{ wedding, pending } as any}>
      {children}
    </WeddingContext.Provider>
  );
};

export const useWedding = () => React.useContext(WeddingContext);
