import publicApi from '../publicApi';
import WeddingType from 'types/WeddingType';
import { pickBy } from 'lodash';

type Params = {
  name?: string;
  userID?: string | number;
  contextID?: string;
  partnerID?: string;
};
export default async (params?: Params): Promise<WeddingType[]> => {
  try {
    const { data } = await publicApi.get('/giftcashes/weddings', {
      params: pickBy({
        name: params?.name,
        userID: params?.userID,
        contextID: params?.contextID,
        partnerID: params?.partnerID,
      }),
    });
    return data;
  } catch (error) {
    throw error;
  }
};
