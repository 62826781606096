import React, { Children, isValidElement } from 'react';
import Separator from 'components/Separator';
import { SizeTypes } from 'typings';

export interface InBetweenSpacingProps {
  children: React.ReactNode | React.ReactNode[];
  size?: SizeTypes;
}

const InBetweenSpacing = ({ size, children }: InBetweenSpacingProps) => {
  const filteredChildren = Children.toArray(children).filter(isValidElement);
  return (
    <>
      {Children.map(filteredChildren, (child, index) =>
        index === filteredChildren.length - 1
          ? child
          : [child, <Separator size={size} />],
      )}
    </>
  );
};

InBetweenSpacing.defaultProps = {
  size: 'md',
} as Partial<InBetweenSpacingProps>;

export default InBetweenSpacing;
