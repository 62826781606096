import couplePlaceholder from 'assets/images/couple-background.png';
import BrideAndGroomNames from 'components/BrideAndGroomNames';
import Button from 'components/Button';
import Container from 'components/Container';
import CoupleThumbImage from 'components/CoupleThumbImage';
import Divider from 'components/Divider';
import FadeIn from 'components/FadeIn';
import Heading from 'components/Heading';
import Icon from 'components/Icon';
import Logo from 'components/Logo';
import CheckoutHeader from 'components/platform/CheckoutHeader';
import RadioInput from 'components/RadioInput';
import SearchInput from 'components/SearchInput';
import Separator from 'components/Separator';
import SkeletonPlaceholder from 'components/SkeletonPlaceholder';
import Text from 'components/Text';
import WeddingDate from 'components/WeddingDate';
import { useCart } from 'contexts/CartContext';
import { useWedding } from 'contexts/WeddingContext';
import { parseISO } from 'date-fns';
import { Box, Col, Row } from 'jsxstyle';
import { orderBy } from 'lodash';
import Paths from 'paths';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import getGiftListService from 'services/platform/getGiftListService';
import useSWR from 'swr';
import { colors, mediaQueries, spacingSizes } from 'theme';
import GiftType from 'types/GiftType';
import Wave from '../SearchScreen/Wave';
import GiftListItem from './GiftListItem';
import './styles.css';

export type BrideAndGroomScreenProps = {};

const BrideAndGroomScreen = () => {
  const { contextID } = useParams<any>();
  const history = useHistory();
  const [sort, setSort] = React.useState<{
    amount: 'asc' | 'desc';
    name: 'asc' | 'desc';
  }>({
    amount: 'asc',
    name: 'asc',
  });
  const [searchValue, setSearchValue] = React.useState('');
  const { items: cartItems } = useCart();
  const [bannerUrl, setBannerURL] = React.useState(couplePlaceholder);
  const { wedding } = useWedding();

  const { data = [] } = useSWR(
    wedding ? ['/wedding/items', wedding.giftCashID] : null,
    (url, id) => getGiftListService({ giftCashID: id }),
  );
  const loading = wedding === undefined;

  const gifts = React.useMemo((): Array<GiftType> => {
    const filteredData = data
      .map((item) => {
        return {
          name: item.productName,
          pictureID: item.productPictureID,
          amount: item.amount,
          giftCashItemID: item.giftCashItemID,
          useCustomPicture: item.useCustomPicture,
        };
      })
      .filter((prod) =>
        String(prod.name).toLowerCase().includes(searchValue.toLowerCase()),
      ) as GiftType[];

    return orderBy(filteredData, Object.keys(sort), Object.values(sort));
  }, [searchValue, data, sort]);

  React.useEffect(() => {
    setBannerURL(
      `${process.env.REACT_APP_RESOURCES_URL}/banking/giftcash/wedding/${
        wedding?.giftCashID
      }-banner?q=${Date.now()}`,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wedding?.giftCashID]);

  React.useLayoutEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }, []);

  return (
    <React.Fragment>
      <FadeIn>
        <Col flex={1}>
          <CheckoutHeader />
          <Col position="relative">
            <Box
              backgroundColor={colors.primary500}
              backgroundImage={`url(${bannerUrl})`}
              backgroundSize="cover"
              backgroundPosition="top"
              marginTop={80}
              height="calc(100vh - 80px)"
              minHeight={600}
              className="zoom-animation"
            />
            <Col
              position="absolute"
              top={0}
              left={0}
              right={0}
              bottom={0}
              justifyContent="center"
              alignItems="center"
            >
              <Box
                padding={2}
                borderRadius={50}
                backgroundColor="white"
                border={`1px solid ${colors.secondary500}`}
              >
                <CoupleThumbImage
                  size="lg"
                  giftCashID={wedding ? wedding.giftCashID : ''}
                />
              </Box>
              <Separator size="lg" />
              <BrideAndGroomNames
                name1={wedding?.personName1}
                name2={wedding?.personName2}
              />
            </Col>
          </Col>
          <Wave fill={colors.white}>
            <Col backgroundColor="white">
              <Container>
                <Col
                  width="100%"
                  borderRadius={5}
                  padding={spacingSizes.md}
                  paddingTop={0}
                  alignSelf="center"
                  justifyContent="center"
                  position="relative"
                >
                  <Separator />
                  <Col>
                    <Text
                      color={colors.black}
                      textAlign="center"
                      textTransform="uppercase"
                      size="xs"
                    >
                      Save the date
                    </Text>
                    <Separator size="xs" />
                    {loading ? (
                      <SkeletonPlaceholder>
                        <div
                          style={{
                            width: 200,
                            height: 30,
                            margin: '0 auto',
                            borderRadius: 4,
                          }}
                        />
                      </SkeletonPlaceholder>
                    ) : (
                      <WeddingDate
                        date={
                          wedding?.weddingDate
                            ? parseISO(wedding.weddingDate)
                            : undefined
                        }
                      />
                    )}

                    <Separator />
                    <Box alignSelf="center">
                      <Icon name="love-chat" />
                    </Box>
                    <Separator />
                    <Box
                      mediaQueries={mediaQueries}
                      display="flex"
                      width="100%"
                      justifyContent="center"
                      alignItems="center"
                      alignSelf="center"
                      //@ts-ignore
                      xlWidth={750}
                      lgWidth={750}
                    >
                      {loading ? (
                        <SkeletonPlaceholder>
                          <div
                            style={{
                              width: 450,
                              height: 18,
                              margin: '0 auto',
                              borderRadius: 4,
                            }}
                          />
                        </SkeletonPlaceholder>
                      ) : (
                        <Text color={colors.gray700} textAlign="justify">
                          {wedding?.welcomeMessage}
                        </Text>
                      )}
                    </Box>
                    <Separator />
                    <Divider />
                    <Separator />
                    <Col
                      mediaQueries={mediaQueries}
                      padding={`0 ${spacingSizes.md}px`}
                      //@ts-ignore
                      xlPadding={`0 ${spacingSizes.md}px`}
                      lgPadding={`0 ${spacingSizes.md}px`}
                    >
                      <Heading
                        size="sm"
                        textTransform="uppercase"
                        color={colors.secondary500}
                        textAlign="center"
                      >
                        Nossa lista de presente
                      </Heading>
                      <Separator />
                      <Row
                        display="flex"
                        mediaQueries={mediaQueries}
                        //@ts-ignore
                        xsFlexDirection="column"
                        smFlexDirection="column"
                      >
                        <Col flex={1}>
                          <SearchInput
                            value={searchValue}
                            onChange={setSearchValue}
                            placeholder="Pesquisar por presentes"
                            minimal
                          />
                        </Col>
                        <Separator />
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          mediaQueries={mediaQueries}
                          //@ts-ignore
                          xsFlexDirection="column"
                        >
                          <Text size="sm" color={colors.gray500}>
                            Ordenar por:
                          </Text>
                          <Separator size="sm" />
                          <Row>
                            <RadioInput
                              label="Menor preço"
                              value="asc"
                              checked={sort.amount === 'asc'}
                              onChange={() =>
                                setSort({ ...sort, amount: 'asc' })
                              }
                            />
                            <Separator size="sm" />
                            <RadioInput
                              label="Maior preço"
                              value="desc"
                              checked={sort.amount === 'desc'}
                              onChange={() =>
                                setSort({ ...sort, amount: 'desc' })
                              }
                            />
                          </Row>
                        </Box>
                      </Row>
                      <Separator size="lg" />
                      <Box
                        mediaQueries={mediaQueries}
                        display="grid"
                        gridTemplateColumns={`repeat(auto-fill, 100%)`}
                        width="100%"
                        gridGap="1.5rem"
                        //@ts-ignore
                        xlGridTemplateColumns={`repeat(auto-fill, minmax(300px, 1fr))`}
                        lgGridTemplateColumns={`repeat(auto-fill, minmax(300px, 1fr))`}
                      >
                        {loading
                          ? Array.from({ length: 6 }).map((_, index) => (
                              <GiftListItemPlaceholder key={String(index)} />
                            ))
                          : gifts?.map((gift) => {
                              return (
                                <GiftListItem
                                  key={gift.giftCashItemID}
                                  variant="showcase"
                                  gift={gift}
                                />
                              );
                            })}
                      </Box>
                      <Separator size="md" />
                      <Box width={300} margin="auto">
                        <Button
                          style={{ width: '100%' }}
                          color="secondary"
                          onClick={() => {
                            if (!cartItems.length) {
                              return;
                            }

                            history.push(
                              Paths.Platform.PERSONAL_DATA_SCREEN.replace(
                                ':contextID',
                                contextID!,
                              ),
                            );
                          }}
                        >
                          Finalizar compra
                        </Button>
                      </Box>
                      <Separator />
                      <Text color={colors.gray500} size="sm">
                        * As imagens dos presente são meramente ilustrativas,
                        todo o valor do presente comprado por você será
                        convertido em dinheiro direto na conta dos noivos.
                      </Text>
                      <Separator size="md" />
                    </Col>
                  </Col>
                  <Separator size="xl" />
                  <Col alignItems="center">
                    <Logo height={64} />
                    <Separator />
                    <Text color={colors.black} size="xs">
                      <b>1875 visitantes</b> já conferiram nossa história de
                      amor!
                    </Text>
                  </Col>
                </Col>
              </Container>
            </Col>
          </Wave>
        </Col>
      </FadeIn>
    </React.Fragment>
  );
};

export default BrideAndGroomScreen;

const GiftListItemPlaceholder = () => (
  <Col
    border={`1px solid ${colors.gray100}`}
    borderRadius={5}
    padding={spacingSizes.md}
  >
    <SkeletonPlaceholder>
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              width: 56,
              height: 56,
              borderRadius: 12,
            }}
          />
          <div style={{ marginLeft: 15 }}>
            <div
              style={{
                width: 200,
                height: 15,
                borderRadius: 4,
              }}
            />
            <div
              style={{
                width: 120,
                height: 15,
                borderRadius: 4,
                marginTop: 4,
              }}
            />
          </div>
        </div>
        <div
          style={{
            marginTop: 15,
            width: '100%',
            height: 32,
            borderRadius: 30,
          }}
        />
      </div>
    </SkeletonPlaceholder>
  </Col>
);
