import Card from 'components/Card';
import Clickable from 'components/Clickable';
import Form from 'components/Form';
import GiftForm from 'components/GiftForm';
import Icon from 'components/Icon';
import Modal from 'components/Modal';
import NumberFormat from 'components/NumberFormat';
import ProductImage from 'components/ProductImage';
import Separator from 'components/Separator';
import SkeletonPlaceholder from 'components/SkeletonPlaceholder';
import Spinner from 'components/Spinner';
import Text from 'components/Text';
import useAsync from 'hooks/useAsync';
import useForm from 'hooks/useForm';
import useMedia from 'hooks/useMedia';
import useToast from 'hooks/useToast';
import { Box, Col, Row } from 'jsxstyle';
import React from 'react';
import GiftBoxInput from 'screens/ChatScreen/GiftBoxInput';
import deleteWeddingItemService from 'services/admin/deleteWeddingItemService';
import getItemPurchasesCountService from 'services/admin/getItemPurchasesCountService';
import { WeddingItemType } from 'services/platform/getGiftListService';
import saveWeddingProductService from 'services/saveWeddingProductService';
import useSWR from 'swr';
import { colors, mediaQueries, spacingSizes } from 'theme';
import handleError from 'utils/handleError';

const GIFT_MIN_VALUE = 40;

export type AdminGiftListItemProps = {
  product: WeddingItemType;
  onDelete?: (item: WeddingItemType) => void;
  onUpdate?: (item: WeddingItemType) => void;
};

const AdminGiftListItem = ({
  product,
  onDelete,
  onUpdate,
}: AdminGiftListItemProps) => {
  const { dangerMessage } = useToast();
  const { media } = useMedia();

  const { getValue, setValue, submit, getError } = useForm({
    initialValues: {
      amount: product.amount,
      modalVisibility: 'hidden',
    },
    validations: {
      amount: [
        (value: any) => {
          if (value < GIFT_MIN_VALUE) {
            return `O valor mínimo permitido é de ${NumberFormat({
              type: 'currency',
              amount: GIFT_MIN_VALUE,
              minimumFractionDigits: 2,
            })}`;
          }
        },
      ],
    },
    onSubmit: ({ amount }) => {
      handleSubmit(amount);
    },
  });

  const { fetch, pending: deleting } = useAsync({
    promiseFn: deleteWeddingItemService,
    onData: () => onDelete?.(product),
    onError: (error) => {
      const handledError = handleError({ error });
      dangerMessage({ message: handledError.message });
    },
  });

  const { fetch: update, pending: updating } = useAsync({
    promiseFn: saveWeddingProductService,
    onData: (_, params) => {
      setValue('modalVisibility', 'hidden');
      setTimeout(() => {
        onUpdate?.({
          ...product,
          productName: params.name ?? product.productName,
          productPictureID: params.pictureID ?? product.productPictureID,
          amount: params.amount ?? product.amount,
          ...(params.useCustomPicture !== undefined
            ? {
                useCustomPicture: params.useCustomPicture,
              }
            : {}),
        });
      }, 0);
    },
    onError: (error) => {
      const handledError = handleError({ error });
      dangerMessage({ message: handledError.message });
    },
  });

  const { data, error } = useSWR(
    [
      '/purchaise-count',
      product.accountID,
      product.giftCashID,
      product.giftCashItemID,
    ],
    (key, accountID, giftCashID, giftCashItemID) =>
      getItemPurchasesCountService({
        accountID,
        giftCashID,
        giftCashItemID,
        status: 'PAID',
      }),
  );
  const pending = !data && !error;

  const handleDelete = () => {
    // eslint-disable-next-line no-restricted-globals
    const confirmed = confirm('Tem certeza que deseja remover esse item');
    if (confirmed) {
      fetch({
        accountID: product.accountID,
        giftCashID: product.giftCashID,
        giftCashItemID: product.giftCashItemID,
      });
    }
  };

  const handleSubmit = (amount = 0) => {
    const shouldUpdate = amount !== product.amount;
    if (shouldUpdate) {
      update({
        ...product,
        amount,
      });
    }
  };

  return (
    <React.Fragment>
      <Card
        padding={`${spacingSizes.sm}px ${spacingSizes.md}px`}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        position="relative"
        minHeight={60}
      >
        <Row alignItems="center" flexBasis={media === 'desktop' && '33%'}>
          <ProductImage
            giftCashItemID={`${product.giftCashItemID}?q=${Date.now()}`}
            pictureID={product.productPictureID}
            useCustomPicture={product.useCustomPicture}
            height={media === 'desktop' ? 64 : 42}
            width={media === 'desktop' ? 64 : 42}
          />
          <Separator size="sm" />
          <Col>
            <Text size="sm" color={colors.black}>
              {product.productName}
            </Text>
            <Form onSubmit={submit}>
              <GiftBoxInput
                onBlur={submit}
                activatedColor={colors.black}
                value={getValue('amount')}
                active
                onChange={(value) => setValue('amount', value)}
                minValue={0}
              />
            </Form>
            <Box position="absolute" bottom={4}>
              <Text size="sm" color={colors.red500}>
                {getError('amount')}
              </Text>
            </Box>
          </Col>
        </Row>
        {media === 'desktop' && (
          <Col flexBasis="33%">
            <Text color={colors.gray300} size="xs" textTransform="uppercase">
              Quantas já compraram
            </Text>
            {pending ? (
              <SkeletonPlaceholder>
                <div
                  style={{
                    height: 18,
                    width: 100,
                    borderRadius: 5,
                    marginTop: 2,
                  }}
                />
              </SkeletonPlaceholder>
            ) : (
              <Text fontWeight="bold">{data?.total} pessoas</Text>
            )}
          </Col>
        )}
        <Row>
          <Clickable
            width={32}
            height={32}
            border={`1px solid ${colors.gray200}`}
            backgroundColor="transparent"
            borderRadius={100}
            onClick={() => setValue('modalVisibility', 'visible')}
          >
            <Icon name="edit" color={colors.black} />
          </Clickable>
          <Separator size="sm" />
          <Clickable
            width={32}
            height={32}
            border={`1px solid ${colors.gray200}`}
            backgroundColor="transparent"
            borderRadius={100}
            onClick={handleDelete}
          >
            {deleting ? (
              <Spinner color={colors.black} />
            ) : (
              <Icon name="delete" />
            )}
          </Clickable>
        </Row>
      </Card>
      <Modal
        visible={getValue('modalVisibility') === 'visible'}
        onRequestClose={() => setValue('modalVisibility', 'hidden')}
      >
        <Box
          mediaQueries={mediaQueries}
          //@ts-ignore
          xlWidth={768}
          lgWidth={768}
          lgHeight="auto"
          xlHeight="auto"
        >
          <GiftForm
            name={product.productName}
            productID={product.productID}
            pictureID={product.productPictureID}
            giftCashItemID={`${product.giftCashItemID}?q=${Date.now()}`}
            useCustomPicture={product.useCustomPicture}
            value={product.amount}
            loading={updating}
            onSubmit={({
              name,
              pictureID,
              value,
              customImage,
              useCustomPicture,
            }) => {
              update({
                accountID: product.accountID,
                giftCashID: product.giftCashID,
                giftCashItemID: product.giftCashItemID,
                amount: value,
                name,
                pictureID,
                customImage: customImage?.file,
                useCustomPicture,
              });
            }}
          />
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default AdminGiftListItem;
