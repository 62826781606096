import Container from 'components/Container';
import FadeIn from 'components/FadeIn';
import Separator from 'components/Separator';
import { Box, Col } from 'jsxstyle';
import React from 'react';
import { colors, mediaQueries } from 'theme';
import CheckoutHeader from './CheckoutHeader';

export type CheckoutScreenLayoutProps = {};

const CheckoutScreenLayout = ({
  children,
}: React.PropsWithChildren<CheckoutScreenLayoutProps>) => {
  return (
    <FadeIn>
      <Col flex={1} backgroundColor={colors.gray50}>
        <CheckoutHeader />
        <Col flex={1} marginTop={80}>
          <Separator />
          <Container>
            <Box
              mediaQueries={mediaQueries}
              display="flex"
              flexDirection="column"
              flex={1}
              //@ts-ignore
              xlMaxWidth="40vw"
              lgMaxWidth="45vw"
            >
              <Col flex={1}>{children}</Col>
              <Box
                mediaQueries={mediaQueries}
                display="block"
                //@ts-ignore
                xlDisplay="none"
                lgDisplay="none"
              >
                <Separator />
              </Box>
            </Box>
          </Container>
        </Col>
      </Col>
    </FadeIn>
  );
};

export default CheckoutScreenLayout;
