import IconButton from 'components/admin/IconButton';
import Form from 'components/Form';
import Icon from 'components/Icon';
import ToastMessage from 'components/ToastMessage';
import WeddingDate from 'components/WeddingDate';
import { useApplication } from 'contexts/ApplicationContext';
import { differenceInDays, format, isValid, parse, parseISO } from 'date-fns';
import useMedia from 'hooks/useMedia';
import { Box } from 'jsxstyle';
import React from 'react';
import { colors, spacingSizes } from 'theme';
import toaster from 'toasted-notes';

export type DateInputProps = {
  onSubmit: (weddingDate: string) => void;
};

const DateInput = ({ onSubmit }: DateInputProps) => {
  const { wedding } = useApplication();
  const { media } = useMedia();
  const [editing, setEditing] = React.useState(false);
  const [localDate, setLocalDate] = React.useState('');

  const parseWeddingDate = () => {
    setLocalDate(format(parseISO(wedding.weddingDate), 'dd.MM.yyyy'));
  };

  React.useEffect(() => {
    if (wedding.weddingDate) {
      parseWeddingDate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wedding.weddingDate]);

  const handleSubmit = () => {
    let parsedDate;
    if (isValid(parse(localDate, 'dd.MM.yyyy', new Date()))) {
      parsedDate = parse(localDate, 'dd.MM.yyyy', new Date());
    } else {
      parseWeddingDate();
      return toaster.notify(({ onClose }) => (
        <ToastMessage
          onClick={onClose}
          icon={
            <Icon name="info" width={18} height={16} color={colors.red500} />
          }
          message="A data inserida não é válida. Por favor tente novamente."
          type="danger"
        />
      ));
    }

    if (differenceInDays(parsedDate, parseISO(wedding.weddingDate)) !== 0) {
      setEditing(false);
      onSubmit(format(parsedDate, 'yyyy-MM-dd'));
    }
  };

  return (
    <Box display="flex" alignSelf="center" position="relative">
      <Box
        padding={`${spacingSizes.xs}px ${spacingSizes.md}px`}
        backgroundColor={colors.gray50}
        borderRadius={5}
        minWidth={194}
        minHeight={44}
        props={{
          onClick: () => {
            setEditing(true);
          },
        }}
      >
        {editing ? (
          <Form onSubmit={handleSubmit}>
            <Box
              props={{
                autoFocus: true,
                value: localDate,
                onChange: (e: any) => setLocalDate(e.target.value),
                onBlur: handleSubmit,
              }}
              component="input"
              color={colors.secondary500}
              fontWeight="bold"
              backgroundColor="transparent"
              border="none"
              outline="none"
              maxWidth={162}
              fontSize={
                {
                  desktop: '2em',
                  tablet: '1.9em',
                  phone: '1.8em',
                }[media]
              }
            />
          </Form>
        ) : (
          <WeddingDate
            date={
              localDate ? parse(localDate, 'dd.MM.yyyy', new Date()) : undefined
            }
          />
        )}
      </Box>
      <Box position="absolute" right={-16} top={-16}>
        <IconButton onClick={() => setEditing(!editing)}>
          <Icon name={editing ? 'close' : 'edit'} color={colors.black} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default DateInput;
